import React from "react";
import DialogButton from './dialogButton';

const OkDialog = ({ title, message, onOK, onClose}) => {
  return (
    <DialogButton title={title} onOK={onOK} onClose={onClose}>
      <div className="padding1em3em">{message}</div>
    </DialogButton>
  );
};

export default OkDialog;