import React from "react";
import { FormControlLabel, Checkbox } from '@mui/material';

const LabelCheckbox = ({label, title, disabled, checked, onClick, labelPlacement}) => {
  return <FormControlLabel
    label={label}
    title={title}
    onClick={(e) => e.stopPropagation()}
    control={<Checkbox
      sx={{padding: 0}}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
      />}
    labelPlacement={labelPlacement ? labelPlacement : 'end'}
  />;
};
export default LabelCheckbox;