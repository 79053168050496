import create from 'zustand';
import { data, backend, syncEngine, } from '../sync-engine';
import { prefID }   from "../sync-engine/modules/Prefs";
import { initAppStore, setSelected, setIconsFromPrefs, showError, fogAndCall } from './appStorage';
import { resetLV, setLvDataFromPrefs } from './lvStorage';
import Utils from '../Utils';

const NO_WSP_IMG  = '/images/logo_default.jpg';
const NO_WSP_LOGO = '/favicon.svg';
const getLogoPath = (wsId, connectionFail) => (wsId && !connectionFail) 
  ? backend.getWorkspaceUrl()+'/logo/logo_svg?salt='+new Date().getTime() 
  : NO_WSP_LOGO
const getImagePath = (wsId, connectionFail) => (wsId && !connectionFail) 
  ? backend.getWorkspaceUrl()+'/logo/logo_jpg?salt='+new Date().getTime() 
  : NO_WSP_IMG

const dropSelected = () => {
  setSelected('', null);
};

const restoreWSCachedState = () =>     workspaceApi.getState().restoreWSCachedState();
const loadWorkspaces = () =>         workspaceApi.getState().loadWorkspaces();
const loadWorkspaceInfo = (workspaceId) => workspaceApi.getState().loadWorkspaceInfo(workspaceId);
const loadLoginInfo = () =>          workspaceApi.getState().loadLoginInfo();
const setLogin = (login) => workspaceApi.getState().setLogin(login);
const setPassword = (password) => workspaceApi.getState().setPassword(password);
const doLogin = () => workspaceApi.getState().doLogin();
const doLogout = () =>               workspaceApi.getState().doLogout();
const removeWorkspaceInfo = () =>    workspaceApi.getState().removeWorkspaceInfo();
const saveLogo = (file, ext) =>           workspaceApi.getState().saveLogo(file, ext);
const setHistoryPeriod = (period) => workspaceApi.getState().setHistoryPeriod(period);
const getHistoryPeriod = () =>       workspaceApi.getState().historyPeriod;
const setLastNDays = (nDays) =>      workspaceApi.getState().setLastNDays(nDays);
const getLastNDays = () =>           workspaceApi.getState().lastNDays;
const setFromDate = (date) =>        workspaceApi.getState().setFromDate(date);
const getFromDate = () =>            workspaceApi.getState().fromDate;
const setToDate = (date) =>          workspaceApi.getState().setToDate(date);
const getToDate = () =>              workspaceApi.getState().toDate;
const updateTimeFilter = () =>       workspaceApi.getState().updateTimeFilter();
const setTFilterTimer = (timer) =>   workspaceApi.getState().setTFilterTimer(timer);
const getTFilterTimer = () =>        workspaceApi.getState().tFilterTimer;

const [useWorkspaceStore, workspaceApi] = create((set, get) => ({
  connectionFail: false,
  workspaces: null,
  workspaceId: null,
  login : '', 
  password: '',
  wsInfo: null,
  wsImage: NO_WSP_IMG,
  wsLogo: NO_WSP_LOGO,
  loginInfo: null,
  historyPeriod: localStorage.getItem('historyPeriod') || 'today',
  lastNDays:     localStorage.getItem('lastNDays') || 1,
  fromDate:      localStorage.getItem('fromDate') || null,
  toDate:        localStorage.getItem('toDate') || null,
  historyDepthStr: 'today',
  nextTFilterTime : null, // next time of TimeFilter update
  tFilterTimer: null,

  initLocalStorageData: (login) => {
    const prevUserLogin = localStorage.getItem('userLogin');
    const lastLogInWspId = localStorage.getItem('lastLogInWspId');
    const workspaceId = localStorage.getItem('wspId');
    const connectionFail = get().connectionFail;
    if (login != prevUserLogin || workspaceId != lastLogInWspId) {
      const wsImage     = localStorage.getItem('wspImage');
  
      localStorage.clear();

      initAppStore();
  
      localStorage.setItem('wspId', workspaceId);
      localStorage.setItem('wspImage', wsImage);
      localStorage.setItem('userLogin', login);
      set({
        workspaceId: workspaceId,
        wsImage: connectionFail ? NO_WSP_IMG : wsImage,
        wsLogo : getLogoPath(workspaceId, connectionFail),
        historyPeriod: 'today',
        lastNDays:      1,
        fromDate:       null,
        toDate:         null,
        historyDepthStr: 'today',
      });
    }
  },

  restoreWSCachedState: () => {
    console.debug("restoreWSCachedState")

    const workspaceId = localStorage.getItem('wspId');
    const wsImage     = localStorage.getItem('wspImage');
    const connectionFail = get().connectionFail;

    set({
      workspaceId: workspaceId,
      wsImage: (!connectionFail && wsImage) ? wsImage : NO_WSP_IMG,
      wsLogo : getLogoPath(workspaceId, connectionFail),
    });
  },

  loadWorkspaces: async () => { 
    console.debug("loadWorkspaces")

    try {
      const response = await fetch( backend.API_URL, {method: 'GET'} );
      // Parse data
      const resp = await response.json();

      if(response.status!==200){
        if(resp.message) throw data.message;
        throw  'response.status: '+resp.status;
      }
      set({workspaces: resp?.workspaces});
    } catch(err){
      console.log("loadWorkspaces failed ", err);
      showError('No workspaces available');
      set({
        connectionFail: true,
        wsImage: NO_WSP_IMG,
        wsLogo: NO_WSP_LOGO
      });
    }
  },  // loadWorkspaces

  loadPrefsData: async () => {
    const params = {
      prop: 'pref',
      mt: 0,
      t1: Utils.startOfThisDay().getTime()  // any time before now
    };

    try {
      const data = await backend.request('POST', '/prefs/load_prefs', params);

      const results = data?.results;
      if (results) {
        const lvPrefs =    results.find(p => p.id == prefID.live_view);
        const iconsPrefs = results.find(p => p.id == prefID.app_icons);
        setLvDataFromPrefs(lvPrefs);
        setIconsFromPrefs(iconsPrefs);
      }
    }
    catch(err) {
      console.log('load_prefs failed ' + err);
      // don't showError, it's OK to have no prefs here
    }
  },

  loadWorkspaceInfo: async (workspaceId) => {
    console.debug("loadWorkspaceInfo")

    const wspId = workspaceId ? workspaceId : localStorage.getItem('wspId');
    if (wspId) {
      try{
        // send request
        backend.workspace = wspId;
        const wsInfo = await backend.request('GET', '/');
        const wsImage = backend.API_URL + '/' + wspId + '/logo/logo_jpg';

        // Sav the Workspace
        localStorage.setItem('wspId', wsInfo.id);
        localStorage.setItem('wspImage', wsImage);

        const connectionFail = get().connectionFail;

        // SET STATE
        set({
          workspaceId: wspId,
          wsInfo: wsInfo,
          wsImage: connectionFail ? NO_WSP_IMG : wsImage,
          wsLogo: getLogoPath(wspId, connectionFail)
        });
      }catch(err){
          console.log("loadWorkspaceInfo failed "+err);
          showError(`workspace ${wspId} is not available`);
          return;
      }

      resetLV();
      await get().loadPrefsData();
    }
  }, // loadWorkspaceInfo

  loadLoginInfo: async () => {
    console.debug("loadLoginInfo")

    try{
      // send request
      const loginInfo = await backend.request('GET','/auth/info', null);
      get().initLoggedInMode(loginInfo);
      set({loginInfo: loginInfo});
    }catch(err){
      console.log("loadLoginInfo failed "+err);
      set({loginInfo: null});
      get().removeLoginInfo();
    }
  }, // loadLoginInfo

  setLogin: (login) => set({login: login}),
  setPassword: (password) => set({password: password}),

  doLogin: async () => {
    console.debug("doLogin");

    const login = get().login;
    const password = get().password;

    try{
      // send request
      const loginInfo = await backend.request('POST','/auth/login', {login:login, password:password} );
      get().initLocalStorageData(login);
      get().initLoggedInMode(loginInfo);
      const workspaceId = localStorage.getItem('wspId');
      localStorage.setItem('lastLogInWspId', workspaceId);
      fogAndCall(() => set({loginInfo: loginInfo}));
    }catch(err){
      showError(`Fail to login: ${err}`);
      set({loginInfo: null});
      get().removeLoginInfo();
    }
  }, // doLogin

  doLogout: async () => {
    console.debug("doLogout")

    try{
      // send request
      await backend.request('GET','/auth/logout',null);
      get().removeLoginInfo();
      fogAndCall(() => set({loginInfo: null, login: '', password: ''}));
    }catch(err){
      showError(`Fail to logout: ${err}`);
      // removeLoginInfo ???
    }
  }, // doLogout

  initLoggedInMode: (loginInfo) =>{
    const timeRange = get().timeRange();
    set({ historyDepthStr: timeRange.str});

    // Init data
    data.init(loginInfo.modules, timeRange);
    // Auto start SyncEngine
    syncEngine.start();
  }, // initLoggedInMode

  removeWorkspaceInfo: () => {
    console.debug("removeWorkspaceInfo")

    localStorage.removeItem('wspId');
    localStorage.removeItem('wspImage');
    set({
      workspaceId: null,
      wsInfo: null,
      wsImage: NO_WSP_IMG,
      wsLogo: getLogoPath(null, true)
    });
    resetLV();
  }, // removeWorkspaceInfo

  removeLoginInfo: () => {
    console.debug("removeLoginInfo")

    // Auto start SyncEngine
    syncEngine.stop();
    data.clear();
    // Desctroy data
    data.destroy();
  }, // removeLoginInfo

  saveLogo: async (file, ext) => {
    if (!file) {
      showError('The file is not choosen');
      return;
    }
    if (ext != 'jpg' && ext != 'svg') {
      showError('Unsupported file');
      return;
    }

    try {
      await backend.sendFILE(`/logo/logo_${ext}`,file);
      const connectionFail = get().connectionFail;
      if (ext == 'jpg') {
        set({wsImage: getImagePath(true, connectionFail)});
      } else {
        set({wsLogo: getLogoPath(true, connectionFail)});
      }
    } catch (err) {
      console.error(err);
      showError(`Fail to save logo: ${err}`);
    }
    syncEngine.syncNow();
  },

  setHistoryPeriod: (period) => {
    set({ historyPeriod: period })
    localStorage.setItem('historyPeriod', period);
  },

  setLastNDays: (nDays) => {
    set({ lastNDays: nDays })
    localStorage.setItem('lastNDays', nDays);
  },

  setFromDate: (date) => {
    set({ fromDate: date })
    localStorage.setItem('fromDate', date);
  },

  setToDate: (date) => {
    set({ toDate: date })
    localStorage.setItem('toDate', date);
  },

  todayTimeRange: () => {
    set({ nextTFilterTime: Utils.startOfNextDay() });
    return {t1: Utils.startOfThisDay().getTime(), t2: null, str: 'today'};
  },

  timeRange: () => {
    const historyPeriod = get().historyPeriod;
    
    switch (historyPeriod) {  // TODO: use Utils.timeFilterData()
      case 'today' :
        return get().todayTimeRange();
      case 'this week' :
        set({ nextTFilterTime: Utils.startOfNextWeek() });
        return {t1: Utils.startOfThisWeek().getTime(), t2: null, str: 'this week'};
      case 'this month' :
        set({ nextTFilterTime: Utils.startOfNextMonth() });
        return {t1: Utils.startOfThisMonth().getTime(), t2: null, str: 'this month'};
      case 'last N days' :
        set({ nextTFilterTime: Utils.startOfNextDay() });
        const nDays = get().lastNDays;
        return {t1: Utils.startOfNDays(nDays).getTime(), t2: null, 
          str: `last ${nDays} ` + (nDays == 1 ? ' day' : ' days')};
      case 'from' :
        const fromDate = get().fromDate;
        if (Utils.invalidTimeStamp(fromDate)) {
          console.error(`=== Wrong fromDate value:`, fromDate);
          get().setHistoryPeriod('today');
          return get().todayTimeRange();
        }
        set({ nextTFilterTime: null });
        return {t1: Number(fromDate) , t2: null, 
          str: 'from ' + Utils.formatDate(Number(fromDate))};
      case 'from to' : {
        const fromDate = get().fromDate;
        if (Utils.invalidTimeStamp(fromDate)) {
          console.error(`=== Wrong fromDate value:`, fromDate);
          get().setHistoryPeriod('today');
          return get().todayTimeRange();
        }
        const toDate = get().toDate;
        if (Utils.invalidTimeStamp(toDate)) {
          console.error(`=== Wrong toDate value:`, toDate);
          get().setHistoryPeriod('today');
          return get().todayTimeRange();
        }
        set({ nextTFilterTime: null });
        return {t1: Number(fromDate) ,t2: Number(toDate), 
          str: Utils.formatDate(Number(fromDate)) + ' - ' + Utils.formatDate(Number(toDate))};
      }
      default : {
        console.error(`=== Wrong historyPeriod value:`, historyPeriod);
        get().setHistoryPeriod('today');
        return get().todayTimeRange();
      }
    }
  },

  updateTimeFilter: () => {
    const timeRange = get().timeRange();
    try{
      syncEngine.stop();
      data.clear();
      data.setTimeRange(timeRange.t1, timeRange.t2);
      syncEngine.start();
      set({ historyDepthStr: timeRange.str});
      dropSelected();
    }catch(e){
      showError(`Fail to change period: ${e}`);
    }
  },

  setTFilterTimer: (timer) => set({ tFilterTimer: timer }),
}))

export { 
  useWorkspaceStore,
  restoreWSCachedState,
  loadWorkspaces,
  loadWorkspaceInfo,
  loadLoginInfo,
  setLogin,
  setPassword,
  doLogin,
  doLogout,
  removeWorkspaceInfo,
  saveLogo,
  setHistoryPeriod,
  getHistoryPeriod,
  setLastNDays,
  getLastNDays,
  setFromDate,
  getFromDate,
  setToDate,
  getToDate,
  updateTimeFilter,
  setTFilterTimer,
  getTFilterTimer
};