import React, { useState } from 'react';
import { styled } from '@mui/styles';
import { TextField, Slider } from '@mui/material';
import DialogButton from './dialogButton';

const OpacitySlider = styled(Slider)({
  width: 198, // alpha_mask.png width
  height: 4,
  borderRadius: 0,
  background: ({bg}) => `${bg} url(/images/alpha_mask.png) 100% 100% no-repeat`,
  '& .MuiSlider-thumb': {
    borderRadius: 0,
    width: 5,
    height: 23,
    border: 'solid grey 1px',
    color: 'white'
  },
  '& .MuiSlider-track': {
    display: 'none'
  },
  '& .MuiSlider-rail': {
    display: 'none'
  },
});

const OpacityDialog = ({title, val, color, onSave, onClose}) => {
  const [opacity, setOpacity] = useState(val);

  const onInputOpacity = (e) => {
    if (isNaN(e.target.value) || e.target.value > 1 || e.target.value < 0) return;
    if (e.target.value.length == 0) {
      setOpacity(0);
      return;
    }
    setOpacity(e.target.value);
  };

  const onInputSlider = (e, val) => {
    setOpacity(val/100);
  };

  const saveAndClose = () => {
    onSave(opacity)
    onClose();
  };

  const sliderVal = Math.round(opacity*100);

  return <DialogButton title={title} okName={'Save'}
      onOK={saveAndClose} onClose={onClose}>

    <div className='flexAlignCenter'>
      <div className='opacityDlgText'>
        <TextField variant="outlined" size="small" label="Opacity"
          inputProps={{ maxLength: 4 }} value={opacity} onChange={onInputOpacity}/>
      </div>
      <OpacitySlider value={sliderVal} onChange={onInputSlider} bg={color}/>
    </div>
  </DialogButton>;
};

export default OpacityDialog;