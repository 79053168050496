import React, { useState } from 'react';
import { TextField } from '@mui/material';
import DialogButton from './dialogButton';

const NameDialog = ({title, onSave, onClose}) => {
  const [name, setName] = useState('');

  const onInputName = (e) => {
    setName(e.target.value);
  };

  const saveAndClose = () => {
    onSave(name)
    onClose();
  };

  return <DialogButton title={title} okName={'Save'} 
    okDisabled={!name} onOK={saveAndClose} onClose={onClose}>
    <TextField
        variant="outlined"
        label="Name"
        value={name}
        onChange={onInputName}
      />
    </DialogButton>;
};

export default NameDialog;