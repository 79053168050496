import React, { useEffect, useMemo } from "react";
import { CircularProgress } from '@mui/material';
import { useSyncEngStore} from '../sync-engine';
import { useAppStore, setPatrolIdForPoints, showPageOnMap } from "../storages/appStorage";
import { usePatrolsStore, initPatrols, setAllCheckedIds, showRemoved } from '../storages/patrolsStorage';
import PatrolsTable from '../components/patrols/patrolsTable';
import LabelCheckbox from "../components/labelCheckbox";
import { AllIcon, SwapIcon } from '../components/common';
import Utils from '../Utils';

const PatrolsView = () => {
  const patrolsObjects = useSyncEngStore(state => state.patrolsObjects, () => false);
  const pagesOnMap = useAppStore(state => state.pagesOnMap);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const checkedIds = usePatrolsStore(state => state.checkedIds);
  const removedShown = usePatrolsStore(state => state.removedShown);

  useEffect(() => {
    initPatrols();
    return () => setPatrolIdForPoints(null);
  }, []);

  const patrolsLoaded = useMemo(() => {
    return loadedMdProps.has('patrol');
  }, [loadedMdProps]);

  const checkAll = () => {
    if (patrolsObjects) {
      const allIds = Object.keys(patrolsObjects);
      setAllCheckedIds(allIds);
    }
  };

  const invertSelection = () => {
    if (patrolsObjects) {
      const allIds = Object.keys(patrolsObjects);
      const unchecked = allIds.filter(id => !checkedIds.has(id));
      setAllCheckedIds(unchecked);
    }
  };

  return (<>
    <div className='rightPanHeader btnsHeader'>
      <div>
        <LabelCheckbox
          label='removed'
          title='Show/Hide removed Patrols'
          checked={removedShown}
          onClick={(e) => showRemoved(e.target.checked)}/>
        <LabelCheckbox
          label='Show on Map'
          title='Show/Hide Patrols on Map'
          checked={pagesOnMap.has('patrols')}
          onClick={(e) => showPageOnMap('patrols', e.target.checked)}/>
      </div>
      {patrolsLoaded && <div>
        <AllIcon
          titleAccess='Select All Patrols'
          onClick={Utils.debouncedPress(checkAll)}/>
        <SwapIcon
          titleAccess='Invert Selection'
          onClick={Utils.debouncedPress(invertSelection)}/>
      </div>}
    </div>
    {patrolsLoaded 
      ? <PatrolsTable/>
      : <div className="circularProgress"><CircularProgress/></div>}
    </>);
}

export default PatrolsView;