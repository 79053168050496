import React, { useMemo, useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useLvStore, showPageOnMapWd } from "../../../../storages/lvStorage";
import DialogButton from '../../../dialog/dialogButton';
import TimeSelector from '../../../timeSelector';
import { ButtonGreen } from '../../../common';
import Utils from '../../../../Utils';

const sensorsKey = 'Sensors Widget';

const SensorsSettings = ({onClose}) => {
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const sensorsTimeFilter = useLvStore(state => state.sensorsTimeFilter);

  const [checked, setChecked] = useState(pagesOnMapWd.has(sensorsKey));
  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);

  const saveAndClose = () => {
    showPageOnMapWd(sensorsKey, checked);
    onClose();
  };

  const getTfData = () => {
    return Utils.timeFilterData(sensorsTimeFilter);
  }
  const {label} = useMemo(getTfData, [sensorsTimeFilter]);

  return <>
    {timeSelectorVisible && <TimeSelector 
      onClose={()=>setTimeSelectorVisible(false)} 
      wdKey={sensorsKey} tfObj={sensorsTimeFilter}/>}
    <DialogButton title={'Sensors Widget Settings'} okName={'Save'}
        onOK={saveAndClose} onClose={onClose}>
      <div className='flexColumn'>
        <FormControlLabel label={'Show on Map'} control={
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />}/>
        history depth<ButtonGreen onClick={()=>setTimeSelectorVisible(true)}>{label}</ButtonGreen>
      </div>
    </DialogButton>
  </>;
};

export default SensorsSettings;