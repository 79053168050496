import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { appVersion } from '../storages/appStorage';

const NotFoundView = () => {
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setWaiting(false), 4000);
    return () => clearTimeout(timer);
  }, []);

  return <div className="fullHeight">
    {waiting ? <div className="circularProgress"><CircularProgress/></div> :
    <><center><h1>404 Not Found</h1></center>
    <center>{`${appVersion} ${window.location.origin}`}</center></>}
  </div>;
}

export default NotFoundView;
