import React, { useState, } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CategorySelect = ({onCategoryChange}) => {

  const [category, setCategory] = useState('acidity');

  const categoryChange = (e) => {
    const category = e.target.value;
    onCategoryChange(category);
    setCategory(category);
  };

  const selectItems = [
    <MenuItem key={1} value={'acidity'}>{'acidity'}</MenuItem>,
    <MenuItem key={2} value={'humidity'}>{'humidity'}</MenuItem>,
    <MenuItem key={3} value={'temperature'}>{'temperature'}</MenuItem>,
    <MenuItem key={4} value={'aslan'}>{'aslan'}</MenuItem>,
    <MenuItem key={5} value={'digital'}>{'digital'}</MenuItem>,
    <MenuItem key={6} value={'sensorbee'}>{'sensorbee'}</MenuItem>,
  ]

  return (
    <FormControl variant='outlined' fullWidth={true} margin={'dense'} size={'small'}>
      <InputLabel id='select-category-label'>Category</InputLabel>
      <Select
        labelId='select-category-label'
        value={category}
        onChange={categoryChange}
        label='Category'
      >
        {selectItems}
      </Select>
    </FormControl>
  );
}

export default CategorySelect;