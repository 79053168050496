import React from "react";
import Chart from '../chart/chart';

const SensorGraph = ({data, onCancel}) => {
  const title = [];
  const lines = [];
  data.types.forEach((type, ind) => {
    title.push(<div key={type} style={{ padding: '0 5px' }}>{type}</div>);
    lines.push({name: type, ind: ind});
  });

  return <Chart 
    title={title} 
    data={data.arr}
    linesAtr={lines}
    onCancel={onCancel}/>;
}

export default SensorGraph;