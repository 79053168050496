import React, { useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import LoginView    from './views/LoginView';
import WorkView     from './views/WorkView';
import OkDialog from "./components/dialog/okDialog";
import ErrorDialog from "./components/dialog/errorDialog";
import FogInOut from './components/fogInOut';
import { escHandlers, useAppStore, showOkDialog, showError } from "./storages/appStorage";
import { useWorkspaceStore } from './storages/workspaceStorage';
import './layout.css';
import './App.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

const App = () => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const wsInfo = useWorkspaceStore(state => state.wsInfo);
  const loginInfo = useWorkspaceStore(state => state.loginInfo);
  const connectionFail = useWorkspaceStore(state => state.connectionFail);
  const appInitialized = useAppStore(state => state.appInitialized);
  const okMessage = useAppStore(state => state.okMessage);
  const errorMessage = useAppStore(state => state.errorMessage);

  const keydownListener = event => {
    if (event.code === 'Escape') {
      event.preventDefault();
      const handlers = Array.from(escHandlers, ([key, fn]) => ({ key, fn }));
      const handler = handlers.pop();
      if (handler) {
        handler.fn();
        escHandlers.delete(handler.key);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, []);

  const onOkDialog = () => {
    const onOK = okMessage.onOK;
    showOkDialog(null);
    if (onOK) onOK();
  };

  return <div className="fullHeight">
    {appInitialized 
      ? (!connectionFail && workspaceId && wsInfo && loginInfo) 
        ? <WorkView/>
        : <LoginView/>
      : <div className="circularProgress"><CircularProgress/></div>}
    {okMessage && 
      <OkDialog 
        title={okMessage.title} 
        message={okMessage.message} 
        onOK={onOkDialog} onClose={()=>showOkDialog(null)}/>}
    {errorMessage && 
      <ErrorDialog message={errorMessage} onClose={()=>showError(null)}/>}
    <FogInOut/>
  </div>;
}

export default App;
