import React, { useMemo, useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { Button, IconButton, Accordion, AccordionSummary, AccordionDetails, 
  CircularProgress} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { backend, useSyncEngStore, objectsSnap } from "../../sync-engine";
import { prefID } from "../../sync-engine/modules/Prefs";
import { appVersion, escHandlers, useAppStore, setShowSettings, savePhone, saveMapCenter, setMapCenter, 
  setIconsFromPrefs, setAppIcon } from '../../storages/appStorage';
import { useMapStore, cleanUpMapState, setMapPopup } from '../../storages/mapStorage';
import { useWorkspaceStore, saveLogo } from '../../storages/workspaceStorage';
import RolesTable from "./rolesTable";
import IconRow from "./iconRow";
import Image from '../image';
import { TitleWithClose, Dialog, StringDialog, FileDialog } from "../dialog";
import { PointIcon } from '../common';
import Utils from "../../Utils";

const SENSORS_ICON =          'Sensors icon';
const SENSORS_SELECTED_ICON = 'Sensors selected icon';
const USERS_ICON =            'Users icon';
const USERS_SELECTED_ICON =   'Users selected icon';
const CAMERAS_ICON =            'Cameras icon';
const CAMERAS_SELECTED_ICON =   'Cameras selected icon';

const IconButtonSt = styled(IconButton)({
  marginLeft: '5px',
  backgroundColor: ({active}) => active ? '#FFFACD' : 'transparent',
  border: ({active}) => active ? 'solid #FFA07A 1px' : 'solid white 1px',
  '&:hover': {
    backgroundColor: ({active}) => active ? '#FFFACD' : 'rgba(0,0,0,0.1)'}
});

const Settings = () => {
  const map = useMapStore(state => state.map);
  const prefsObjects = useSyncEngStore(state => state.prefsObjects, () => false);
  const prefsChanges = useSyncEngStore(state => state.prefsChanges);
  const mapPopup = useMapStore(state => state.mapPopup);
  const wsLogo  = useWorkspaceStore(state => state.wsLogo);
  const wsImage = useWorkspaceStore(state => state.wsImage);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const sensorIcon = useAppStore(state => state.sensorIcon);
  const sensorSelIcon = useAppStore(state => state.sensorSelIcon);
  const userIcon = useAppStore(state => state.userIcon);
  const userSelIcon = useAppStore(state => state.userSelIcon);
  const cameraIcon = useAppStore(state => state.cameraIcon);
  const cameraSelIcon = useAppStore(state => state.cameraSelIcon);

  const [phoneEditor,   showPhoneEditor]  = useState(false);
  const [centerEditor,  showCenterEditor] = useState(false);
  const [svgEditor,     showSvgEditor]    = useState(false);
  const [jpgEditor,     showJpgEditor]    = useState(false);
  const [changeLog,     showChangeLog]    = useState(false);
  const [iconDlgTitle,  setIconDlgTitle]  = useState('');

  const iconeDialog = Boolean(iconDlgTitle);

  const deInit = () => {
    cleanUpMapState();
    escHandlers.delete('Settings');
  } 

  useEffect(() => {
    escHandlers.set('Settings', () => setShowSettings(false));
    return deInit; // on page exit
  }, []);

  const prefsLoaded = useMemo(() => {
    return loadedMdProps.has('pref');
  }, [loadedMdProps]);

  const onOkBtn = () => {
    saveMapCenter();
    cleanUpMapState();
  };

  const pickCenterOnMap = () => {
    if (mapPopup) {
      cleanUpMapState();
      return;
    }

    map.setOptions({draggableCursor: 'crosshair'});

    const changeCenterPopup = new window.google.maps.InfoWindow({
      content: 
        `<div>
          <h1>Map Center</h1>
          <div>
            <p>Set map default center here?</p>
            <button id='popupOkBtnId'>OK</button>
          </div>
        </div>`
    });

    setMapPopup(changeCenterPopup);

    // on popup shown
    new window.google.maps.event.addListenerOnce(changeCenterPopup, 'domready', function(){
      const okBtn = document.getElementById("popupOkBtnId");
      if (okBtn) {
        okBtn.onclick = onOkBtn;
      }
    });

    // on popup close
    new window.google.maps.event.addListenerOnce(changeCenterPopup, 'closeclick', function(){
      cleanUpMapState();
    });

    window.google.maps.event.addListener(map, 'click', function(event) {
      if (event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        setMapCenter({lat, lng});  // remember potential new center coords

        changeCenterPopup.setPosition(event.latLng); 
        changeCenterPopup.open(map);
      }
    });
  }

  const saveSvg = (file) => saveLogo(file, 'svg');
  const saveJpg = (file) => saveLogo(file, 'jpg');

  const saveMarkerIcon = (file) => {
    const {module, selected} = getAppIconData();
    setAppIcon(file, module, selected)
  };

  const setDefault = (module, selected) => {
    setAppIcon(null, module, selected);
  };

  const getAppIconData = () => {
    switch (iconDlgTitle) {
      case SENSORS_ICON:          return {module: 'sensors', selected: false};
      case SENSORS_SELECTED_ICON: return {module: 'sensors', selected: true};
      case USERS_ICON:            return {module: 'users', selected: false};
      case USERS_SELECTED_ICON:   return {module: 'users', selected: true};
      case CAMERAS_ICON:          return {module: 'cameras', selected: false};
      case CAMERAS_SELECTED_ICON: return {module: 'cameras', selected: true};
      default: return {module: '', selected: false};
    }
  };

  const phone   = prefsObjects ? (prefsObjects[prefID.phone_oc]?.value) : null;
  const center  = prefsObjects ? (prefsObjects[prefID.map_default]?.value?.center) : null;
  const centerTxt = useMemo(() => Utils.formatLatLng(center, 'not set'), 
    [center]);

  const changeLogUrl = window.location.origin + '/changeLog.htm';

  const setIconsData = () => {
    const prefsObjects = objectsSnap('prefs');
    const iconPrefs = prefsObjects ? prefsObjects[prefID.app_icons] : null;
    setIconsFromPrefs(iconPrefs);
  };

  useEffect(() => {
    setIconsData();
  }, [prefsChanges]);

  const getDevRows = () => {
    const rows = [];
    for (const id in prefsObjects) {
      const obj = prefsObjects[id];
      if(!obj.rm)
        rows.push(<tr key={obj.id}>
          <td>{obj.id}</td><td>{JSON.stringify(obj.value)}</td></tr>);
    }
    return rows;
  };
  const devRows = useMemo(getDevRows, [prefsObjects, prefsChanges]);

  const mapCenterValidator = (str) => {
    const {pos, err} = Utils.parseLatLng(str);
    if (pos) setMapCenter(pos);
    return err;
  };

  return (<>
    {phoneEditor && 
    <StringDialog title='OC phone number' label='phone number' val={phone} 
      onSave={savePhone} onClose={() => showPhoneEditor(false)}/>}
    {centerEditor && 
    <StringDialog title='Default map center' label='map center' val={centerTxt} 
      validator={mapCenterValidator}
      onSave={saveMapCenter} onClose={() => showCenterEditor(false)}/>}
    {svgEditor && 
    <FileDialog title='Logo (svg-file)' accept='.svg'
      onSave={saveSvg} onClose={() => showSvgEditor(false)}/>}
    {jpgEditor &&
    <FileDialog title='Logo (jpg-file)' accept='.jpg'
      onSave={saveJpg} onClose={() => showJpgEditor(false)}/>}
    {iconeDialog && 
    <FileDialog title={iconDlgTitle} accept='.svg'
      onSave={saveMarkerIcon} onClose={() => setIconDlgTitle('')}/>}
    {changeLog && 
    <Dialog 
      title='Change Log'
      onClose={()=>showChangeLog(false)}>
        <iframe className='changeLog' frameBorder='0' title='changeLog' 
          src={changeLogUrl}></iframe>
    </Dialog> }
    <TitleWithClose onClose={() => setShowSettings(false)}>
      Settings
    </TitleWithClose>
    {prefsLoaded ?
    <div className='paddingButTop'>
      <div className='leftAlignText'><b>General</b></div>
      <div className='flexAlignCenter'>
        <div className='settingsItemTitle'>OC phone number</div>
        <Button onClick={() => showPhoneEditor(true)}>
          {phone ? phone : 'not set'}
        </Button>
      </div>
      <div className='leftAlignText'><b>Map</b></div>
      <div className='flexAlignCenter'>
        <div className='settingsItemTitle'>Default center</div>
        <Button onClick={() => showCenterEditor(true)}>
          {centerTxt}
        </Button>
        <IconButtonSt 
          title='Pick position on map'
          active={mapPopup}
          onClick={pickCenterOnMap}>
          <PointIcon 
            className="paddingLeft"
            />
        </IconButtonSt>
      </div>
      <div className='leftAlignText'><b>Apearance</b></div>
      <div className='flexAlignCenter paddingBottom'>
        <div className='settingsItemTitle'>Logo</div>
        <div className='settingsImgBtn' onClick={() => showSvgEditor(true)}>
          <Image imgCl='size5em' src={wsLogo}/>
        </div>
      </div>
      <div className='flexAlignCenter paddingBottom'>
        <div className='settingsItemTitle'>Login page image</div>
        <div className='settingsImgBtn' onClick={() => showJpgEditor(true)}>
          <Image imgCl='settingsImgBtn' src={wsImage}/>
        </div>
      </div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div><b>Map markers icons</b></div>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}}>

          <IconRow title={SENSORS_ICON}
            imgUrl={sensorIcon.url} 
            onIconClick={() => setIconDlgTitle(SENSORS_ICON)} 
            onDefaultClick={() => setDefault('sensors', false)}/>
          
          <IconRow title={SENSORS_SELECTED_ICON}
            imgUrl={sensorSelIcon.url} 
            onIconClick={() => setIconDlgTitle(SENSORS_SELECTED_ICON)} 
            onDefaultClick={() => setDefault('sensors', true)}/>
          
          <IconRow title={USERS_ICON}
            imgUrl={userIcon.url} 
            onIconClick={() => setIconDlgTitle(USERS_ICON)} 
            onDefaultClick={() => setDefault('users', false)}/>
          
          <IconRow title={USERS_SELECTED_ICON}
            imgUrl={userSelIcon.url} 
            onIconClick={() => setIconDlgTitle(USERS_SELECTED_ICON)} 
            onDefaultClick={() => setDefault('users', true)}/>
          
          <IconRow title={CAMERAS_ICON}
            imgUrl={cameraIcon.url} 
            onIconClick={() => setIconDlgTitle(CAMERAS_ICON)} 
            onDefaultClick={() => setDefault('cameras', false)}/>
          
          <IconRow title={CAMERAS_SELECTED_ICON}
            imgUrl={cameraSelIcon.url} 
            onIconClick={() => setIconDlgTitle(CAMERAS_SELECTED_ICON)} 
            onDefaultClick={() => setDefault('cameras', true)}/>

        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <div><b>User roles and permissions</b></div>
        </AccordionSummary>
        <AccordionDetails sx={{padding: 0}}>
          <RolesTable/>
        </AccordionDetails>
      </Accordion>
      <div className='flexAlignCenter paddingTop'>
        <div className='settingsItemTitle'>Backend URL</div>
        <a href={backend.getBackendUrl()} target="_blank">{backend.getBackendUrl()}</a>
      </div>
      <div className='flexAlignCenter'>
        <div className='settingsItemTitle'>{'Version: ' + appVersion}</div>
        <Button onClick={() => showChangeLog(true)}>
          Change log
        </Button>
      </div>
      {global.DEVELOPMENT && 
      <div className='bgLightGrey'>
        <b>Setings List (dev mode only)</b>
        <table className='fullWidth leftAlignTable'>
          <tbody>
          <tr><td><b>name</b></td><td><b>value</b></td></tr>
          {devRows}
        </tbody></table>
      </div>}
    </div>
    : <div className="circularProgress"><CircularProgress/></div>}
  </>);
}

export default Settings;