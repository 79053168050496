import React, { useState, useMemo, useEffect } from "react";
import { escHandlers, showOkDialog, showError } from "../storages/appStorage";
import { backend, data, syncEngine, useSyncEngStore } from "../sync-engine";
import TitleWithClose from "../components/dialog/titleWithClose";

const DataView = ({onClose}) => {
  const storeState = useSyncEngStore(state => state.storeState);
  const timeRange = useSyncEngStore(state => state.timeRange);

  // SyncEngine
  const syncInterval = useSyncEngStore(state => state.syncInterval);
  const syncDelay = useSyncEngStore(state => state.syncDelay);
  const syncState = useSyncEngStore(state => state.syncState);
  const syncError = useSyncEngStore(state => state.syncError);
  const syncSuccess = useSyncEngStore(state => state.syncSuccess);

  const [time, setTime] = useState('2020-04-01');

  useEffect(() => {
    escHandlers.set('DataView', onClose);
    return () => escHandlers.delete('DataView');
  }, []);
  
  const onClearButton = () => {
      console.log('onClearButton');
      showOkDialog({
        title: 'Stop and clear', 
        message: 'Stop SyncEngine and clear data?', 
        onOK: () => {
          syncEngine.stop();
          data.clear();
        }});
  }

  const onTimeInputChanged = (e) => {
    setTime(e.target.value);
  }
    const onSetTimeButton = () => {
        // Check data state
        if(storeState!='EMPTY' || syncState!='STOPPED'){
          showError('Clear data and stop SyncEngine before');
            return;
        }
        // Read input
        let subs = time.split(",");
        var t1 = new Date(subs[0]);
        var t2 = new Date(subs[1]);
        if(isNaN(t1)){
          showError('Time format error. Use ISO format (YYYY-MM-DD).');
            return;
        }
        if(isNaN(t2)) t2=null

        // APPLY!
        try{
            data.setTimeRange(
                t1.getTime(), 
                t2?t2.getTime():null
            );
        }catch(err){
          showError(err);
        }
    }

    const onSyncNowClick = () => {
        console.log('onSyncNowClick');
        syncEngine.syncNow();
    }

    const onSyncStateClick = () => {
        console.log('onSyncStateClick');

        if(syncState=='STOPPED'){
          showOkDialog({
            title: 'Start SyncEngine', 
            message: `Do you want to Start SyncEngine?`, 
            onOK: () => syncEngine.start()});
        }else{
          showOkDialog({
            title: 'Stop SyncEngine', 
            message: `Do you want to Stop SyncEngine?`, 
            onOK: () => syncEngine.stop()});
        }
    }

    const getTimeRangeStr = () => {
      if(timeRange){
        const t1 = timeRange.t1;
        const t2 = timeRange.t2;
        return  (t1?new Date(t1).toISOString().slice(0,10):'null') + 
          ', '+ (t2?new Date(t2).toISOString().slice(0,10):'null');
      }
      return 'not set';
    }

    const timeRangeStr = useMemo(() => getTimeRangeStr(), [timeRange]);

        let modules='';
        for(const name in data.modules) modules+=name+' ';
        let props='';
        for(const name in data.props)   props+=name+' ';

  const result = syncError 
    ? <div className='colorRed'>{syncError}</div> 
    : <div className='colorGreen'>{syncSuccess}</div>;

  const dataRow = (label, value) => 
    <div className='dataRow'>
      <div className='flexAlignCenter'>{label}</div>
      <div className="dataValue">{value}</div>
    </div>;

  return (
    <div className='dataView'>
      <TitleWithClose onClose={onClose}>Data State</TitleWithClose>
      {dataRow('Backend', backend.API_URL)}
      {dataRow('State', storeState)}
      {dataRow('Modules', modules)}
      {dataRow('Props', props)}
      {dataRow('Time Range', timeRangeStr)}
      {dataRow('Set Time Range', 
        <div>
          <input type='text' value={time} onChange={onTimeInputChanged} />
          <button onClick={onSetTimeButton}>SET</button>
        </div>)}
      {dataRow('', <button onClick={onClearButton}>Clear Data</button>)}
      <hr/>
      <div className="dataHeader">Synchronization Engine</div>
      {dataRow('State', syncState)}
      {dataRow('Sync Delay', syncDelay/1000)}
      {dataRow('Sync Interval', syncInterval)}
      {dataRow('Last Result', result)}
      {dataRow('Actions', 
        <div>
          <button onClick={onSyncNowClick}>sync now</button>
          <button onClick={onSyncStateClick}>start / stop</button>
        </div>)}
    </div>
  );
}

export default DataView;
