import React, { useMemo, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppStore } from '../../../storages/appStorage';
import SensorsTable from '../../sensors/sensorsTable';

const SensorsWidget = ({onPubLvMap}) => {
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const contentRef = useRef();

  const sensorsLoaded = useMemo(() => {
    return loadedMdProps.has('sensor');
  }, [loadedMdProps]);

  return <div className='wdContainer'>
    <div className='wdContent' ref={contentRef}>
      {sensorsLoaded 
        ? <SensorsTable viewMode={true} containerWidth={contentRef?.current?.offsetWidth} 
            onPubLvMap={onPubLvMap}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </div>;
};
export default SensorsWidget;