import React, { useState, useMemo, } from 'react';
import { backend } from '../../sync-engine';
import { useMapStore } from '../../storages/mapStorage';
import { LocationIcon } from '../common';
import ImageViewer from '../ImageViewer';

const PointExpElementShort = ({point, drawing}) => {
  const map = useMapStore(state => state.map);

  const photos = point.photos;
  const path = backend.getWorkspaceUrl()+'/file/';

  const [showImgViewer, setShowImgViewer] = useState(false);
  const [currentImgInd, setCurrentImgInd] = useState(0);
  const onImgClick = (ind) => {
    setCurrentImgInd(ind);
    setShowImgViewer(true);
  };
  const onCloseImgViewer = () => setShowImgViewer(false);

  const photoUrls = useMemo(() => {
      return photos 
          ? photos.map((photo) => path+photo)
          : [];
  }, [photos]);

  const photoRows = useMemo(() => {
      return photoUrls.slice(0,2).map((photo, ind) => (
              <img 
              key={photo}
              className="thumbExp"
              onClick={() => onImgClick(ind)}
              src={photo}/>));
  }, [photoUrls]);

  const locateItem = (drawing) => {
    drawing.locate(map);
  }

  const canLocate = useMemo(() => map && point && !point.rm, [map]);
  const locator = (<LocationIcon enabled={canLocate} 
    onClick={canLocate ? () => locateItem(drawing) : null}></LocationIcon>);

  return (
    <div className='flexElem paddingElem'>
      <div className='fullWidth'>
        <div className='spaceBetween'>
          {photoRows}
          {locator}
        </div>
        {showImgViewer && 
            <ImageViewer images={photoUrls} onClose={onCloseImgViewer} index={currentImgInd}/>}
      </div>
    </div>
  );
};

export default PointExpElementShort;