import Module from "../Module";

//--------------------------------------------------------------------------------
// MODULE: PREFS
// item data format:
//      id    : numeric ID
//      value : JSON content or null

// ALLOWED ID LIST:
const prefID = {
    map_default : 2001,
    phone_oc    : 2002,
    ftp_server  : 2021,
    live_view   : 2022,
    app_icons   : 2030,
}

class  Prefs extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            pref:           {syncUrl:'/prefs/load_prefs'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        let results = {};
        if(changes.pref) this.onChanges (changes.pref, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onChanges(changes,results){
        changes.forEach(pref => {
            this.objects[pref.id] = pref;
            results     [pref.id] = pref;
        });
    }
}

export {prefID}
export default Prefs

