import React from "react";
import RvTable from './rvTable'

const RvTableExpand = ({rows,  header, idInd, clickColmns, 
  selectedId, orderBy, orderAsc, setSelectedId, rowHeight, 
  expElHeight, getExpEl, getColumnWidth, containerWidth}) => {

  const setRowOpen = (id) => {
    if (id == selectedId) {
      setSelectedId(null);
    } else {
      setSelectedId(id);
    }
  };

  const onRowClick = (index) => {
    const id = rows[index][idInd];
    if (id) setRowOpen(id);
  };

  const getRowStyle = (id) => {
    return id == selectedId ? 'listCell listRowSelected' : 'listCell';
  };

  const rvtProps = {rows, header, idInd, clickColmns, selectedId, 
    orderBy, orderAsc, onRowClick, rowHeight, expElHeight, getExpEl, 
    getColumnWidth, getRowStyle, containerWidth};

  return (
    <RvTable {... rvtProps}/>
  );
};

export default RvTableExpand;