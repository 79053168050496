import debounce from 'lodash/debounce';

const THRESHOLD = 500;
const debounceProps = {
  leading: true,
  trailing: false,
};

const second = 1000;  // ms
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const month = day * 29.3; // approx
const year = day * 365.24; // approx

const months3char = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export default {

  formatDate(time){
    if(!time) return "not set"
    let date = new Date(time) 
    return date.toLocaleDateString()
  },

  formatDateTime(time) { // 2021-02-26 17:31
    if(!time) return '';
    const date = new Date(time);
    const parts = date.toISOString().split('T');
    return `${parts[0]} ${date.getHours()}:${date.getMinutes()}`;
  },

  formatDuration (duration, full = true, dec = 0) {
    if(!duration) return '';

    const yearsRaw = duration / year;
    const years = Math.round(yearsRaw) > 1 
      ? Math.round(yearsRaw) 
      : Math.floor(yearsRaw);
    if (years) {
      return full 
        ? years === 1 ? '1 year' : `${years} years`
        : `${yearsRaw.toFixed(dec)} yr`;
    }

    const monthsRaw = duration / month;
    const months = Math.round(monthsRaw) > 1
      ? Math.round(monthsRaw) 
      : Math.floor(monthsRaw);
    if (months) {
      return full 
        ? months === 1 ? '1 month' : `${months} months`
        : `${monthsRaw.toFixed(dec)} mnth`;
    }

    const daysRaw = duration / day;
    const days = Math.round(daysRaw) > 1
      ? Math.round(daysRaw) 
      : Math.floor(daysRaw);
    if (days) {
      return full 
        ? days === 1 ? '1 day' : `${days} days`
        : `${daysRaw.toFixed(dec)} d`;
    }

    const hoursRaw = duration / hour;
    const hours = Math.round(hoursRaw) > 1
      ? Math.round(hoursRaw) 
      : Math.floor(hoursRaw);
    if (hours) {
      return full 
        ? hours === 1 ? '1 hour' : `${hours} hours`
        : `${hoursRaw.toFixed(dec)} hr`;
    }

    const minutesRaw = duration / minute;
    const minutes = Math.round(minutesRaw) > 1
      ? Math.round(minutesRaw) 
      : Math.floor(minutesRaw);
    if (minutes) {
      return full 
        ? minutes === 1 ? '1 minute' : `${minutes} minutes`
        : `${minutesRaw.toFixed(dec)} min`;
    }
    
    const seconds = Math.round(duration / second);
    return full 
      ? seconds === 1 ? '1 second' : `${seconds} seconds`
      : `${seconds} sec`;
  },

  formatFullDuration (duration) {
    if(!duration) return '';

    const yearsRaw = duration / year;
    const years = Math.floor(yearsRaw); 
    const yearsStr = years ? `${years} yr` : '';

    const monthsRaw = (duration % year) / month;
    const months = Math.floor(monthsRaw);
    const monthsStr = months ? `${months} mnth` : '';

    const daysRaw = (duration % month) / day;
    const days = Math.floor(daysRaw);
    const daysStr = days ? `${days} d` : '';

    const hoursRaw = (duration % day) / hour;
    const hours = Math.floor(hoursRaw);
    const hoursStr = hours ? `${hours} h` : '';

    const minutesRaw = (duration % hour) / minute;
    const minutes = Math.floor(minutesRaw);
    const minutesStr = minutes ? `${minutes} min` : '';

    const seconds = Math.round((duration % minute) / second);
    const secondsStr = seconds ? `${seconds} sec` : '';

    const allParts = [yearsStr, monthsStr, daysStr, hoursStr, minutesStr, secondsStr];
    const parts = allParts.filter(part => part.length > 0);
    return parts.join(' ');
  },

  formatAgoDate(time){
    if(!time) return '';
    const duration = Date.now() - time;
    return this.formatDuration(duration) + ' ago';
  },

  getDayTime(time) {
    if (!time) return '';
    const date = new Date(time);
    const hours = date.getHours();
    const hrStr = hours < 10 ? '0' + hours : hours;
    const minutes = date.getMinutes();
    const minStr = minutes < 10 ? '0' + minutes : minutes;

    return date.getDate() + months3char[date.getMonth()] + hrStr + ':' + minStr;
  },

  startOfThisDay () {
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfNextDay () {
    const dt = new Date();
    dt.setDate(dt.getDate() + 1);
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfThisWeek () {
    const dt = new Date();
    dt.setDate(dt.getDate() - dt.getDay());
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfNextWeek () {
    const dt = new Date();
    dt.setDate(dt.getDate() + 7 - dt.getDay());
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfThisMonth () {
    const dt = new Date();
    dt.setDate(1);
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfNextMonth () {
    const dt = new Date();
    dt.setMonth(dt.getMonth() + 1, 1);
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  startOfNDays (n) {
    const dt = new Date();
    dt.setDate(dt.getDate() - n + 1);
    dt.setHours(0, 0, 0, 0);
    return dt;
  },

  todayTimeRange () {
    return {t1: this.startOfThisDay().getTime(), t2: null, label: 'today', nextTFilterTime: this.startOfNextDay()};
  },

  timeFilterData ({period, nDays, from, to}) {
    switch (period) {
      case 'today' :
        return this.todayTimeRange();
      case 'this week' :
        return {t1: this.startOfThisWeek().getTime(), t2: null, label: 'this week', nextTFilterTime: this.startOfNextWeek()};
      case 'this month' :
        return {t1: this.startOfThisMonth().getTime(), t2: null, label: 'this month', nextTFilterTime: this.startOfNextMonth()};
      case 'last N days' :
        return {t1: this.startOfNDays(nDays).getTime(), t2: null, 
          label: `last ${nDays} ` + (nDays == 1 ? 'day' : 'days'), nextTFilterTime: this.startOfNextDay()};
      case 'from' :
        if (this.invalidTimeStamp(from)) {
          console.error(`=== Wrong fromDate value:`, from);
          return this.todayTimeRange();
        }
        return {t1: Number(from) , t2: null, label: 'from ' + this.formatDate(Number(from)), nextTFilterTime: null};
      case 'from to' : {
        if (this.invalidTimeStamp(from)) {
          console.error(`=== Wrong fromDate value:`, from);
          return this.todayTimeRange();
        }
        if (this.invalidTimeStamp(to)) {
          console.error(`=== Wrong toDate value:`, to);
          return this.todayTimeRange();
        }
        return {t1: Number(from) ,t2: Number(to), 
          label: this.formatDate(Number(from)) + ' - ' + this.formatDate(Number(to)), nextTFilterTime: null};
      }
      default : {
        console.error(`=== Wrong historyPeriod value:`, period);
        return this.todayTimeRange();
      }
    }
  },

  invalidTimeStamp (timeStamp) {
    const date = new Date(Number(timeStamp));
    return isNaN(date.getTime());
  },

  isSameDay (t1, t2) {
    if (!t1 || !t2) return false;

    const date1 = new Date(t1);
    const date2 = new Date(t2);
    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      return false;
    }

    if (date1.getFullYear() != date2.getFullYear()) return false;
    if (date1.getMonth() != date2.getMonth()) return false;
    if (date1.getDate() != date2.getDate()) return false;

    return true;
  },

  formatPeriod(t1, t2) {
    if (!t1) return '';

    const first = this.formatDate(t1);
    if (!t2) return first;

    const second = this.formatDate(t2);
    if (first === second) return first;

    return first + ' - ' + second;
  },
  
  time2ISOdate(time){
    if(time) 
      return new Date(time).toISOString().slice(0,10)
    else
      return "not set"
  },

  ISOdate2time(str){
    var d = new Date(str);
    if(isNaN(d))
      throw 'Time format error. Use ISO format (YYYY-MM-DD).'
    return d.getTime()
  },

  // lat,lng to string fot text fields
  formatLatLng(p, emptyText){
    if(p&&p.lat&&p.lng)
      return p.lat.toFixed(5)+'   '+p.lng.toFixed(5)
    else 
      return emptyText
  },


  parseLatLng (string) {
    const errNoCrd = 'No Coordinates';
    if (!string) return {pos: null, err: errNoCrd};
    const str = string.trim();
    if (str.length == 0) return {pos: null, err: errNoCrd};
  
    const p={};
    const subs = str.split(/\s+/);

    const errFormat = 'Coordinates must be two numbers splitted by space';
    if (subs.length != 2 ) return {pos: null, err: errFormat};
    p.lat = Number(subs[0]);
    p.lng = Number(subs[1]);
    if (isNaN(p.lat) || isNaN(p.lng)) return {pos: null, err: errFormat};

    if (Math.abs(p.lat) > 90) {
      return {pos: null, err: 'Latitude must be between -90 and 90'};
    }
    if (Math.abs(p.lng) > 180) {
      return {pos: null, err: 'Longitude must be between -180 and 180'};
    }

    return {pos: p, err: ''};
  },

  formatDistance (distance, decPl = 0, separ = '') {
    if (isNaN(distance)) return '';
    const km = distance / 1000;
    if (km > 1) {
      return km.toFixed(decPl) + separ +'km';
    }
    return distance.toFixed(decPl) + separ +'m';
  },

  formatFullDistance (distance, decPl = 0, separ = '') {
    if (isNaN(distance)) return '';
    const km = Math.floor(distance / 1000);
    const m = distance % 1000;
    const kmStr = km > 0 ? km + separ +'km ' : '';
    return kmStr + m.toFixed(decPl) + separ +'m';
  },

  matchRange (time, timeRange) {
    if (!timeRange) return false;
    if(timeRange.t2) return timeRange.t1 < time && time < timeRange.t2;
    return timeRange.t1 < time;
  },

  simpleArraysAreEqual (array1, array2) {
    if (!array1 || !array2) return false;
    return array1.length === array2.length && 
      array1.every((value, index) => value === array2[index]);
  },

  ascComparator (a, b) {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  },

  debouncedPress (func) {
    return debounce(func, THRESHOLD, debounceProps);
  },

  isNumber (val) {
    if (val === 0) return true;
    if (val === true) return false;
    return val && isFinite(val);
  },
  
  strDifferIfSet (str1, str2) {
    if (!Boolean(str1) && !Boolean(str2)) return false;
    return str1 != str2;
  },

  colorAlpha2Opacity (colorAlp) {
    if (colorAlp) {
      if (colorAlp.length == 7) return {color: colorAlp, opacity: null};
      if (colorAlp.length == 9) {
        const alpha = parseInt("0x" + colorAlp.slice(1, 3));
        const opacity = isNaN(alpha) ? null : +((alpha/255).toFixed(2));
        const color = '#' + colorAlp.slice(3);
        return {color, opacity};
      }
    }
    return {color: null, opacity: null};
  },

  colorOpacity2Alpha (color, opacity) {
    if (color?.length == 7) {
      const alphaNum = Math.round(opacity * 255);
      const alphaStr = this.isNumber(opacity) ? alphaNum.toString(16) : '';
      const alpha = alphaStr.length == 1 ? '0' + alphaStr : alphaStr
      return '#' + alpha + color.slice(1);
    }
    return null;
  },

  color2contrastBWs (color) {
    if (color.length != 7) return {sharp: '#000000', medium: '#696969'};

    const r = parseInt("0x" + color[1] + color[2]);
    const g = parseInt("0x" + color[3] + color[4]);
    const b = parseInt("0x" + color[5] + color[6]);
    const darkColor = (r + g + b) < 383; // FFFFFF == 765; 765/2 = 382.5
    const sharp = darkColor ? '#FFFFFF' : '#000000';
    const medium = darkColor ? '#A9A9A9' : '#696969';
    return {sharp, medium}
  },

  getRandomColor (hueRange, hueRool) {
    const hueRand = (hueRange ? hueRange : 360) * Math.random();
    const hue = hueRool ? hueRool(hueRand) : hueRand;

    // saturation = 100%, lightness = 50% => full color and bright
    return `hsl(${hue},100%,50%)`;
  },

  getMapRandomColor () {
    // hue ranges: (30-45, 90-330) to cut out red and yellow
    // red - color of selection, yellow - too weak on map
    return this.getRandomColor(255, (h) => h < 15 ? 30 + h : 75 + h)
  },

  getGraphRandomColor () {
    // hue ranges: (30-45 190-210 270-330) to cut out red green blue and yellow
    // red green blue - predefined colors, yellow - too weak
    return this.getRandomColor(95, 
      (h) => h < 15 
        ? 30 + h 
        : h < 35 
          ? 175 + h 
          : 235 + h)
  },

  degrees2km(p1, p2) { // simplified distance calculation (deg->km)
    const latD = p1.lat - p2.lat;
    const lngD = p1.lng - p2.lng;
    const lngFactor = 111.320 * Math.cos(p1.lat * Math.PI / 180);

    const latDkm = 110.574 * latD; // latFactor is const: 110.574
    const lngDkm = lngFactor * lngD;

    return Math.sqrt(latDkm * latDkm + lngDkm * lngDkm);
  },

  pointCategoryIconFileName (point) {
    const cat = point?.category
      if(cat=='animal') {
        const animalSighting = point.details?.animal && point.details.sighting 
          ? `-${point.details.animal}-${point.details.sighting}`
          : '';
        return 'animal' + animalSighting + '.svg';
      }
      else{
          const subcat = point?.details?.category;
          if(subcat) return `${cat}-${subcat}.svg`;
          else       return `${cat}.svg`;
      }
  },

  mapReplacer (key, value) {
    if(value instanceof Map) {
      return {
        dataType: 'Map',
        value: Array.from(value.entries()),
      };
    }
    return value;
  },

  mapReviver (key, value) {
    if(typeof value === 'object' && value !== null) {
      if (value.dataType === 'Map') {
        return new Map(value.value);
      }
    }
    return value;
  },

  getSelectedIds (page, selectedIds) {
    const selectedId =     selectedIds[0]?.page == page ? selectedIds[0].id : '';
    const prevSelectedId = selectedIds[1]?.page == page ? selectedIds[1].id : '';
    return {selectedId, prevSelectedId};
  },
}