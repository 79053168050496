import React, { useState, useMemo } from 'react';
import LctIcon from '@mui/icons-material/MyLocation';
import { useSyncEngStore } from '../../sync-engine';
import { useAppStore, setSelected } from "../../storages/appStorage";
import { useMapStore } from '../../storages/mapStorage';
import { useLvStore } from '../../storages/lvStorage';
import { useSensorsStore, setOrder } from '../../storages/sensorsStorage';
import RvTableExpand from "../rvTable/rvTableExpand";
import SensorExpElement from './sensorExpElement';
import { LocationIcon, SortIcon } from '../common';
import Utils from '../../Utils';

const SensorsTable = ({viewMode, containerWidth, onPubLvMap}) => {
  const map = useMapStore(state => state.map);
  const sensorsObjects = useSyncEngStore(state => state.sensorsObjects, () => false);
  const sensorsChanges = useSyncEngStore(state => state.sensorsChanges);
  const selectedIds =  useAppStore(state => state.selectedIds);
  const pagesOnMap =   useAppStore(state => state.pagesOnMap);
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const orderBy =          useSensorsStore(state => state.orderBy);
  const orderAsc =         useSensorsStore(state => state.orderAsc);
  const removedShown =     useSensorsStore(state => state.removedShown);

  const [rows, setRows] = useState([]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('sensors', selectedIds), [selectedIds]);

  const locateItem = (drawing) => {
    drawing.locate(map);
  };

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push(<div className='sortHeader'>Name{getSortIcon(0)}</div>);
    header.push(<div className='sortHeader'>Category{getSortIcon(1)}</div>);
    header.push(<div className='sortHeader'>Data{getSortIcon(2)}</div>);
    header.push(<LctIcon/>);
    header.push('');   // hidden value (idInd={4})
    return header;
  };

  const header = useMemo(() => getHeader(), 
    [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(sensorsObjects) {
        for(const id in sensorsObjects){
          const obj = sensorsObjects[id];
          const sensor = obj.sensor;
          const data = obj.data;

          if (sensor && (removedShown || !sensor.rm))
          {
            const onMap = onPubLvMap ? pagesOnMapWd.has('Sensors Widget') : pagesOnMap.has('sensors');
            const canLocate = onMap && map && !sensor.rm && obj.location;
            const locator = (<LocationIcon enabled={canLocate} 
              onClick={canLocate ? () => locateItem(obj.drawing) : null}></LocationIcon>);

            const vals = [];
            vals.push(sensor.name);
            vals.push(sensor.category);
            vals.push(data.length);
            vals.push(locator);
            vals.push(id); // hidden id value (idInd={4})
            vals.push(sensor.rm); // hidden rm value (row[5])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- sensor count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [
        <div className='textCell' style={row[5] // sensor.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[0]}
        </div>,  // sensor.name
        <div className='textCell'>{row[1]}</div>,  // sensor.category
        row[2],  // data.length
        row[3], // locator
        row[4]], // id
        ['', '', '', '', row[4]] // expRow
      ]);

      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });

    return [];
  };
  useMemo(getRows, 
    [map, sensorsChanges, orderBy, orderAsc, removedShown, pagesOnMap, pagesOnMapWd, onPubLvMap]);

  const getExpEl = () => {
    if (selectedId && sensorsObjects) {
      const obj = sensorsObjects[selectedId];
      if (obj) {
        const sensor = obj.sensor;
        const data = obj.data;
        return <SensorExpElement sensor={sensor} data={data} active={!viewMode}/>;
      }
    }

    return <div>Fail to get sensor data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 230; //Identificator+Images+icon
    const freeSpace = listWidth - fixedColumns;
    const name = freeSpace > 10 ? freeSpace : 10;
    switch (index) {
      case 0: return name; // Name
      case 1: return 120;  // Category
      case 2: return 65;   // Data
      case 3: return 45;   // LctIcon
    }
    return 0;
  };

  return <RvTableExpand rows={rows} header={header} idInd={4} 
    clickColmns={[0,1,2]} selectedId={selectedId} 
    orderBy={orderBy} orderAsc={orderAsc}
    setSelectedId={(id) => setSelected('sensors', id)}
    rowHeight={32}
    expElHeight={120}
    getExpEl={getExpEl}
    getColumnWidth={getColumnWidth}
    containerWidth={containerWidth}/>;
};
export default SensorsTable;