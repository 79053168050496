import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';

const useStyles = makeStyles(() => ({
  drawer: {
    width: (props) => props.width,
  },
  drawerPaper: {
    width: (props) => props.width,
  },
}));

export default function DrawerLeftRight({open, anchor, width, body}) {
  const classes = useStyles({width: width ? width : 200});

  return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor={anchor ? anchor : 'right'}
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {body}
      </Drawer>
  );
}

DrawerLeftRight.propTypes = {
  open: PropTypes.bool.isRequired,
  anchor: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.number,
  body: PropTypes.node,
};