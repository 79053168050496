import React, { useMemo, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppStore } from '../../../storages/appStorage';
import PointsTable from '../../points/pointsTable';

const PointsWidget = ({onPubLvMap}) => {
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const contentRef = useRef();

  const pointsLoaded = useMemo(() => {
    return loadedMdProps.has('point');
  }, [loadedMdProps]);

  return <div className='wdContainer'>
    <div className='wdContent' ref={contentRef}>
      {pointsLoaded 
        ? <PointsTable viewMode={true} containerWidth={contentRef?.current?.offsetWidth} 
            onPubLvMap={onPubLvMap}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </div>;
};
export default PointsWidget;