import React, { useState, } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { CheckIcon } from '../common';

const ColumnInput = ({className, label, value, onSave, disabled}) => {
  const [val, setVal] = useState(value);
  const canSave = value != val;

  return (
    <TextField className={className}
      variant='standard'
      size='small'
      label={label}
      value={val}
      disabled={disabled}
      onChange={(e) => setVal(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <CheckIcon enabled={canSave} 
            onClick={canSave ? () => onSave(val) : null}/></InputAdornment>,
      }}
    />
  );
}

export default ColumnInput;