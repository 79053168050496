import React, { useState, useMemo, useEffect } from 'react';
import { styled, makeStyles } from '@mui/styles';
import Icon from '@mui/material/Icon';
import { TreeView, TreeItem } from '@mui/lab';
import { backend, useSyncEngStore } from '../../sync-engine';
import { useMapStore } from '../../storages/mapStorage';
import { useAppStore, setSelected, showOkDialog } from "../../storages/appStorage";
import { type, useFeaturesStore, setFeatureLegend, setInitialFeature, 
  setCreatingFeature, setShowEditFeature, setShowEditFolder, changeCreatingFeature, 
  cleanupCreatingFeature, setShowImportKML, saveFeature, delRestorFeature, 
  setExpandedNodeIds, getExpandedNodeIds } from '../../storages/featuresStorage';
import FeatureExpElement from './featureExpElement';
import NameDialog from "../dialog/nameDialog";
import { PlusSquare, MinusSquare, AddFolderIcon, AddIcon, EditIcon,
  LocationIcon, ImportIcon, DelRestorIcon } from '../common';
import Colors from '../../styles/colors';
import Utils from '../../Utils';

const useStyles = makeStyles({
  treeItemStyle: {
    borderLeft: props => props.isroot ? 'none' : 'solid black 1px',
  },
});

const TreeItemStyled = (props) => {
  const {isroot, ...rest} = props;
  const { treeItemStyle } = useStyles({isroot});
  return <TreeItem  {...rest} className={`${treeItemStyle}`}/>;
}

const FeatureExpTreeItem = styled(TreeItem)({
  backgroundColor: Colors.tabBgDisabl,
});

const TreeLeaf = ({id, isroot, obj, typeIcone, locator, expId, onEdit, 
  onDelRestore, onClick
  }) => {
  const feature = obj.feature;
  return <TreeItemStyled nodeId={id} isroot={isroot}
  onClick={onClick}
  collapseIcon={typeIcone}
  expandIcon={typeIcone}
  label={
  <div className='flexStartElem'>
    <div style={feature.rm ? {textDecoration: 'line-through'} : {}}>
      {feature.name}
    </div>
    <div className='treeNodeLeftPart'>
      <div className='flexAlignCenter'>
        {!feature.rm && <>
        <EditIcon 
          titleAccess='Edit item'
          onClick={(e) => onEdit(e, obj)}/></>}
        <DelRestorIcon 
          rm={feature.rm} 
          enabled={true}
          onDelete={(e) => onDelRestore(e, feature, false)}
          onRestore={(e) => onDelRestore(e, feature, true)}/>
      </div>
      {locator}
    </div>
  </div>}>
  <FeatureExpTreeItem key={expId} nodeId={expId} id={expId} label={
    <FeatureExpElement feature={feature}/>}>
  </FeatureExpTreeItem>
</TreeItemStyled>;
};

const TreeFolder = ({id, feature, children, onDelRestore, onEdit, 
  onAddNewFolder, onAddNewLeaf, onImportKML, onClick
  }) => {
  return <TreeItemStyled nodeId={id} isroot={!feature.parent_id} 
    onClick={onClick}
    label={
    <div className='flexStartElem'>
      <div
        style={feature.rm ? {textDecoration: 'line-through'} : {}}>
        {feature.name}
      </div>
      <div className='treeNodeLeftPart'>
        
        {!feature.rm &&
        <div className='flexAlignCenter'>
          <EditIcon titleAccess='Edit Folder' 
            onClick={(e) => onEdit(e, feature)}/>
          <AddFolderIcon titleAccess='Add Folder' 
            onClick={(e) => onAddNewFolder(e, id)}/>
          <AddIcon titleAccess='Add Item' 
            onClick={(e) => onAddNewLeaf(e, id)}/>
          <ImportIcon titleAccess='Import from KML' 
            onClick={(e) => onImportKML(e, id)}/>
        </div>}
        <DelRestorIcon 
          rm={feature.rm} 
          enabled={true}
          onDelete={(e) => onDelRestore(e, feature, false)}
          onRestore={(e) => onDelRestore(e, feature, true)}/>
      </div>
    </div>}>
    {children}
  </TreeItemStyled>;
};

const FeatureTree = () => {
  const map = useMapStore(state => state.map);
  const featuresObjects = useSyncEngStore(state => state.featuresObjects, () => false);
  const featuresChanges = useSyncEngStore(state => state.featuresChanges);
  const categoriesObjects = useSyncEngStore(state => state.categoriesObjects, () => false);
  const categoriesChanges = useSyncEngStore(state => state.categoriesChanges);
  const selectedIds =       useAppStore(state => state.selectedIds);
  const expandedNodeIds = useFeaturesStore(state => state.expandedNodeIds);
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);
  const removedShown = useFeaturesStore(state => state.removedShown);
  const unvisibleLayers = useFeaturesStore(state => state.unvisibleLayers);

  const [treeClick, setTreeClick] = useState(false);
  const [newFolderOpen, setNewFolderOpen] = useState(false);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('features', selectedIds), [selectedIds]);

  const onEditFolder = (e, feature) => {
    e.stopPropagation();
    setInitialFeature(feature);
    setCreatingFeature(feature);
    setShowEditFolder(true);
  };

  const onEditFeature = (e, obj) => {
    e.stopPropagation();
    setInitialFeature(Object.assign({}, obj.feature));

    const featureClone = Object.assign({}, obj.feature);
    featureClone.drawing = obj.drawing;
    setCreatingFeature(featureClone);
    setShowEditFeature(true);
    if (map && obj.drawing) {
      obj.drawing.locate(map);
      obj.drawing.startEdit((event) => {
        if (featureClone.type == type.POINT) {
          if (event.latLng) {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            changeCreatingFeature({coordinates: [lng, lat]});
          }
        } else {
          const path = obj.drawing.getPath();
          changeCreatingFeature({coordinates: path});
        }
      });
    }
  };

  const onDelRestore = (e, feature, restore, entity) => {
    e.stopPropagation();
    const DelRest = restore ? 'Restore' : 'Delete'
    showOkDialog({
      title: DelRest + ' ' + entity, 
      message: DelRest + ' ' + entity +` "${feature.name}"?`, 
      onOK: () => delRestorFeature(feature.id, restore)});
  }

  const onDelRestorItem = (e, feature, restore) => 
    onDelRestore(e, feature, restore, 'item');
  
  const onDelRestorFolder = (e, feature, restore) => 
    onDelRestore(e, feature, restore, 'folder');

  const expandNode = (id) => {
    const path = treePaths.get(id);
    if (path) {
      setExpandedNodeIds([id, ...path]);
    }
  };

  const onAddNewFolder = (e, parent_id) => { // show New Folder Dialog
    e.stopPropagation();
    expandNode(parent_id);  // open folder
    setSelected('features', null);
    setCreatingFeature({parent_id: parent_id});
    setNewFolderOpen(true);
  };

  const onSaveNewFolder = (name) => {  // create new folder
    if (creatingFeature?.parent_id) {
      changeCreatingFeature({name: name, type: type.FOLDER});
      saveFeature();
    }
  };

  const onAddNewFeature = (e, parent_id) => {
    e.stopPropagation();
    expandNode(parent_id);  // open folder
    setSelected('features', null);
    const legend = document.getElementById("featureMapLegend");
    if (map && legend) {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(legend);
      setFeatureLegend(legend);
      cleanupCreatingFeature(true);
      setInitialFeature({parent_id});
      setCreatingFeature({parent_id});
      setShowEditFeature(true);
    }
  }

  const onImportKML = (e, parent_id) => {
    expandNode(parent_id);  // open folder
    setSelected('features', null);
    e.stopPropagation();
    setCreatingFeature({parent_id});
    setShowImportKML(true);
  }

  const onNodeClick = (feature) => {
    const expandedIds = getExpandedNodeIds();
    const id = String(feature.id);
    const idInd = expandedIds.indexOf(id);

    if (idInd != -1) {  // close Node
      expandedIds.splice(0, idInd + 1);
      setExpandedNodeIds([...expandedIds]);
      setSelected('features', null);
    } else {            // open Node
      expandNode(id);
      if (feature.type == type.POINT || feature.type == type.LINE || 
        feature.type == type.POLYGON ) {
        setTreeClick(true);
        setSelected('features', id);
      } else {
        setSelected('features', null);
      }
    }
  };

  const scrollToSelected = () => {
      const expId = `${selectedId}exp`;
      const element = document.getElementById(expId);
      const container = document.getElementById('treeView_id');
      if (element && container) {
        const contTop = parseInt(container.getBoundingClientRect().top);
        const contBot = parseInt(container.getBoundingClientRect().bottom);

        const elTop = parseInt(element.getBoundingClientRect().top);
        const elBot = parseInt(element.getBoundingClientRect().bottom);

        const scrollBy = elTop < contTop ? elTop - contTop : elBot - contBot;
        if(scrollBy != 0) {
          container.scrollTop += scrollBy;
        }
      }
  };

  const expandSelected = () => {
    if (selectedId && !treeClick) {
      expandNode(selectedId);
      setTimeout(scrollToSelected, 500);
    }
    setTreeClick(false);
  }

  useEffect(() => {
    expandSelected();
  }, [selectedId]);

  const locateItem = (e, drawing) => {
    e.stopPropagation(); 
    return drawing.locate(map);
  };

  const getTypeIcon = (type) => {
    if (type < 1 || type > 3) return null;
    const typeIconNames = ['', 'featurePoint', 'featureLine', 'featurePolygon'];
    const iconName = typeIconNames[type];
    return <Icon>
      <img className={'featureTypeIcon'} src={`/images/${iconName}.svg`}/>
    </Icon>;
  };

  const getFeatureIcon = (feature) => {
    if (feature.type == type.POINT) {
      const categoryObj = categoriesObjects ? categoriesObjects[feature.category_id] : null;
      if (categoryObj) {
        const pointIcon = categoryObj.category?.point_icon;
        if (pointIcon) {
          const url = backend.getWorkspaceUrl()+'/file/' + pointIcon;
          return <Icon>
            <img className={'featureTypeIcon'} src={url}/>
          </Icon>;
        }
      }
    }
    return getTypeIcon (feature.type)
  };

  const treePaths = useMemo(() => new Map(),
    [featuresChanges, categoriesChanges, removedShown]);

  const getNodes = (parent, path=[]) => {
    const treeNodes = [];
    for(const id in featuresObjects){
      const obj = featuresObjects[id];
      const feature = obj?.feature;
      if (feature && (parent ? feature.parent_id == parent.id : !feature.parent_id)
          && (removedShown || !feature.rm)) {

        if (feature.type == type.FOLDER) {
          treePaths.set(id, path);
          const newPath = [...path];
          newPath.unshift(id)
          const node = <TreeFolder key={id} id={id} feature={feature} 
            children={getNodes(feature, newPath)}
            onDelRestore={onDelRestorFolder}
            onEdit={onEditFolder}
            onAddNewFolder={onAddNewFolder}
            onAddNewLeaf={onAddNewFeature}
            onImportKML={onImportKML}
            onClick={()=>onNodeClick(feature)}
          />;      
          treeNodes.push(node);
        } else {
          const typeIcone = getFeatureIcon(feature);
          if (typeIcone) {
            treePaths.set(id, path);

            const categoryObj = categoriesObjects ? categoriesObjects[feature.category_id] : null;
            const categoryRM = categoryObj?.category ? categoryObj.category.rm : false;
            const hidden = unvisibleLayers.has(String(feature.category_id)) || categoryRM;
            const canLocate = map && !feature.rm && !hidden;
            const locator = (<LocationIcon enabled={canLocate} 
              onClick={canLocate ? (e) => locateItem(e, obj.drawing) : null}></LocationIcon>);
            const expId = `${id}exp`;
            const node = 
            <TreeLeaf key={id} id={id} isroot={!feature.parent_id} obj={obj} 
              typeIcone={typeIcone} locator={locator} expId={expId}
              onEdit={onEditFeature}
              onDelRestore={onDelRestorItem}
              onClick={()=>onNodeClick(feature)}
            />;
            treeNodes.push(node);
          }
        }
      }
    }
    return treeNodes;
  };

  const treeNodes = useMemo(getNodes,
    [featuresChanges, categoriesChanges, removedShown, unvisibleLayers]);

  return(<>
    {newFolderOpen && <NameDialog title={'New Folder'} onSave={onSaveNewFolder} 
        onClose={()=>setNewFolderOpen(false)}/>}
    <TreeView
      className='bgLightGrey'
      defaultCollapseIcon={<MinusSquare/>}
      defaultExpandIcon={<PlusSquare/>}
      expanded={expandedNodeIds}
    >
      {treeNodes}
    </TreeView>
    </>);
}

export default FeatureTree;