import React, { useEffect, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { useAppStore, showPageOnMap } from "../storages/appStorage";
import { useSensorsStore, setCreatingSensor, showRemoved } from '../storages/sensorsStorage';
import SensorsTable from '../components/sensors/sensorsTable';
import EditSensor from '../components/sensors/editSensor';
import LabelCheckbox from "../components/labelCheckbox";
import DrawerLR from '../components/drawerLeftRight';
import { AddIcon } from '../components/common';

const SensorsView = () => {
  const pagesOnMap =   useAppStore(state => state.pagesOnMap);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const editSensorOpen = useSensorsStore(state => state.editSensorOpen);
  const removedShown =     useSensorsStore(state => state.removedShown);

  useEffect(() => {
    return () => setCreatingSensor(null);
  }, []);

  const sensorsLoaded = useMemo(() => {
    return loadedMdProps.has('sensor');
  }, [loadedMdProps]);

  const addSensor = () => {
    setCreatingSensor({name: ''});
  };

  return (<>
    <DrawerLR open={editSensorOpen} width={530} body={
      editSensorOpen ? <EditSensor/> : <></>
    }/>
    <div className={'fullHeight flexColumn' + (editSensorOpen ? ' disabled' : '')}>
      <div className='rightPanHeader btnsHeader'>
        <div>
          <LabelCheckbox
            label='removed'
            title='Show/Hide removed Sensors'
            checked={removedShown}
            onClick={(e) => showRemoved(e.target.checked)}/>
          <LabelCheckbox
            label='Show on Map'
            title='Show/Hide Users on Map'
            checked={pagesOnMap.has('sensors')}
            onClick={(e) => showPageOnMap('sensors', e.target.checked)}/>
        </div>
        <AddIcon
          titleAccess='Add Sensor'
          onClick={addSensor}/>
      </div>
      {sensorsLoaded 
        ? <SensorsTable/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </>);
}

export default SensorsView;