import React, {useMemo, useEffect} from 'react';
import { backend, useSyncEngStore } from '../../sync-engine';
import { escHandlers, useAppStore, showOkDialog } from "../../storages/appStorage";
import { setShowEditUser, setShowFullProfile, deleteUser, 
  restoreUser} from '../../storages/usersStorage';
import { TitleWithClose } from "../dialog";
import { EditIcon, DelRestorIcon} from '../common';
import Colors from '../../styles/colors';
import Utils from '../../Utils';

const FullProfile = () => {
  const usersObjects = useSyncEngStore(state => state.usersObjects, () => false);
  const rolesObjects = useSyncEngStore(state => state.rolesObjects, () => false);
  const selectedIds = useAppStore(state => state.selectedIds);

  const {selectedId: selectedUserId} = useMemo(
    () => Utils.getSelectedIds('users', selectedIds), [selectedIds]);

  const obj = usersObjects?.[selectedUserId];
  const user =   obj?.user;
  const points = obj?.points;
  const patrols = obj?.patrols;
  const loginState = obj?.loginState;
  const pointsArr = points ? Array.from(points.values()) : [];

  const animalPoints = pointsArr.filter(point => point.category === 'animal');
  const animalSightings = animalPoints .filter(point => point.details?.sighting === 'direct');
  const infoPoints = pointsArr.filter(point => point.category === 'info');

  useEffect(() => {
    escHandlers.set('FullProfile', () => setShowFullProfile(false));
    return () => escHandlers.delete('FullProfile');
  }, []);

  const getTotalDistance = () => {
    let distance = 0;
    if (!patrols) return distance;

    for (const patrol of patrols.values()) {
      if (!isNaN(patrol.distance)) distance = distance + patrol.distance;
    }
    return distance;
  }

  const userPhoto = user?.photo
  ? <img 
    className='prifilePhoto'
    src={backend.getWorkspaceUrl()+'/file/'+user.photo}/>
  : null;

  const roleIdToName = (roleId) => 
    (rolesObjects[roleId]?.name) ? rolesObjects[roleId].name : 'Unknown';

  const totalDistanceStr = Utils.formatDistance(getTotalDistance());

  const login = user?.login ? user.login : '';
  const phone = user?.phone ? user.phone : '';
  const timeJoined = user?.timeJoined ? Utils.formatDate(user.timeJoined) : '';
  const device = loginState ? loginState.device_id : '';
  
  const userData =
    <div className='expTable'>
      <table className='fullWidth leftAlignTable'>
        <tbody>
          <tr><td>Name</td><td>{user?.name}</td></tr>
          <tr><td>Login</td><td>{login}</td></tr>
          <tr><td>Telephone</td><td>{phone}</td></tr>
          <tr><td>Password</td><td>******</td></tr>
          <tr><td>Type</td><td>{roleIdToName(user?.role_id)}</td></tr>
        </tbody>
      </table>
    </div>;

  const statistics =
    <div className='expTable'>
      <table className='fullWidth leftAlignTable'>
        <tbody>
          <tr><td>Added:</td><td>{timeJoined}</td></tr>
          <tr><td>Devise:</td><td>{device ? device : '-'}</td></tr>
          <tr><td>Patrols:</td><td>{patrols?.size ? patrols.size : 0}</td></tr>
          <tr><td>Total Distance:</td><td>{totalDistanceStr}</td></tr>
          <tr><td>Animal Reports:</td><td>{animalPoints.length}</td></tr>
          <tr><td>Animal Sightings:</td><td>{animalSightings.length}</td></tr>
          <tr><td>Info reports:</td><td>{infoPoints.length}</td></tr>
        </tbody>
      </table>
    </div>;

  const onDeleteUser = (user) => {
    showOkDialog({
      title: 'Delete user', 
      message: `Do you want to delete user ${user.name}?`, 
      onOK: () => deleteUser(user.id)});
  };
  const onRestoreUser = (user) => {
    showOkDialog({
      title: 'Restore user', 
      message: `Do you want to restore user ${user.name}?`, 
      onOK: () => restoreUser(user.id)});
  };

  return (
    user ? 
      <div>
        <TitleWithClose 
          onClose={() => setShowFullProfile(false)} 
          bgcolor={Colors.listRowOpened}>
          {'Profile: ' + user.name}
        </TitleWithClose>
        <div className='prifileContent'>
          <div className='prifile1Row'>
            {userPhoto}
            <div className='prifileName'>
              {roleIdToName(user.role_id)} {user.name}
            </div>
          </div>
          <div className='prifile2Row'>
            {userData}
            {!user.rm && <EditIcon onClick={() => setShowEditUser(true)}/>}
            <DelRestorIcon 
              rm={user.rm} 
              enabled={true}
              onDelete={() => onDeleteUser(user)}
              onRestore={() => onRestoreUser(user)}/>
          </div>
          <div className='prifile3Row'>
            {statistics}
          </div>
        </div>
      </div>
      : <div>User details can't be found</div> // TODO: error dialog
  );
}

export default FullProfile;