import Module from "../Module";

//--------------------------------------------------------------------------------
// MODULE: ROLES
export default class  Roles extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            role:           {syncUrl:'/roles/load_roles'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        let results = {};
        if(changes.role) this.onChanges (changes.role, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onChanges(changes,results){
        changes.forEach(role => {
            this.objects[role.id] = role
            results     [role.id] = role;
        });
    }
}
