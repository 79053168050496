import React from "react";
import { FormControlLabel, Radio } from '@mui/material';
const LabelRadio = ({label, title, disabled, checked, value, onChange}) => {
  return <FormControlLabel
    label={label}
    title={title}
    onClick={(e) => e.stopPropagation()}
    control={<Radio
      size="small"
      sx={{padding: 0}}
      disabled={disabled}
      checked={checked}
      value={value}
      onChange={onChange}
      name="radio-buttons2"
    />}
  />;
};
export default LabelRadio;