import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/styles';
import { TextField, Paper } from '@mui/material';
import { escHandlers } from "../../storages/appStorage";
import CategorySelect from './categorySelect';
import MappersSelect from './mappersSelect';
import { TitleWithClose } from "../dialog";
import { ButtonGreen } from '../common';
import { useSensorsStore, setShowEditSensor, setCreatingSensor, createSensor, 
  editSensor, } from '../../storages/sensorsStorage';

const StyledButton = styled(ButtonGreen)({
  margin: '1em 1em 0',
});

const EditSensor = () => {
  const creatingSensor = useSensorsStore(state => state.creatingSensor);
  
  const editMode = creatingSensor.id;

  const [name, setName] = useState(editMode ? creatingSensor.name : '');
  const [category, setCategory] = useState('acidity');

  useEffect(() => {
    escHandlers.set('EditSensor', () => setShowEditSensor(false));
    return () => escHandlers.delete('EditSensor');
  }, []);

  const changeNewSensorCategory = () => {
    if (editMode) return;
    if (category == 'aslan') {
      const aslanCreatingSensor = {
        name: '',
        category: 'aslan',
        conf: {sensor_id: '',
        table: '',
        mappers: {
          x: '', 
          y: '', 
          z: ''}}
      };
      setCreatingSensor(aslanCreatingSensor);
    } else if (category == 'digital') {
      const digitalCreatingSensor = {
        name: '',
        category: 'digital',
        conf: {SerialNumber: ''}
      };
      setCreatingSensor(digitalCreatingSensor);
    } else if (category == 'sensorbee') {
      const beeCreatingSensor = {
        name: '',
        category: 'sensorbee',
        conf: {installationID: ''}
      };
      setCreatingSensor(beeCreatingSensor);
    } else {
      setCreatingSensor({name: '', category: ''});
    }
  };
  useMemo(changeNewSensorCategory, [category]);

  // aslan
  const aslanSensor = creatingSensor.category == 'aslan';
  const aslanConf = aslanSensor ? creatingSensor.conf : {};
  const [sensorId, setSensorId] = useState(aslanSensor ? aslanConf.sensor_id : '');
  const [table, setTable] = useState(aslanSensor ? aslanConf.table : '');

  const sensor2SelectVal = (val) => {
    if (val == 'lat' || val == 'lng') return val;
    if (val) return 'chart';
    return '-'
  }
  const [mX, setMX] = useState(sensor2SelectVal(aslanConf?.mappers?.x));
  const onInputMX = (val) => setMX(val);

  const [mY, setMY] = useState(sensor2SelectVal(aslanConf?.mappers?.y));
  const onInputMY = (val) => setMY(val);

  const [mZ, setMZ] = useState(sensor2SelectVal(aslanConf?.mappers?.z));
  const onInputMZ = (val) => setMZ(val);

  const getAvailableItems = () => {
    const availItems = new Set(['-', 'lat', 'lng', 'chart']);
    if (mX == 'lat' || mY == 'lat' || mZ == 'lat') availItems.delete('lat');
    if (mX == 'lng' || mY == 'lng' || mZ == 'lng') availItems.delete('lng');
    return Array.from(availItems);
  };

  const availableItems = useMemo(getAvailableItems, [mX, mY, mZ]);

  const getMapperItems = (val) => {
    if (val == 'lat' || val == 'lng') return [val, ...availableItems];
    return availableItems;
  }

  const onInputSensorId = (e) => setSensorId(e.target.value);
  const onInputTable =   (e) => setTable(e.target.value);

  const mapperChanged = (sensorVal, selectVal) => {
    if (sensorVal == 'lat' || sensorVal == 'lng') return sensorVal != selectVal;
    if (sensorVal) return selectVal != 'chart';
    return selectVal != '-';
  }
  const aslanDataChanged = () => {
    if (aslanSensor) {
      if (!sensorId || !table || (mX == '-' && mY == '-' && mZ == '-')) return false;
      if (aslanConf.sensor_id != sensorId) return true;
      if (aslanConf.table != table) return true;
      if (aslanConf.mappers) {
        if (mapperChanged(aslanConf.mappers.x, mX)) return true;
        if (mapperChanged(aslanConf.mappers.y, mY)) return true;
        if (mapperChanged(aslanConf.mappers.z, mZ)) return true;
      }
    }
    return false;
  }

  // digital
  const digital = creatingSensor.category == 'digital';
  const digitalConf = digital ? creatingSensor.conf : {};
  const [serialNumber, setSerialNumber] = useState(digital ? digitalConf.SerialNumber : '');
  const onInputSerialNumber = (e) => setSerialNumber(e.target.value);
  const digitalDataChanged = () => {
    if (digital) {
      return serialNumber && digitalConf.SerialNumber != serialNumber;
    }
    return false;
  }

  // sensorbee
  const sensorBee = creatingSensor.category == 'sensorbee';
  const beeConf = sensorBee ? creatingSensor.conf : {};
  const [installationID, setInstallationID] = useState(sensorBee ? beeConf.installationID : '');
  const onInputInstId = (e) => setInstallationID(e.target.value);
  const beeDataChanged = () => {
    if (sensorBee) {
      return installationID && beeConf.installationID != installationID;
    }
    return false;
  }

  const onInputName = (e) => setName(e.target.value);

  const select2SensorVal = (val, colName) => {
    if (val == 'lat' || val == 'lng') return val;
    if (val == 'chart') return colName;
    return null;
  }

  const getSensorConf = () => {
    if (aslanSensor) {
      const mappers = {};
      const mapperX = select2SensorVal(mX, 'x');
      const mapperY = select2SensorVal(mY, 'y');
      const mapperZ = select2SensorVal(mZ, 'z');
      if (mapperX) mappers.x = mapperX;
      if (mapperY) mappers.y = mapperY;
      if (mapperZ) mappers.z = mapperZ;
      return {sensor_id: sensorId, table: table, mappers: mappers};
    }
    if (digital)     return {SerialNumber: serialNumber};
    if (sensorBee)   return {installationID};
    return null;
  };

  const onSave = () => {
    const conf = getSensorConf();
    if (editMode) {
      editSensor(creatingSensor.id, name, conf);
    } else {
      createSensor(name, category, conf);
    }
    setCreatingSensor(null);
  };

  const title = editMode ? `Edit "${creatingSensor.name}"` : 'New Sensor';
  const canSave = name && (name != creatingSensor.name
                  || aslanDataChanged() || beeDataChanged() || digitalDataChanged());

  return(
  <div className='flexColumn paddingButTop'>
    <TitleWithClose onClose={() => setCreatingSensor(null)}>
      {title}
    </TitleWithClose>
    <TextField
      variant='outlined'
      margin='normal'
      label='Sensor Name'
      value={name}
      onChange={onInputName}
    />
    {!editMode && <CategorySelect onCategoryChange={setCategory}/>}
    {aslanSensor &&
    <Paper elevation={3} className='flexColumn paddingElem marginElem'>
      Aslan Data
      <TextField
        variant='outlined'
        margin='normal'
        label='sensor_id'
        value={sensorId}
        onChange={onInputSensorId}
      />
      <TextField
        variant='outlined'
        margin='normal'
        label='table'
        value={table}
        onChange={onInputTable}
      />


      <MappersSelect 
        label={'mapper x'}
        items={getMapperItems(mX)}
        value={mX}
        onMapperChange={onInputMX}
      />
      <MappersSelect 
        label={'mapper y'}
        items={getMapperItems(mY)}
        value={mY}
        onMapperChange={onInputMY}
      />
      <MappersSelect
        label={'mapper z'} 
        items={getMapperItems(mZ)}
        value={mZ}
        onMapperChange={onInputMZ}
      />
    </Paper>}
    {digital && 
    <Paper elevation={3} className='flexColumn paddingElem marginElem'>
      Digital Data
      <TextField
        variant='outlined'
        margin='normal'
        label='SerialNumber'
        value={serialNumber}
        onChange={onInputSerialNumber}
      />
    </Paper>}
    {sensorBee && 
    <Paper elevation={3} className='flexColumn paddingElem marginElem'>
      Sensorbee Data
      <TextField
        variant='outlined'
        margin='normal'
        label='installationID'
        value={installationID}
        onChange={onInputInstId}
      />
    </Paper>}
    <div>
      <StyledButton disabled={!canSave} onClick={onSave}>Save</StyledButton>
    </div>
  </div>
  );
}

export default EditSensor;