import React, { useState } from 'react';
import { TextField } from '@mui/material';
import DialogButton from './dialogButton';

const NumberDialog = ({title, val, onSave, onClose}) => {
  const [nmbVal, setNmbVal] = useState(val);

  const onInputNumber = (e) => {
    if (e.target.value < 1 || e.target.value > 32) return;
    if (e.target.value.length == 0) {
      setNmbVal(1);
      return;
    }
    setNmbVal(e.target.value);
  };

  const saveAndClose = () => {
    onSave(nmbVal)
    onClose();
  };

  return <DialogButton title={title} okName={'Save'}
      onOK={saveAndClose} onClose={onClose}>

    <div className='flexJustCenter'>
      <div className='numberDlgText'>
          <TextField variant="outlined" size="small" label="Width" type="number"
            value={nmbVal} onChange={onInputNumber}/>
      </div>
    </div>
  </DialogButton>;
};

export default NumberDialog;