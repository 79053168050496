// https://stackoverflow.com/questions/39777833/image-onload-event-in-isomorphic-universal-react-register-event-after-image-is
import { useRef, useState, useEffect} from "react";

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const ref = useRef();

  const reset = () => {
    setLoaded(false);
    setError(false);
  }

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      setLoaded(true);
    }
  })

  return [ref, loaded, error, ()=>setLoaded(true), ()=>setError(true), reset];
}

export default useImageLoaded;
