import React, { useState, useEffect, useMemo, useLayoutEffect, useRef } from "react";
import { AutoSizer } from 'react-virtualized';
import RvGridAnimated from './rvGridAnimated';

const TblHeader = ({header, width, getColumnWidth}) => {

  const getWidths = () => {
    if (header) {
      return header.map((item, ind) => 
        getColumnWidth({index: ind, listWidth: width}));
    }
    return [];
  };

  const getItems = () => 
    columnWidths.map((width, ind) => <div
      key={ind}
      className='flexCenter'
      style={{ width: width }}>{header[ind]}</div>
    );

  const columnWidths = useMemo(() => getWidths(), 
    [header, width]);

  const headerItems = useMemo(() => getItems(), 
    [columnWidths]);

  return <div className='flexElem fullHeight'>{headerItems}</div>
};

const RvTable = ({rows, header, idInd, clickColmns, selectedId, 
  orderBy, orderAsc, onRowClick, rowHeight, expElHeight, getExpEl, 
  getColumnWidth, getRowStyle, containerWidth}) => {
  const [resizedWidth, setResizedWidth] = useState(0);
  const [initialWidth, setInitialWidth] = useState(0);
  const [scrollInd, setScrollInd] = useState(-1);
  const gridRef = useRef();
  const headerRef = useRef();

  const onSizeChange = () => {
    if (headerRef.current) {
      setResizedWidth(headerRef.current.offsetWidth);
    }
  }

  const init = () => 
    window.addEventListener('resize', onSizeChange);

  const deInit = () => 
    window.removeEventListener('resize', onSizeChange);

  useEffect(() => {
    init();
    return deInit;
  }, []);

  useLayoutEffect(() => {
    if (headerRef.current) {
      setInitialWidth(headerRef.current.offsetWidth);
    }
  }, []);

  const scrollToSelected = () => {
    if (selectedId) {
      let selectedInd = rows.findIndex(row => row[idInd] == selectedId);
      if (selectedInd > -1) setScrollInd(selectedInd + 1);  // findIndex finds common row; +1 == srcroll to Exp row
    }
  };

  useEffect(() => {
    if (gridRef.current) gridRef.current.recomputeGridSize();
    scrollToSelected();
  }, [selectedId]);

  useEffect(() => {
    if (gridRef.current) gridRef.current.recomputeGridSize();
  }, [orderBy, orderAsc, expElHeight, resizedWidth, containerWidth]);

  const getValStr = (value) => {
    if (value && typeof value == 'string') return value;
    if (value?.type == 'div') {
      const ch = value.props?.children;
      if (ch && typeof ch == 'string') return ch;
    }
    return null;
  }

  const cellRenderer = ({columnIndex, rowIndex, key, style, listWidth}) => {
    const newStyle = Object.assign({}, style);
    newStyle.height = style.height - 1;   // height correction to show 1px bottom border
    const id = rows[rowIndex][idInd];

    if (rowIndex % 2 == 1) { // handle exp rows
      if (id == selectedId) { // selected exp row
        const expEl = getExpEl();
        if (columnIndex == 0) { // single sell full width
          newStyle.width = listWidth;
          return (
            <div className='listExpCell' key={key} style={newStyle}>
              {expEl}
            </div>
          );
        }
        newStyle.width = 0;
        return (   // hidden other sells 0 width
          <div key={key} style={newStyle}>
          </div>
        );
      }
      
      return <div key={key} style={newStyle}></div>; // closed exp rows
    }

    const row = rows[rowIndex];
    const value = row[columnIndex];

    const cellStyle = getRowStyle(id);
    const onClick = clickColmns.includes(columnIndex) ? () => onRowClick(rowIndex) : null;

    return (
      <div className={cellStyle} key={key} style={newStyle} onClick={onClick}>
        <div title={getValStr(value)} className='cellContent'>{value}</div>
      </div>
    );
  };

  const getRowHeight = ({index}) => {
    if (index % 2 == 1) { // handle exp rows
      const id = rows[index][idInd];
      if (id == selectedId) {
        return expElHeight ? expElHeight : 280;    // selexted exp row
      }
      return 0; // closed exp rows
    }

    return rowHeight ? rowHeight : 41;
  }

  const easeInQuad = (x) => x * x;

  // shamanic dance for Safari v13.
  // Safari can't calculate full heigt child in flex-grow container.
  // before: "height: 100%"
  // after: "flex-grow:1" in "flex-direction:column" container ("height:100%"->"flex-grow:1" in listContainer)
  // <... flex-grow>                <... flex-grow, display:flex, flex-direction:column>
  //   <... height: 100%;/>   =>      <... flex-grow:1 />
  // </... flex-grow>               </... flex-grow, display:flex, flex-direction:column>

  return (
    <div className='flexGrow flexColumn'>
      <div className='listContainer'>
        <div className='listHeader' ref={headerRef}>
          <TblHeader 
            header={header}
            width={containerWidth ? containerWidth : resizedWidth ? resizedWidth : initialWidth}
            getColumnWidth={getColumnWidth}/>
            
        </div>
        <AutoSizer>
          {({width, height}) => {
            const listWidth = containerWidth ? containerWidth : width;
            return (
              <RvGridAnimated
                ref={gridRef}
                height={height - 30} // listHeader height: 30px;
                width={listWidth}
                scrollToRow={scrollInd}
                duration={800}
                easing={easeInQuad}
                cellRenderer={(args) => {
                  args.listWidth = listWidth;
                  return cellRenderer(args);
                } }
                columnCount={idInd}
                columnWidth={(args) => {
                  args.listWidth = listWidth;
                  return getColumnWidth(args);
                } }
                rowCount={rows.length}
                rowHeight={getRowHeight} />
            );
          }}
        </AutoSizer>
      </div>
    </div>
  );
};

export default RvTable;