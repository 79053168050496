import React from "react";
import Dialod from "./dialog";

const ErrorDialog = ({ message, onClose }) => {
  return (
    <Dialod title={'Error'} onClose={onClose}>
      <div className="padding1em3em">{message}</div>
    </Dialod>
  );
};

export default ErrorDialog;