
const initList = (pref, set, defOrderBy) => {
  const orderByStr = localStorage.getItem(pref + 'OrderBy');
  const orderAscStr = localStorage.getItem(pref + 'OrderAsc');

  const orderBy = orderByStr ? Number(orderByStr) : defOrderBy;
  const orderAsc = orderAscStr ? orderAscStr === 'true' : true;
  set({ orderBy: orderBy, orderAsc: orderAsc});

  const removedShownStr = localStorage.getItem(pref + 'RemovedShown');
  const removedShown = removedShownStr ? removedShownStr === 'true' : false;
  set({ removedShown: removedShown });
};

const setOrder = (pref, orderBy, get, set) => {
  const currentOB = get().orderBy;
  if (currentOB === orderBy) {
    const orderAsc = get().orderAsc;
    set({ orderAsc: !orderAsc });
    localStorage.setItem(pref + 'OrderAsc', !orderAsc);
  } else {
    set({ orderBy: orderBy, orderAsc: true });
    localStorage.setItem(pref + 'OrderBy', orderBy);
    localStorage.setItem(pref + 'OrderAsc', true);
  }
}

const showRemoved = (pref, show, set) => {
  set({ removedShown: show });
  localStorage.setItem(pref + 'RemovedShown', Boolean(show));
};

export {
  initList,
  setOrder,
  showRemoved,
};