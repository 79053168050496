import React from "react";
import { useWorkspaceStore, } from '../../storages/workspaceStorage';
import LiveView from './liveView';
import Dialod from '../dialog/dialog';

const LiveViewSetup = ({onCancel}) => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  return <Dialod
    fullScreen={true}
    title={<div className="flexJustCenter">Live View Setup</div>}
    onClose={onCancel}>
    <LiveView wsId={workspaceId} setup={true}/>
  </Dialod>;
}

export default LiveViewSetup;