import create from 'zustand';
import { showOkDialog, showError } from './appStorage';
import { backend, syncEngine } from '../sync-engine';
import * as storageUtils from "./utils/storageUtils";

const initCameras = () => camerasStoreApi.getState().initCameras();
const setCreatingCamera = (camera) => camerasStoreApi.getState().setCreatingCamera(camera);
const createEditCamera = (camera) => camerasStoreApi.getState().createEditCamera(camera);
const deleteCamera = (cameraId) => camerasStoreApi.getState().deleteCamera(cameraId);
const restoreCamera = (cameraId) => camerasStoreApi.getState().restoreCamera(cameraId);
const setCameraMarkers = (markers) => camerasStoreApi.getState().setCameraMarkers(markers);
const getCameraMarkers = () => camerasStoreApi.getState().cameraMarkers;
const setOrder = (orderBy) => camerasStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => camerasStoreApi.getState().showRemoved(show);

const DEF_ORDER_BY = 0; // index of 'name' header in CamerasView

const [useCamerasStore, camerasStoreApi] = create((set, get) => ({
  creatingCamera: null,
  cameraMarkers: new Map(), // all camera markers currenly shown on map
  orderBy: DEF_ORDER_BY,
  orderAsc: true,
  removedShown: false,

  initCameras: () => 
    storageUtils.initList('cameras', set, DEF_ORDER_BY),

  setCreatingCamera: (camera) => set({ creatingCamera: camera }),

  createEditCamera: async (camera) => {
    const params = {
      id          : camera.id,
      name        : camera.name,
      folder      : camera.folder,
      prefix      : camera.prefix,
      lat         : camera.lat,
      lng         : camera.lng
    }
    const path = params.id ? '/cameras/update_camera' : '/cameras/insert_camera';
    
    try {    
      await backend.request("post", path, params);
    } catch (err) {
      console.error(err);
      showError('Fail to ' + (params.id ? 'update ' : 'create new ') + `camera: ${err}`);
    }
    syncEngine.syncNow();
  },

  deleteCamera : async (id) => {
    try{    
      await backend.request('POST', '/cameras/remove_camera', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to delete camera: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  restoreCamera : async (id) => {
    try{    
      await backend.request('POST', '/cameras/restore_camera', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to restore camera: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  setCameraMarkers: (markers) => set({ cameraMarkers: markers }),

  setOrder: (orderBy) => 
    storageUtils.setOrder('cameras', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('cameras', show, set),
}));

export { 
  useCamerasStore,
  initCameras,
  setCreatingCamera,
  createEditCamera,
  deleteCamera,
  restoreCamera,
  setCameraMarkers,
  getCameraMarkers,
  setOrder,
  showRemoved
};