import React, { useMemo, useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useLvStore, showPageOnMapWd } from "../../../../storages/lvStorage";
import DialogButton from '../../../dialog/dialogButton';
import TimeSelector from '../../../timeSelector';
import { ButtonGreen } from '../../../common';
import Utils from '../../../../Utils';

const patrolsKey = 'Patrols Widget';

const PatrolsSettings = ({onClose}) => {
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const patrolsTimeFilter = useLvStore(state => state.patrolsTimeFilter);

  const [checked, setChecked] = useState(pagesOnMapWd.has(patrolsKey));
  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);

  const saveAndClose = () => {
    showPageOnMapWd(patrolsKey, checked);
    onClose();
  };

  const getTfData = () => {
    return Utils.timeFilterData(patrolsTimeFilter);
  }
  const {label} = useMemo(getTfData, [patrolsTimeFilter]);

  return <>
    {timeSelectorVisible && <TimeSelector 
      onClose={()=>setTimeSelectorVisible(false)} 
      wdKey={patrolsKey} tfObj={patrolsTimeFilter}/>}
    <DialogButton title={'Patrols Widget Settings'} okName={'Save'}
        onOK={saveAndClose} onClose={onClose}>
      <div className='flexColumn'>
        <FormControlLabel label={'Show on Map'} control={
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />}/>
        history depth<ButtonGreen onClick={()=>setTimeSelectorVisible(true)}>{label}</ButtonGreen>
      </div>
    </DialogButton>
  </>;
};

export default PatrolsSettings;