import React, { useState } from 'react';
import DialogButton from './dialogButton';

const FileDialog = ({title, accept, onSave, onClose}) => {
  const [file, setFile]   = useState(null);

  const saveAndClose = () => {
    onSave(file);
    onClose();
  };

  const onInputFile = (event) => {
    setFile(event.target.files[0])
  };

  return <DialogButton title={title} okName={'Save'} 
    okDisabled={!Boolean(file)} onOK={saveAndClose} onClose={onClose}>

    <input accept={accept} onChange={onInputFile} type='file'/>
  </DialogButton>;
};

export default FileDialog;