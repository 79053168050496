import Module from "../Module"

//--------------------------------------------------------------------------------
// MODULE: BLE

class  Ble extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            ble:           {syncUrl:'/ble/load_ble'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        console.log('Ble.onDataChanges')
        let results = {};
        if(changes.ble) this.onBleChanges (changes.ble, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onBleChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            obj.ble = prop;
        });
    }
    
    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
            obj = {
                id : id,
                ble:null
            }
            this.objects[id] = obj;
        }
        return obj;
    }
}

export default Ble;
