import { useState, useMemo, useEffect, } from 'react';
import { useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected, 
  setSubSelected } from "../../../storages/appStorage";
import { useMapStore, removeDrawingsFromMap, clusterMarkers, 
  unClusterMarkers } from '../../../storages/mapStorage';
import { usePointsStore, setPointMarkers, 
  getPointMarkers } from '../../../storages/pointsStorage';
import { PointDrawing } from './mapObjects';
import Utils from '../../../Utils';

const PointDrawings = () => {
  const map = useMapStore(state => state.map);
  const pointsChanges = useSyncEngStore(state => state.pointsChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const subSelectedIds = useAppStore(state => state.subSelectedIds);
  const patrolIdToShowPoints = useAppStore(state => state.patrolIdToShowPoints);
  const filters = usePointsStore(state => state.filters);

  const [isShown, setShown] = useState(false);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('points', selectedIds), [selectedIds]);

  const getSubSelectedIds = () => {
    const subSelectedId =     subSelectedIds[0] ? subSelectedIds[0] : '';
    const prevSubSelectedId = subSelectedIds[1] ? subSelectedIds[1] : '';
    return {subSelectedId, prevSubSelectedId};
  };
  const {subSelectedId, prevSubSelectedId} = useMemo(getSubSelectedIds, 
    [subSelectedIds]);

  const MARKER_ICONS_PATH = '/images/points/marker-icons/';

  const getIcones = (point) => {
    // viewBox (24.27 38.21)
    const selectSize = new window.google.maps.Size(42, 66.12); // 66.12/42 == 38.21/24.27
    const path = window.location.origin + MARKER_ICONS_PATH;
    const url = path + Utils.pointCategoryIconFileName(point);
    return {icon:         {url: url},
            selectedIcon: {url: url, scaledSize: selectSize}};
  }

  const checkAndUpdateObjDrawing = (obj, mapMarkers) => {
    if (obj && obj.point && !obj.point.rm) {
      updateObjDrawing(obj, null, mapMarkers);
    }
  };

  const updateObjDrawing = (obj, map, mapMarkers) => {
    const point = obj.point;
    const id = point.id;
    if (!obj.drawing) {
      obj.drawing = new PointDrawing(getIcones(point));
    }
    const selected = patrolIdToShowPoints 
      ? (subSelectedId == id) : (selectedId == id);
    obj.drawing.update(map, [point.lng, point.lat], 
      selected, {title: 'pointid: '+id});
    if (obj.listener) obj.listener.remove();
    obj.listener = window.google.maps.event.addListener(obj.drawing.mapobj, 
      'click', patrolIdToShowPoints 
        ? () => setSubSelected(id) 
        : () => setSelected('points', id));

    mapMarkers.set(String(obj.id), {
      listener:     obj.listener, 
      drawing:      obj.drawing,
    });
  };

  const showOnMap = (withMap = true) => {
    if (map) {
      const objects = objectsSnap('points');
      const oldMarkers = getPointMarkers();
      const newMarkers = new Map();
      for(const id in objects){
        const obj = objects[id];
        const point = obj.point;

        const filtered = patrolIdToShowPoints || (point &&
          (filters.has(point.category)));
        
        if (point && !point.rm && filtered && (!patrolIdToShowPoints || 
            point.patrol_id == patrolIdToShowPoints)) {
          oldMarkers.delete(id);
          updateObjDrawing(obj, withMap ? map : null, newMarkers);
        } else {
          if (obj.listener) obj.listener.remove();
          if (obj.drawing) obj.drawing.remove();
        }
      }
      setPointMarkers(newMarkers);
      unClusterMarkers(oldMarkers); // the rest of oldMarkers
      removeDrawingsFromMap(oldMarkers);
      clusterMarkers(newMarkers);
    }
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap();
  }

  const onPageHide = () => {
    setShown(false);
    const allPointMarkers = getPointMarkers();
    unClusterMarkers(allPointMarkers);
    removeDrawingsFromMap(allPointMarkers);
    setPointMarkers(new Map());
  }

  const onChanges = () => {
    if (isShown) showOnMap(false);
  }

  const onFilterChanges = () => {
    if (isShown) showOnMap(false);
  }

  const onSelectedChange = () => {
    if (isShown) {
      const snap = objectsSnap('points');
      if (!snap) return;  // can be at Live View
      const allPointMarkers = getPointMarkers();
      const prevObj = patrolIdToShowPoints 
        ? snap[prevSubSelectedId] : snap[prevSelectedId];
      const selectObj = patrolIdToShowPoints 
        ? snap[subSelectedId] : snap[selectedId];

      checkAndUpdateObjDrawing(prevObj, allPointMarkers);
      checkAndUpdateObjDrawing(selectObj, allPointMarkers);
    }
  };

  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  useEffect(() => {
    onSelectedChange();
  }, [selectedIds, subSelectedIds]);

  useMemo(onChanges, [map, pointsChanges, patrolIdToShowPoints]);
  useMemo(onFilterChanges, [filters]);
  
  return(null); // no html render, draw on map directly instead
}

export default PointDrawings;