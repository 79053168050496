import React, { useState, } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageViewer = ({images, onClose, index}) => {
  const currentInd = (!index || index < 0 || index > images?.length -1) ? 0 : index;
  const [photoIndex,    setPhotoIndex] =    useState(currentInd);

  const isFirst = photoIndex === 0;
  const isLast = photoIndex === images?.length-1;

  return ( images?.length > 0 ?      
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={isLast ? null : images[photoIndex + 1]}
      prevSrc={isFirst ? null : images[photoIndex - 1]}
      onCloseRequest={onClose}
      onMovePrevRequest={isFirst ? null : 
        () => setPhotoIndex(photoIndex - 1)
      }
      onMoveNextRequest={isLast ? null : 
        () => setPhotoIndex(photoIndex + 1)
      }
    />
    : null
  );
}

export default ImageViewer;