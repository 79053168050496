import backend from "./Backend"
import data, { setSyncInterval, setSyncDelay, setSyncState, 
  setSyncData } from "./Data"

class SyncEngine {
    constructor() {
        this.state = "STOPPED";
        this.syncDelay = 0;
        this.syncTime = 0;
        this.syncInterval = 30;
        this.error= null;
        this.success= null;
        this.clock=null;

        setSyncInterval(this.syncInterval);

        this.onClock = this.onClock.bind(this);
        this.onSyncFinished = this.onSyncFinished.bind(this);
    }

    onClock(){
        this.updateSyncDelay();
    }

    syncNow(){
        this.onSyncRequired();
    }
    
    // UPDATE SYNC DELAY & START SYNC IF NEED
    updateSyncDelay(){
        // Calculate the new syncDelay
        this.syncDelay = new Date().getTime() - this.syncTime;
        //console.log('updateSyncDelay',this.syncDelay);

        if(this.syncDelay>=(this.syncInterval*1000))
            this.onSyncRequired();
        else {
          setSyncDelay(this.syncDelay);
        }
    }    

    // SYNC DATA
    onSyncRequired(){
        if(this.state!='CONNECTED'){ // to prevent sync in connected state
            // Change state
            this.state = 'CONNECTED';
            this.error = null;
            this.success = null;
            setSyncData({
              state:this.state, 
              error:this.error, 
              success:this.success
            });

            // Sync data
            this.sync();
        }
    }

    onSyncFinished(error, success){
        this.state = "RUNNING";
        this.error = error;
        this.success = success;
        if(!error){
            this.syncDelay = 0;
            this.syncTime = new Date().getTime();
        }

        setSyncData({
          state:this.state, 
          error:this.error, 
          success:this.success
        });
        setSyncDelay(this.syncDelay);
    }

    start(){
        console.log('SyncEngine.start');
        // Start clock
        this.clock = window.setInterval(this.onClock, 5000);

        // Change state
        this.state = "RUNNING";
        setSyncState(this.state);
        this.updateSyncDelay();
    }

    stop(){
        console.log('SyncEngine.stop');
        // Stop clock
        if(this.clock){
            window.clearInterval(this.clock);
            this.clock = null;
        }

        // Change state
        this.state = "STOPPED";
        this.syncDelay = 0;
        this.syncTime = 0; // to sync immediately after start

        setSyncState(this.state);
        setSyncDelay(this.syncDelay);
    }

    sync(){
        //console.log('loading data... ');
    
        // ROOT request
        fetch(backend.API_URL+'/',{
            method:'GET',
            credentials: "include",// to allow cookies
            headers: { 
                'Content-Type':'application/json'
            }
        })
        .then(response => {
            response.json()
              .then(apiInfo =>{
                  if(response.status===200){
                    this.doSync(apiInfo);
                  }else{
                    this.onSyncFinished('response.status: '+response.status);
                  }
              })
              .catch(err=>{
                this.onSyncFinished('Response parcing error: '+err);    
              });
        })
        .catch(() => {
            this.onSyncFinished('Backend is not available');
        });
    }

    async doSync(apiInfo){
        await data.sync();
        this.onSyncFinished(null, apiInfo.name+', v'+apiInfo.version);
    }
}

export default new SyncEngine();
