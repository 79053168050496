import React from "react";
import { useSyncEngStore } from "../../sync-engine";

const DataState = () => {
  const storeState = useSyncEngStore(state => state.storeState);

  return <span className="data-state">
    {storeState}
  </span>
}

export default DataState;

