import React, { useState, } from "react";
import { styled } from '@mui/styles';
import { Icon } from '@mui/material';
import { syncEngine, } from "../../sync-engine"
import { useWorkspaceStore, doLogout, } from '../../storages/workspaceStorage'
import { appModes, useAppStore, setShowSettings, 
  showOkDialog } from '../../storages/appStorage'
import TimeSelector from '../timeSelector';
import AppModeSelector from '../AppModeSelector';
import DataState    from './DataState';
import DataView     from '../../views/DataView';
import LiveViewSetup from '../liveView/liveViewSetup';
import Settings     from '../settings/settings';
import DrawerLR from '../drawerLeftRight';
import { settingsSvg } from "../svg";

const HeaderIcon = styled(Icon)({
  height: '24px', 
  width: '24px',
  color: 'white',
});

export default () => {
    const wsInfo    = useWorkspaceStore(state => state.wsInfo);
    const wsLogo    = useWorkspaceStore(state => state.wsLogo);
    const loginInfo = useWorkspaceStore(state => state.loginInfo);
    const historyDepthStr = useWorkspaceStore(state => state.historyDepthStr);

    const appMode = useAppStore(state => state.appMode);
    const showSettings = useAppStore(state => state.showSettings);

    const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
    const [modeSelectorVisible, setModeSelectorVisible] = useState(false);
    const [dataViewVisible,     setDataViewVisible]     = useState(false);
    const [liveViewVisible,     setLiveViewVisible]     = useState(false);

    const onLogout = () => {
      showOkDialog({
        title: 'Confirm Logout', 
        message: 'Do you want to logout?', 
        onOK: doLogout});
    };

    return <><header>
        <img className="hLogo" title="Workspace logo" src={wsLogo}/>
        <span className="hBlock" title="Workspace name">
            <div className="hFld">{wsInfo.name}</div>
        </span>
        <span className="hBlock hBtn" title="Change Time Period"
          onClick={()=>setTimeSelectorVisible(true)}>
          <div>
            <div className="hTxt">history depth</div>
            <div className="hFld">{historyDepthStr}</div>
          </div>
        </span>   

        <span className="hBlock">
          <div className="hBlock hBtn" title="Backend details" 
              onClick={()=>setDataViewVisible(true)}>
            <div>
              <div className="hTxt">data</div>
              <div className="hFld"><DataState/></div>
            </div>
          </div>
            <div className="hIcon hBtn hSync" title="Sync Backend data"
              onClick={()=>syncEngine.syncNow()}></div>      
        </span>

        <span className="hBlock hBtn" title="Application mode"
          onClick={()=>setModeSelectorVisible(true)}>
          <div>
            <div className="hTxt">mode</div>
            <div className="hFld">{appModes[appMode]}</div>
          </div>
        </span>

        <span className="hBlock hBtn" title="Live View"
          onClick={()=>setLiveViewVisible(true)}>
          <div>
            <div className="hFld">Live View</div>
          </div>
        </span>

        <div className="hBtn hIcon flexCenter" title="Settings">
          <HeaderIcon onClick={() => setShowSettings(!showSettings)}>{settingsSvg}</HeaderIcon>
        </div>

        <span className="hBlock">
          <div className="hFld paddingRight" title="User Name">
            {loginInfo?.user ? loginInfo.user.name : "..."}</div>
          <div className="hIcon hBtn hLogout" title="Logout" onClick={onLogout}/>      
        </span>
    </header>
    <DrawerLR open={showSettings} width={530} body={
      showSettings ? <Settings/> : <></>
    }/>
    {timeSelectorVisible && 
      <TimeSelector onClose={()=>setTimeSelectorVisible(false)}/> }
    {modeSelectorVisible && 
      <AppModeSelector onClose={()=>setModeSelectorVisible(false)}/> }
    {dataViewVisible && <DataView onClose={()=>setDataViewVisible(false)}/>}
    {liveViewVisible && <LiveViewSetup onCancel={()=>setLiveViewVisible(false)}/>}
    </>
}
