import React, { useMemo, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppStore } from '../../../storages/appStorage';
import PatrolsTable from '../../patrols/patrolsTable';

const PatrolsWidget = ({onPubLvMap}) => {
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const contentRef = useRef();

  const patrolsLoaded = useMemo(() => {
    return loadedMdProps.has('patrol');
  }, [loadedMdProps]);

  return <div className='wdContainer'>
    <div className='wdContent' ref={contentRef}>
      {patrolsLoaded 
        ? <PatrolsTable viewMode={true} containerWidth={contentRef?.current?.offsetWidth} 
            onPubLvMap={onPubLvMap}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </div>;
};
export default PatrolsWidget;