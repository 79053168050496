const LINE = 2;
const POLYGON = 3;

const deleteVertex = (e, path, type) => {
  if (e.vertex == undefined) return;
  const minLength = type == LINE ? 2 : 3;
  if (path && path.getLength() > minLength) path.removeAt(e.vertex);
};

class Drawing{
  constructor(){
      this.mapobj = null // Marker|Polyline|Polygon
  }
  remove(){
    if (this.clickListener) {
      window.google.maps.event.removeListener(this.clickListener);
    }
    if (this.mapobj) this.mapobj.setMap(null);
    delete this.clickListener;
    delete this.onClick;
  }
  locate(map){
      if(!this.mapobj) return
      if(this.mapobj.getPath){ // polyline,polygon
          let bounds = new window.google.maps.LatLngBounds();
          this.mapobj.getPath().forEach(pt=>{
              bounds.extend(pt)
          })
          //map.panToBounds(bounds)
          map.panTo(bounds.getCenter())
      }else if(this.mapobj.position){ // marker
          map.panTo(this.mapobj.position) 
      }
  }

  getPath () {
    return [];
  }

  getLynePolyPath () {
    if (this.mapobj) {
      const path = this.mapobj.getPath();
      const pathArr = [];
      path.forEach(point => pathArr.push([point.lng(), point.lat()]));
      return pathArr;
    }
    return [];
  }

  setClickListener (onClick) {
    if (this.mapobj) {
      this.onClick = onClick;
      this.clickListener = window.google.maps.event.addListener(this.mapobj, 'click', onClick); 
    }
  }

  startLynePolyEdit (listener, type) {
    if(this.mapobj) {
      if (this.clickListener) {
        window.google.maps.event.clearListeners(this.mapobj, 'click');
        delete this.clickListener;
      }

      this.oldPath = [];
      const path = this.mapobj.getPath();
      path.forEach(point => this.oldPath.push(point));
      this.mapobj.setEditable(true);
      window.google.maps.event.addListener(path, 'set_at', listener);
      window.google.maps.event.addListener(path, 'insert_at', listener);
      window.google.maps.event.addListener(path, 'remove_at', listener);
      window.google.maps.event.addListener(this.mapobj, 'rightclick', (e) => deleteVertex(e, path, type));
    }
  }

  stopLynePolyEdit (restorePos) {
    if(this.mapobj) {
      this.mapobj.setEditable(false);
      if (restorePos) {
        this.mapobj.setPath(this.oldPath);
      }

      if (this.onClick) {
        this.clickListener = window.google.maps.event.addListener(
          this.mapobj, 'click', this.onClick); 
      }
      const path = this.mapobj.getPath();
      window.google.maps.event.clearListeners(path, 'set_at');
      window.google.maps.event.clearListeners(path, 'insert_at');
      window.google.maps.event.clearListeners(path, 'remove_at');
      window.google.maps.event.clearListeners(this.mapobj, 'rightclick');
  
    }
    delete this.oldPath;
  }
}

class PointDrawing extends Drawing{
  constructor (options) {
    super();
    this.options = {  // default options
      icon:         {url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'},
      selectedIcon: {url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'}
    }
    Object.assign(this.options, options);
  }
  getOptions(selected){
      return {
          icon : (selected? this.options.selectedIcon :
                            this.options.icon)
      };
  }
  update(map, coord, selected, options){
      if(!coord) return

      if (options?.pointIcon) this.options = options.pointIcon;
      const pointOptions = {
          position : {lat:coord[1], lng:coord[0]},
      };
      if (options?.title) pointOptions.title = options.title;

      if (map) pointOptions.map = map;
      Object.assign(pointOptions, this.getOptions(selected))

      // Create / update
      if(this.mapobj)this.mapobj.setOptions(pointOptions)
      else           this.mapobj = new window.google.maps.Marker(pointOptions);
  }
  startEdit (listener) {
    const options = {
      draggable:true,
      icon: 'images/marker_point.png'
    }
    if(this.mapobj) {
      this.oldPos = this.mapobj.getPosition();
      this.mapobj.setOptions(options);

      window.google.maps.event.addListener(this.mapobj, 'dragend', listener);
    }
  }
  stopEdit (restorePos) {
    if(this.mapobj) {
      const options = {
        draggable:false,
        icon: this.options.selectedIcon
      };
      if (restorePos) {
        options.position = this.oldPos;
      }
      this.mapobj.setOptions(options);
      window.google.maps.event.clearListeners(this.mapobj, 'dragend');

      delete this.oldPos;
    }
  }
}

class LineDrawing extends Drawing{
  constructor (options) {
    super();
    this.options = {  // default options
      strokeColor:         '#00008F',
      selectedColor: '#FF0000',
      weight: 3,
      selectedWeight: 5,
      opacity : 1.0,
      selectedOpacity : 1.0,
    }
    Object.assign(this.options, options);
  }
  getOptions(selected){
      return {
          strokeColor   : (selected ? this.options.selectedColor : this.options.strokeColor),
          strokeWeight  : (selected ? this.options.selectedWeight : this.options.weight ),
          strokeOpacity : (selected ? this.options.selectedOpacity : this.options.opacity ),
      }
  }
  update(map, coord, selected, options){
      if(!coord || coord.length < 2) return;

      const path = coord[0].lat && coord[0].lng 
        ? coord                                 // coord is array of objects - patrol style
        : coord.map(x=>({lat:x[1], lng:x[0]})); // coord is array of arrays - feature style

      if (options?.lineColor) this.options.strokeColor = options.lineColor;
      if (options?.lineWidth) this.options.weight = options.lineWidth;

      const lineOptions = {
          map           : map,
          path          : path
      }
      Object.assign(lineOptions, this.getOptions(selected))

      // Create / update
      if(this.mapobj)this.mapobj.setOptions(lineOptions)
      else           this.mapobj = new window.google.maps.Polyline(lineOptions);
  }

  getPath () {
    return this.getLynePolyPath();
  }
  
  startEdit (listener) {
    this.startLynePolyEdit(listener, LINE);
  }

  stopEdit (restorePos) {
    this.stopLynePolyEdit(restorePos);
  }
}
class PolygonDrawing extends Drawing{
  constructor (options) {
    super();
    this.options = {  // default options
      strokeColor:         '#00008F',
      fillColor:     '#00008F',
      strokeOpacity: 1.0,
      fillOpacity: 0.2,
      strokeWeight: 3
    }
    Object.assign(this.options, options);
  }
  getOptions(selected){
      return {
          strokeColor   : (selected ? '#FF0000' : this.options.strokeColor),
          strokeWeight  : (selected ? 5 : this.options.strokeWeight ),
          strokeOpacity : this.options.strokeOpacity,
          fillColor     : (selected ? '#FF0000' : this.options.fillColor),
          fillOpacity   : (selected ? 0.3 : this.options.fillOpacity )
      }
  }
  update(map, coord, selected, options){
      if(!coord || coord.length < 2) return;

      if (options?.strokeColor) this.options.strokeColor = options.strokeColor;
      if (options?.fillColor) this.options.fillColor = options.fillColor;
      if (options?.strokeOpacity) this.options.strokeOpacity = options.strokeOpacity;
      if (options?.fillOpacity) this.options.fillOpacity = options.fillOpacity;
      if (options?.strokeWidth) this.options.strokeWeight = options.strokeWidth;

      const polyOptions = {
          map           : map,
          path          : coord.map(x=>({lat:x[1], lng:x[0]}))
      }
      Object.assign(polyOptions, this.getOptions(selected))

      // Create / update
      if(this.mapobj)this.mapobj.setOptions(polyOptions)
      else           this.mapobj = new window.google.maps.Polygon(polyOptions);
  }
  getPath () {
    return this.getLynePolyPath();
  }
  startEdit (listener) {
    this.startLynePolyEdit(listener, POLYGON);
  }
  stopEdit (restorePos) {
    this.stopLynePolyEdit(restorePos);
  }
}

export {
  PointDrawing,
  LineDrawing,
  PolygonDrawing,
};