import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/styles';
import { Button, IconButton, TextField } from '@mui/material';
import { backend, useSyncEngStore } from '../../sync-engine';
import { escHandlers } from "../../storages/appStorage";
import { categoryAlpha2Opacity, setShowCategoryPref, editCategory, 
  editCategoryIcon } from '../../storages/featuresStorage';
import { TitleWithClose, ColorDialog, FileDialog, NumberDialog, 
  OpacityDialog, SelectDialod, StringDialog, TextDialog } from "../dialog";
import Utils from '../../Utils';

const lineColorTitle = 'Line color';
const strokeColorTitle = 'Polygon stroke color';
const fillColorTitle = 'Polygon fill color';
const strokeOpacityTitle = 'Polygon stroke color opacity';
const fillOpacityTitle = 'Polygon fill color opacity';
const lineWidthTitle = 'Line width';
const strokeWidthTitle = 'Polygon stroke width';

const TextButton = styled(Button)({
  textTransform: 'none'
});

const ColorButton = styled(Button)({
  backgroundColor: ({bg}) => bg,
  color: ({clr}) => clr,
  '&:hover': {
    backgroundColor: ({bghvr}) => bghvr,}
});

const PrefColorItem = ({title, btnTxt, btnBg, btnClr, btnHwr, setDlgTitle}) => 
  <div className='categoryPrefItem'>
    <div className='settingsItemTitle'>{title}</div>
    <ColorButton variant="contained" size="small" bg={btnBg} clr={btnClr} 
      bghvr={btnHwr}
        onClick={() => setDlgTitle(title)}>
      {btnTxt}
    </ColorButton>
  </div>;

const PrefTextItem = ({title, btnTxt, setDlgTitle}) => 
  <div className='categoryPrefItem'>
    <div className='settingsItemTitle'>{title}</div>
    <TextButton variant="outlined" size="small" 
        onClick={() => setDlgTitle(title)}>
      {btnTxt}
    </TextButton>
  </div>;

const CategoryPrefs = ({id}) => {
  const categoriesObjects = useSyncEngStore(state => state.categoriesObjects, () => false);
  const categoriesChanges = useSyncEngStore(state => state.categoriesChanges);

  const [categoryName, setCategoryName] = useState('');

  const [nameDlgTitle, setNameDlgTitle] = useState('');
  const [descrDlgTitle, setDescrDlgTitle] = useState('');
  const [iconDlgTitle, setIconDlgTitle] = useState('');
  const [anchorDlgTitle, setAnchorDlgTitle] = useState('');
  const [colorDlgTitle, setColorDlgTitle] = useState('');
  const [opacityDlgTitle, setOpacityDlgTitle] = useState('');
  const [widthDlgTitle, setWidthDlgTitle] = useState('');

  useEffect(() => {
    escHandlers.set('CategoryPrefs', () => setShowCategoryPref(false));
    return () => escHandlers.delete('CategoryPrefs');
  }, []);

  const header = useMemo(() => <TitleWithClose 
    onClose={() => setShowCategoryPref(false)}>
      {categoryName ? `Category "${categoryName}" Settings` : 'Fail to get category data'}
    </TitleWithClose>, [categoryName])

  const PrefGroup = useMemo(() => ({children}) => 
    <div className='categoryPrefGroup'>{children}</div>, [categoriesChanges]);

  const NameItem = useMemo(() => (props) => 
    <PrefTextItem {...props} setDlgTitle={setNameDlgTitle}/>, [categoriesChanges]);

  const DescrItem = useMemo(() => ({title, descr}) => 
    <div className='categoryPrefItem'>
      <div className='settingsItemTitle'>{title}</div>
      <TextField variant="outlined" disabled multiline rows={2} defaultValue={descr}
        onClick={() => setDescrDlgTitle(title)}/>
    </div>, [categoriesChanges]);

  const IconItem = useMemo(() => ({title, url}) => 
    <div className='categoryPrefItem'>
      <div className='settingsItemTitle'>{title}</div>
      <IconButton onClick={() => setIconDlgTitle(title)}>
        {url ? <img src={url} height={30} /> 
          : <div className='categoryPrefIconText'>NOT SET</div>}
      </IconButton>
    </div>, [categoriesChanges]);

  const AncorItem = useMemo(() => (props) => 
    <PrefTextItem {...props} setDlgTitle={setAnchorDlgTitle}/>, [categoriesChanges]);

  const ColorItem = useMemo(() => (props) => 
    <PrefColorItem {...props} setDlgTitle={setColorDlgTitle}/>, [categoriesChanges]);

  const OpacityItem = useMemo(() => (props) => 
    <PrefColorItem {...props} setDlgTitle={setOpacityDlgTitle}/>, [categoriesChanges]);

  const WidthItem = useMemo(() => (props) => 
    <PrefColorItem {...props} setDlgTitle={setWidthDlgTitle}/>, [categoriesChanges]);

  const getCategory = () => categoryAlpha2Opacity(categoriesObjects[id]?.category);
  const category = useMemo(getCategory, 
    [categoriesChanges]);

  useEffect(() => {
    if (category) setCategoryName(category.name);
  }, [categoriesChanges]);

  if (!category) {
    return <>
      {header}
      <div className='paddingElem'>Wrong Category data: id {id}</div></>;
  }

  const onNameSave = (name) =>       editCategory({id, name: name});
  const onDescrSave = (descr) =>     editCategory({id, descr: descr});

  const onIconSave = (file) =>       editCategoryIcon(id, file);
  const onAncorSave = (anchor) =>    editCategory({id, icon_anchor:  anchor});

  const lineColorSave = (color) =>   editCategory({id, line_color:   color});
  const strokeColorSave = (color) => {
    const alpha = category.stroke_color?.length == 9 ? category.stroke_color.slice(0, 3) : '#';
    editCategory({ id, stroke_color: alpha + color.slice(1) });
  };
  const fillColorSave = (color) => {
    const alpha = category.fill_color?.length == 9 ? category.fill_color.slice(0, 3) : '#';
    editCategory({ id, fill_color: alpha + color.slice(1) });
  };

  const onColorSave = (color) => {
    switch (colorDlgTitle) {
      case lineColorTitle: 
        lineColorSave(color);
        break;
      case strokeColorTitle:
        strokeColorSave(color);
        break;
      case fillColorTitle:
        fillColorSave(color);
        break;
      default: console.warn(`Category color can't be saved: ${colorDlgTitle}`);
    }
  };

  const strokeOpacitySave = (opacity) => {
    const strokeColor = Utils.colorOpacity2Alpha(category.stroke_color, opacity);
    editCategory({ id, stroke_color: strokeColor });
  };
  const fillOpacitySave = (opacity) => {
    const fillColor = Utils.colorOpacity2Alpha(category.fill_color, opacity);
    editCategory({ id, fill_color: fillColor });
  };

  const onOpacitySave = (opacity) => {
    switch (opacityDlgTitle) {
      case strokeOpacityTitle: 
        strokeOpacitySave(opacity);
        break;
      case fillOpacityTitle:
        fillOpacitySave(opacity);
        break;
      default: console.warn(`Category opacity can't be saved: ${opacityDlgTitle}`);
    }
  };

  const lineWidthSave = (width) =>   editCategory({id, line_width: width});
  const strokeWidthSave = (width) => editCategory({id, stroke_width:   width});

  const onWidthSave = (width) => {
    switch (widthDlgTitle) {
      case lineWidthTitle: 
        lineWidthSave(width);
        break;
      case strokeWidthTitle:
        strokeWidthSave(width);
        break;
      default: console.warn(`Category opacity can't be saved: ${opacityDlgTitle}`);
    }
  };

  const anchorTexts = {
    MM: 'Center',
    LT: 'Left Top',
    MT: 'Middle Top',
    RT: 'Right Top',
    RM: 'Right Middle',
    RB: 'Right Bottom',
    MB: 'Middle Bottom',
    LB: 'Left Bottom',
    LM: 'Left Middle',
  };

  const nameDlgOpen = Boolean(nameDlgTitle);
  const descrDlgOpen = Boolean(descrDlgTitle);
  const iconDlgOpen = Boolean(iconDlgTitle);
  const anchorDlgOpen = Boolean(anchorDlgTitle);
  const colorDlgOpen = Boolean(colorDlgTitle);
  const opacityDlgOpen = Boolean(opacityDlgTitle);
  const widthDlgOpen = Boolean(widthDlgTitle);

  const descrText = category.descr ? category.descr : '';
  const pathIcons = backend.getWorkspaceUrl()+'/file/';
  const pointIconUrl = category.point_icon ? pathIcons + category.point_icon : '';
  const anchorText = category.icon_anchor ? anchorTexts[category.icon_anchor] : anchorTexts['MB'];
  const anchorVal = category.icon_anchor ? category.icon_anchor : 'MB';

  const lineColorText = category.line_color ? category.line_color : 'Not Set';
  const lineColorBg = category.line_color ? category.line_color : '#808080';
  const {sharp: lineColor, medium: lineColorHv} = Utils.color2contrastBWs(lineColorBg);

  const strokeColorText = category.stroke_color ? category.stroke_color : 'Not Set';
  const strokeColorBg = category.stroke_color ? category.stroke_color : '#808080';
  const {sharp: strokeColor, medium: strokeColorHv} = Utils.color2contrastBWs(strokeColorBg);

  const fillColorText = category.fill_color ? category.fill_color : 'Not Set';
  const fillColorBg = category.fill_color ? category.fill_color : '#808080';
  const {sharp: fillColor, medium: fillColorHv} = Utils.color2contrastBWs(fillColorBg);

  const strokeOpacityText = Utils.isNumber(category.stroke_opacity) ? category.stroke_opacity : 'Not Set';
  const fillOpacityText = Utils.isNumber(category.fill_opacity) ? category.fill_opacity : 'Not Set';

  const lineWidthText = category.line_width ? category.line_width : 'Not Set';
  const strokeWidthText = category.stroke_width ? category.stroke_width : 'Not Set';

  const getColorDlgVal = () => {
    switch (colorDlgTitle) {
      case lineColorTitle: return lineColorBg;
      case strokeColorTitle:  return strokeColorBg;
      case fillColorTitle:  return fillColorBg;
      default: return '';
    }
  }

  const getOpacityDlgVal = () => {
    switch (opacityDlgTitle) {
      case strokeOpacityTitle: return strokeOpacityText != 'Not Set' ? strokeOpacityText : 1;
      case fillOpacityTitle: return fillOpacityText != 'Not Set' ? fillOpacityText : 0;
      default: return 1;
    }
  }

  const getOpacityDlgColor = () => {
    switch (opacityDlgTitle) {
      case strokeOpacityTitle: return strokeColorBg;
      case fillOpacityTitle:  return fillColorBg;
      default: return '#FFFFFF';
    }
  }

  const getWidthDlgVal = () => {
    switch (widthDlgTitle) {
      case lineWidthTitle: return lineWidthText != 'Not Set' ? lineWidthText : 1;
      case strokeWidthTitle: return strokeWidthText != 'Not Set' ? strokeWidthText : 1;
      default: return 1;
    }
  }

  return <>
    {nameDlgOpen && <StringDialog title={nameDlgTitle} label='Name' val={category.name} 
      onSave={onNameSave} onClose={()=>setNameDlgTitle('')}/>}
    {descrDlgOpen && <TextDialog title={descrDlgTitle} val={descrText} 
      onSave={onDescrSave} onClose={()=>setDescrDlgTitle('')}/>}
    {iconDlgOpen && <FileDialog title={iconDlgTitle} accept='.svg' 
      onSave={onIconSave} onClose={()=>setIconDlgTitle('')}/>}
    {anchorDlgOpen && <SelectDialod title={anchorDlgTitle} val={anchorVal} 
      onSave={onAncorSave} onClose={()=>setAnchorDlgTitle('')}/>}
    {colorDlgOpen && <ColorDialog title={colorDlgTitle} val={getColorDlgVal()} 
      onSave={onColorSave} onClose={()=>setColorDlgTitle('')}/>}
    {opacityDlgOpen && <OpacityDialog title={opacityDlgTitle} val={getOpacityDlgVal()} 
      color={getOpacityDlgColor()}
      onSave={onOpacitySave} onClose={()=>setOpacityDlgTitle('')}/>}
    {widthDlgOpen && <NumberDialog title={widthDlgTitle} val={getWidthDlgVal()} 
      onSave={onWidthSave} onClose={()=>setWidthDlgTitle('')}/>}
    {header}
    <div className='fullSize bgGrey'>
      <PrefGroup>
        <NameItem title='Category name' btnTxt={category.name}/>
        <DescrItem title='Category description' descr={category.descr}/>
      </PrefGroup>
      <PrefGroup>
        <IconItem title='Point icon' url={pointIconUrl}/>
        <AncorItem title='Point anchor' btnTxt={anchorText}/>
      </PrefGroup>
      <PrefGroup>
        <ColorItem title={lineColorTitle} btnTxt={lineColorText} 
          btnBg={lineColorBg} btnClr={lineColor} btnHwr={lineColorHv}/>
        <WidthItem title={lineWidthTitle} btnTxt={lineWidthText} 
          btnBg={lineColorBg} btnClr={lineColor} btnHwr={lineColorHv}/>
      </PrefGroup>
      <PrefGroup>
        <ColorItem title={strokeColorTitle} btnTxt={strokeColorText} 
          btnBg={strokeColorBg} btnClr={strokeColor} btnHwr={strokeColorHv}/>
        <OpacityItem title={strokeOpacityTitle} btnTxt={strokeOpacityText} 
          btnBg={strokeColorBg} btnClr={strokeColor} btnHwr={strokeColorHv}/>
        <WidthItem title={strokeWidthTitle} btnTxt={strokeWidthText} 
          btnBg={strokeColorBg} btnClr={strokeColor} btnHwr={strokeColorHv}/>
      </PrefGroup>
      <PrefGroup>
        <ColorItem title={fillColorTitle} btnTxt={fillColorText} 
          btnBg={fillColorBg} btnClr={fillColor} btnHwr={fillColorHv}/>
        <OpacityItem title={fillOpacityTitle} btnTxt={fillOpacityText} 
          btnBg={fillColorBg} btnClr={fillColor} btnHwr={fillColorHv}/>
      </PrefGroup>
    </div>
  </>;
};

export default CategoryPrefs;