import Module from "../Module"

//--------------------------------------------------------------------------------
// POINT LIST
class  Points extends Module{
    constructor() {
        super();
        // TABLE LIST
        this.props={
            //location:       {syncUrl:'/load/changes'},
            point:          {syncUrl:'/points/load_points'},
            point_comment:  {syncUrl:'/points/load_point_comments'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        let results = {};
        if(changes.point) this.onPointChanges(changes.point, results);
        this.onStandardPropChanges('point_comment', 'comments', changes, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onPointChanges(changes, results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            // Update the value
            obj.point = prop;
        });
    }

    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
            obj = {
                id:id,
                point: null,
                comments:[]
            }
            this.objects[id] = obj;
        }
        return obj;
    }



}

export default Points;
