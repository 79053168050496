import Module from "../Module";

//--------------------------------------------------------------------------------
// MODULE: Sensors
class  Sensors extends Module{
  constructor() {
    super();
    // TABLE LIST
    this.props={
        sensor:          {syncUrl:'/sensors/load_sensors'},
        sensor_data:     {syncUrl:'/sensors/load_data'},
    }
}

  async onDataChanges (changes) {
    return new Promise((resolve) => {
      let results = {};
        if(changes.sensor)      this.onSensorChanges(changes.sensor, results);
        if(changes.sensor_data) this.onSensorDataChanges(changes.sensor_data, results);
      resolve(this.isNotEmpty(results) ? results : null);
    });
  }

  onNodeChanges(nodes, results){
    nodes.forEach(node => {
      let obj  = this.getObject(node.id);// id must exist
      results[node.id] = obj;
      obj.node = node;
    });
  }

  onSensorChanges(sensors, results){
    sensors.forEach(sensor => {
      let obj  = this.getObject(sensor.id);// id must exist
      obj.sensor = sensor;
      if (sensor.lat && sensor.lng) {
        obj.location = [sensor.lng, sensor.lat];
      }
      results[sensor.id] = obj;
    });
  }

  onSensorDataChanges(values, results){
    values.forEach(value => {
        let obj  = this.getObject(value.id);// id must exist
        obj.data.push(value);
        if (value.lat && value.lng) {
          obj.location = [value.lng, value.lat];
        }
        results[value.id] = obj;
    });
  }

  // Search/create object
  getObject(id){
    // search existed object
    let obj = this.objects[id]; 
    // Inset new object if not found
    if(!obj){
      obj = {
        id:      id,
        sensor:  null,
        data:    [],
        location: null,
      };
      this.objects[id] = obj;
    }
    return obj;
  }
}

export default Sensors;
