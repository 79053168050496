import Module from "../Module";

//--------------------------------------------------------------------------------
// MODULE: FEATURES
class  Features extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            feature:           {syncUrl:'/features/load_features'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        //console.log('Features.onDataChanges')
        let results = {};
        if(changes.feature) this.onFeatureChanges (changes.feature, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onFeatureChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            // Update tree value
            obj.feature = prop;
        });
    }
    
    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
          obj = {
            id:      id,
            feature: null
          };
          this.objects[id] = obj;
        }
        return obj;
    }
}

export default Features;
