import { useState, useMemo, useEffect, } from 'react';
import { backend, useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected } from "../../../storages/appStorage";
import { useMapStore } from '../../../storages/mapStorage';
import { type, categoryAlpha2Opacity, useFeaturesStore } from '../../../storages/featuresStorage';
import { PointDrawing, LineDrawing, PolygonDrawing } from './mapObjects';
import Utils from '../../../Utils';

const FeatureDrawings = () => {
  const map = useMapStore(state => state.map);
  const featuresChanges = useSyncEngStore(state => state.featuresChanges);
  const categoriesChanges = useSyncEngStore(state => state.categoriesChanges);
  const unvisibleLayers = useFeaturesStore(state => state.unvisibleLayers);
  const selectedIds = useAppStore(state => state.selectedIds);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('features', selectedIds), [selectedIds]);

  const [isShown, setShown] = useState(false);

  const anchorCoord = (anchor, x, y) => {
    switch (anchor) {
      case 'MM': return [x/2, y/2];
      case 'LT': return [0,   0];
      case 'MT': return [x/2, 0];
      case 'RT': return [x,   0];
      case 'RM': return [x,   y/2];
      case 'RB': return [x,   y];
      case 'MB': return [x/2, y];
      case 'LB': return [0,   y];
      case 'LM': return [0,   y/2];
      
      default: return [x/2, y];
    }
  };

  const showOnMap = (objects, categoryObjects) => {
    if (map) {
      for(const id in objects) {
        const obj = objects[id];
        const feature = obj.feature;
        if (feature) {
          const categoryObj = categoryObjects ? categoryObjects[feature.category_id] : null;
          const category = categoryAlpha2Opacity(categoryObj?.category);
          const pointIcon = category?.point_icon;
          const iconAnchor = category?.icon_anchor;
          const lineColor = category?.line_color;
          const strokeColor = category?.stroke_color;
          const fillColor = category?.fill_color;
          const strokeOpacity = category?.stroke_opacity;
          const fillOpacity = category?.fill_opacity;
          const lineWidth = category?.line_width;
          const strokeWidth = category?.stroke_width;

          const options = lineColor ? {lineColor: lineColor} : {};
          if (strokeColor) options.strokeColor = strokeColor;
          if (fillColor) options.fillColor = fillColor;
          if (Utils.isNumber(strokeOpacity)) options.strokeOpacity = strokeOpacity;
          if (Utils.isNumber(fillOpacity)) options.fillOpacity = fillOpacity;
          if (lineWidth) options.lineWidth = lineWidth;
          if (strokeWidth) options.strokeWidth = strokeWidth;

          if (pointIcon) {
            const url = backend.getWorkspaceUrl()+'/file/' + pointIcon;
            const size = new window.google.maps.Size(30, 30);
            const anchor = new window.google.maps.Point(...anchorCoord(
              iconAnchor, 30,30));
            const selSize = new window.google.maps.Size(50, 50);
            const selAnchor = new window.google.maps.Point(...anchorCoord(
              iconAnchor, 50,50));
            options.pointIcon = {
              icon: {url: url, scaledSize: size, anchor: anchor},
              selectedIcon: {url: url, scaledSize: selSize, anchor: selAnchor}
            };
          }
          options.title = feature.name;
          const hide = unvisibleLayers.has(String(feature.category_id)) || 
                        categoryObj?.category?.rm;
          if (feature.rm || (hide)) {     // Remove drawing
            if (obj.drawing) obj.drawing.remove();
          } else {
            if (!obj.drawing) { // Create new
              switch(feature.type) {
                case type.POINT:   obj.drawing = new PointDrawing();   break;
                case type.LINE:    obj.drawing = new LineDrawing();    break;
                case type.POLYGON: obj.drawing = new PolygonDrawing(); break;
              }
            }
            // Update existed
            if (obj.drawing){
                obj.drawing.update(map, feature.coordinates, selectedId === id, options);
                obj.drawing.setClickListener(() => setSelected('features', id));
            }
          }        
        }
      }
    }
  }

  const hideOnMap = (objects) => {
    for(const id in objects) {
      const obj = objects[id];
      if (obj.drawing) obj.drawing.remove();
    }
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap(objectsSnap('features'), objectsSnap('categories'));
  }

  const onPageHide = () => {
    setShown(false);
    hideOnMap(objectsSnap('features'));
  }

  const onChanges = () => {
    if (isShown) showOnMap(featuresChanges, objectsSnap('categories'));
  }

  const addToChangesById = (changes, snap, id) => {
    if (id && snap) {
      const obj = snap[id];
      if (obj) {
        changes[id] = obj;
      }
    }
  };

  const onSelectedChange = () => {
    if (isShown) {
      const changes = {};
      const snap = objectsSnap('features');
      addToChangesById(changes, snap, prevSelectedId);
      addToChangesById(changes, snap, selectedId);
      showOnMap(changes, objectsSnap('categories'));
    }
  };

  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  useEffect(() => {
    onSelectedChange();
  }, [selectedIds]);

  useMemo(onChanges, 
    [map, featuresChanges, categoriesChanges, unvisibleLayers]);

  return(null); // no html render, draw on map directly instead
};

export default FeatureDrawings;