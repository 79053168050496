import React from 'react';
import { styled } from '@mui/styles';
import { PointDrawing, LineDrawing, PolygonDrawing } from '../map/drawings/mapObjects';
import { ButtonGreen } from '../common';
import { type, useFeaturesStore, getCreatingFeature, 
  changeCreatingFeature } from '../../storages/featuresStorage';
import { useMapStore, cleanUpMapState, setTempDrawing, getTempDrawing } from '../../storages/mapStorage';

const LegendButton = styled(ButtonGreen)({
  marginRight: '1em',
  marginBottom: '1em',
});

const FeatureLegend = () => {
  const map = useMapStore(state => state.map);
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);

  const getMapTempPoint = () => {
    const tempDrawing = getTempDrawing();
    if (tempDrawing) return tempDrawing;

    const tempPoint = new PointDrawing({icon: {url: 'images/marker_point.png'}});
    setTempDrawing(tempPoint);
    return tempPoint;
  };

  const getMapTempLine = (tp) => {
    const tempDrawing = getTempDrawing();
    if (tempDrawing) return tempDrawing;

    const tempLine = tp == type.POLYGON ? new PolygonDrawing({strokeColor: 'grey'}) : new LineDrawing({strokeColor: 'grey'});
    setTempDrawing(tempLine);
    return tempLine;
  };

  const onPoint = () => {
    if ( creatingFeature?.type != type.POINT) {
      cleanUpMapState();
      changeCreatingFeature({type: type.POINT, coordinates: null});
    
      map.setOptions({draggableCursor: 'crosshair'});

      window.google.maps.event.addListener(map, 'click', function(event) {
        if (event.latLng) {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();

          changeCreatingFeature({coordinates: [lng, lat]});

          const tempPoint = getMapTempPoint();
          tempPoint.update(map, [lng, lat], false, '');
        }
      });
    }
  }

  const addCreatingFeaturePoint = (point) => {
    const feature = getCreatingFeature();
    const coordinates = feature?.coordinates ? feature?.coordinates : [];
    coordinates.push(point);
    changeCreatingFeature({coordinates: coordinates});
  }

  const clearTempLineListeners = () => {
    const tempLine = getMapTempLine();
    window.google.maps.event.clearListeners(tempLine.mapobj, 'click');
    window.google.maps.event.clearListeners(tempLine.mapobj, 'rightclick');
    window.google.maps.event.clearListeners(tempLine.mapobj, 'mousemove');
    window.google.maps.event.clearListeners(map,             'mousemove');
  };

  const setTempLineListeners = (tp, line) => {
    if (!line.clickListener) {
      line.clickListener = window.google.maps.event.addListener(line.mapobj, 'click', (e) => {
        if (e.latLng) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();

          addCreatingFeaturePoint([lng, lat]);

          const tempLine = getMapTempLine();
          const path = tempLine.getPath();
          path.push([lng, lat]);
          tempLine.update(map, path, false);
        }
      });
    }

    if (!line.rgtClkListener) {
      line.rgtClkListener = window.google.maps.event.addListener(line.mapobj, 'rightclick', (e) => {
        if (e.latLng) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();

          const tempLine = getMapTempLine();
          const path = tempLine.getPath();
          const prevPoint = path[path.length - 2];
          const lastPoint = path[path.length - 1];

          // if rightclick isn't at click position
          if (prevPoint[0] != lastPoint[0] || prevPoint[1] != lastPoint[1]) {
            addCreatingFeaturePoint([lng, lat]);
            path.push([lng, lat]);
            tempLine.update(map, path, false);
          }

          map.setOptions({draggableCursor: ''}); // set default cursor
          tempLine.mapobj.setEditable(false);
          clearTempLineListeners();
        }
      });
    }

    if (tp == type.POLYGON && !line.moveListener) {
      line.moveListener = window.google.maps.event.addListener(line.mapobj, 'mousemove', (e) => {
        if (e.latLng) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();
  
          const tempLine = getMapTempLine(type);
          const path = tempLine.getPath();

          path[path.length - 1] = [lng, lat];
          tempLine.update(map, path, false);  
        }
      });
    }
  };

  const initTempLine = (type, frstPoint) => {
    window.google.maps.event.clearListeners(map, 'click');

    window.google.maps.event.addListener(map, 'mousemove', (e) => {
      if (e.latLng) {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();

        const tempLine = getMapTempLine(type);
        const path = tempLine.getPath();

        if (path.length < 2 && (lat != frstPoint[1] || lng != frstPoint[0]) ) {
          tempLine.update(map, [frstPoint, [lng, lat]], false);
          tempLine.mapobj.setEditable(true);
          setTempLineListeners(type, tempLine);
        } else {
          path[path.length - 1] = [lng, lat];
          tempLine.update(map, path, false);
        }
      }
    });
  };

  const onLineOrPolygon = (type) => {
    if ( creatingFeature?.type != type) {
      cleanUpMapState();
      changeCreatingFeature({type: type, coordinates: null});

      map.setOptions({draggableCursor: 'crosshair'});

      window.google.maps.event.addListener(map, 'click', (e) => {
        if (e.latLng) {
          const lat = e.latLng.lat();
          const lng = e.latLng.lng();

          addCreatingFeaturePoint([lng, lat]);
          initTempLine(type, [lng, lat]);
        }
      });
    }
  }

  return(
  <div className='flexColumn'>
    <LegendButton onClick={onPoint}>Point</LegendButton>
    <LegendButton onClick={() => onLineOrPolygon(type.LINE)}>Line</LegendButton>
    <LegendButton onClick={() => onLineOrPolygon(type.POLYGON)}>Polygon</LegendButton>
  </div>);
}

export default FeatureLegend;