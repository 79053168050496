export { default as TitleWithClose } from './titleWithClose';
export { default as Dialog } from './dialog';
export { default as DialogButton } from './dialogButton';
export { default as OkDialog } from './okDialog';
export { default as ErrorDialog } from './errorDialog';
export { default as ColorDialog } from './colorDialog';
export { default as FileDialog } from './fileDialog';
export { default as NumberDialog } from './numberDialog';
export { default as OpacityDialog } from './opacityDialog';
export { default as SelectDialod } from './selectDialod';
export { default as StringDialog } from './stringDialog';
export { default as TextDialog } from './textDialog';