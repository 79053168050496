import React, { useState, useMemo, } from 'react';
import { Card, CardContent, Paper, Button } from '@mui/material';
import UTMLatLng from 'utm-latlng';
import { useAppStore } from '../../storages/appStorage';
import { data, backend } from '../../sync-engine';
import CommentsPanel from "./commentsPanel";
import ImageViewer from '../ImageViewer';
import Colors from '../../styles/colors';
import Utils from '../../Utils';

const getCategoryIcon = (fileName) => {
  const bg = `url(/images/points/cat-icons/${fileName}) 100% 100% no-repeat`;
  return <div className='pntCatIcon' style={{background: bg}}/>;
}

const helthScale = ['', 'very poor', 'poor', 'fair', 'good', 'excellent']

const AnimalCard = ({card}) => {
  const number =    card.number;
  const gender =    card.gender;
  const age =       card.age;
  const behaviour = card.behaviour;
  const health =    card.health;
  const hlthGrade = health ? helthScale[health] : null;

  return (
    <div className='paddingButTop'>
      <Paper elevation={3} className='padding1em3em'>
        <table className='fullWidth leftAlignTable'>
          <tbody>
            {number && 
              <tr key = {1}><td>Number of animals</td><td><b>{number}</b></td>
              </tr>}
            {gender && 
              <tr key = {2}><td>Gender</td><td><b>{gender}</b></td>
              </tr>}
            {age &&
              <tr key = {3}><td>Age</td><td><b>{age}</b></td>
              </tr>}
            {behaviour &&
              <tr key = {4}><td>Behaviour</td><td><b>{behaviour}</b></td>
              </tr>}
            {hlthGrade &&
              <tr key = {5}><td>Helth condition</td><td><b>{hlthGrade}</b></td>
              </tr>}
          </tbody>
        </table>
      </Paper>
    </div>
  );
};

const PointExpElement = ({point, comments, date, active}) => {
  const loadedMdProps = useAppStore(state => state.loadedMdProps);

  const photos = point.photos;
  const path = backend.getWorkspaceUrl()+'/file/';

  const [showImgViewer, setShowImgViewer] = useState(false);
  const [currentImgInd, setCurrentImgInd] = useState(0);
  const onImgClick = (ind) => {
    setCurrentImgInd(ind);
    setShowImgViewer(true);
  };
  const onCloseImgViewer = () => setShowImgViewer(false);

  const [openCommentsPanel, setOpenCommentsPanel] = useState(false);
  const showComments = () => setOpenCommentsPanel(!openCommentsPanel);

  const photoUrls = useMemo(() => {
      return photos 
          ? photos.map((photo) => path+photo)
          : [];
  }, [photos]);

  const commentsLoaded = useMemo(() => {
    return loadedMdProps.has('point_comment');
  }, [loadedMdProps]);

  const photoRows = useMemo(() => {
    return photoUrls.slice(0,2).map((photo, ind) => (
            <img 
            key={photo}
            className="thumbExp"
            onClick={() => onImgClick(ind)}
            src={photo}/>));
}, [photoUrls]);

  const latLng2UTM = (lat, lng) => {
    const utmObj = new UTMLatLng().convertLatLngToUtm(lat, lng, 6);
    return String(utmObj.ZoneNumber) + ' ' + utmObj.ZoneLetter + ' ' + 
      utmObj.Easting.toFixed(2) + ' ' + utmObj.Northing.toFixed(2);
  }

  const utm = useMemo(() => latLng2UTM(point.lat, point.lng), 
    [point.lat, point.lng]);

  // animal details
  const animal = point.details?.animal;
  const sighting = point.details?.sighting;
  const cards = point.details?.cards;
  const animalCards = useMemo(() => 
    (cards && cards.length > 0)
      ? cards.map((card, ind) => <AnimalCard key={ind} card={card}/>)
      : null, 
    [cards]);
  
  const totAnNmb = useMemo(() => 
    cards ? cards.reduce((sum, card) => sum + card.number, 0) : 0,
    [cards]);
  
  const totalAnimNumberElem = totAnNmb ? 
    <div className='paddingButTop'>Total animals number:<b> {totAnNmb}</b></div> : null;

  return (
    <Card className='flexElem'>
      <div className='pntType' style={{background: Colors.listRowOpened}}>
        {getCategoryIcon(Utils.pointCategoryIconFileName(point))}</div>
      <CardContent>
        <div className='flexElem'>
          <div className='paddingElem'>
            <div>Lat,Lng:<b> {point.lat} {point.lng}</b></div>
            <div>UTM:<b> {utm}</b></div>
            <br/>
            <div>Posted by:<b> {data.getUserName(point.user_id)}</b></div>
            <div>Date:<b> {date}</b></div>
            <br/>
          </div>
          <div className='paddingElem'>
            <div>Category:<b> {point.category}</b></div>
            <div>Description:<b> {point.descr}</b></div>
            {animal && <div>Animal:<b> {animal}</b></div>}
            {sighting && <div>Sighting:<b> {sighting}</b></div>}
          </div>
          <div className='paddingElem'>
            {photoRows}
          </div>
        </div>
        {totalAnimNumberElem}
        {animalCards}
        <div className='paddingButTop'>
          <Button variant='contained' disabled={!commentsLoaded} onClick={showComments}>
              {commentsLoaded 
                ? openCommentsPanel ? 'Hide Comments' : 'View Comments'
                : 'Loading Comments...'
              }</Button>
        </div>
        {openCommentsPanel && <CommentsPanel pointId={point.id} comments={comments} active={active}/>
        }
        {showImgViewer && 
            <ImageViewer images={photoUrls} onClose={onCloseImgViewer} index={currentImgInd}/>}
      </CardContent>
    </Card>
  );
};

export {getCategoryIcon};
export default PointExpElement;