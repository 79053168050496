import React, { useState, useMemo } from 'react';
import { TextField } from '@mui/material';
import DialogButton from './dialogButton';

const StringDialog = ({title, label, val, validator, onSave, onClose}) => {
  const [strVal, setStrVal] = useState(val);

  const onInputString = (e) => {
    setStrVal(e.target.value);
  };

  const saveAndClose = () => {
    onSave(strVal)
    onClose();
  };

  const error = useMemo( validator ? () => validator(strVal) : () => '',
    [strVal]);

  const canSave = !error && strVal && strVal != val;

  return <DialogButton title={title} okName={'Save'} okDisabled={!canSave}
      onOK={saveAndClose} onClose={onClose}>

    <div className='stringDlgText'>
        <TextField variant="outlined" fullWidth={true} size="small" 
          label={label} value={strVal} error={Boolean(error)}
          helperText={error}
          onChange={onInputString}/>
    </div>
  </DialogButton>;
};

export default StringDialog;