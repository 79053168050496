import Module from "../Module"

//--------------------------------------------------------------------------------
// USER LIST

class  Users extends Module{
    constructor() {
        super();
        // TABLE LIST
        this.props={
            user:          {syncUrl:'/users/load_users'},
            login_state:   {syncUrl:'/users/load_login_states'},
            user_state:    {syncUrl:'/users/load_user_states'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        let results = {};
        if(changes.user)          this.onUserChanges      (changes.user,           results);
        if(changes.login_state)   this.onLoginStateChanges(changes.login_state,    results);
        if(changes.user_state)    this.onUserStateChanges (changes.user_state,     results);
        if(changes.point)         this.onPointChanges     (changes.point,          results);
        if(changes.patrol)        this.onPatrolChanges    (changes.patrol,         results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onUserChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj
            // Append prop value
            obj.user = prop
        });
    }
    onLoginStateChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            // Update prop value
            obj.loginState = prop.logout?null:prop;
        });
    }
    onUserStateChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            // Update prop value
            obj.state = prop;
        });
    }
    onPointChanges(points, results){
        points.forEach(point => {
            if(!point.user_id) return
            let obj  = this.getObject(point.user_id);
            results[obj.id] = obj;
            // Update prop value
            obj.points.set(point.id, point);
        });
    }
    onPatrolChanges(patrols, results){
      patrols.forEach(patrol => {
          if( !patrol.user_id ) return;
          const obj  = this.getObject(patrol.user_id);
          results[obj.id] = obj;
          obj.patrols.set(patrol.id, patrol);
      });
    }

    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
            obj = {
                id          : id,
                user        : null,
                loginState  : null,
                state       : null,
                points      : new Map(),
                patrols     : new Map()
            }
            this.objects[id] = obj;
        }
        return obj;
    }
}



export default Users;
