import React from "react";
import { Button, IconButton } from '@mui/material';


const IconRow = ({ title, imgUrl, onIconClick, onDefaultClick }) => {

  return <div className='flexElem'>
      <div className='flexAlignCenter settingsIconTitle'>{title}</div>
      <div className='settingsIconButton'>
        <IconButton onClick={onIconClick}>
          <img src={imgUrl} alt={title} height={30} />
        </IconButton>
      </div>
      <div className='paddingLeft'>
        <Button onClick={onDefaultClick}>
          Set default
        </Button>
      </div>
    </div>;
};

export default IconRow;