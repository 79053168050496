import React, { useState, useEffect, } from 'react';
import { styled } from '@mui/styles';
import { TextField } from '@mui/material';
import FoldersSelect from './foldersSelect';
import { ButtonGreen } from '../common';
import { escHandlers } from "../../storages/appStorage";
import { useFeaturesStore, getInitialFeature, changeCreatingFeature, 
  setShowEditFolder, saveFeature } from '../../storages/featuresStorage';
import { TitleWithClose } from "../dialog";
import Utils from "../../Utils";

const TextFieldSt = styled(TextField)({
  marginBottom: '1em',
});

const StyledButton = styled(ButtonGreen)({
  margin: '1em 1em 0',
});

const EditFolder = () => {
  const initialFeature = getInitialFeature();
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    escHandlers.set('EditFolder', () => setShowEditFolder(false));
    return () => escHandlers.delete('EditFolder');
  }, []);

  const checkChanges = () => {
    const change = 
      initialFeature.name      != creatingFeature.name || 
      initialFeature.parent_id != creatingFeature.parent_id ||
      Utils.strDifferIfSet(initialFeature.descr, creatingFeature.descr)
    setChanged(change);
  }

  useEffect(() => {
    checkChanges();
  }, [creatingFeature]);

  const onSave = () => {
    saveFeature();
    setShowEditFolder(false);
  };

  const onInputName = (e) => {
    changeCreatingFeature({name: e.target.value});
  }

  const onInputDescr = (e) => {
    changeCreatingFeature({descr: e.target.value});
  }

  const title = `Edit "${initialFeature.name}"`;

  const canSave = changed && creatingFeature.name;
  const nameVal = creatingFeature.name ? creatingFeature.name : '';
  const descrVal = creatingFeature.descr ? creatingFeature.descr : '';

  return(
  <div className='flexColumn paddingElem'>
    <TitleWithClose onClose={() => setShowEditFolder(false)}>
      {title}
    </TitleWithClose>
    <TextFieldSt
      variant='outlined'
      margin='normal'
      label='Folder Name'
      value={nameVal}
      onChange={onInputName}
    />
    <FoldersSelect/>
    <TextField
      variant='outlined'
      margin='normal'
      label='Description'
      value={descrVal}
      onChange={onInputDescr}
    />
    <div>
      <StyledButton disabled={!canSave} onClick={onSave}>Save</StyledButton>
    </div>
  </div>
  );
}

export default EditFolder;