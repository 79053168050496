import React, { useEffect } from "react";
import { useAppStore, setIconsFromPrefs } from "../../storages/appStorage";
import WidgetsGrid from './widgets/widgetsGrid';
import WidgetsPanel from './widgets/widgetsPanel';
import { data, backend, syncEngine, useSyncEngStore, objectsSnap } from '../../sync-engine';
import { prefID } from "../../sync-engine/modules/Prefs";
import { useLvStore, setLvDataFromPrefs, updateSyncFilters } from '../../storages/lvStorage';
import Utils from '../../Utils';

const LiveView = ({wsId, setup}) => {
  const appInitialized = useAppStore(state => state.appInitialized);
  const prefsChanges = useSyncEngStore(state => state.prefsChanges);
  const lvBgColor = useLvStore(state => state.lvBgColor);

  const startSyncEngine = () => {
    console.log(`LiveView StartSyncEngine`, wsId);

    const modules = {patrols: {}, points: {}, prefs: {}, sensors: {}, features: {}, categories: {}};
    const timeRange = {t1: Utils.startOfThisWeek().getTime(), t2: null, str: 'this week'};

    try {
      data.init(modules, timeRange);
      syncEngine.start();
    } catch (error) {
      console.log(`LiveView StartSyncEngine error`, error);
    }
  };

  const initLV = () => {
    if (lvBgColor) {
      const bodyEl = document.querySelector("body");
      bodyEl.style.backgroundColor = lvBgColor;
    } 
    backend.workspace = wsId;
    startSyncEngine();
  };

  useEffect(() => {
    if (!setup && appInitialized) initLV();
  }, [appInitialized]);

  const setLvData = () => {
    const prefsObjects = objectsSnap('prefs');
    const lvPrefs = prefsObjects ? prefsObjects[prefID.live_view] : null;
    const iconsPrefs = prefsObjects ? prefsObjects[prefID.app_icons] : null;
    setLvDataFromPrefs(lvPrefs);
    setIconsFromPrefs(iconsPrefs);  // TODO: can be excessive: test LV on new browser (browser which newer opens dashboard)
    if (prefsChanges) updateSyncFilters();
  };

  useEffect(() => {
    if (!setup) setLvData();
  }, [prefsChanges]);

  console.log(`LiveView`, wsId);

  return <>
    <WidgetsGrid setup={setup}/>
    {setup && <WidgetsPanel/>}
  </>
}

export default LiveView;