import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import DialogButton from './dialogButton';

const SelectDialod = ({title, val, onSave, onClose}) => {
  const [selectVal, setVal] = useState(val);

  const saveAndClose = () => {
    onSave(selectVal)
    onClose();
  };

  const anchorItems = [
    <MenuItem key={1} value='MM'>Center</MenuItem>,
    <MenuItem key={2} value='LT'>Left Top</MenuItem>,
    <MenuItem key={3} value='MT'>Middle Top</MenuItem>,
    <MenuItem key={4} value='RT'>Right Top</MenuItem>,
    <MenuItem key={5} value='RM'>Right Middle</MenuItem>,
    <MenuItem key={6} value='RB'>Right Bottom</MenuItem>,
    <MenuItem key={7} value='MB'>Middle Bottom</MenuItem>,
    <MenuItem key={8} value='LB'>Left Bottom</MenuItem>,
    <MenuItem key={9} value='LM'>Left Middle</MenuItem>
  ];

  return <DialogButton title={title} okName={'Save'}
      onOK={saveAndClose} onClose={onClose}>

    <FormControl variant='outlined' margin={'none'} size={'small'}>
      <InputLabel id='select-dialog-label'>Anchor</InputLabel>
      <Select
        labelId='select-dialog-label'
        value={selectVal}
        onChange={(e) => setVal(e.target.value)}
        label='Anchor'
      >
        {anchorItems}
      </Select>
    </FormControl>
  </DialogButton>;
};

export default SelectDialod;