import React, { useState, useMemo } from "react";
import { Button, Menu, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/styles';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { setHistoryPeriod, getHistoryPeriod, setLastNDays, getLastNDays, setFromDate, 
  getFromDate, setToDate, getToDate, updateTimeFilter } from '../storages/workspaceStorage';
import { setWdTimeFilter } from '../storages/lvStorage';
import Dialog from './dialog/dialogButton';
import Utils from '../Utils';

const PeriodButton = styled(Button)({
  width: '20em',
});

const NDaysTextField = styled(TextField)({
  marginTop: '1em'
});

const ONE_DAY = 86400000;
const maxDateDefault = new Date('2100-01-01');
const minDateDefault = new Date('1900-01-01');

const TimeSelector = ({ onClose, wdKey, tfObj }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const periodsListopen = Boolean(anchorEl);

  // saved values
  const savedPeriod = tfObj ? tfObj.period : getHistoryPeriod();
  const savedNDays = tfObj ? tfObj.nDays : getLastNDays();
  const fromTimestamp = tfObj ? tfObj.from : (getFromDate() !== null ? Number(getFromDate()) : null);
  const toTimestamp = tfObj ? tfObj.to : (getToDate() !== null ? Number(getToDate()) : null);

  const [period, setPeriod] = useState(savedPeriod);
  const [nDays, setNDays] =   useState(savedNDays);
  const [fromDate, setFrom] = useState(fromTimestamp !== null ? new Date(fromTimestamp) : null);
  const [toDate, setTo] =     useState(toTimestamp !== null ? new Date(toTimestamp) : null);

  const isDateValid = (date) => date && !isNaN(date.getTime());

  const canBeSaved = () => {
    switch (period) {
      case 'today':
      case 'this week':
      case 'this month':
        return period != savedPeriod;
      case 'last N days':
        return period != savedPeriod || nDays != savedNDays;
      case 'from':
        return period != savedPeriod || isDateValid(fromDate) && !Utils.isSameDay(fromDate.getTime(), fromTimestamp);
      case 'from to':
        return period != savedPeriod || 
          (isDateValid(fromDate) && isDateValid(toDate) && toDate - fromDate >= ONE_DAY &&
            (!Utils.isSameDay(fromDate.getTime(), fromTimestamp) || 
            !Utils.isSameDay(toDate.getTime(), toTimestamp)));
    }
    return false;
  };

  const canSave = useMemo(() => canBeSaved(), 
    [period, nDays, fromDate, toDate]);

  const fromMaxDate = useMemo(() => {
    if (period == 'from to' && isDateValid(toDate)) {
      const maxDate = new Date(toDate.getTime());
      maxDate.setDate(maxDate.getDate() - 1); // 1 day before toDate
      return maxDate;
    }
    return maxDateDefault;
  }, [toDate, period]);

  const fromMaxDateMessage = useMemo(() => {
    return isDateValid(toDate) 
      ? 'From Date should be before To Date' 
      : 'Date should not be after maximal date';
  }, [toDate]);

  const toMinDate = useMemo(() => {
    if (isDateValid(fromDate)) {
      const minDate = new Date(fromDate.getTime());
      minDate.setDate(minDate.getDate() + 1); // 1 day after fromDate
      return minDate;
    }
    return minDateDefault;
  }, [fromDate]);

  const toMinDateMessage = useMemo(() => {
    return isDateValid(fromDate) 
      ? 'To Date should be after From Date' 
      : 'Date should not be before minimal date';
  }, [fromDate]);

  const showPeriods = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hidePeriods = () => {
    setAnchorEl(null);
  };

  const changePeriod = (period) => {
    setPeriod(period);
    setAnchorEl(null);
  };

  const changeNDays = (event) => {
    const nDays = event.target.value;
    if (nDays > 0) {
      setNDays(nDays);
    }
  };

  const changeFromDate = (date) => {
    setFrom(date);
    if (toDate && toDate - date < ONE_DAY) {
      setTo(null);
    } else if (!toDate) {
      setTo(toTimestamp ? new Date(toTimestamp) : null);
    }
  };

  const changeToDate = (date) => {
    setTo(date);
  };

  const onSave = () => {
    if (toDate) toDate.setHours(23, 59, 59, 0);
    if (fromDate) fromDate.setHours(0, 0, 0, 0);

    if (wdKey) {
      setWdTimeFilter(wdKey, {
        period: period, 
        nDays: nDays, 
        from: fromDate ? fromDate.getTime() : null, 
        to: toDate ? toDate.getTime() : null});
    } else {
      setHistoryPeriod(period);
      switch (period) {
        case 'last N days':
          setLastNDays(nDays);
          break;
        case 'from to':
          if (toDate) {
            setToDate(toDate.getTime());}
        case 'from':
          if (fromDate) {
            setFromDate(fromDate.getTime());
          }
      }
      updateTimeFilter();
    }
    onClose();
  };

  const periodsList = [
    <MenuItem key={1} value={1} onClick={() => changePeriod('today')}>
      today
    </MenuItem>,
    <MenuItem key={2} value={2} onClick={() => changePeriod('this week')}>
      this week
    </MenuItem>,
    <MenuItem key={3} value={3} onClick={() => changePeriod('this month')}>
      this month
    </MenuItem>,
    <MenuItem key={4} value={4} onClick={() => changePeriod('last N days')}>
      last N days
    </MenuItem>,
    <MenuItem key={5} value={4} onClick={() => changePeriod('from')}>
      from
    </MenuItem>,
    <MenuItem key={6} value={6} onClick={() => changePeriod('from to')}>
      from to
    </MenuItem>
  ];

  return (
    <Dialog title={'History Depth'} okName={'Save'} okDisabled={!canSave} onOK={onSave} onClose={onClose}>
      <div className='flexColumn'>
        <PeriodButton aria-haspopup="true" onClick={showPeriods}>
          {period}
        </PeriodButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={periodsListopen}
          onClose={hidePeriods}
        >
          {periodsList}
        </Menu>

        {period == 'last N days' && 
        <NDaysTextField
          variant="outlined"
          label="N"
          type="number"
          value={nDays}
          onChange={changeNDays}
          InputLabelProps={{
            shrink: true,
          }}
        />}

        {(period == 'from' || period == 'from to') && 
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            label="From"
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={changeFromDate}
            maxDate={fromMaxDate}
            renderInput={(params) => <TextField {...params} />}
          />
          {period == 'from to' && <div style={{height: '1em'}}></div>}
          {period == 'from to' &&
          <DesktopDatePicker
            label="To"
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={changeToDate}
            minDate={toMinDate}
            renderInput={(params) => <TextField {...params} />}
          />
          }
        </LocalizationProvider>}
      </div>
    </Dialog>
  );
}

export default TimeSelector;