import React, { useState, useMemo, useEffect } from 'react';
import { showOkDialog } from "../../storages/appStorage";
import { useSensorsStore, setCreatingSensor, addSensorTrack, removeSensorTrack, 
  getSensorTracks, updateSensor, deleteSensor, 
  restoreSensor} from '../../storages/sensorsStorage';
import { backend, useSyncEngStore } from '../../sync-engine';
import SensorGraph from './sensorGraph';
import SensorDataTable from './sensorDataTable';
import LabelCheckbox from "../labelCheckbox";
import LabelRadio from "../labelRadio";
import { EditIcon, ChartIcon, TableIcon, DelRestorIcon } from '../common';
import Utils from '../../Utils';

const SensorExpElement = ({sensor, data, active}) => {
  const sensorTracks = useSensorsStore(state => state.sensorTracks);

  const trackPrefs = useMemo(
    () => sensorTracks.get(String(sensor.id)), 
    [sensorTracks]);

  const [chart, showChart] = useState(false);
  const [table, showTable] = useState(false);
  const [trackType, setTrackType] = useState(trackPrefs ? trackPrefs.type : '2km');
  const [trackDots, setTrackDots] = useState(trackPrefs ? trackPrefs.dots : true);
  const [trackLines, setTrackLines] = useState(trackPrefs ? trackPrefs.lines : true);

  // const editSensor = () => setCreatingSensor(sensor);
  const editSensor = () => {
    console.log(`editSensor sensor`, sensor);
    setCreatingSensor(sensor);
  };

  const updateTrackType = (type) => {
    const sensorTracksPrefs = getSensorTracks();
    const trackPrefs = sensorTracksPrefs.get(String(sensor.id));
    if (trackPrefs) {
      addSensorTrack(String(sensor.id), {
        type: type,
        dots: trackPrefs.dots,
        lines: trackPrefs.lines,
      });
    }
  };

  const updateTrackDots = (dots) => {
    const sensorTracksPrefs = getSensorTracks();
    const trackPrefs = sensorTracksPrefs.get(String(sensor.id));
    if (trackPrefs) {
      addSensorTrack(String(sensor.id), {
        type: trackPrefs.type,
        dots: dots,
        lines: trackPrefs.lines,
      });
    }
  };

  const updateTrackLines = (lines) => {
    const sensorTracksPrefs = getSensorTracks();
    const trackPrefs = sensorTracksPrefs.get(String(sensor.id));
    if (trackPrefs) {
      addSensorTrack(String(sensor.id), {
        type: trackPrefs.type,
        dots: trackPrefs.dots,
        lines: lines,
      });
    }
  };

  const onShowTrackClick = (e) => {
    if (e.target.checked) {
      addSensorTrack(String(sensor.id), {
        type: trackType,
        dots: trackDots,
        lines: trackLines,
      });
    } else {
      removeSensorTrack(String(sensor.id));
    }
  };

  const onTrackTypeClick = (e) => {
    setTrackType(e.target.value);
    updateTrackType(e.target.value);
  };

  const onTrackDotsClick = (e) => {
    setTrackDots(e.target.checked);
    updateTrackDots(e.target.checked);
  };

  const onTrackLinesClick = (e) => {
    setTrackLines(e.target.checked);
    updateTrackLines(e.target.checked);
  };

  const onColumnChange = (dataKey, name) => {
    if (!sensor.columns) sensor.columns = {};
    const trName = name.trim();
    if (trName) {
      sensor.columns[dataKey] = {name: trName};
    } else {
      delete sensor.columns[dataKey];
    }
    updateSensor(sensor);
  };

  const onDeleteSensor = (sensor) => {
    showOkDialog({
      title: 'Delete sensor', 
      message: `Delete sensor "${sensor.name}"?`, 
      onOK: () => {
        removeSensorTrack(String(sensor.id));
        deleteSensor(sensor.id);
      }});
  };

  const onRestoreSensor = (sensor) => {
    showOkDialog({
      title: 'Restore sensor', 
      message: `Restore sensor "${sensor.name}"?`, 
      onOK: () => restoreSensor(sensor.id)});
  };
  
  const timeRange = useSyncEngStore(state => state.timeRange);
  const onDataExport = () => {
    let url = backend.getWorkspaceUrl()
    url += `/sensors/export_data?id=${sensor.id}&t1=${timeRange.t1}`
    if(timeRange.t2) url += `&t2=${timeRange.t2}`
    window.open(url, '_blank');
  };

  const getGraphData = (data) => {
    if (data.length > 0) console.log(`sensor data item`, data[0]);
    const sensorKeys = new Set();

    const graphObjArr = data.map(item => {
      const point = {time: item.time, timeStr: Utils.getDayTime(item.time)};
      if (item.data && typeof item.data === 'object') {
        for(const key in item.data) {
          const name = sensor.columns?.[key]?.name ?
                      sensor.columns[key].name : key;
          sensorKeys.add(name);
          point[name] = item.data[key];
        }
      }
      return point;
    });

    if (sensorKeys.size) {
      return {arr: graphObjArr, types: Array.from(sensorKeys)};
    }
    return null;
  };

  const graphData = useMemo(
    () => getGraphData(data), 
    [data, sensor.columns]);

  const getTableData = (data) => {
    const columns = new Map([
      ['stat', ''],
      ['locat', ''],
      ['time', 'Time']]);

    const tableObjArr = data.map(item => {
      const point = {
        stat: item.status,
        locat: Utils.formatLatLng(item, ''),
        time: Utils.getDayTime(item.time), 
        descr: item.descr};

      for(const dataKey in item.data) {
        point[dataKey] = item.data[dataKey];
        const name = sensor.columns?.[dataKey]?.name ?
                      sensor.columns[dataKey].name : dataKey;
        columns.set(dataKey, name);
      }
      return point;
    });

    columns.set('descr', 'Description'); // Description is last column

    if (tableObjArr.length) {
      return {arr: tableObjArr, columns: columns};
    }
    return null;
  };

  const tableData = useMemo(
    () => getTableData(data), 
    [data, sensor.columns]);

  const getTrack = (data) => {
    return data.filter(point => 
      Utils.isNumber(point.lng) && Utils.isNumber(point.lat) && 
      point.lng != 0 && point.lat != 0);
  };

  const track = useMemo(
    () => getTrack(data), 
    [data]);

  const trackDisabled = useMemo(
    () => Boolean(sensor.rm || track.length < 2), 
    [sensor, track]);

  useEffect(() => {
    if (trackDisabled) {removeSensorTrack(String(sensor.id));}
  }, [trackDisabled]);

  const lastPosTime = track.length > 0 
    ? Utils.formatDateTime(track[track.length - 1].time) 
    : 'no data';

  const shownTrack = Boolean(trackPrefs);

  return (
    <div className='paddingHoriz'>
      {chart && 
        <SensorGraph data={graphData} onCancel={()=>showChart(false)}/>}
      {table && 
        <SensorDataTable title={`Sensor "${sensor.name}" data`} data={tableData} 
          onColumnChange={onColumnChange} onCancel={()=>showTable(false)} disabled={!active}/>}

      <table className='leftAlignTable'><tbody>
        {sensor.descr && 
        <tr><td><i>Description:</i></td><td>{sensor.descr}</td></tr>}
        <tr><td><i>Last seen:</i></td><td>{lastPosTime}</td></tr>
        {sensor.lat && sensor.lng &&
        <tr><td><i>Default position:</i></td><td>{`${sensor.lat}, ${sensor.lng}`}</td></tr>}
      </tbody></table>

      <div className='spaceBtwFlexEnd'>
        <div className='sensorTrackBox'>
          <LabelCheckbox
            label='show track'
            disabled={trackDisabled}
            checked={shownTrack}
            onClick={onShowTrackClick}/>
          <div className='sensorTrackControls'>
            <div>
              <LabelRadio 
                label='whole' 
                title='show whole track'
                disabled={!shownTrack}
                checked={trackType === 'whole'}
                value="whole"
                onChange={onTrackTypeClick}/>
              <LabelRadio 
                label='last 2km' 
                title='show last 2km'
                disabled={!shownTrack}
                checked={trackType === '2km'}
                value="2km"
                onChange={onTrackTypeClick}/>
            </div>
            <div>
              <LabelCheckbox
                label='dots'
                title='show dots'
                disabled={!shownTrack}
                checked={trackDots}
                onClick={onTrackDotsClick}/>
              <LabelCheckbox
                label='lines'
                title='show lines'
                disabled={!shownTrack}
                checked={trackLines}
                onClick={onTrackLinesClick}/>
            </div>
          </div>
        </div>
        <div>
          {!sensor.rm && <>
          <ChartIcon title='Show Chart' enabled={graphData} 
            onClick={graphData ? () => showChart(true) : null}/>
          <TableIcon title='Show Sensor Data' enabled={graphData} 
            onClick={graphData ? () => showTable(true) : null}/>
          <TableIcon title='Export Sensor Data to CSV' enabled={graphData} 
            onClick={graphData ? () => onDataExport() : null}/>

          {active && <EditIcon titleAccess='Edit Sensor' onClick={editSensor}/>}
          </>}
          {active && <DelRestorIcon 
            rm={sensor.rm} 
            enabled={true}
            onDelete={() => onDeleteSensor(sensor)}
            onRestore={() => onRestoreSensor(sensor)}/>}
        </div>
      </div>
    </div>
  );
};

export default SensorExpElement;