import backend from "./Backend"
import data from "./Data"
import { setMdPropLoaded } from "../storages/appStorage";

// const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
class  PropList{
    constructor(name, syncUrl) {
        this.name     = name;
        this.syncUrl = syncUrl;
        this.mt  = 0;
    }

    clear(){
        this.mt = 0;
    }

    async sync(){
        // Sync request

        // if (this.name == 'point_comment') { // simulate slow connection
        //     await sleep(10000);
        // }

        let params = {
            prop    : this.name,
            mt: this.mt
        };
        // Time range
        const syncFilter = data.getSyncFilter(this.name);

        if (syncFilter.maxN !== undefined && Number(syncFilter.maxN) !== -1) {
            params.t1 = 1;
            params.limit = syncFilter.maxN;
        } else {
            if(syncFilter.t1)  params.t1 = syncFilter.t1;
            if(syncFilter.t2)  params.t2 = syncFilter.t2;
        }

        try{
            let syncData = await backend.request('POST',this.syncUrl, params);
            // Finish
            this.onSyncSuccess(syncData);
            setMdPropLoaded(this.name);
            return syncData.results;// changes
        }catch(err){
            //'Backend is not available'
            this.onSyncError(err);
        }
    }

    onSyncError(error){
        console.log('PropList['+this.name+'].onSyncError',error);
    }

    onSyncSuccess(syncData){
        console.log(`PropList[${this.name}].onSyncSuccess time ${Date.now()}`,syncData);
        this.mt = syncData.mt;
    }
}

export default PropList;

