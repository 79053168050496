import React, { useState, useEffect, useMemo } from "react";
import { CircularProgress } from '@mui/material';
import LctIcon from '@mui/icons-material/MyLocation';
import { useMapStore, cleanUpMapState, setChangedDrawing, setNewCoordinates, 
  expandAllClusters } from '../storages/mapStorage';
import { backend, useSyncEngStore } from "../sync-engine";
import { useAppStore, setSelected, showPageOnMap } from "../storages/appStorage";
import { useCamerasStore, initCameras, setCreatingCamera, setOrder, 
  showRemoved } from '../storages/camerasStorage';
import RvTableExpand from '../components/rvTable/rvTableExpand'
import CameraExpElement from '../components/cameras/cameraExpElement';
import EditCamera from '../components/cameras/editCamera';
import DrawerLR from '../components/drawerLeftRight';
import CameraSettings from '../components/cameras/cameraSettings';
import LabelCheckbox from "../components/labelCheckbox";
import Utils from "../Utils" 
import { AddIcon, LocationIcon, SortIcon, 
  SettingsIcon} from '../components/common';

const CamerasView = () => {
  const map = useMapStore(state => state.map);
  const camerasObjects = useSyncEngStore(state => state.camerasObjects, () => false);
  const camerasChanges = useSyncEngStore(state => state.camerasChanges);
  const timeRange =      useSyncEngStore(state => state.timeRange);
  const selectedIds =  useAppStore(state => state.selectedIds);
  const pagesOnMap =   useAppStore(state => state.pagesOnMap);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const creatingCamera = useCamerasStore(state => state.creatingCamera);
  const orderBy =          useCamerasStore(state => state.orderBy);
  const orderAsc =         useCamerasStore(state => state.orderAsc);
  const removedShown =     useCamerasStore(state => state.removedShown);

  const [settingsVisible, setSettingsVisible] = useState(false);

  const [rows, setRows] = useState([]);

  const cleanupEditMode = () => {
    cleanUpMapState();
    setCreatingCamera(null);
  };

  useEffect(() => {
    initCameras();
    return cleanupEditMode;
  }, []);

  const camerasLoaded = useMemo(() => {
    return loadedMdProps.has('camera');
  }, [loadedMdProps]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('cameras', selectedIds), [selectedIds]);

  const onEdit = ({obj, canLocate}) => {
    if (canLocate && obj.drawing) {
      locateItem(obj);
      map.setZoom(13);
      expandAllClusters(true);
      setChangedDrawing(obj.drawing);
      obj.drawing.startEdit((event) => {
        if (event.latLng) {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          setNewCoordinates({lng, lat});
        }
      });
    }
    setCreatingCamera(obj.camera);
  };

  const locateItem = (obj) => {
    if (obj.drawing) obj.drawing.locate(map);
  };

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push(<div className='sortHeader'>Name{getSortIcon(0)}</div>);
    header.push(<div className='sortHeader'></div>);
    header.push(<div className='sortHeader'>Images{getSortIcon(2)}</div>);
    header.push(<LctIcon/>);
    header.push('');   // hidden value (idInd={4})
    return header;
  };

  const header = useMemo(() => getHeader(), 
    [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(camerasObjects) {
        for(const id in camerasObjects){
          const obj = camerasObjects[id];
          const camera = obj.camera;

          if (camera && (removedShown || !camera.rm))
          {
            const cameraImages = obj.images.filter((image) => Utils.matchRange(image.time, timeRange));
            const canLocate = pagesOnMap.has('cameras') && map && !camera.rm && camera.lat && camera.lng;
            const locator = (<LocationIcon enabled={canLocate} 
              onClick={canLocate ? () => locateItem(obj) : null}></LocationIcon>);

            const vals = [];
            vals.push(camera.name);
            vals.push('');
            vals.push(cameraImages.length);
            vals.push(locator);
            vals.push(id); // hidden id value (idInd={4})
            vals.push(camera.rm); // hidden rm value (row[5])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- cameras count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [
        <div className='textCell' style={row[5] // camera.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[0]}
        </div>,  // camera.name
        <div className='textCell'>{row[1]}</div>,  // camera.mac
        row[2],  // cameraImages.length
        row[3], // locator
        row[4]], // id
        ['', '', '', '', row[4]] // expRow
      ]);

      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });

    return [];
  };

  useMemo(getRows, 
    [map, camerasChanges, orderBy, orderAsc, removedShown, pagesOnMap]);

  const getExpEl = () => {
    if (selectedId && camerasObjects) {
      const obj = camerasObjects[selectedId];
      if (obj) {
        const camera = obj.camera;
        if (camera) {
          const cameraImages = obj.images.filter((image) => Utils.matchRange(image.time, timeRange));
          cameraImages.sort((image1, image2) => image2.time - image1.time);
          const canLocate = pagesOnMap.has('cameras') && map && !camera.rm && camera.lat && camera.lng;
          return <CameraExpElement camera={camera} images={cameraImages} onEdit={() => onEdit({obj, canLocate})}/>;
        }
      }
    }

    return <div>Fail to get camera data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 130; //Identificator+Images+icon
    const freeSpace = listWidth - fixedColumns;
    const name = freeSpace > 10 ? freeSpace : 10;
    switch (index) {
      case 0: return name;  // Name
      case 1: return 0;  // Identificator
      case 2: return 85;  // Images
      case 3: return 45;  // LctIcon
    }
    return 0;
  };

  const openEditCamera = creatingCamera != null;

  return (<>
    {settingsVisible && 
      <CameraSettings onClose={()=>setSettingsVisible(false)}/> }
    <DrawerLR open={openEditCamera} width={530} body={
      openEditCamera ? <EditCamera/> : <></>
    }/>
    <div className={'fullHeight flexColumn' + (openEditCamera ? ' disabled' : '')}>
      <div className='rightPanHeader btnsHeader'>
        <div>
          <LabelCheckbox
            label='removed'
            title='Show/Hide removed Cameras'
            checked={removedShown}
            onClick={(e) => showRemoved(e.target.checked)}/>
          <LabelCheckbox
            label='Show on Map'
            title='Show/Hide Users on Map'
            checked={pagesOnMap.has('cameras')}
            onClick={(e) => showPageOnMap('cameras', e.target.checked)}/>
        </div>
        <button onClick={()=>{window.open(`/cameras/?ws=${backend.workspace}`)}}
          >Open Cameras Dashboard</button>
        <div>
          <SettingsIcon
            title='Cameras Settings'
            onClick={() => setSettingsVisible(true)}/>
          <AddIcon
            titleAccess='Add Camera'
            onClick={() => setCreatingCamera({})}/>
        </div>
      </div>
      {camerasLoaded 
        ? <RvTableExpand rows={rows} header={header} idInd={4} 
          clickColmns={[0,1,2]} selectedId={selectedId} 
          orderBy={orderBy} orderAsc={orderAsc}
          setSelectedId={(id) => setSelected('cameras', id)}
          rowHeight={32}
          expElHeight={60}
          getExpEl={getExpEl}
          getColumnWidth={getColumnWidth}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </>);
}   

export default CamerasView;