import React, { useMemo } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import useImageLoaded from '../hooks/useImageLoaded';

const Image = ({src, imgCl, progInd}) => {
  const [ref, loaded, error, onLoad, onError, reset] = useImageLoaded();

  const getImage = () => {
    reset();
    return <img className={imgCl} ref={ref} onLoad={onLoad} onError={onError} 
      src={src}/>;
  };
  const image = useMemo(() => getImage(), [src]);

  const getOutput = () => {
    if (error) return <div title='Fail to load image' 
      className='fullSize flexCenter'><img className="size5em" 
        src={'/images/noImage.svg'}/></div>;
    if (loaded) return image;
    return progInd ? progInd : <CircularProgress/>;
  }
  const output = useMemo(() => getOutput(), [loaded, error]);

  return(<>
    <div className="displayNone">{image}</div>
    {output}
    </>);
}

export default Image;