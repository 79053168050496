export { default as alertCircleOutlineSvg } from './alertCircleOutlineSvg';
export { default as alertCircleSvg } from './alertCircleSvg';
export { default as bluetoothSvg } from './bluetoothSvg';
export { default as camerasSvg } from './camerasSvg';
export { default as chartLineSvg } from './chartLineSvg';
export { default as chartScatterPlotSvg } from './chartScatterPlotSvg';
export { default as imageMultipleSvg } from './imageMultipleSvg';
export { default as mapLayersSvg } from './mapLayersSvg';
export { default as markerOutlineSvg } from './markerOutlineSvg';
export { default as markerSvg } from './markerSvg';
export { default as patrolsSvg } from './patrolsSvg';
export { default as reportsSvg } from './reportsSvg';
export { default as sensorsSvg } from './sensorsSvg';
export { default as settingsSvg } from './settingsSvg';
export { default as tableSvg } from './tableSvg';
export { default as usersSvg } from './usersSvg';
export { default as webSvg } from './webSvg';
export { default as xmlSvg } from './xmlSvg';