import React, { useState, useMemo } from 'react';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import { data, useSyncEngStore} from '../../sync-engine';
import { useAppStore, setSelected, setPatrolIdForPoints } from "../../storages/appStorage";
import { usePatrolsStore, setCheckedId, setOrder } from '../../storages/patrolsStorage';
import RvTableSelect from '../rvTable/rvTableSelect';
import PatrolExpElement from './patrolExpElement';
import { SortIcon } from '../common';
import Utils from '../../Utils';

const PatrolsTable = ({viewMode, containerWidth, onPubLvMap}) => {
  const patrolsObjects = useSyncEngStore(state => state.patrolsObjects, () => false);
  const pointsObjects = useSyncEngStore(state => state.pointsObjects, () => false);
  const patrolsChanges = useSyncEngStore(state => state.patrolsChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const patrolIdToShowPoints = useAppStore(state => state.patrolIdToShowPoints);
  const checkedIds = usePatrolsStore(state => state.checkedIds);
  const orderBy = usePatrolsStore(state => state.orderBy);
  const orderAsc = usePatrolsStore(state => state.orderAsc);
  const removedShown = usePatrolsStore(state => state.removedShown);

  const [rows, setRows] = useState([]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('patrols', selectedIds), [selectedIds]);

  const pointsArr = useMemo(() => {
    return pointsObjects ? Object.values(pointsObjects) : [];
  }, [pointsObjects]);

  const onChangeSelectedPatrol = (id) => {
    setPatrolIdForPoints(null);
    setSelected('patrols', id);
  };

  const getExpIcon = (id) => id == selectedId 
    ? <DownIcon onClick={() => onChangeSelectedPatrol(null)}/> 
    : <RightIcon onClick={() => onChangeSelectedPatrol(id)}/>;

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push('');
    header.push(<div className='sortHeader'>Time{getSortIcon(1)}</div>);
    header.push(<div className='sortHeader'>User{getSortIcon(2)}</div>);
    header.push(<div className='sortHeader'>Dist{getSortIcon(3)}</div>);
    header.push(<div className='sortHeader'>Dur{getSortIcon(4)}</div>);
    header.push(<div className='sortHeader'>Rep{getSortIcon(5)}</div>);
    header.push('');   // hidden value (idInd={6})
    return header;
  };

  const header = useMemo(() => getHeader(), 
    [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(patrolsObjects) {
        for(const id in patrolsObjects){
          const obj = patrolsObjects[id];
          const patrol= obj.patrol;

          if (patrol && (removedShown || !patrol.rm))
          {
            const userName = data.getUserName(patrol.user_id);
            const pntCount = pointsArr.reduce(
              (count, pntObj) => 
                count + (pntObj.point?.patrol_id == id ? 1 : 0), 0);
            const vals = [];
            vals.push(getExpIcon(id));
            vals.push(patrol.time);
            vals.push(userName);
            vals.push(patrol.distance);
            vals.push(patrol.duration);
            vals.push(pntCount);
            vals.push(id); // hidden id value (idInd={6})
            vals.push(patrol.rm); // hidden rm value (row[7])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- patrols count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [row[0],  // ExpIcon
        new Date(row[1]).toLocaleString(),  // time
        <div className='textCell' style={row[7] // patrol.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[2]}
        </div>,  // userName
        Utils.formatDistance(row[3], 1, ' '), //distance
        Utils.formatDuration(row[4], false, 1), //duration
        row[5], // pntCount
        row[6]], // id
        ['', '', '', '', '', '', row[6]] // expRow
      ]);

      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });
    return [];
  };
  useMemo(getRows, [patrolsChanges, selectedId, checkedIds, 
    orderBy, orderAsc, removedShown]);

  const getExpEl = () => {
    if (selectedId && patrolsObjects) {
      const obj = patrolsObjects[selectedId];
      const patrol= obj?.patrol;
      if (patrol) {
        return <PatrolExpElement id={obj.id} patrol={patrol} segments={obj.segments} drawings={obj.drawings} 
                  active={!viewMode} onPubLvMap={onPubLvMap}/>;
      }
    }

    return <div>Fail to get patrol data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 360; //Time+Distance+Locations+icons
    const freeSpace = listWidth - fixedColumns;
    const user = freeSpace > 10 ? freeSpace : 10;
    switch (index) {
      case 0: return 28;  // ExpIcon
      case 1: return 97;  // Time
      case 2: return user;  // user
      case 3: return 75;  // Distance
      case 4: return 90;  // Duration
      case 5: return 70;  // pntCount
    }
    return 0;
  }

  return <RvTableSelect rows={rows} header={header} 
    idInd={6} clickColmns={[1,2,3,4,5]} selectedId={selectedId} 
    orderBy={orderBy} orderAsc={orderAsc} checkedIds={checkedIds} setCheckedId={setCheckedId}
    expElHeight={patrolIdToShowPoints ? 410 : 210}
    getExpEl={getExpEl}
    getColumnWidth={getColumnWidth}
    containerWidth={containerWidth}/>;
};
export default PatrolsTable;