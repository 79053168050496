import React, { useState, useMemo, } from 'react';
import { data, objectsSnap } from '../../sync-engine';
import { useAppStore, showOkDialog, setPatrolIdForPoints, 
  setSubSelected } from "../../storages/appStorage";
  import { useMapStore } from '../../storages/mapStorage';
import { useLvStore } from '../../storages/lvStorage';
import { usePatrolsStore, deletePatrol, 
  restorePatrol } from '../../storages/patrolsStorage';
import { getCategoryIcon } from '../points/pointExpElement';
import PointExpElementShort from '../points/pointExpElementShort';
import { ButtonGreen, LocationIcon, DelRestorIcon } from '../common';
import RvTableExpand from '../rvTable/rvTableExpand';
import Utils from '../../Utils';


const PatrolExpElement = ({id, patrol, segments, drawings, active, onPubLvMap}) => {
  const map = useMapStore(state => state.map);
  const points = objectsSnap('points');
  const checkedIds = usePatrolsStore(state => state.checkedIds);
  const pagesOnMap = useAppStore(state => state.pagesOnMap);
  const patrolIdToShowPoints = useAppStore(state => state.patrolIdToShowPoints);
  const subSelectedIds = useAppStore(state => state.subSelectedIds);
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);

  const [pointRows, setPointRows] = useState([]);

  const subSelectedId = useMemo(
    () => subSelectedIds[0] ? subSelectedIds[0] : '', [subSelectedIds]);

  const userName = useMemo(() => data.getUserName(patrol.user_id), [patrol.user_id]);
  const startTime = useMemo(() => Utils.formatDateTime(patrol.start_time), [patrol.start_time]);
  const distance = useMemo(
    () => Utils.formatFullDistance(patrol.distance, 1, ' ')
    , [patrol.distance]);
  const duration = useMemo(
    () => Utils.formatFullDuration(patrol.duration), [patrol.duration]);
  const patrolPoints = useMemo(() => {
    if (points) {
      const pointsArr = Object.values(points);
      return pointsArr.filter((obj) => obj.point?.patrol_id == id);
    }
    return [];
  }, [id]);
  const speed = useMemo(
    () => patrol.distance && patrol.duration 
      ? (3600 * patrol.distance / patrol.duration).toFixed(2) + ' km/h'
      : ''
    , [patrol.distance, patrol.duration]);

  const showReports = (patrolId) => {
    if (patrolIdToShowPoints) {
      setPatrolIdForPoints(null);
    } else {
      setPatrolIdForPoints(patrolId);
    }
  };

  const locateItem = (drawings) => {
      const bounds = new window.google.maps.LatLngBounds();
      drawings.forEach(drawing=>{
        drawing.mapobj.getPath().forEach(pt=>{
          bounds.extend(pt)
        })
      })
      
      //map.panToBounds(bounds)
      map.panTo(bounds.getCenter());
  }

  const onDeletePatrol = (id, user) => {
    showOkDialog({
      title: 'Delete patrol', 
      message: `Delete patrol of ${user}?`, 
      onOK: () => deletePatrol(id)});
  }

  const onRestorePatrol = (id, user) => {
    showOkDialog({
      title: 'Restore patrol', 
      message: `Restore patrol of ${user}?`, 
      onOK: () => restorePatrol(id)});
  }

  const canLocate = useMemo(
    () => {
      const onMap = onPubLvMap ? pagesOnMapWd.has('Patrols Widget') : pagesOnMap.has('patrols');
      return onMap && checkedIds.has(String(id)) && map && segments && segments.find(segment => segment?.length > 1);
    }
    , [map, pagesOnMap, pagesOnMapWd, onPubLvMap, checkedIds, segments, id]);
  const locator = useMemo(
    () => <LocationIcon enabled={canLocate} 
        onClick={canLocate ? () => locateItem(drawings) : null}></LocationIcon>
    , [canLocate, drawings]);

  const showReportsText = patrolIdToShowPoints ? 'Hide Attached Reports' : 'Show Attached Reports';

  const getHeader = () => {
    const header = [];
    header.push('Type');
    header.push('Descr');
    header.push('Time');
    header.push('');   // hidden value (idInd={3})
    return header;
  };

  const header = useMemo(() => getHeader(), [id]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const rows = [];

      if(patrolPoints.length > 0) {
        for(const obj of patrolPoints){
          const point= obj.point;

          if (point)
          {
            const vals = [];
            vals.push(getCategoryIcon(Utils.pointCategoryIconFileName(point)));
            vals.push(point.descr);
            vals.push(new Date(point.time).toLocaleString());
            vals.push(point.id); // hidden id value (idInd={3})
            rows.push(vals);

            const expRow = ['', '', '', point.id];
            rows.push(expRow);
          }
        }
      }
      resolve(rows);
    });
  };

  const getRows = () => {
    buildTableRows().then((rows) => {
      setPointRows(rows);
    });
  };

  useMemo(() => getRows(), [patrolPoints]);

  const getExpEl = () => {
    if (subSelectedId && patrolPoints) {
      const obj = patrolPoints.find(obj => obj.id == subSelectedId);
      const point= obj?.point;
      if (point) {
        return <PointExpElementShort point={point} drawing={obj.drawing}/>;
      }
    }

    return <div>Fail to get report data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const descr = listWidth - 150; // 150 = Type + Time
    switch (index) {
      case 0: return 50;  // Type
      case 1: return descr;  // Descr
      case 2: return 100;  // Time
    }
    return 0;
  };

  return (
    <div className='patrolExp'>
      <div className='patrolExpData'>
      <table className='expTbl leftAlignTable'>
        <tbody>
          <tr><td>Creator</td><td>{userName}</td></tr>
          <tr><td>Start Time</td><td>{startTime}</td></tr>
          <tr><td>Distance</td><td>{distance}</td></tr>
          <tr><td>Duration</td><td>{duration}</td></tr>
          <tr><td>Reports</td><td>{patrolPoints.length}</td></tr>
          <tr><td>Speed</td><td>{speed}</td></tr>
        </tbody>
      </table>
      {locator}
      </div>
      <div className='spaceBetween'>
        <ButtonGreen disabled={patrolPoints.length < 1} onClick={() => showReports(id)}>
            {showReportsText}</ButtonGreen>
        {active && <DelRestorIcon 
          rm={patrol.rm} 
          enabled={true}
          onDelete={() => onDeletePatrol(patrol.id, userName)}
          onRestore={() => onRestorePatrol(patrol.id, userName)}/>}
      </div>
      {patrolIdToShowPoints && <div className='patrolPoints'>
        <RvTableExpand rows={pointRows} header={header} idInd={3} 
          clickColmns={[0,1,2]} selectedId={subSelectedId} 
          orderBy={0} orderAsc={true} expElHeight={100}
          setSelectedId={setSubSelected}
          getExpEl={getExpEl}
          getColumnWidth={getColumnWidth}/>
      </div>}
    </div>
  );
};

export default PatrolExpElement;