import { useState, useMemo, useEffect, } from 'react';
import { useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected } from "../../../storages/appStorage";
import { useMapStore, removeDrawingsFromMap, clusterMarkers, 
  unClusterMarkers } from '../../../storages/mapStorage';
import { setUserMarkers, getUserMarkers } from '../../../storages/usersStorage';
import { PointDrawing } from './mapObjects';
import Utils from "../../../Utils";

const UserDrawings = () => {
  const map = useMapStore(state => state.map);
  const usersChanges = useSyncEngStore(state => state.usersChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const userIcon = useAppStore(state => state.userIcon);
  const userSelIcon = useAppStore(state => state.userSelIcon);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('users', selectedIds), [selectedIds]);

  const [isShown, setShown] = useState(false);

  const checkAndUpdateObjDrawing = (obj, map, mapMarkers) => {
    if (obj && obj.user && !obj.user.rm && obj.state && 
        obj.state.lng && obj.state.lat) {
      updateObjDrawing(obj, map, mapMarkers);
    }
  };

  const updateObjDrawing = (obj, map, mapMarkers) => {
    const state = obj.state;
    if (state) {
      const user = obj.user;
      const id = user.id;
      const size =       new window.google.maps.Size(46, 46);
      const selectSize = new window.google.maps.Size(80, 80);
      const icons = {
        icon:         {url: userIcon.url, scaledSize: size},
        selectedIcon: {url: userSelIcon.url, scaledSize: selectSize}
      };

      if (!obj.drawing) {
        obj.drawing = new PointDrawing(icons);
      }
      const options = {title: user.name, pointIcon: icons};
      obj.drawing.update(map, 
        [state.lng, state.lat], selectedId == id, options);
      if (obj.listener) obj.listener.remove();
      obj.listener = window.google.maps.event.addListener(obj.drawing.mapobj, 
        'click', () => setSelected('users', id));

      mapMarkers.set(String(obj.id), {
        listener:     obj.listener, 
        drawing:      obj.drawing,
      });
    }
  };

  const showOnMap = () => {
    if (map) {
      const objects = objectsSnap('users');
      const oldMarkers = getUserMarkers();
      const newMarkers = new Map();

      for(const id in objects){
        const obj = objects[id];
        const user = obj.user;
        const state = obj.state;

        const canLocate = user && !user.rm && 
          state && state.lng && state.lat;

        if (canLocate) {
          oldMarkers.delete(id);
          updateObjDrawing(obj, map, newMarkers);
        } else {
          if (obj.listener) obj.listener.remove();
          if (obj.drawing) obj.drawing.remove();
        }
      }
      setUserMarkers(newMarkers);
      unClusterMarkers(oldMarkers); // the rest of oldMarkers
      removeDrawingsFromMap(oldMarkers);
      clusterMarkers(newMarkers);
    } 
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap();
  }

  const onPageHide = () => {
    setShown(false);
    const allUserMarkers = getUserMarkers();
    unClusterMarkers(allUserMarkers);
    removeDrawingsFromMap(allUserMarkers);
    setUserMarkers(new Map());
  }

  const onChanges = () => {
    if (isShown) showOnMap();
  }

  const onSelectedChange = () => {
    if (isShown) {
      const snap = objectsSnap('users');
      const allUserMarkers = getUserMarkers();
      const prevObj = snap[prevSelectedId];
      const selectObj = snap[selectedId];
      checkAndUpdateObjDrawing(prevObj, null, allUserMarkers);
      checkAndUpdateObjDrawing(selectObj, null, allUserMarkers);
    }
  };

  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  useEffect(() => {
    onSelectedChange();
  }, [selectedIds]);

  useMemo(onChanges, [map, usersChanges]);
  useEffect(onChanges, [userIcon, userSelIcon]);
  
  return(null); // no html render, draw on map directly instead
}

export default UserDrawings;