import React from "react";
import { Radio, RadioGroup, FormControl, 
  FormControlLabel} from '@mui/material';
import {appModes,useAppStore,setAppMode} from '../storages/appStorage';
import Dialog from './dialog/dialogButton';

export default ({onClose}) => {
    const appMode = useAppStore(state => state.appMode);

    const [inputMode, setMode] = React.useState(appMode);

    const onModeChange = (event) => setMode(event.target.value);

    const onSetButton = ()=>{
        console.warn("***** SET",inputMode)
        setAppMode(inputMode)
        onClose()
    }

    const modes = []
    for(let key in appModes){
        modes.push(
            <FormControlLabel key={key} value={key} control={<Radio/>} label={appModes[key]} />)
    }

    return (
      <Dialog title={'App Mode'} okName={'Set'} 
        okDisabled={appMode == inputMode} onOK={onSetButton} 
        onClose={onClose}>
        <FormControl component="fieldset">
          <RadioGroup value={inputMode} onChange={onModeChange}>
            {modes}
          </RadioGroup>
        </FormControl>
      </Dialog>
    )
}
