import React from 'react';
import { Button, Icon } from '@mui/material';
import { styled, makeStyles } from '@mui/styles';
import MuiLocationIcon from '@mui/icons-material/LocationOn';
import LctIcon from '@mui/icons-material/MyLocation';
import MuiCheckIcon from '@mui/icons-material/Check';
import MuiAddIcon from '@mui/icons-material/AddCircleOutline';
import MuiAddFolderIcon from '@mui/icons-material/CreateNewFolder';
import MuiEditIcon from '@mui/icons-material/Edit';
import MuiAllIcon from '@mui/icons-material/DoneAll';
import MuiSwapIcon from '@mui/icons-material/CompareArrows';
import SrtIcon from '@mui/icons-material/Sort';
import DelIcon from '@mui/icons-material/Delete';
import RstIcon from '@mui/icons-material/RestoreFromTrash';
import UploadIcon from '@mui/icons-material/UploadFile';
import MuiCategoryIcon from '@mui/icons-material/Category';
import MuiStatisticsIcon from '@mui/icons-material/Insights';
import { alertCircleOutlineSvg, alertCircleSvg, chartLineSvg, 
  chartScatterPlotSvg, imageMultipleSvg, markerOutlineSvg, markerSvg, 
  settingsSvg, tableSvg } from "./svg";
import Colors from '../styles/colors';

const ButtonGreen = styled(Button)({
  fontSize: 10,
  color: 'white',
  backgroundColor: Colors.listExpBtn,
  '&:hover': {
    backgroundColor: Colors.listExpBtnHov,
  },
});

const SizedIcon = styled(Icon)({
  height: '24px', 
  width: '24px',
});

const SortIcon = styled(SrtIcon)({
  transform: ({asc}) => `rotate(${asc ? 180 : 0}deg)`,
  paddingLeft: '0.1em',
  color: ({active}) => active ? 'black' : 'lightgray',
  '&:hover': { 
    color: ({active}) => active ? 'black' : 'grey' }
});

const useStyles = makeStyles({
  iconStyle: {
    cursor: props => props.enabled ? 'pointer' : 'default',
    color: props => props.enabled ? Colors.iconEnabl : Colors.iconDisabl,
    '&:hover': {
      color: props => props.enabled ? Colors.iconHover : Colors.iconDisabl,}
  },
  enabledIcon: {
    cursor: 'pointer',
    color: Colors.iconEnabl,
    '&:hover': {color: Colors.iconHover}
  },
  errStyle: {
    color: props => props.error ? Colors.error : Colors.warning
  },
  errStyle2: {
    color: 'red'
  },
});

const ErrorIcon = (props) => {
  const {error, ...rest} = props;
  const { errStyle } = useStyles({error});
  return <Icon {...rest} className={`${errStyle}`}>{alertCircleSvg}</Icon>;
}

const PlusSquare = () => {
  return <Icon>
      <img src={`/images/plusSquare.svg`}/>
    </Icon>;
}

const MinusSquare = (props) => {
  return <Icon>
      <img src={`/images/minusSquare.svg`}/>
    </Icon>;
}

const PointIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiLocationIcon {...props} className={`${enabledIcon}`}/>;
}

const AddIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiAddIcon {...props} className={`${enabledIcon}`}/>;
}

const AddFolderIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiAddFolderIcon {...props} className={`${enabledIcon}`}/>;
}

const EditIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiEditIcon {...props} className={`${enabledIcon}`}/>;
}

const AllIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiAllIcon {...props} className={`${enabledIcon}`}/>;
}

const SwapIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiSwapIcon {...props} className={`${enabledIcon}`}/>;
}

const ImportIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <UploadIcon  {...props} className={`${enabledIcon}`}/>;
}

const CategoryIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <MuiCategoryIcon {...props} className={`${enabledIcon}`}/>;
}

const SettingsIcon = (props) => {
  const { enabledIcon } = useStyles();
  return <SizedIcon {...props} className={`${enabledIcon}`}>
    {settingsSvg}</SizedIcon>;
}

const StatisticsIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <MuiStatisticsIcon {...rest} className={`${iconStyle}`}/>;
}

// {enabled, ...rest} = props; -> extract `enabled` to fix waring
// Received `false` for a non-boolean attribute `enabled`
const LocationIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <LctIcon {...rest} className={`${iconStyle}`}/>;
}

const CheckIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <MuiCheckIcon {...rest} className={`${iconStyle}`}/>;
}

const AlertCircleOutlineIcon = (props) => 
  <SizedIcon {...props}>{alertCircleOutlineSvg}</SizedIcon>;

const MarkerOutlineIcon = (props) => 
  <SizedIcon {...props}>{markerOutlineSvg}</SizedIcon>;

const MarkerIcon = (props) => 
  <SizedIcon {...props}>{markerSvg}</SizedIcon>;

const ChartIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <SizedIcon {...rest} className={`${iconStyle}`}>{chartLineSvg}</SizedIcon>;
}

const CoverageReportIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <SizedIcon {...rest} className={`${iconStyle}`}>{chartScatterPlotSvg}</SizedIcon>;
}

const ImageMultipleIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <SizedIcon {...rest} className={`${iconStyle}`}>{imageMultipleSvg}</SizedIcon>;
}

const TableIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <SizedIcon {...rest} className={`${iconStyle}`}>{tableSvg}</SizedIcon>;
}

const DeleteIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <DelIcon  {...rest} className={`${iconStyle}`}/>;
}

const RestoreIcon = (props) => {
  const {enabled, ...rest} = props;
  const { iconStyle } = useStyles({enabled});
  return <RstIcon  {...rest} className={`${iconStyle}`}/>;
}

const DelRestorIcon = (props) => {
  const {rm, enabled, onDelete, onRestore} = props;
  return rm 
    ? <RestoreIcon enabled={enabled} titleAccess='Restore'
        onClick={enabled ? onRestore : null}/> 
    : <DeleteIcon enabled={enabled} titleAccess='Delete'
        onClick={enabled ? onDelete : null}/>
}

export {
  ButtonGreen,
  PlusSquare,
  MinusSquare,
  PointIcon,
  AddIcon,
  AddFolderIcon,
  EditIcon,
  AllIcon,
  SwapIcon,
  ImportIcon,
  CategoryIcon,
  ImageMultipleIcon,
  SettingsIcon,
  StatisticsIcon,
  LocationIcon,
  CheckIcon,
  AlertCircleOutlineIcon,
  MarkerOutlineIcon,
  MarkerIcon,
  ChartIcon,
  CoverageReportIcon,
  TableIcon,
  ErrorIcon,
  SortIcon,
  DeleteIcon,
  RestoreIcon,
  DelRestorIcon
}