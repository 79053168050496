import React, { useEffect, useMemo } from 'react';
import { data } from '../sync-engine';
import { initSensors } from '../storages/sensorsStorage';
import { useWorkspaceStore, updateTimeFilter, setTFilterTimer, 
  getTFilterTimer } from '../storages/workspaceStorage';
import Map from '../Map';
import TabsView from "../components/tabsView";
import BleView    from "./bleView";
import CamerasView    from './camerasView';
import MLayersView    from './mLayersView';
import PatrolsView    from './patrolsView';
import PointsView    from './pointsView';
import SensorsView    from './sensorsView';
import UsersView    from './usersView';
// import KmlViewerView    from './kmlViewerView';
import { bluetoothSvg, camerasSvg, mapLayersSvg, patrolsSvg, reportsSvg, 
  sensorsSvg, usersSvg} from "../components/svg";
import Header from '../components/header/Header';
import callAt from '../components/timer';

const WorkView = () => {
  const nextTFilterTime = useWorkspaceStore(state => state.nextTFilterTime);

  const restartSyncTimer = () => {
    const tFilterTimer = getTFilterTimer();
    if (tFilterTimer) clearTimeout(tFilterTimer);
    if (nextTFilterTime) {
      console.debug(`restart timer nextTFilterTime`, nextTFilterTime);
      const syncTimePlus = new Date(nextTFilterTime.getTime() + 1000); // sync time + 1s
      const newSyncTimer = callAt(syncTimePlus, updateTimeFilter);
      setTFilterTimer(newSyncTimer);
    }
    
  };

  useMemo(restartSyncTimer, [nextTFilterTime]);

  useEffect(() => {
    initSensors();
  }, []);

  const views = [
    {key: 'points',   title: 'Reports',     icon: reportsSvg,   content: <PointsView/>},
    {key: 'patrols',  title: 'Patrols',     icon: patrolsSvg,   content: <PatrolsView/>},
    {key: 'users',    title: 'Users',       icon: usersSvg,     content: <UsersView/>},
    {key: 'sensors',  title: 'Sensors',     icon: sensorsSvg,   content: <SensorsView/>},
    {key: 'cameras',  title: 'Cameras',     icon: camerasSvg,   content: <CamerasView/>},
    {key: 'ble',      title: 'BLE-devices', icon: bluetoothSvg, content: <BleView/>},
    {key: 'features', title: 'Map Layers',  icon: mapLayersSvg, content: <MLayersView/>},
  ];

  const validViews = views.filter(view => data.modules[view.key]);

  // validViews.push(
  //   {key: 'xml', title: 'KML Viewer', content: <KmlViewerView/>});

  return <div className="centerAlignText">
            <Header/>
            <div className="layout-main">
              <Map/>
              <div className="layout-info">
                <TabsView views={validViews}/>
              </div>
            </div>
          </div>
};
export default WorkView;