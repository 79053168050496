import React from "react";
import { AutoSizer } from 'react-virtualized';
import { useWorkspaceStore, } from '../../storages/workspaceStorage';
import Dialod from '../dialog/dialog';

const LiveViewPublic = ({onCancel}) => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  return <Dialod
    fullScreen={true}
    title={<div className="flexJustCenter">{`${workspaceId} Live View`}</div>}
    onClose={onCancel}>
      <AutoSizer>{({width, height}) => (
        <iframe
          title='Live View'
          width={width}
          height={height}
          src={window.location.origin + '/#/live-view/' + workspaceId}>
        </iframe>
      )}
      </AutoSizer>
  </Dialod>;
}

export default LiveViewPublic;