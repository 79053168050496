import React, { useState, useEffect, useMemo } from "react";
import { CircularProgress } from "@mui/material";
import { useAppStore, setSelected } from "../storages/appStorage";
import { useMapStore, cleanUpMapState } from '../storages/mapStorage';
import { type, useFeaturesStore, setFeatureLegend, getFeatureLegend, 
  setCreatingFeature, cleanupCreatingFeature, setShowCategories, setShowCategoryPref, 
  setShowImportKML, setShowEditFeature, saveFeature,
  showRemoved } from '../storages/featuresStorage';
import EditFeature from "../components/features/editFeature";
import EditFolder from "../components/features/editFolder";
import ImportKML from "../components/features/importKML";
import NameDialog from "../components/dialog/nameDialog";
import CategoriesList from "../components/features/categoriesList";
import CategoryPrefs from "../components/features/categoryPrefs";
import FeatureTree from '../components/features/featureTree';
import FeatureLegend from "../components/features/featureLegend" 
import DrawerLR from '../components/drawerLeftRight';
import LabelCheckbox from "../components/labelCheckbox";
import { AddFolderIcon, CategoryIcon } from '../components/common';

const MLayersView = () => {
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const map = useMapStore(state => state.map);
  const showCategories = useFeaturesStore(state => state.showCategories);
  const showCategoryPref = useFeaturesStore(state => state.showCategoryPref);
  const showEditFeature = useFeaturesStore(state => state.showEditFeature);
  const showEditFolder = useFeaturesStore(state => state.showEditFolder);
  const showImportKML = useFeaturesStore(state => state.showImportKML);
  const removedShown = useFeaturesStore(state => state.removedShown);
  const creatingCategory = useFeaturesStore(state => state.creatingCategory);

  const [newFolderOpen, setNewFolderOpen] = useState(false);

  const featuresLoaded = useMemo(() => {
    return loadedMdProps.has('feature');
  }, [loadedMdProps]);

  const categoriesLoaded = useMemo(() => {
    return loadedMdProps.has('category');
  }, [loadedMdProps]);

  const hideLegend = () => {
    const legend = getFeatureLegend();
    if (map && legend) {
      map.controls[window.google.maps.ControlPosition.RIGHT_TOP].pop();
      setFeatureLegend(null);
      const legendParking = document.getElementById("legendParking");
      if (legendParking) {
        legendParking.appendChild(legend);
      }
    }
  };

  const cleanupOnClose = () => {
    setSelected('features', null);
    hideLegend();
    cleanUpMapState();
    cleanupCreatingFeature(true);
    setShowEditFeature(false);
    setShowCategoryPref(false);
  };

  useEffect(() => {
    if (!showEditFeature) hideLegend();
  }, [showEditFeature]);

  useEffect(() => {
    return cleanupOnClose;
  }, []);

  const onSaveNewFolder = (name) => {  // create new folder
    setCreatingFeature({name: name, type: type.FOLDER});
    saveFeature();
  };

  const haeder = (disableStyle) => {
    return <div className={'rightPanHeader btnsHeader' + disableStyle}>
        <LabelCheckbox
          label='removed'
          title='Show/Hide removed Layers'
          checked={removedShown}
          onClick={(e) => showRemoved(e.target.checked)}/>
        <div>
          {categoriesLoaded && <CategoryIcon
            titleAccess='Open Categories'
            onClick={() => setShowCategories(true)}/>}
          {featuresLoaded && <AddFolderIcon
            titleAccess='Add Folder'
            onClick={() => setNewFolderOpen(true)}/>}
          {/* <ButtonGreen onClick={() => setImportKML(true)}>Import from KML</ButtonGreen> */}
        </div>
      </div>
  }

  const disableStyle = showEditFeature || showCategoryPref || showImportKML 
    ? ' disabled' : '';

  return (<>
      {newFolderOpen && <NameDialog title={'New Folder'} onSave={onSaveNewFolder} 
        onClose={()=>setNewFolderOpen(false)}/>}
      <DrawerLR open={showCategories} width={530} body={
        showCategories ? <CategoriesList id={1}/> : <></>
      }/>
      <DrawerLR open={showCategoryPref} width={530} body={
        showCategoryPref ? <CategoryPrefs id={creatingCategory.id}/> : <></>
      }/>
      <DrawerLR open={showEditFeature} width={530} body={
        showEditFeature ? <EditFeature/> : <></>
      }/>
      <DrawerLR open={showEditFolder} width={530} body={
        showEditFolder ? <EditFolder/> : <></>
      }/>
      <DrawerLR open={showImportKML} width={530} body={
        showImportKML ? <ImportKML onClose={() => setShowImportKML(false)}/> : <></>
      }/>
      {haeder(disableStyle)}
      {featuresLoaded 
        ? <div className={'smoothYscroll' + disableStyle} id='treeView_id'>
            <FeatureTree/>
          </div>
        : <div className="circularProgress"><CircularProgress/></div>}
      <div id='legendParking' style={{display: 'none'}}>
        <div id='featureMapLegend'>
          <FeatureLegend/>
        </div>
      </div>
    </>);
}    

export default MLayersView;