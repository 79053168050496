import React, { useState, useMemo, } from 'react';
import { backend, syncEngine } from '../../sync-engine';
import { showOkDialog } from "../../storages/appStorage";
import { setCreatingUser, setShowFullProfile, } from '../../storages/usersStorage';
import ImageViewer from '../ImageViewer';
import {ButtonGreen} from '../common';
import Colors from '../../styles/colors';
import Utils from '../../Utils';

const UserExpElement = ({obj}) => {
  const id =         obj?.id;
  const user =       obj?.user;
  const loginState = obj?.loginState;
  const points =     obj?.points;
  const patrols =     obj?.patrols;

  const getPhotosAndLastReport = () => {
    const photos = [];
    let lastReport = {time: 0};
    if (!points?.size) return {photos, lastReport};

    for (const point of points.values()) {
      if (point.photos) photos.push(...point.photos);
      if (point.time > lastReport.time) lastReport = point;
    }
    return {photos, lastReport};
  }

  const getLastPatrol = () => {
    let lastPatrol = {time: 0};
    if (!patrols) return lastPatrol;

    for (const patrol of patrols.values()) {
      if (patrol.time > lastPatrol.time) lastPatrol = patrol;
    }
    return lastPatrol;
  }

  const doForceLogout = async (id) => {
    // send request
    await backend.request('POST','/auth/logoutDeviceUser',{id:id} );
    // Update UI
    syncEngine.syncNow();
  };

  const onForceLogout = (user, id) => {
    showOkDialog({
      title: 'Logout User', 
      message: `Logout "${user.name}"?`, 
      onOK: () => doForceLogout(id)});
  }

  const timeJoined = user?.timeJoined ? Utils.formatDate(user.timeJoined) : '';

  const photosAndLastReport = getPhotosAndLastReport();
  const lastReportStr = photosAndLastReport.lastReport.time && photosAndLastReport.lastReport.category 
    ? photosAndLastReport.lastReport.category + ', ' + 
        Utils.formatAgoDate(photosAndLastReport.lastReport.time)
    : '';

  const lastPatrol = getLastPatrol();
  const lastPatrolStr = lastPatrol.time 
    ? Utils.formatDistance(lastPatrol.distance) + ' ' + 
      Utils.formatAgoDate(lastPatrol.time)
    : '';

  const patrolsReports =
    <div className='expTable'>
      <table className='fullWidth leftAlignTable'>
        <tbody>
          <tr><td>Added:</td><td>{timeJoined}</td></tr>
          <tr className='expTbRow'>
              <td>#Patrols:</td><td>{patrols?.size ? patrols.size : 0}</td></tr>
          <tr><td>Last Patrol:</td><td>{lastPatrolStr}</td></tr>
          <tr className='expTbRow'>
              <td>#Reports:</td><td>{points?.size ? points.size : 0}</td></tr>
          <tr><td>Last Report:</td><td>{lastReportStr}</td></tr>
        </tbody>
      </table>
    </div>;

  // PHOTOS
  const [showImgViewer, setShowImgViewer] = useState(false);
  const [currentImgInd, setCurrentImgInd] = useState(0);
  const [imgViewPhotos, setIVPhotos] = useState([]);
  const onImgClick = (ind, urls) => {
    setCurrentImgInd(ind);
    setIVPhotos(urls);
    setShowImgViewer(true);
  };

  const onCloseImgViewer = () => setShowImgViewer(false);
  const pathPhotos = backend.getWorkspaceUrl()+'/file/';
  const photoUrl = pathPhotos + user?.photo;

  const userPhoto = user?.photo
    ? <img 
      className="usrPhoto"
      onClick={() => onImgClick(0, [photoUrl])}
      src={photoUrl}
      alt=''/>
    : null;
  
  const photoUrls = useMemo(() => {
    return photosAndLastReport.photos.map((photo) => pathPhotos+photo);
  }, [photosAndLastReport.photos]);

  const photoRows = useMemo(() => {
    return photoUrls.slice(0,2).map((photo, ind) => (
            <img 
            key={photo}
            className="thumbExp"
            onClick={() => onImgClick(ind, photoUrls)}
            src={photo}
            alt=''/>));
  }, [photoUrls]);

  const onViewFullProfile = () => {
    setShowFullProfile(true);
    setCreatingUser(Object.assign({}, user));
  };

  return (
    user ?
    <div className='detailsExp flexElem'>
      <div className='usrPhotoCol' style={{background: Colors.listRowOpened}}>
        {userPhoto}
        <ButtonGreen onClick={onViewFullProfile}>View Full Profile</ButtonGreen>
      </div>
      <div className='paddingElem'>
        {patrolsReports}
        {loginState && <ButtonGreen onClick={()=>{onForceLogout(user, id)}}>Force Logout</ButtonGreen>}
      </div>
      <div className='paddingElem'>
        {photoRows}
      </div>
      {showImgViewer && 
      <ImageViewer images={imgViewPhotos} onClose={onCloseImgViewer} index={currentImgInd}/>}
    </div>
    : <div>User details can't be found</div> // TODO: error dialog
  );
};

export default UserExpElement;