import React, { useMemo, }  from "react";
import Fade from "@mui/material/Fade";
import { FOG_TIMEOUT, useAppStore, fogScreen } from "../storages/appStorage";

const Fog = React.forwardRef((props, ref) => {
  return (<div ref={ref} {...props} className='fog'></div>);
})

const FogInOut = () => {
  const fogged = useAppStore(state => state.fogged);

  const screenFogged = useMemo(() => {
    if (fogged) {
      setTimeout(() => fogScreen(false), FOG_TIMEOUT);
    }
    return fogged;
  },
  [fogged]);

  return <Fade in={screenFogged} timeout={FOG_TIMEOUT}><Fog/></Fade>;
};
export default FogInOut;