import React, { useMemo } from 'react';
import MapSettings from './mapSettings';
import PatrolsSettings from './patrolsSettings';
import PointsSettings from './pointsSettings';
import SensorsSettings from './sensorsSettings';
import ErrorDialog from '../../../dialog/errorDialog';

const WdSettings = ({wdKey, onClose}) => {

  const getWdSetting = () => {
    switch (wdKey) {
      case 'Map Widget':     return <MapSettings onClose={onClose}/>;
      case 'Patrols Widget': return <PatrolsSettings onClose={onClose}/>;
      case 'Reports Widget': return <PointsSettings onClose={onClose}/>;
      case 'Sensors Widget': return <SensorsSettings onClose={onClose}/>;
    default:  return <ErrorDialog message={`Wrong widget: ${wdKey}`} onClose={onClose}/>;
    }
  };
  const setting = useMemo(getWdSetting, [wdKey]);

  return <>{setting}</>;
};

export default WdSettings;