import React from "react";
import { backend, useSyncEngStore } from '../../sync-engine';
import { type } from '../../storages/featuresStorage';
import { StatisticsIcon, CoverageReportIcon } from '../common';

const FeatureExpElement = ({feature}) => {
  const timeRange = useSyncEngStore(state => state.timeRange);

  const openStatistics = (type, id) => {
    const t2 = timeRange.t2 ? `&t2=${timeRange.t2}` : '';
    const statUrl = backend.getWorkspaceUrl() + 
      `/statistics/${type}?polygon_id=${id}&t1=${timeRange.t1}` + t2;

    window.open(statUrl);
  };

  const onCoverageReportNEW = (e, polygon) => {
    e.stopPropagation();
    const t2 = timeRange.t2 ? `&t2=${timeRange.t2}` : '';
    window.open(
      `/reports/?ws=${backend.workspace}&polygon=${polygon}&t1=${timeRange.t1}${t2}` );
  };

  const onCoverageReport = (e, feature_id) => {
    e.stopPropagation();
    openStatistics('patrols_coverage', feature_id);
  };

  const onStatisticsReport = (e, feature_id) => {
    e.stopPropagation();
    openStatistics('reports', feature_id);
  };

  const enabled = !feature.rm;

  return (
    <div className="featureExp">
      <div><b>Description:</b></div>
      <div>{feature.descr}</div>
      {feature.type == type.POLYGON &&
      <div className="flexJustEnd">
        <CoverageReportIcon 
            title='Show Patrols Coverage Report'
            enabled={enabled}
            onClick={enabled ? (e) => onCoverageReportNEW(e, feature.id) : null}/>
        <CoverageReportIcon 
            title='Show Coverage Report (old)'
            enabled={enabled}
            onClick={enabled ? (e) => onCoverageReport(e, feature.id) : null}/>
        <StatisticsIcon 
            titleAccess='Show Statistics (old)'
            enabled={enabled}
            onClick={enabled ? (e) => onStatisticsReport(e, feature.id) : null}/>
      </div>}
    </div>
  );
};

export default FeatureExpElement;