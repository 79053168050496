import React from "react";
import Dialod from '../dialog/dialog';
import VisxChart from './visxChart';
import { AutoSizer } from 'react-virtualized';

const Chart = ({title, data, linesAtr, onCancel}) => (
  <Dialod
    fullScreen={true}
    title={<div className="flexJustCenter">{title}</div>}
    onClose={onCancel}>
    <AutoSizer>{({ width, height }) => 
      (width && height) ? <VisxChart width={width} height={height} data={data} linesAtr={linesAtr} /> : <></>}
    </AutoSizer>
  </Dialod>
);

export default Chart;