import React from "react";
import RvTable from './rvTable'

const RvTableSelect = ({rows, header, idInd, clickColmns, selectedId, 
  orderBy, orderAsc, checkedIds, setCheckedId, rowHeight, 
  expElHeight, getExpEl, getColumnWidth, containerWidth}) => {

  const onRowClick = (index) => {
    const id = rows[index][idInd];
    const checked = checkedIds.has(String(id));
    setCheckedId(id, !checked);
  };

  const getRowStyle = (id) => {
    const checked = checkedIds.has(String(id));
    return checked ? 'listCell listRowSelected' : 'listCell';
  };

  const rvtProps = {rows, header, idInd, clickColmns, selectedId, 
    orderBy, orderAsc, onRowClick, rowHeight, expElHeight, getExpEl, 
    getColumnWidth, getRowStyle, containerWidth};

  return (
    <RvTable {... rvtProps}/>
  );
};

export default RvTableSelect;