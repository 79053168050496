import { useState, useMemo, useEffect, } from 'react';
import { useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected } from "../../../storages/appStorage";
import { useMapStore } from '../../../storages/mapStorage';
import { usePatrolsStore, setPatrolMarkers, 
  getPatrolMarkers } from '../../../storages/patrolsStorage';
import { LineDrawing } from './mapObjects';
import Utils from '../../../Utils';

const PatrolDrawings = () => {
  const map = useMapStore(state => state.map);
  const patrolsChanges = useSyncEngStore(state => state.patrolsChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const checkedIds = usePatrolsStore(state => state.checkedIds);

  const [isShown, setShown] = useState(false);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('patrols', selectedIds), [selectedIds]);

  const clearObjDrawings = (obj) => {
    if(obj.listeners){
      obj.listeners.forEach( listener => listener.remove() );
      obj.listeners = undefined;
    }
    if(obj.drawings){
      obj.drawings.forEach( drawing => drawing.remove() );
      obj.drawings = undefined;
    }
  };

  const updateObjDrawings = (obj, strokeColor, mapMarkers) => {
    if (obj) {
      const patrol = obj.patrol;
      const segments = obj.segments;
      const canDraw = segments && segments.find(segment => segment?.length > 1);
      if (patrol && canDraw && !patrol.rm) {
        const id = patrol.id;
        obj.drawings = [];
        obj.listeners = [];

        segments.forEach(segment=>{
          if (segment.length > 1) {
            const lineDrawing = new LineDrawing({strokeColor: strokeColor});
            lineDrawing.update(map, segment, selectedId == id);
            const listener = window.google.maps.event.addListener(lineDrawing.mapobj, 
              'click', () => setSelected('patrols', id));
            
            obj.listeners.push(listener);
            obj.drawings.push(lineDrawing);
          }
        });

        mapMarkers.set(String(obj.id), 
          {listeners: obj.listeners, drawings: obj.drawings});
      }
    }
  };

  const removePatrolDrawingsFromMap = (drawings) => { // specific for patrols
    drawings.forEach((obj) => clearObjDrawings(obj));
  };

  const showOnMap = () => {
    const objects = objectsSnap('patrols');
    if (map) {
      const oldMarkers = getPatrolMarkers();
      const newMarkers = new Map();

      for(const id in objects){
        if (checkedIds.has(String(id))) {
          oldMarkers.delete(id);
          const obj = objects[id];
          let strokeColor = null;
          if (obj.drawings?.length > 0) {
            strokeColor = obj.drawings[0].getOptions(false).strokeColor;
          } else {
            strokeColor = Utils.getMapRandomColor();
          }

          clearObjDrawings(obj); // Clear old
          updateObjDrawings(obj, strokeColor, newMarkers);
        }
      }
      setPatrolMarkers(newMarkers);
      removePatrolDrawingsFromMap(oldMarkers); // the rest of oldMarkers
    }
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap();
  }

  const onPageHide = () => {
    setShown(false);
    const allPatrolMarkers = getPatrolMarkers();
    removePatrolDrawingsFromMap(allPatrolMarkers);
    setPatrolMarkers(new Map());
  }

  const onChanges = () => {
    if (isShown) showOnMap();
  }

  const onSelectedChange = () => {
    if (isShown) {
      const snap = objectsSnap('patrols');
      if (!snap) return;  // can be at Live View
      const prevObj = snap[prevSelectedId];
      const selectObj = snap[selectedId];

      const allPatrolMarkers = getPatrolMarkers();

      if (checkedIds.has(String(prevSelectedId)) && 
          prevObj?.drawings?.length > 0) {
        const prevStrokeColor = prevObj.drawings[0].getOptions(false).strokeColor;
        clearObjDrawings(prevObj);
        updateObjDrawings(prevObj, prevStrokeColor, allPatrolMarkers);
      }
      
      if (checkedIds.has(String(selectedId)) && 
          selectObj?.drawings?.length > 0) {
        const selectStrokeColor = selectObj.drawings[0].getOptions(false).strokeColor;
        clearObjDrawings(selectObj);
        updateObjDrawings(selectObj, selectStrokeColor, allPatrolMarkers);
      }
    }
  };

  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  useEffect(() => {
    onSelectedChange();
  }, [selectedIds]);

  useMemo(onChanges, [map, patrolsChanges, checkedIds]);
  
  return(null); // no html render, draw on map directly instead
}

export default PatrolDrawings;