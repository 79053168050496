import React from "react";
import { styled } from '@mui/styles';
import { DialogTitle, IconButton, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const IconButtonRight = styled(IconButton)({
  position: 'absolute',
  right: '0.3em',
  top: '0.1em',
});

const DialogTitleSt = styled(DialogTitle)({
  padding: '0.3em 2.5em 0.3em 1em',
  backgroundColor: ({bgcolor}) => bgcolor ? bgcolor : 'none'
});

const TitleWithClose = ({ children, onClose, bgcolor }) => {
  return (
    <DialogTitleSt bgcolor={bgcolor}>
      {children}
      {onClose ? (
        <IconButtonRight onClick={onClose}>
          <CloseIcon />
        </IconButtonRight>
      ) : null}
    </DialogTitleSt>
  );
};

export default TitleWithClose;