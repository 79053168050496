import create from 'zustand';
import { showError } from "./appStorage";
import { backend, syncEngine } from '../sync-engine';
import * as storageUtils from "./utils/storageUtils";
import Utils from "../Utils" 

const initSensors = () => sensorsStoreApi.getState().initSensors();
const setShowEditSensor = (show) => sensorsStoreApi.getState().setShowEditSensor(show);
const setCreatingSensor = (sensor) => sensorsStoreApi.getState().setCreatingSensor(sensor);
const createSensor = (name, category, conf) => sensorsStoreApi.getState().createSensor(name, category, conf);
const editSensor = (id, name, conf) => sensorsStoreApi.getState().editSensor(id, name, conf);
const updateSensor = (sensor) => sensorsStoreApi.getState().updateSensor(sensor);
const deleteSensor = (sensorId) => sensorsStoreApi.getState().deleteSensor(sensorId);
const restoreSensor = (sensorId) => sensorsStoreApi.getState().restoreSensor(sensorId);
const addSensorTrack = (id, track) => sensorsStoreApi.getState().addSensorTrack(id, track);
const removeSensorTrack = (sensorId) => sensorsStoreApi.getState().removeSensorTrack(sensorId);
const addSensorTrackColor = (id, color) => sensorsStoreApi.getState().addSensorTrackColor(id, color);
const saveSensorTrackColors = () => sensorsStoreApi.getState().saveSensorTrackColors();
const setColorIndex = (index) => sensorsStoreApi.getState().setColorIndex(index);
const getColorIndex = () => sensorsStoreApi.getState().colorIndex;
const getSensorTracks = () => sensorsStoreApi.getState().sensorTracks;
const setSensorMarkers = (markers) => sensorsStoreApi.getState().setSensorMarkers(markers);
const getSensorMarkers = () => sensorsStoreApi.getState().sensorMarkers;
const setOrder = (orderBy) => sensorsStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => sensorsStoreApi.getState().showRemoved(show);

const DEF_ORDER_BY = 0; // index of 'name' header in SensorsView

const [useSensorsStore, sensorsStoreApi] = create((set, get) => ({
  creatingSensor: null,
  editSensorOpen: false,
  sensorTracks: new Map(),
  sensorTrackColors: new Map(), // to store only, isn't cause any render
  colorIndex: 0,
  sensorMarkers: new Map(), // all sensor markers currenly shown on map
  orderBy: DEF_ORDER_BY,
  orderAsc: true,
  removedShown: false,

  initSensors: () => {
    storageUtils.initList('sensors', set, DEF_ORDER_BY);
    const sensorTracksStr = localStorage.getItem('sensorTracksPrefs');
    try {    
      const restoredTracks = JSON.parse(sensorTracksStr, Utils.mapReviver);
      if (restoredTracks) set({ sensorTracks: restoredTracks });
    } catch (err) {
      console.error('Fail to restore sensor tracks: ' + err);
    }
  },

  setShowEditSensor: (show) => set({ editSensorOpen: show }),

  setCreatingSensor: (sensor) => {
    const editSensorOpen = get().editSensorOpen;
    set({ creatingSensor: sensor });
    if (sensor && !editSensorOpen) set({ editSensorOpen: true });
    if (!sensor && editSensorOpen) set({ editSensorOpen: false });
  },

  createSensor: async (name, category, conf) => {
    try {
      const data = {name: name, category: category};
      if (conf) data.conf = conf;
      await backend.request('POST', '/sensors/insert_sensor', 
        data);
    } catch (err) {
      console.error(err);
      showError(`Fail to add new Sensor: ${err}`);
    }
    syncEngine.syncNow();
  },


  editSensor: async (id, name, conf) => { // outdated? should be replaced with updateSensor
    const time = Date.now();
    const data = { values: [] };
    const value = {
      id  : id,
      name: name,
      time: time,
    }
    if (conf) value.conf = conf;
    data.values.push(value);
    try {    
      await backend.request('POST', '/sensors/save_sensors', data);
    } catch (err) {
      console.error(err);
      showError(`Fail to save Sensor: ${err}`);
    }
    syncEngine.syncNow();
  },

  updateSensor: async (sensor) => {
    try {    
      await backend.request('POST', '/sensors/update_sensor', sensor);
    } catch (err) {
      console.error(err);
      showError(`Fail to update Sensor: ${err}`);
    }
    syncEngine.syncNow();
  },

  deleteSensor : async (id) => {
    try{    
      await backend.request('POST', '/sensors/remove_sensor', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to delete sensor: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  restoreSensor : async (id) => {
    try{    
      await backend.request('POST', '/sensors/restore_sensor', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to restore sensor: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  addSensorTrack: (sensorId, track) => {
    const currentSensorTracks = get().sensorTracks;
    currentSensorTracks.set(sensorId, track);
    const sensorTracksStr = JSON.stringify(currentSensorTracks, Utils.mapReplacer);
    localStorage.setItem('sensorTracksPrefs', sensorTracksStr);
    set({ sensorTracks: new Map(currentSensorTracks) });

  },

  removeSensorTrack: (sensorId) => {
    const currentSensorTracks = get().sensorTracks;
    currentSensorTracks.delete(sensorId);
    const sensorTracksStr = JSON.stringify(currentSensorTracks, Utils.mapReplacer);
    localStorage.setItem('sensorTracksPrefs', sensorTracksStr);
    set({ sensorTracks: new Map(currentSensorTracks) });
  },

  addSensorTrackColor: (sensorId, color) => {
    const currentColors = get().sensorTrackColors;
    currentColors.set(sensorId, color);
    set({ sensorTrackColors: new Map(currentColors) });

  },

  saveSensorTrackColors: () => {
    const currentColors = get().sensorTrackColors;
    const currentSensorTracks = get().sensorTracks;

    for (let [id, color] of currentColors) {
      const track = currentSensorTracks.get(id);
      if (track) {
        track.color = color;
        get().addSensorTrack(id, track);
      }
    }
  },

  setColorIndex: (index) => set({ colorIndex: index }),
  setSensorMarkers: (markers) => set({ sensorMarkers: markers }),

  setOrder: (orderBy) => 
    storageUtils.setOrder('sensors', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('sensors', show, set),

}));

export { 
  useSensorsStore,
  initSensors,
  setShowEditSensor,
  setCreatingSensor,
  createSensor,
  editSensor,
  updateSensor,
  deleteSensor,
  restoreSensor,
  addSensorTrack,
  removeSensorTrack,
  addSensorTrackColor,
  saveSensorTrackColors,
  setColorIndex,
  getColorIndex,
  getSensorTracks,
  setSensorMarkers,
  getSensorMarkers,
  setOrder,
  showRemoved
};