import React, { useState, useMemo, } from 'react';
import { styled } from '@mui/styles';
import ImgIcon from '@mui/icons-material/PanoramaOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LctIcon from '@mui/icons-material/MyLocation';
import { data, syncEngine, useSyncEngStore } from '../../sync-engine';
import { useAppStore, setSelected } from '../../storages/appStorage';
import { useLvStore } from '../../storages/lvStorage';
import { useMapStore } from '../../storages/mapStorage';
import { usePointsStore, setStar, setOrder } from '../../storages/pointsStorage';
import RvTableExpand from '../rvTable/rvTableExpand';
import PointExpElement, {getCategoryIcon} from './pointExpElement';
import { LocationIcon, SortIcon } from '../common';
import Utils from '../../Utils';

const onStarClick = async (id, val) => {
  await setStar(id, val);
  syncEngine.syncNow();
}

const getStarIcon = (star, id, clickable) => {
  const className = clickable ? 'pClk' : '';
  const onClick = clickable ? () => onStarClick(id, 0) : null;
  if (star) {
    const YellowStar = styled(StarIcon)({
      color: 'yellow',
    });
    return <YellowStar className={className} onClick={onClick}/>;
  }
  return <StarBorderIcon className={className} onClick={onClick}/>;
}

const PointsTable = ({viewMode, containerWidth, onPubLvMap}) => {
  const map = useMapStore(state => state.map);
  const pointsObjects = useSyncEngStore(state => state.pointsObjects, () => false);
  const pointsChanges = useSyncEngStore(state => state.pointsChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const pagesOnMap = useAppStore(state => state.pagesOnMap);
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const filters = usePointsStore(state => state.filters);
  const orderBy = usePointsStore(state => state.orderBy);
  const orderAsc = usePointsStore(state => state.orderAsc);
  const removedShown = usePointsStore(state => state.removedShown);

  const [rows, setRows] = useState([]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('points', selectedIds), [selectedIds]);

  const locateItem = (drawing) => drawing.locate(map);

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push(<div className='sortHeader'>Type{getSortIcon(0)}</div>);
    header.push(<div className='sortHeader'>User{getSortIcon(1)}</div>);
    header.push(<div className='sortHeader'>Description{getSortIcon(2)}</div>);
    header.push(<div className='sortHeader'>Time{getSortIcon(3)}</div>);
    header.push(<ImgIcon/>);
    header.push(<ChatIcon/>);
    header.push(<StarIcon/>);
    header.push(<LctIcon/>);
    header.push('');   // hidden value (idInd={8})
    return header;
  };

  const header = useMemo(() => getHeader(), 
    [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(pointsObjects) {
        for(const id in pointsObjects){
          const obj = pointsObjects[id];
          const point= obj.point;

          if (point && filters.has(point.category))
          {
            const userName = data.getUserName(point.user_id);
            const photosCountStr = (point.photos?.length) ? point.photos.length : '';
            const commtsCountStr = (obj.comments?.length) ? obj.comments.length : '';
            const starIcon = getStarIcon(point.star, id, !viewMode);
            const onMap = onPubLvMap ? pagesOnMapWd.has('Reports Widget') : pagesOnMap.has('points');
            const canLocate = onMap && map && point && !point.rm;
            const locator = (<LocationIcon enabled={canLocate} 
              onClick={canLocate ? () => locateItem(obj.drawing) : null}></LocationIcon>);

            const vals = [];
            vals.push(Utils.pointCategoryIconFileName(point));
            vals.push(userName);
            vals.push(point.descr);
            vals.push(point.time);
            vals.push(photosCountStr);
            vals.push(commtsCountStr);
            vals.push(starIcon);
            vals.push(locator);
            vals.push(id); // hidden id value (idInd={8})
            vals.push(point.rm); // hidden rm value (row[9])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- points count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [getCategoryIcon(row[0]), // point category IconFileName
        <div className='textCell' style={row[9] // point.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[1]}
        </div>,  // userName
        <div className='textCell'>{row[2]}</div>, // point.descr
        new Date(row[3]).toLocaleString(),  //point.time
        row[4], // photosCountStr
        row[5], // commtsCountStr
        row[6], // starIcon
        row[7], // locator
        row[8]], // id
        ['', '', '', '', '', '', '', '', row[8]] // expRow
      ]);

      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });

    return [];
  };

  useMemo(getRows, 
    [map, pointsChanges, filters, orderBy, orderAsc, removedShown, pagesOnMap, pagesOnMapWd, onPubLvMap]);

  const getExpEl = () => {
    if (selectedId && pointsObjects) {
      const obj = pointsObjects[selectedId];
      const point= obj?.point;
      if (point) {
        return <PointExpElement point={point} comments={obj.comments} 
          date={new Date(point.time).toLocaleString()} active={!viewMode}/>;
      }
    }
    return <div>Fail to get report data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 309; //Type+Time+Icones
    const freeSpace = listWidth - fixedColumns;
    const description = freeSpace > 2 ? freeSpace * 0.6 : 10;
    const user = freeSpace > 2 ? freeSpace - description : 10;
    switch (index) {
      case 0: return 62;  // Type
      case 1: return user;  // User
      case 2: return description;  // Description
      case 3: return 97;  // Time
      case 4: return 35;  // ImgIcon
      case 5: return 35;  // ChatIcon
      case 6: return 35;  // StarIcon
      case 7: return 45;  // LctIcon
    }
    return 0;
  };

  return <RvTableExpand rows={rows} header={header} idInd={8} 
    clickColmns={[0,1,2,3,4,5]} selectedId={selectedId} 
    orderBy={orderBy} orderAsc={orderAsc}
    setSelectedId={(id) => setSelected('points', id)}
    getExpEl={getExpEl}
    getColumnWidth={getColumnWidth}
    containerWidth={containerWidth}/>;
};
export default PointsTable;