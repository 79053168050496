import React, { useMemo } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import {Img} from 'react-image';
import { backend } from "../sync-engine";
import Dialod from './dialog/dialog';

const ImageGallery = ({title, images, onImgClick, onCancel}) => {

  const path = backend.getWorkspaceUrl()+'/cam_prv/';

  // TODO: src={[path + image.file, 'FailToLoad.jpg']}
  const getImages = () => images.map(
    (image, ind) => <Img
      key={image.file}
      className="thumbExp"
      onClick={() => onImgClick(ind)}
      loader={<div className="thumbLoader"><CircularProgress /></div>}
      src={path +image.id+'/'+image.file} />
  );

  const imgs = useMemo(() => getImages(), [images]);

  return <Dialod
    fullScreen={true}
    lowZind={true}
    title={<div className="flexJustCenter">{title}</div>}
    onClose={onCancel}>
    <AutoSizer>{({ width, height }) => 
      (width && height) ? 
      <div className='flexWrap' style={{ width: width}}>{imgs}</div>
      : <></>
    }
    </AutoSizer>
  </Dialod>;
}

export default ImageGallery;