import React, { useState, useMemo, useEffect } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { CircularProgress } from "@mui/material";
import { useAppStore } from "../../../storages/appStorage";
import { useMapStore } from "../../../storages/mapStorage";
import { useWorkspaceStore } from "../../../storages/workspaceStorage";
import { useLvStore, setWidgetLayouts, getWidget2show } from "../../../storages/lvStorage";
import MapWidget from './mapWidget';
import PatrolsWidget from './patrolsWidget';
import PointsWidget from './pointsWidget';
import SensorsWidget from './sensorsWidget';
import WdSettings from './settings/wdSettings';
import Image from "../../image";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const widgetMins = {
  'Map Widget': {minW: 2, minH: 3},
  'Patrols Widget': {minW: 3, minH: 3},
  'Reports Widget': {minW: 3, minH: 3},
  'Sensors Widget': {minW: 3, minH: 3},
};

const WidgetsGrid = ({setup}) => {
  const widgetLayouts = useLvStore(state => state.widgetLayouts);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const mapLoaded = useMapStore(state => state.mapLoaded);
  const wsImage = useWorkspaceStore(state => state.wsImage);

  const [mounted, setMounted] = useState(false);
  const [settingsWdKey, setSettingsWdKey] = useState('');

  useEffect(() => {
    setMounted(true);
  }, []);

  const prefsLoaded = useMemo(() => {
    return loadedMdProps.has('pref');
  }, [loadedMdProps]);

  const getDroppingWidget = (layoutItem) => {
    const wdKey = getWidget2show();
    const widgetMin = widgetMins[wdKey];
    return {i: wdKey, 
      x: layoutItem.x, 
      y: layoutItem.y,
      w: widgetMin ? widgetMin.minW : 1,
      h: widgetMin ? widgetMin.minH : 1,
      minW: widgetMin?.minW,
      minH: widgetMin?.minH};
  } 

  const onDrop = (layout, layoutItem) => {
    if (!layoutItem) return;
    const widget = getDroppingWidget(layoutItem);
    const layouts = widgetLayouts.lg.filter(w => w.i != layoutItem.i);
    layouts.push(widget);
    setWidgetLayouts({lg: layouts}, true);
  };

  const onWidgetHide = (l) => {
    const layouts = widgetLayouts.lg.filter(w => w.i != l.i);
    setWidgetLayouts({lg: layouts}, true);
  };

  const isMapOnPubLv = () => {
    if (setup) return false;
    return Boolean(widgetLayouts.lg.find(w => w.i == 'Map Widget'));
  };
  const mapOnPubLv = useMemo(isMapOnPubLv, [setup, widgetLayouts]);

  const getWidget = (key) => {
    switch (key) {
      case 'Map Widget':
        return <MapWidget setup={setup}/>;
      case 'Patrols Widget':
        return <PatrolsWidget onPubLvMap={mapOnPubLv}/>;
      case 'Reports Widget':
        return <PointsWidget onPubLvMap={mapOnPubLv}/>;
      case 'Sensors Widget':
        return <SensorsWidget onPubLvMap={mapOnPubLv}/>;
      default:
        return null;
    }
  };

  const getMapWidget = () => widgetLayouts.lg.find(l => l.i == 'Map Widget');
  const mapWidget = useMemo(getMapWidget, [widgetLayouts]);

  const getCells = () => widgetLayouts.lg.map(l => 
    <div key={l.i} className='flexColumn'>
      {setup && <div className='widgetHandle'>
        <div className='widgetTitle'>{l.i}</div>
        <div className='widgetButton' title='Settings' onClick={() => setSettingsWdKey(l.i)}>
          &#9881;
        </div>
        <div className='widgetButton' title='Hide' onClick={() => onWidgetHide(l)}>
          &times;
        </div>
      </div>}
      <div className='widgetContent'>{getWidget(l.i)}</div>
    </div>);

  const cells = useMemo(getCells, [widgetLayouts]);

  const mapLoading = mapWidget && (!mapLoaded || !prefsLoaded);

  return <>
    {settingsWdKey && <WdSettings wdKey={settingsWdKey}  
      onClose={()=>setSettingsWdKey('')}/>}    
    {mapLoading && <Image imgCl='wsImage' src={wsImage} 
      progInd={<div className="fullSize flexCenter"><CircularProgress /></div>}/>}
    <ResponsiveReactGridLayout
      rowHeight={30}
      layouts={widgetLayouts}
      onLayoutChange={(layout, layouts) => setWidgetLayouts(layouts)}
      // WidthProvider option
      measureBeforeMount={false}
      // animate on mount. To cansel delete `useCSSTransforms` (it's default `true`)
      // and set `measureBeforeMount={true}`.
      useCSSTransforms={mounted}
      compactType={"vertical"}
      preventCollision={false}
      onDrop={onDrop}
      isDroppable={true}
      isResizable={Boolean(setup)}
      isDraggable={Boolean(setup)}
      draggableCancel='.widgetContent'
    >
      {cells}
    </ResponsiveReactGridLayout>
  </>;
}

export default WidgetsGrid;