import React, { useMemo, } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSyncEngStore } from '../../sync-engine';
import { type, useFeaturesStore, changeCreatingFeature } from '../../storages/featuresStorage';

const FoldersSelect = () => {
  const featuresObjects = useSyncEngStore(state => state.featuresObjects, () => false);
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);

  const rootVal = '--- root ---';

  const folderChange = (e) => {
    const parentId = e.target.value == rootVal ? null : e.target.value;
    changeCreatingFeature({parent_id: parentId});
  };

  const getFoldersItems = () => {
    const foldersItems = [
      <MenuItem key={0} value={rootVal}>{rootVal}</MenuItem>
    ];

    for(const id in featuresObjects){
      const feature = featuresObjects[id]?.feature;

      if (feature && ! feature.rm && feature.type == type.FOLDER) {
        const item = <MenuItem key={id} value={id}>{feature.name}</MenuItem>
        foldersItems.push(item);
      }
    }

    return foldersItems;
  }

  const foldersSelectItems = useMemo(
    () => getFoldersItems(), [featuresObjects]);

  return (
    <FormControl variant='outlined' margin={'none'} size={'medium'}>
      <InputLabel id='select-folder-label'>Parent</InputLabel>
      <Select
        labelId='select-folder-label'
        value={creatingFeature.parent_id ? creatingFeature.parent_id : rootVal}
        onChange={folderChange}
        label='Parent'
      >
        {foldersSelectItems}
      </Select>
    </FormControl>
  );
}

export default FoldersSelect;