import React from "react";
import { DialogActions, Button, } from '@mui/material';
import Dialod from './dialog';

const DialogButton = ({ title, okName, okDisabled, onOK, onClose, children }) => {
  return (
    <Dialod title={title} onClose={onClose} actions={
      <DialogActions>
        <Button disabled={okDisabled} onClick={onOK} color="primary">
          {okName ? okName : 'OK'}
        </Button>
      </DialogActions>
    }>
      {children}
    </Dialod>
  );
};

export default DialogButton;