import create from 'zustand';
import { showError } from '../storages/appStorage';
import { backend, syncEngine } from '../sync-engine';
import * as storageUtils from "./utils/storageUtils";

const initUsers = () => usersStoreApi.getState().initUsers();
const setCreatingUser =    (user) => usersStoreApi.getState().setCreatingUser(user);
const setAddUserState = () =>     usersStoreApi.getState().setAddUserState();
const setShowEditUser =    (show) => usersStoreApi.getState().setShowEditUser(show);
const setShowFullProfile = (show) => usersStoreApi.getState().setShowFullProfile(show);
const createEditUser = (user) => usersStoreApi.getState().createEditUser(user);
const saveCredentials = (params) => usersStoreApi.getState().saveCredentials(params);
const deleteUser = (userId) => usersStoreApi.getState().deleteUser(userId);
const restoreUser = (userId) => usersStoreApi.getState().restoreUser(userId);
const setUserMarkers = (markers) => usersStoreApi.getState().setUserMarkers(markers);
const getUserMarkers = () => usersStoreApi.getState().userMarkers;
const setOrder = (orderBy) => usersStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => usersStoreApi.getState().showRemoved(show);

const DEF_ORDER_BY = 1; // index of 'Name' header in UsersView

const [useUsersStore, usersStoreApi] = create((set, get) => ({
  creatingUser: null,
  showEditUser: false,
  showFullProfile: false,
  userMarkers: new Map(), // all user markers currenly shown on map
  orderBy: DEF_ORDER_BY,
  orderAsc: true,
  removedShown: false,

  initUsers: () => 
    storageUtils.initList('users', set, DEF_ORDER_BY),

  setCreatingUser: (user) => set({ creatingUser: user }),

  setAddUserState: () => set({showEditUser: true}),
  setShowEditUser: (show) => set({showEditUser: show}),
  setShowFullProfile: (show) => set({showFullProfile: show}),

  createEditUser: async (user) => {
    const path = user.id ? '/users/modifyUser' : '/users/insertUser';
    try{
      await backend.request('POST', path, user);
    }catch(err){
      console.error(err);
      showError('Fail to' + (user.id ? 'update ' : 'create new ') +  `user: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  saveCredentials: async (params) => {
    try{
      await backend.request('POST', '/auth/upsert', params);
    }catch(err){
      console.error(err);
      showError(`Fail to save Credentials: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  deleteUser : async (userId) => {
    try{    
      await backend.request('POST', '/users/removeUser', {id: userId});
    }catch(err){
      console.error(err);
      showError(`Fail to delete user: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  restoreUser : async (userId) => {
    try{    
      await backend.request('POST', '/users/restoreUser', {id: userId});
    }catch(err){
      console.error(err);
      showError(`Fail to restore user: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  setUserMarkers: (markers) => set({ userMarkers: markers }),

  setOrder: (orderBy) => 
    storageUtils.setOrder('users', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('users', show, set),
}));

export { 
  useUsersStore,
  initUsers,
  setCreatingUser,
  setAddUserState,
  setShowEditUser,
  setShowFullProfile,
  createEditUser,
  saveCredentials,
  deleteUser,
  restoreUser,
  setUserMarkers,
  getUserMarkers,
  setOrder,
  showRemoved
};