import create from 'zustand';
import { Loader } from 'google-maps';
import MarkerClustererWrap from "../components/map/markerClustererWrap";

const removeDrawingsFromMap = (drawings) => {
  drawings.forEach((obj) => {
    if (obj.listener) obj.listener.remove();
    if (obj.drawing) obj.drawing.remove();
  });
};

const objs2markers = objs => Array.from(objs.values()).map(item => item.drawing.mapobj);

const initGoogleMap = (params) =>   mapStoreApi.getState().initGoogleMap(params);
const setLayoutMapRef = (ref) => mapStoreApi.getState().setLayoutMapRef(ref);
const getLayoutMapRef = () =>     mapStoreApi.getState().layoutMapRef;
const cleanUpGoogleMap = () =>   mapStoreApi.getState().cleanUpGoogleMap();
const cleanUpMapState = () =>   mapStoreApi.getState().cleanUpMapState();
const setMapPopup = (popup) =>   mapStoreApi.getState().setMapPopup(popup);
const setTempDrawing = (drawing) => mapStoreApi.getState().setTempDrawing(drawing);
const getTempDrawing = () =>     mapStoreApi.getState().tempDrawing;
const setChangedDrawing = (drawing) => mapStoreApi.getState().setChangedDrawing(drawing);
const getChangedDrawing = () =>  mapStoreApi.getState().changedDrawing;
const setNewCoordinates = (coords) => mapStoreApi.getState().setNewCoordinates(coords);
const clusterMarkers = (objs) => mapStoreApi.getState().clusterMarkers(objs);
const unClusterMarkers = (objs) => mapStoreApi.getState().unClusterMarkers(objs);
const expandAllClusters = (expand) => mapStoreApi.getState().expandAllClusters(expand);
const setClustererStyles = (onLV) => mapStoreApi.getState().setClustererStyles(onLV);

const [useMapStore, mapStoreApi] = create((set, get) => ({
  map: null,
  markerClusterer: null,
  layoutMapRef: null,
  mapLoaded: false,
  mapPopup: null,
  tempDrawing: null, // temp object to be cleaned
  changedDrawing: null, // drawing of changed object; shouldn't be cleaned
  newCoords: null, // new coordinates of changed object

  createMap: ({center, zoom, mapDivId, onLV}) => {
    const googleMap = new window.google.maps.Map(
      document.getElementById(mapDivId), 
      {
        center: center,
        zoom: zoom,
      }
    );
    set({ map: googleMap, markerClusterer: new MarkerClustererWrap(googleMap, onLV) });
  },

  initGoogleMap: (params) => {
    if(window.google){
      get().createMap(params)
    }else{
      const loader = new Loader('AIzaSyAeM9hAb_UitOiVfNKxxR3wIWwnvU7m-LY');
      loader.load().then(() => {
        //console.log('Maps API loader finished!')
        get().createMap(params);
        set({ mapLoaded: true });
      });
    }
  },

  setLayoutMapRef: (ref) => set({ layoutMapRef: ref }),
  cleanUpGoogleMap: () => set({ map: null, markerClusterer: null }),

  cleanUpMapState: () => {
    const map = get().map;
    if (map) {
      map.setOptions({draggableCursor: ''});  // set default cursor
  
      // drop listeners
      window.google.maps.event.clearListeners(map, 'click'); 
      window.google.maps.event.clearListeners(map, 'mousemove');
  
      // close popup
      const popup = get().mapPopup;
      if (popup) {
        popup.close();
        set({ mapPopup: null });
      }
  
      // remove temp markers
      const tempDrawing = get().tempDrawing;
      if (tempDrawing) {
        tempDrawing.remove();
        set({ tempDrawing: null });
      }
  
      set({ changedDrawing: null, newCoords: null })
      get().expandAllClusters(false);
    }
  },

  setMapPopup: (popup) => set({ mapPopup: popup }),
  setTempDrawing: (drawing) => set({ tempDrawing: drawing }),
  setChangedDrawing: (drawing) => set({ changedDrawing: drawing }),
  setNewCoordinates: (coords) => set({ newCoords: coords }),

  clusterMarkers: (objs) => {
    const markerClusterer = get().markerClusterer;
    if (markerClusterer) markerClusterer.clusterMarkers(objs2markers(objs));
  },

  unClusterMarkers: (objs) => {
    const markerClusterer = get().markerClusterer;
    if (markerClusterer) markerClusterer.unClusterMarkers(objs2markers(objs));
  },

  expandAllClusters: (expand) => {
    const markerClusterer = get().markerClusterer;
    if (markerClusterer) markerClusterer.expandAllClusters(expand);
  },

  setClustererStyles: (onLV) => {
    const markerClusterer = get().markerClusterer;
    if (markerClusterer) markerClusterer.setStyles(onLV);
  },
}));

export { 
  useMapStore,
  initGoogleMap,
  setLayoutMapRef,
  getLayoutMapRef,
  cleanUpGoogleMap,
  cleanUpMapState,
  setMapPopup,
  setTempDrawing,
  getTempDrawing,
  setChangedDrawing,
  getChangedDrawing,
  setNewCoordinates,
  removeDrawingsFromMap,
  clusterMarkers,
  unClusterMarkers,
  expandAllClusters,
  setClustererStyles
};