import Module from "../Module"

//--------------------------------------------------------------------------------
// MODULE: BLE

class  Cameras extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            camera:           {syncUrl:'/cameras/load_cameras'},
            camera_image:     {syncUrl:'/cameras/load_camera_images'}
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        console.log('Cameras.onDataChanges')
        let results = {};
        if(changes.camera)       this.onCameraChanges (changes.camera, results);
        if(changes.camera_image) this.onImageChanges  (changes.camera_image, results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onCameraChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            obj.camera = prop;
        });
    }
    onImageChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            obj.images.push(prop);
        });
    }

    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
          obj = {
            id:      id,
            camera: null,
            images: []
          };
          this.objects[id] = obj;
        }
        return obj;
    }
}

export default Cameras;
