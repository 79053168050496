import Module from "../Module"

//--------------------------------------------------------------------------------
// MODULE: PATROLS

class  Patrols extends Module{
    constructor() {
        super();
        // TABLES LIST
        this.props = {
            patrol:           {syncUrl:'/patrols/load_patrols'},//'/load/changes'},
            patrol_location:  {syncUrl:'/patrols/load_patrol_locations'} // load all locations
        }
    }

    async onDataChanges (changes) {
      return new Promise((resolve) => {
        console.log('Patrols.onDataChanges')
        let results = {};
        if(changes.patrol)          this.onPatrolChanges   (changes.patrol,         results);
        if(changes.patrol_location) this.onLocationChanges (changes.patrol_location,results);
        resolve(this.isNotEmpty(results) ? results : null);
      });
    }

    onPatrolChanges(changes,results){
        changes.forEach(prop => {
            let obj  = this.getObject(prop.id);// id must exist
            results[prop.id] = obj;
            // Update main object value
            obj.patrol = prop;
        });
    }

    onLocationChanges(changes,results){
        changes.forEach(loc => {
            let obj  = this.getObject(loc.id);// id must exist
            results[loc.id] = obj;
            // Update main object value
            if(!obj.segments){
                obj.segments = [];
                obj.locCount = 0;
            }
            obj.locCount++;
            let segment = obj.segments[loc.seg];
            if(!segment){
                segment = [];
                obj.segments[loc.seg]=segment;
            }
            segment.push(loc);
        });
    }
    
    // Search/create object
    getObject(id){
        // search existed object
        let obj = this.objects[id]; 
        // Inset new object if not found
        if(!obj){
            obj = {
                id : id,
                patrol:null
            }
            this.objects[id] = obj;
        }
        return obj;
    }
}

export default Patrols;
