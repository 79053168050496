import create from 'zustand';
import { data, backend, syncEngine } from "../sync-engine";
import { setSelected, showOkDialog, showError } from './appStorage';
import { prefID }   from "../sync-engine/modules/Prefs";
import Utils from '../Utils';

const resetLV = () => lvStoreApi.getState().resetLV();
const setWidgetLayouts = (layouts, update) => lvStoreApi.getState().setWidgetLayouts(layouts, update);
const setLvEnabled = (enabled) => lvStoreApi.getState().setLvEnabled(enabled);
const setLvBgColor = (color) => lvStoreApi.getState().setLvBgColor(color);
const setWidget2show = (wdKey) => lvStoreApi.getState().setWidget2show(wdKey);
const getWidget2show = () => lvStoreApi.getState().widget2show;
const showPageOnMapWd = (page, show) => lvStoreApi.getState().showPageOnMapWd(page, show);
const setShowMapLayers = (show) => lvStoreApi.getState().setShowMapLayers(show);
const setPointsMaxNumber = (maxNumber) => lvStoreApi.getState().setPointsMaxNumber(maxNumber);
const setWdTimeFilter = (wdKey, timeFilter) => lvStoreApi.getState().setWdTimeFilter(wdKey, timeFilter);
const updateSyncFilters = () => lvStoreApi.getState().updateSyncFilters();
const setLvDataFromPrefs = (lvPrefs) => lvStoreApi.getState().setLvDataFromPrefs(lvPrefs);
const saveLiveView = () => lvStoreApi.getState().saveLiveView();

const allWidgets = ['Map Widget', 'Patrols Widget', 'Reports Widget', 'Sensors Widget'];

const [useLvStore, lvStoreApi] = create((set, get) => ({
  widgetLayouts: {lg: []},
  lvEnabled: false,
  lvBgColor: null,
  widget2show: null,
  hiddenWidgets: new Set(allWidgets),
  pagesOnMapWd: new Set(),
  showMapLayers: true,
  patrolsTimeFilter: {period: 'last N days', nDays: 10, from: null, to: null},
  pointsTimeFilter:  {period: 'last N days', nDays: 10, from: null, to: null},
  sensorsTimeFilter: {period: 'last N days', nDays: 10, from: null, to: null},
  pointsMaxNumber: -1,

  resetLV: () => {
    set({ 
      widgetLayouts: {lg: []},
      lvEnabled: false,
      lvBgColor: null,
      widget2show: null,
      hiddenWidgets: new Set(allWidgets), })
  },

  setWidgetLayouts: (layouts, update) => {
    if (update) {
      const hiddenWidgets = new Set(allWidgets);
      for (let wdg of layouts.lg) {
        hiddenWidgets.delete(wdg.i);
      }
      set({ hiddenWidgets: hiddenWidgets });
    }
    set({ widgetLayouts: layouts });
  },
  setLvEnabled: (enabled) => set({ lvEnabled: enabled }),
  setLvBgColor: (color) => set({ lvBgColor: color }),
  setWidget2show: (wdKey) => set({ widget2show: wdKey }),

  showPageOnMapWd: (page, show) => {
    const pagesOnMapWd = get().pagesOnMapWd;
    if (show) {
      pagesOnMapWd.add(page);
    } else {
      pagesOnMapWd.delete(page);
    }
    set({ pagesOnMapWd: new Set(pagesOnMapWd) });
  },

  setShowMapLayers: (show) => set({ showMapLayers: show }),
  setPointsMaxNumber: (maxNumber) => set({ pointsMaxNumber: maxNumber }),

  setWdTimeFilter: (wdKey, timeFilter) => {
    switch(wdKey) {
      case 'Patrols Widget': set({ patrolsTimeFilter: timeFilter });
      break;
      case 'Reports Widget': set({ pointsTimeFilter: timeFilter });
      break;
      case 'Sensors Widget': set({ sensorsTimeFilter: timeFilter });
      break;
      default: console.error('ERROR: setWdTimeFilter wrong wdKey', wdKey);
    }
  },

  updateSyncFilters: () => {
    const { patrolsTimeFilter, pointsTimeFilter, sensorsTimeFilter, pointsMaxNumber } = get();

    const pointyncFilter = Utils.timeFilterData(pointsTimeFilter);
    pointyncFilter.maxN = pointsMaxNumber;

    const syncFilters = {
      patrol: Utils.timeFilterData(patrolsTimeFilter),
      point:  pointyncFilter,
      sensor: Utils.timeFilterData(sensorsTimeFilter)
    };
    try{
      syncEngine.stop();
      data.clear(
        ['patrols', 'points', 'sensors'], 
        ['patrol', 'patrol_location', 'point', 'point_comment', 'sensor', 'sensor_data']);
      data.syncFilters = syncFilters;
      syncEngine.start();      
      setSelected('', null);  // drop selection
    }catch(e){
      showError(`Fail to change sync filters: ${e}`);
    }
  },

  setLvDataFromPrefs: (lvPrefs) => {
    if (lvPrefs?.value) {

      set({ lvEnabled: lvPrefs.value.lvEnabled });

      if (lvPrefs.value.pagesOnMapWd) {
        set({ pagesOnMapWd: new Set(lvPrefs.value.pagesOnMapWd.split(',')) });
      }

      if (lvPrefs.value.layouts) {
        get().setWidgetLayouts(lvPrefs.value.layouts, true);
      }

      if (lvPrefs.value.settings) {
        set({ lvBgColor: lvPrefs.value.settings.lvBgColor });
      }

      const mapWdSettings =     lvPrefs.value.settings?.widgets?.map;
      const patrolsWdSettings = lvPrefs.value.settings?.widgets?.patrols;
      const pointsWdSettings =  lvPrefs.value.settings?.widgets?.points;
      const sensorsWdSettings = lvPrefs.value.settings?.widgets?.sensors;

      if (mapWdSettings?.showMapLayers) {
        set({ showMapLayers: mapWdSettings.showMapLayers })
      }
      if (patrolsWdSettings?.timeFilter) {
        set({ patrolsTimeFilter: patrolsWdSettings.timeFilter })
      }
      if (pointsWdSettings?.timeFilter) {
        set({ pointsTimeFilter: pointsWdSettings.timeFilter })
      }
      if (sensorsWdSettings?.timeFilter) {
        set({ sensorsTimeFilter: sensorsWdSettings.timeFilter })
      }
      if (pointsWdSettings?.maxN !== undefined) {
        set({ pointsMaxNumber: pointsWdSettings.maxN })
      }
    }
  },

  saveLiveView: async () => {
    const {widgetLayouts, lvEnabled, lvBgColor, pagesOnMapWd, showMapLayers, 
      patrolsTimeFilter, pointsTimeFilter, sensorsTimeFilter, pointsMaxNumber} = get();

    try {
      await backend.request("post", "/prefs/set_common_pref", {id: prefID.live_view, value: {
        layouts: widgetLayouts, 
        lvEnabled: lvEnabled,
        pagesOnMapWd: String(Array.from(pagesOnMapWd)),
        settings: {
          lvBgColor: lvBgColor,
          widgets: {
            map:     {showMapLayers: showMapLayers},
            patrols: {timeFilter: patrolsTimeFilter},
            points:  {timeFilter: pointsTimeFilter, maxN: pointsMaxNumber},
            sensors: {timeFilter: sensorsTimeFilter},
          }
        }
      }});
      syncEngine.syncNow();
      showOkDialog({
        title: 'Live View', 
        message: 'Live View saved', 
        onOK: () => null});
    } catch (err) {
      console.error(err);
      showError(`Fail to save Live View state: ${err}`);
    }
  },
}));

export { 
  useLvStore,
  resetLV,
  setWidgetLayouts,
  setLvEnabled,
  setLvBgColor,
  setWidget2show,
  getWidget2show,
  showPageOnMapWd,
  setShowMapLayers,
  setPointsMaxNumber,
  setWdTimeFilter,
  updateSyncFilters,
  setLvDataFromPrefs,
  saveLiveView,
};