import React, { useMemo } from 'react';
import { styled, makeStyles } from '@mui/styles';
import { Tabs, Tab, Icon, Popover } from '@mui/material';
import { useAppStore, setActivePageKey } from "../storages/appStorage";
import Colors from '../styles/colors';

const useStyles = makeStyles({
  tabStyle: {
    margin: '5px 1px 0 1px',
    padding: '5px',
    minWidth: '60px',
    borderRadius: '15px 15px 0 0',
    color: Colors.tabDisabl,
    backgroundColor: props => 
      props.active ? Colors.tabBgEnabl : Colors.tabBgDisabl,
    boxShadow: props => 
      props.active ? '-3px -1px 5px 2px rgba(0, 0, 0, 0.4)' : 'none',
    '&:hover': {
      color: props => props.active ? Colors.tabEnabl : Colors.tabHover,}
  },
});

const TabIcon = styled(Icon)({
  height: '1.5em', 
  width: '1.5em',
});

const StyledTabs = styled(Tabs)({
  backgroundColor: 'light grey',
  '& .Mui-selected': {
    color: Colors.tabEnabl,
  },
  '& .MuiTabs-indicator': {
    height: 0
  },
});

const StyledTab = (props) => {
  const {active, ...rest} = props;
  const { tabStyle } = useStyles({active});
  return <Tab  {...rest} className={`${tabStyle}`}/>;
}


const TabPanel = ({children, tabInd, index}) => 
  tabInd == index ? children : <div></div>;

const TabsView = ({views}) => {
  const activePageKey = useAppStore(state => state.activePageKey);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverText, setPopoverText] = React.useState('');
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [popoverTimer, setPopoverTimer] = React.useState(null);
  const popoverIsOpen = Boolean(anchorEl) && popoverOpen;

  const onPopoverOpen = (event, text) => {
    setPopoverText(text);
    setAnchorEl(event.currentTarget);
    const timer = setTimeout(() => setPopoverOpen(true), 1000);
    setPopoverTimer(timer);
  };

  const onPopoverClose = () => {
    clearTimeout(popoverTimer);
    setPopoverText('');
    setPopoverOpen(false);
    setAnchorEl(null);
    setPopoverTimer(null);
  };
  const getTabs = (views, tabInd) => 
    views.map((view, index) => 
      {
        if (view.icon) {
          return <StyledTab 
            active={tabInd == index} key={index} 
            onMouseEnter={(e) => onPopoverOpen(e, view.title)}
            onMouseLeave={onPopoverClose}
            icon={<TabIcon>{view.icon}</TabIcon>} />;
        }
        return <StyledTab active={tabInd == index} key={index} label={view.title} />;
      });

  const getContents = (views, tabInd) => 
    views.map((view, index) => 
      <TabPanel key={index} index={index} tabInd={tabInd}>
        {view.content}
      </TabPanel>);

  const getTabInd = (views, activePageKey) => {
    const ind = views.findIndex(view => view.key == activePageKey);
    if (ind == -1) {
      if (views.length > 0) setActivePageKey(views[0].key);
      return 0;
    }
    return ind;
  };

  const tabInd = useMemo(() => getTabInd(views, activePageKey), 
    [views, activePageKey]);

  const tabs = useMemo(() => getTabs(views, tabInd), 
    [views, tabInd]);

  const contents = useMemo(() => getContents(views, tabInd), 
    [views, tabInd]);

  const onTabChange = (e, newInd) => setActivePageKey(views[newInd].key);

  return (
    <div className='tabsContainer'>
      <div>
        <StyledTabs 
          value={tabInd} 
          onChange={onTabChange}
          variant="scrollable"
          scrollButtons={false}>
            {tabs}
        </StyledTabs>
      </div>
      {contents}
      <Popover
        sx={{pointerEvents: 'none'}}
        open={popoverIsOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 45,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onPopoverClose}
        disableRestoreFocus
      >
        <div className='popover'>{popoverText}</div>
      </Popover>
    </div>
  );
};

export default TabsView;
