import create from 'zustand';
import PropList from "./PropList";
import DataModules from "./DataModules";
import { resetMdPropLoaded } from '../storages/appStorage';


// <zustand> --------------------------------------------------------------------------------
const setStoreState = (state) => syncEngStoreApi.getState().setStoreState(state);
const setStoreTimeRange = (timeRange) => syncEngStoreApi.getState().setStoreTimeRange(timeRange);
const setSyncInterval = (interval) => syncEngStoreApi.getState().setSyncInterval(interval);
const setSyncDelay = (delay) => syncEngStoreApi.getState().setSyncDelay(delay);
const setSyncState = (state) => syncEngStoreApi.getState().setSyncState(state);
const setSyncData = (data) => syncEngStoreApi.getState().setSyncData(data);

const setStoreData = (moduleName, objects, changes, clear) => syncEngStoreApi.getState().setStoreData(moduleName, objects, changes, clear);
// snaps
const objectsSnap = (moduleName) => {
  switch (moduleName) {
    case 'ble':
      return syncEngStoreApi.getState().bleObjects;

    case 'cameras':
      return syncEngStoreApi.getState().camerasObjects;

    case 'features':
      return syncEngStoreApi.getState().featuresObjects;

    case 'categories':
      return syncEngStoreApi.getState().categoriesObjects;

    case 'patrols':
      return syncEngStoreApi.getState().patrolsObjects;

    case 'points':
      return syncEngStoreApi.getState().pointsObjects;

    case 'prefs':
      return syncEngStoreApi.getState().prefsObjects;

    case 'roles':
      return syncEngStoreApi.getState().rolesObjects;

    case 'sensors':
      return syncEngStoreApi.getState().sensorsObjects;

    case 'users':
      return syncEngStoreApi.getState().usersObjects;

    default:
      console.log(`objectsSnap unknown module: ${moduleName}`)
  }
  return null;
};

const [useSyncEngStore, syncEngStoreApi] = create((set) => ({
  storeState: null,
  timeRange: null,

  // SyncEngine
  syncInterval: null,
  syncDelay: null,
  syncState: null,
  syncError: null,
  syncSuccess: null,

  bleObjects: null,
  camerasObjects: null,
  featuresObjects: null,
  categoriesObjects: null,
  patrolsObjects: null,
  pointsObjects: null,
  prefsObjects: null,
  rolesObjects: null,
  sensorsObjects: null,
  usersObjects: null,

  bleChanges: null,
  camerasChanges: null,
  featuresChanges: null,
  categoriesChanges: null,
  patrolsChanges: null,
  pointsChanges: null,
  prefsChanges: null,
  rolesChanges: null,
  sensorsChanges: null,
  usersChanges: null,

  setStoreState:     (state) =>     set({storeState: state}),
  setStoreTimeRange: (timeRange) => set({timeRange: timeRange}),
  setSyncInterval:   (interval) =>  set({syncInterval: interval}),
  setSyncDelay:      (delay) =>     set({syncDelay: delay}),
  setSyncState:      (state) =>     set({syncState: state}),
  setSyncData: ({state, error, success}) => 
    set({ syncState: state, syncError: error, syncSuccess: success }),

  setStoreData: (moduleName, objects, changes, clear=false) => {
    if (changes || clear) {
      switch (moduleName) {
        case 'ble':
          set({bleObjects: objects, bleChanges: changes});
          break;

        case 'cameras':
          set({camerasObjects: objects, camerasChanges: changes});
          break;

        case 'features':
          set({featuresObjects: objects, featuresChanges: changes});
          break;

        case 'categories':
          set({categoriesObjects: objects, categoriesChanges: changes});
          break;

        case 'patrols':
          set({patrolsObjects: objects, patrolsChanges: changes});
          break;

        case 'points':
          set({pointsObjects: objects, pointsChanges: changes});
          break;

        case 'prefs':
          set({prefsObjects: objects, prefsChanges: changes});
          break;

        case 'roles':
          set({rolesObjects: objects, rolesChanges: changes});
          break;

        case 'sensors':
          set({sensorsObjects: objects, sensorsChanges: changes});
          break;

        case 'users':
          set({usersObjects: objects, usersChanges: changes});
          break;

        default:
          console.log(`setStoreData unknown module: ${moduleName}`)
      }
    }
  },  // setStoreData
}));
// </zustand> --------------------------------------------------------------------------------


//--------------------------------------------------------------------------------
// THE BACKEND DATA MIRROR

class Data{
    constructor() {
        //super();
        this.destroy()
    }

    destroy(){
        //console.warn('Data.destroy!')
        this.state = 'DESTROYED';
        this.timeRange = null;
        this.modules = null;
        this.props   = null;
    }

    init(modules, timeRange){
        console.log("Data.init. Required modules:", modules);
        this.state = 'EMPTY';
        this.timeRange = null;
        
        // Create modules & props
        this.modules = {};
        this.props   = {};
        this.loadModule('categories');
        for(const name in modules) 
            this.loadModule(name);
        
        this.timeRange = timeRange;
        setStoreTimeRange(this.timeRange);

        console.log("Data.init. Created modules:", this.modules);
    }

    loadModule(name){
        let mClass = DataModules[name];
        if(mClass){
            // Create module
            let m = new mClass();
            this.modules[name] = m;
        }else{
            console.warn('Unknown module: '+ name);
        }
    }

    setTimeRange(t1,t2){
        // Check conditions
        if(this.state!='EMPTY') throw 'Empty data before setTimeRange';
        if(!t1) throw 't1 must be set';
        if(t1&&t2&&t1>=t2) throw 't2 must greater than t1';

        console.debug(`setTimeRange t2 ${t2} t1`, t1);
        // Update data
        this.timeRange = {t1:t1, t2:t2}
        setStoreTimeRange(this.timeRange);
        localStorage.removeItem('data.timeRange'); // clear unused localStorage value; TODO: remove later
    }

    getSyncFilter(name) {
      if (this.syncFilters) {
        switch(name) {
          case 'patrol':          return this.syncFilters.patrol;
          case 'patrol_location': return this.syncFilters.patrol;
          case 'point':           return this.syncFilters.point;
          case 'point_comment':   return this.syncFilters.point;
          case 'sensor':          return this.syncFilters.sensor;
          case 'sensor_data':     return this.syncFilters.sensor;
          default: //Do Nothing
        }
      }
      return this.timeRange;
    }

    clear(mdNames, prNames){
      if (mdNames?.length || prNames?.length) { // some modules clean
        for (const mdName of mdNames) {
          const module = this.modules[mdName];
          if(module.clear) {
            module.clear();
            setStoreData(mdName, {}, null, true);
          }
        }
        for (const prName of prNames) {
          this.props[prName].clear();
        }
      } else {                            // full clean
        for(const name in this.modules){
          let m = this.modules[name];
          if(m.clear) {
            m.clear();
            setStoreData(name, {}, null, true);
          }
        }
        for(const name in this.props){
          this.props[name].clear();
        }

        this.state = 'EMPTY';
        setStoreState('EMPTY');
      }
      resetMdPropLoaded();
    }

    async syncProp (module, mdName, prName) {
      if( !this.props[prName] ) {
        this.props[prName] = new PropList(prName, module.props[prName].syncUrl);
      }
      const syncResult = await this.props[prName].sync();

      const changes = {[prName]: syncResult};
      const fillResult = await module.onDataChanges(changes);

      setStoreData(mdName, module.objects, fillResult); 
    }

    async syncModule (module, mdName) {
      if(module.props) {
        const propsPromises = [];

        for(const prName in module.props){
          propsPromises.push (this.syncProp(module, mdName, prName));
        }
        return Promise.allSettled(propsPromises);
      }
    }

    async syncAllModules (modules) {
      const modulesPromises = [];
      for (const mdName in modules) {
        modulesPromises.push (this.syncModule(modules[mdName], mdName));
      }

      return Promise.allSettled(modulesPromises);
    }

    async sync () {
      this.state = 'LOADING';
      setStoreState('LOADING');

      console.log(`-- START sync ${Date.now()}`);

      await this.syncAllModules(this.modules);

      console.log(`-- END sync: time ${Date.now()}`);
      this.state = 'LOADED';
      setStoreState('LOADED');
    }

    //--------------------------------------------------------------
    // Utils
    getUserName(id){
        let userObj = this.modules.users?.objects[id]
        return userObj?.user ? userObj.user.name : 'not found [#'+id+']'
    }

}

export{
  useSyncEngStore,
  setSyncInterval,
  setSyncDelay,
  setSyncState,
  setSyncData,
  objectsSnap
};
export default new Data();
