import React, { useMemo } from "react";
import { AutoSizer, Column, Table } from 'react-virtualized';
import 'react-virtualized/styles.css';
import ColumnInput from './columnInput';
import Dialod from '../dialog/dialog';
import { AlertCircleOutlineIcon, MarkerOutlineIcon, MarkerIcon, 
  ErrorIcon } from '../common';

const SizedTable = ({width, height, data, onColumnChange, disabled}) => {

  const columnWidth = (dataKey) => {

    const paddings = (data.columns.size +1)*10;
    // stat + locat + time = 140
    const freeSpace = width - 140 - (data.columns.size - 4)*200 - paddings;
    const descrWidth = freeSpace > 20 ? freeSpace : 20;

    switch (dataKey) {
      case 'stat': return 25;
      case 'locat': return 25;
      case 'time': return 90; 
      case 'descr': return descrWidth;
      default: return 200;
    }
  }

  const fixedColumns = new Set(['time', 'descr']);

  const headerRenderer = ({
    dataKey,
    label,
  }) => {
    if (dataKey == 'stat') return <AlertCircleOutlineIcon title={'status'}/>;
    if (dataKey == 'locat') return <MarkerOutlineIcon title={'location'}/>;
    return (
      fixedColumns.has(dataKey)
      ? <div className="sensorColumn">{label}</div>
      : <ColumnInput 
          className="sensorColumn" 
          label={dataKey} 
          value={label}
          disabled={disabled}
          onSave={(val) => onColumnChange(dataKey, val)}/>
    );
  }

  const cellRenderer = ({dataKey, cellData}) => {
    if (cellData == null) return '';
    if (dataKey == 'stat') {
      if (cellData == 0) return '';
      const title = (cellData < 0 ? 'error' : 'warning') + ` ${cellData}`;
      return <ErrorIcon error={cellData<0} title={title}/>;
    }
    if (dataKey == 'locat') {
      if (!cellData) return '';
      return <MarkerIcon title={cellData}/>;
    }
    return String(cellData);
  };

  const getColumns = () => {
    const columns = [];
    for (const [dataKey, label] of data.columns) {
      columns.push(
        <Column key={dataKey}
          headerRenderer={headerRenderer}
          cellRenderer={cellRenderer}
          width={columnWidth(dataKey)} 
          label={label} 
          dataKey={dataKey}/>
      )
    }
    return columns;
  };

  const columns = useMemo(() => getColumns(), 
    [data.columns, width]);

  return <Table
    width={width}
    height={height}
    headerHeight={50}
    rowHeight={22}
    rowCount={data.arr.length}
    rowGetter={({index}) => data.arr[index]}
    >
    {columns}
  </Table>
};

const SensorDataTable = ({title, data, onColumnChange, onCancel, disabled}) => {

  return <Dialod
    fullScreen={true}
    title={<div className="flexJustCenter">{title}</div>}
    onClose={onCancel}>
    <AutoSizer>{({ width, height }) => 
      (width && height) ? 
      <SizedTable 
        width={width} 
        height={height} 
        data={data}
        disabled={disabled}
        onColumnChange={onColumnChange}/>
      : <></>
    }
    </AutoSizer>
  </Dialod>;
}

export default SensorDataTable;