import React, { useMemo, useState } from 'react';
import { lightTheme, AnimatedAnnotation, Axis, Grid, LineSeries, 
  AnnotationCircleSubject, AnnotationConnector, AnnotationLabel, Tooltip, 
  XYChart } from '@visx/xychart';
import { curveLinear } from '@visx/curve'; // curveStep, curveCardinal
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { Brush } from '@visx/brush';
import { PatternLines } from '@visx/pattern';
import { scaleTime, scaleLinear } from '@visx/scale';
import ChartBackground from './chartBackground';
import { min, max, extent } from 'd3-array';
import Utils from '../../Utils';

const numTicks = 4;
const brushHeight = 100;
const controlsHeight = 50;
const brushMargin = { top: 0, bottom: 0, left: 50, right: 50 };
const yBrushMax = Math.max(brushHeight - brushMargin.top - brushMargin.bottom, 0);

const PATTERN_ID = 'brush_pattern';

const brushPatternColor = '#f6acc8';
const brushStyle = {
  fill: `url(#${PATTERN_ID})`,
  stroke: brushPatternColor,
};

const VisxChart = ({ width, height, data, linesAtr }) => {

  const fullWidth = Math.max(width, 400);
  const fullHeight = Math.max(height, 400);

  const xyChartHeight = fullHeight - brushHeight - controlsHeight;

  const [showTooltip, setShowTooltip] = useState(true);
  const [showAnnotation, setShowAnnotation] =useState(true);
  const [annotationDataKey, setAnnotationDataKey] =useState(null);
  const [annotationDataIndex, setAnnotationDataIndex] = useState(0);
  const [annotationLabelPosition, setAnnotationLabelPosition] = useState({ dx: -40, dy: -20 });
  const [showColumns, setShowColumns] = useState(false);
  const [showRows, setShowRows] = useState(false);
  const [filteredData, setFilteredData] = useState(data);

  const getLines = () => {
    return linesAtr.map(lineAtr => 
      <LineSeries
      key={lineAtr.name}
      dataKey={lineAtr.name}
      data={filteredData}
      xAccessor={(d) => d.time}
      yAccessor={(d) => Number(d[lineAtr.name])}
      curve={curveLinear}
    />);
  };

  const lines = useMemo(getLines,
    [filteredData, linesAtr],
  );

  const annotationDatum = useMemo(
    () => filteredData[annotationDataIndex],
    [filteredData, annotationDataIndex],
  );
  
  const getMinChartVal = () => {
    const chartMin = min(linesAtr.map(lineAtr => min(data.flatMap(
      d => Utils.isNumber(d[lineAtr.name]) ? Number(d[lineAtr.name]) : []))));
    return chartMin > 0 ? 0 : chartMin;
  };
  const minChartVal = useMemo(getMinChartVal,
    [data, linesAtr],
  );

  const getMaxChartVal = () => max(
    linesAtr.map(lineAtr => max(data.flatMap(
      d => Utils.isNumber(d[lineAtr.name]) ? Number(d[lineAtr.name]) : []))));

  const maxChartVal = useMemo(getMaxChartVal,
    [data, linesAtr],
  );

  const [yScaleMin, setYScaleMin] = useState(minChartVal);
  const [yScaleMax, setYScaleMax] = useState(maxChartVal);

  const onBrushChange = (domain) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const dataCopy = data.filter(d => d.time > x0 && d.time < x1);

    setFilteredData(dataCopy);
    setYScaleMin(y0);
    setYScaleMax(y1);
  };

  const dropBrushFilter = () => {
    setFilteredData(data);
    setYScaleMin(minChartVal);
    setYScaleMax(maxChartVal);
  };

  const xBrushMax = Math.max(fullWidth - brushMargin.left - brushMargin.right, 0);

  const brushXScale = useMemo(
    () =>
      scaleTime({
        range: [0, xBrushMax],
        domain: extent(data, (d) => d.time),
      }),
    [data, xBrushMax],
  );

  const brushYScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [minChartVal, maxChartVal],
        nice: true,
      }),
    [data, yBrushMax],
  );

  const initialBrushPosition = useMemo(() => ({
    start: { x: brushXScale(data[0].time), y: 0 },
    end: { x: brushXScale(data[data.length - 1].time), y: yBrushMax },
  }), [data, xBrushMax, yBrushMax]);

  const getLineSpans = () => {  // handle data gaps
    const spans = [];
    for (let lineAtr of linesAtr) {
      const dataWithGaps = data.map(d => {
        return {time: d.time, val: d[lineAtr.name]}});

      while (dataWithGaps.length) {
        const indOfNaN = dataWithGaps.findIndex(d => !Utils.isNumber(d.val));
        const indOfNum = dataWithGaps.findIndex(d => Utils.isNumber(d.val));

        if (indOfNaN == 0) {
          const gaps = (indOfNum == -1) ? dataWithGaps.splice(0) : dataWithGaps.splice(0, indOfNum); // ignore result
        } else {
          const span = (indOfNaN == -1) ? dataWithGaps.splice(0) : dataWithGaps.splice(0, indOfNaN);
          spans.push(span);
        }
      }
    }
    return  spans.map((span, ind) => ({ key: ind, data: span }))
  };

  const lineSpans = useMemo(getLineSpans,
    [data, linesAtr],
  );

  const getBrushLines = () => {
    return lineSpans.map(span => 
      <LinePath
        key={span.key}
        curve={curveLinear}
        data={span.data}
        x={(d) => brushXScale(d.time)}
        y={(d) => brushYScale(Number(d.val))}
        stroke="#999"
        strokeWidth={1}
        strokeOpacity={1}
        shapeRendering="geometricPrecision"
        markerMid="url(#marker-circle)"
      />);
  };

  const brushLines = useMemo(getBrushLines,
    [data, linesAtr, xBrushMax, yBrushMax],
  );

  const timeTickFormat = (date) => Utils.getDayTime(date.getTime());

  return (
      <div className="flexColumn" style={{width: fullWidth}}>
        <XYChart
          theme={lightTheme}
          xScale={{type: 'time'}}
          yScale={{type: 'linear', domain: [yScaleMin, yScaleMax], 
            zero: false, clamp: true}}
          height={xyChartHeight}
          width={fullWidth}
          captureEvents={true}
          onPointerUp={(d) => {
            setAnnotationDataKey(d.key);
            setAnnotationDataIndex(d.index);
          }}
        >
          <ChartBackground/>
          <Grid
            key={`grid-center`} // force animate on update
            rows={showRows}
            columns={showColumns}
            numTicks={numTicks}
          />
          {lines}
          <Axis
            key={`time-axis-center-false`}
            orientation={'bottom'}
            numTicks={numTicks}
            tickFormat={timeTickFormat}
          />
          <Axis
            key={`top-axis`}
            orientation={'top'}
            numTicks={0}
            hideTicks={true}
            stroke={'#888'}
          />
          <Axis
            key={`temp-axis-center-false`}
            orientation={'left'}
            numTicks={numTicks}
            // values don't make sense in stream graph
            tickFormat={undefined}
          />
          {showAnnotation && annotationDataKey && annotationDatum && (
            <AnimatedAnnotation
              dataKey={annotationDataKey}
              datum={annotationDatum}
              dx={annotationLabelPosition.dx}
              dy={annotationLabelPosition.dy}
              editable={false}
              canEditSubject={false}
              onDragEnd={({ dx, dy }) => setAnnotationLabelPosition({ dx, dy })}
            >
              <AnnotationConnector />
              <AnnotationCircleSubject />
              <AnnotationLabel
                title={annotationDataKey}
                subtitle={`${annotationDatum.timeStr}, ${annotationDatum[annotationDataKey]}`}
                width={135}
                backgroundProps={{
                  stroke: lightTheme.gridStyles.stroke,
                  strokeOpacity: 0.5,
                  fillOpacity: 0.8,
                }}
              />
            </AnimatedAnnotation>
          )}
          {showTooltip && (
            <Tooltip
              showHorizontalCrosshair={false}
              showVerticalCrosshair={true}
              snapTooltipToDatumX={true}
              snapTooltipToDatumY={true}
              showDatumGlyph={true}
              showSeriesGlyphs={true}
              renderGlyph={undefined}
              renderTooltip={({ tooltipData, colorScale }) => {
                return (
                  <>
                    {/** time */}
                    {(tooltipData?.nearestDatum?.datum?.timeStr) || 'No time'}
                    <br />
                    <br />
                    {/** values */}
                    {(
                      (Object.keys(tooltipData?.datumByKey ?? {})
                      ).filter(valName => valName)
                    ).map(valName => {
                      const val = tooltipData?.nearestDatum?.datum?.[valName];
                      const valStr = Utils.isNumber(val) ? (' ' + Math.floor(val * 1000) / 1000) : ' -';
                      return (
                        <div key={valName} style={{
                          color: colorScale?.(valName),
                          fontWeight: tooltipData?.nearestDatum?.key === valName ? 'bold' : 'normal',
                          textDecoration: tooltipData?.nearestDatum?.key === valName ? 'underline' : undefined,
                        }}>
                          {valName + valStr}
                        </div>
                      );})
                    }
                  </>
                );
              }}
            />
          )}
        </XYChart>

        <svg width={fullWidth} height={brushHeight + 10}>
          <Group left={brushMargin.left} top={brushMargin.top}>
            {brushLines}
            <PatternLines
              id={PATTERN_ID}
              height={8}
              width={8}
              stroke={brushPatternColor}
              strokeWidth={1}
              orientation={['diagonal']}
            />
            <Brush
              xScale={brushXScale}
              yScale={brushYScale}
              width={xBrushMax}
              height={yBrushMax}
              margin={brushMargin}
              handleSize={8}
              resizeTriggerAreas={['left', 'top', 'right', 'bottom']}
              brushDirection="both"
              initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              onClick={dropBrushFilter}
              selectedBoxStyle={brushStyle}
              useWindowMoveEvents={false}
            />
          </Group>
        </svg>
        <div className='flexJustCenter'>
          <label className='paddingHoriz'>
            <input
              type="checkbox"
              onChange={() => setShowTooltip(!showTooltip)}
              checked={showTooltip}
            />
            tooltip
          </label>
          <label className='paddingHoriz'>
            <input
              type="checkbox"
              onChange={() => setShowAnnotation(!showAnnotation)}
              checked={showAnnotation}
            />
            annotation (click chart)
          </label>
          <label className='paddingHoriz'>
            <input
              type="checkbox"
              onChange={() => setShowColumns(!showColumns)}
              checked={showColumns}
            />
            columns
          </label>
          <label className='paddingHoriz'>
            <input
              type="checkbox"
              onChange={() => setShowRows(!showRows)}
              checked={showRows}
            />
            rows
          </label>
        </div>
      </div>
  );
}

export default VisxChart;