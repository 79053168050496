import React, { useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const MappersSelect = ({label, items, value, onMapperChange}) => {

  const getMenuItems = () => items.map(
    item => <MenuItem key={item} value={item}>{item}</MenuItem>);

  const menuItems = useMemo(getMenuItems, [items]);

  return (
    <FormControl variant='outlined' fullWidth={true} margin={'dense'} size={'small'}>
      <InputLabel id='select-mapper-label'>{label}</InputLabel>
      <Select
        labelId='select-mapper-label'
        value={value}
        onChange={(e) => onMapperChange(e.target.value)}
        label={label}
      >
        {menuItems}
      </Select>
    </FormControl>
  );
}

export default MappersSelect;