import MarkerClusterer from '@googlemaps/markerclustererplus';

const CLUSTER_MIN_SIZE = 8;
const clustererStyles = [
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 53,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 53,
    textSize: 17,
    anchorText: [0, 27],
    url: "../images/m1.png",
    width: 53
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 56,
    textColor: "darkblue",
    textDecoration: "none",
    textLineHeight: 56,
    textSize: 17,
    anchorText: [1, 28],
    url: "../images/m2.png",
    width: 56
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 66,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 66,
    textSize: 16,
    anchorText: [1, 34],
    url: "../images/m3.png",
    width: 66
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 78,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 78,
    textSize: 14,
    anchorText: [1, 40],
    url: "../images/m4.png",
    width: 78
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 90,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 90,
    textSize: 12,
    anchorText: [1, 40],
    url: "../images/m5.png",
    width: 90
  },
];

const clustererStylesLV = [
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 53,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 53,
    textSize: 17,
    anchorText: [0, 0],
    url: "../images/m1.png",
    width: 53
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 56,
    textColor: "darkblue",
    textDecoration: "none",
    textLineHeight: 56,
    textSize: 17,
    anchorText: [1, 0],
    url: "../images/m2.png",
    width: 56
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 66,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 66,
    textSize: 16,
    anchorText: [1, 0],
    url: "../images/m3.png",
    width: 66
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 78,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 78,
    textSize: 14,
    anchorText: [1, 0],
    url: "../images/m4.png",
    width: 78
  },
  {
    backgroundPosition: "0 0",
    fontFamily: "Arial,sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    height: 90,
    textColor: "white",
    textDecoration: "none",
    textLineHeight: 90,
    textSize: 12,
    anchorText: [1, 0],
    url: "../images/m5.png",
    width: 90
  },
];

class MarkerClustererWrap{
  constructor (map, onLV) {
      this._markerClusterer = new MarkerClusterer(map);
      if (this._markerClusterer) {
        this._markerClusterer.setStyles(onLV ? clustererStylesLV : clustererStyles);
        this._markerClusterer.setMinimumClusterSize(CLUSTER_MIN_SIZE);
      }
  }

  clusterMarkers (markers) {
    if (this._markerClusterer) {
      const currentMarkers = this._markerClusterer.getMarkers();
      const newMarkers = markers.flatMap(marker => 
        currentMarkers.includes(marker) ? [] : marker);

      this._markerClusterer.addMarkers(newMarkers);
    }
  }

  unClusterMarkers (markers) {
    if (this._markerClusterer) {
      this._markerClusterer.removeMarkers(markers);
    }
  }

  expandAllClusters (expand) {
    if (this._markerClusterer) {
      this._markerClusterer.setMinimumClusterSize( expand ? 100000 : CLUSTER_MIN_SIZE);
      this._markerClusterer.repaint();
    }
  };

  setStyles (onLV) {
    this._markerClusterer.setStyles(onLV ? clustererStylesLV : clustererStyles);
    this._markerClusterer.repaint();
  }
}

export default MarkerClustererWrap;