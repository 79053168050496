import create from 'zustand';
import * as storageUtils from "./utils/storageUtils";

const initBle = () => bleStoreApi.getState().initBle();
const setOrder = (orderBy) => bleStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => bleStoreApi.getState().showRemoved(show);

const DEF_ORDER_BY = 2; // index of 'name' header in BleView

const [useBleStore, bleStoreApi] = create((set, get) => ({
  orderBy: DEF_ORDER_BY, 
  orderAsc: true,
  removedShown: false,

  initBle: () => 
    storageUtils.initList('ble', set, DEF_ORDER_BY),

  setOrder: (orderBy) => 
    storageUtils.setOrder('ble', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('ble', show, set),

}));

export { 
  useBleStore,
  initBle,
  setOrder,
  showRemoved
};