import create from 'zustand';
import { backend, syncEngine } from "../sync-engine";
import { prefID }   from "../sync-engine/modules/Prefs";


// APP LOCAL SETTINGS
const appVersion = '3.3.0-220928';
const appModes = {
    simple  : 'Simple',
    normal  : 'Normal',
    dev     : 'Development'
}
const FOG_TIMEOUT = 1000;

const escHandlers = new Map();

const getIconData = (module, selected) => {
  switch (module) {
    case 'sensors' :
      return {
        iconName: selected   ? 'sensorSelIcon.svg' : 'sensorIcon.svg', 
        storeField: selected ? 'sensorSelIcon' : 'sensorIcon'};
    case 'users' :
      return {
        iconName: selected   ? 'userSelIcon.svg' : 'userIcon.svg', 
        storeField: selected ? 'userSelIcon' : 'userIcon'};
    case 'cameras' :
      return {
        iconName: selected   ? 'cameraSelIcon.svg' : 'cameraIcon.svg', 
        storeField: selected ? 'cameraSelIcon' : 'cameraIcon'};
    default: return {iconName: '', storeField: ''};
  }
};

const setAppInitialized = () => appStoreApi.getState().setAppInitialized();
const initAppStore =    () => appStoreApi.getState().initAppStore();
const restoreAppCachedState = () =>  appStoreApi.getState().restoreAppCachedState();
const setAppMode =      (mode) => appStoreApi.getState().setAppMode(mode);
const setActivePageKey = (page) => appStoreApi.getState().setActivePageKey(page);
const setSelected = (page, id) => appStoreApi.getState().setSelected(page, id);
const setSubSelected = (id) => appStoreApi.getState().setSubSelected(id);
const setPatrolIdForPoints = (patrolId) => appStoreApi.getState().setPatrolIdForPoints(patrolId);
const showPageOnMap = (page, show) => appStoreApi.getState().showPageOnMap(page, show);
const savePhone = (phone) => appStoreApi.getState().savePhone(phone);
const saveMapCenter = () => appStoreApi.getState().saveMapCenter();
const setMapCenter = (center) => appStoreApi.getState().setMapCenter(center);
const saveCamerasSettings = (settings) => appStoreApi.getState().saveCamerasSettings(settings);
const setAppIcon = (file, module, selected) => appStoreApi.getState().setAppIcon(file, module, selected);
const setIconsFromPrefs = (iconPrefs) => appStoreApi.getState().setIconsFromPrefs(iconPrefs);
const setMdPropLoaded = (propName) => appStoreApi.getState().setMdPropLoaded(propName);
const resetMdPropLoaded = () => appStoreApi.getState().resetMdPropLoaded();
const setShowSettings = (show) => appStoreApi.getState().setShowSettings(show);
const showOkDialog = (okMessage) => appStoreApi.getState().showOkDialog(okMessage);
const showError = (error) => appStoreApi.getState().showError(error);
const fogScreen = (fogged) => appStoreApi.getState().fogScreen(fogged);

const fogAndCall = (onFog) => {
  fogScreen(true);
  setTimeout(onFog, FOG_TIMEOUT);
};

const [useAppStore, appStoreApi] = create((set, get) => ({
  appInitialized: false,
  appMode :      localStorage.getItem('appMode') || 'normal',
  activePageKey: localStorage.getItem('page'),
  selectedIds: [],      // selected and prev selected item ids
  subSelectedIds: [],   // secondary prev/selected items (track points)
  patrolIdToShowPoints: null,
  pagesOnMap: new Set(),
  mapCenter: null,
  loadedMdProps: new Set(),
  showSettings: false,

  sensorIcon: {name: '', url: ''},
  sensorSelIcon: {name: '', url: ''},
  userIcon: {name: '', url: ''},
  userSelIcon: {name: '', url: ''},
  cameraIcon: {name: '', url: ''},
  cameraSelIcon: {name: '', url: ''},

  okMessage: null,
  errorMessage: null,
  fogged: false,

  setAppInitialized: () => set({ appInitialized: true }),

  initAppStore: () => set({ appMode: 'normal', activePageKey: null }),

  restoreAppCachedState: () => {
    const pagesOnMapStr = localStorage.getItem('pagesOnMap');
    if (pagesOnMapStr) {
      const pagesOnMap = new Set(pagesOnMapStr.split(','));
      set({ pagesOnMap: pagesOnMap });
    }
  },

  setAppMode: (mode) => {
      localStorage.setItem('appMode', mode)
      set({ appMode: mode })
  },

    setActivePageKey: (page) => {
      set({ activePageKey: page });
      localStorage.setItem('page', page);
    },

    setSelected: (page, id) => {
      const changePage = id && page != get().activePageKey;
      if (changePage) {
        get().setActivePageKey(page);
      }
      const currentSelected = get().selectedIds;
      currentSelected.unshift({page, id});
      if (currentSelected.length > 2) currentSelected.length = 2;

      if (changePage) {
        setTimeout(() => {
          set({ selectedIds: [...currentSelected] });}, 100);
      } else {
        set({ selectedIds: [...currentSelected] });
      }
    },

    setSubSelected: (id) => {
      const currentSelected = get().subSelectedIds;
      currentSelected.unshift(id);
      if (currentSelected.length > 2) currentSelected.length = 2;
      set({ subSelectedIds: [...currentSelected] })
    },

    setPatrolIdForPoints: (patrolId) => {
      get().setSubSelected('');
      set({ patrolIdToShowPoints: patrolId })
    },

    showPageOnMap: (page, show) => {
      const pagesOnMap = get().pagesOnMap;
      if (show) {
        pagesOnMap.add(page);
      } else {
        pagesOnMap.delete(page);
      }
      set({ pagesOnMap: new Set(pagesOnMap) });
      localStorage.setItem('pagesOnMap', String(Array.from(pagesOnMap)));
    },

    savePhone: async (phone) => {
      try {
        await backend.request("post", "/prefs/set_common_pref", {id: prefID.phone_oc, value: phone });
      } catch (err) {
        console.error(err);
        showError(`Fail to save phone number: ${err}`);
      }
      syncEngine.syncNow();
    },

    saveMapCenter: async () => {
      const pos = get().mapCenter;
      if (!pos) showError(`Fail to save map center: no position set`); 
      try {
        await backend.request("post", "/prefs/set_common_pref", { id:prefID.map_default, value:{center:pos} })
      } catch (err) {
        console.error(err);
        showError(`Fail to save map center: ${err}`);
      }
      syncEngine.syncNow();
    },

    setMapCenter: (center) => set({ mapCenter: center }),

    saveCamerasSettings: async (settings) => {
      try {
        await backend.request("post", "/prefs/set_common_pref", {id: prefID.ftp_server, value: settings });
      } catch (err) {
        console.error(err);
        showError(`Fail to save cameras settings: ${err}`);
      }
      syncEngine.syncNow();
    },

    saveAppIconsSettings: async () => {
      const { sensorIcon, sensorSelIcon, userIcon, userSelIcon, cameraIcon, cameraSelIcon } = get();
      try {
        await backend.request("post", "/prefs/set_common_pref", {id: prefID.app_icons, value: {
          sensorIcon: sensorIcon.name,
          sensorSelIcon: sensorSelIcon.name,
          userIcon: userIcon.name,
          userSelIcon: userSelIcon.name,
          cameraIcon: cameraIcon.name,
          cameraSelIcon: cameraSelIcon.name
        }});
      } catch (err) {
        console.error(err);
        showError(`Fail to save icons: ${err}`);
      }
      syncEngine.syncNow();
    },

    setAppIcon: async (file, module, selected) => {
      try {
        const {iconName, storeField} = getIconData(module, selected);
        const iconUrl = get()[storeField].url;

        if (file) {
          await backend.sendFILE(`/file/${iconName}`,file); // upload file
          set({ [storeField]: {name: iconName, url: iconUrl} });
        } else {
          set({ [storeField]: {name: '', url: iconUrl} });
        }
        await get().saveAppIconsSettings();  // save in settings
      } catch (err) {
        console.error(err);
        showError(`Fail to save ${module} icon: ${err}`);
      }
      syncEngine.syncNow();
    },

    setIconsFromPrefs: (iconPrefs) => {
      const icons = iconPrefs?.value;

      if (icons) {
        const backPath = backend.getWorkspaceUrl()+'/file/';
        const frontPath = window.location.origin + '/images/';

        const sensorIconUrl =   frontPath + 'map_sensor_s.svg';
        const userIconUrl =     frontPath + 'mapUser.svg';
        const userSelIconUrl =  frontPath + 'mapUserRed.svg';
        const cameraIconUrl =     frontPath + 'marker_camera.svg';
        const cameraSelIconUrl =  frontPath + 'marker_camera_selected.svg';
        
        set({ sensorIcon   : {
                name: icons.sensorIcon ? icons.sensorIcon : '',
                url: icons.sensorIcon  ? backPath + icons.sensorIcon       : sensorIconUrl
              },
              sensorSelIcon: {
                name: icons.sensorSelIcon ? icons.sensorSelIcon : '',
                url: icons.sensorSelIcon  ? backPath + icons.sensorSelIcon : sensorIconUrl
              },
              userIcon   : {
                name: icons.userIcon ? icons.userIcon : '',
                url: icons.userIcon  ? backPath + icons.userIcon       : userIconUrl
              },
              userSelIcon: {
                name: icons.userSelIcon ? icons.userSelIcon : '',
                url: icons.userSelIcon  ? backPath + icons.userSelIcon : userSelIconUrl
              },
              cameraIcon   : {
                name: icons.cameraIcon ? icons.cameraIcon : '',
                url: icons.cameraIcon  ? backPath + icons.cameraIcon      : cameraIconUrl
              },
              cameraSelIcon: {
                name: icons.cameraSelIcon ? icons.cameraSelIcon : '',
                url: icons.cameraSelIcon  ? backPath + icons.cameraSelIcon : cameraSelIconUrl
              }
            });
      }
    },

    setMdPropLoaded: (propName) => {
      const loadedMdProps = get().loadedMdProps;
      if (loadedMdProps.has(propName)) return;
      loadedMdProps.add(propName);
      set({ loadedMdProps: new Set(loadedMdProps) });
    },
    resetMdPropLoaded: () => set({ loadedMdProps: new Set() }),

    setShowSettings: (show) => set({ showSettings: show }),
    showOkDialog: (okMessage) => set({ okMessage: okMessage }),
    showError: (error) => set({ errorMessage: error }),
    fogScreen: (fogged) => set({ fogged: fogged }),
}))

export { 
  appVersion,
  appModes,
  FOG_TIMEOUT,
  escHandlers,
  useAppStore,
  setAppInitialized,
  initAppStore,
  restoreAppCachedState,
  setAppMode,
  setActivePageKey,
  setSelected,
  setSubSelected,
  setPatrolIdForPoints,
  showPageOnMap,
  savePhone,
  saveMapCenter,
  setMapCenter,
  saveCamerasSettings,
  setAppIcon,
  setIconsFromPrefs,
  setMdPropLoaded,
  resetMdPropLoaded,
  setShowSettings,
  showOkDialog,
  showError,
  fogScreen,
  fogAndCall
};
