import create from 'zustand';
import { showError } from "./appStorage";
import { backend, syncEngine } from '../sync-engine';
import * as storageUtils from "./utils/storageUtils";

const initPatrols = () => patrolsStoreApi.getState().initPatrols();
const setCheckedId = (patrolId, checked) => patrolsStoreApi.getState().setCheckedId(patrolId, checked);
const setAllCheckedIds = (ids) => patrolsStoreApi.getState().setAllCheckedIds(ids);
const setPatrolMarkers = (markers) => patrolsStoreApi.getState().setPatrolMarkers(markers);
const getPatrolMarkers = () => patrolsStoreApi.getState().patrolMarkers;
const setOrder = (orderBy) => patrolsStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => patrolsStoreApi.getState().showRemoved(show);
const deletePatrol = (patrolId) => patrolsStoreApi.getState().deletePatrol(patrolId);
const restorePatrol = (patrolId) => patrolsStoreApi.getState().restorePatrol(patrolId);

const DEF_ORDER_BY = 0; // index of 'Time' header in PatrolsView

const [usePatrolsStore, patrolsStoreApi] = create((set, get) => ({
  checkedIds: new Set(),
  patrolMarkers: new Map(), // all patrol markers currenly shown on map
  orderBy: DEF_ORDER_BY,
  orderAsc: true,
  removedShown: false,

  initPatrols: () => 
    storageUtils.initList('patrols', set, DEF_ORDER_BY),

  setCheckedId: (patrolId, checked) => {
    if (patrolId) {
      const ids = get().checkedIds;
      if (checked) {
        ids.add(String(patrolId));
      } else {
        ids.delete(String(patrolId))
      }
      const newIds = new Set(ids);
      set({ checkedIds: newIds });
    }
  },

  setAllCheckedIds: (ids) => {
    const newIds = new Set(ids);
    set({ checkedIds: newIds });
  },

  setPatrolMarkers: (markers) => set({ patrolMarkers: markers }),

  setOrder: (orderBy) => 
    storageUtils.setOrder('patrols', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('patrols', show, set),

  deletePatrol : async (id) => {
    try{    
      await backend.request('POST', '/patrols/removePatrol', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to delete patrol: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

  restorePatrol : async (id) => {
    try{    
      await backend.request('POST', '/patrols/restorePatrol', {id: id});
    }catch(err){
      console.error(err);
      showError(`Fail to restore patrol: ${err}`);
      return;
    }
    syncEngine.syncNow(); 
  },

}));

export { 
  usePatrolsStore,
  initPatrols,
  setCheckedId,
  setAllCheckedIds,
  setPatrolMarkers,
  getPatrolMarkers,
  setOrder,
  showRemoved,
  deletePatrol,
  restorePatrol
};