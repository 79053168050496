import { useState, useMemo, useEffect, } from 'react';
import { useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected } from "../../../storages/appStorage";
import { useMapStore, removeDrawingsFromMap, clusterMarkers, 
  unClusterMarkers } from '../../../storages/mapStorage';
import { setCameraMarkers, getCameraMarkers } from '../../../storages/camerasStorage';
import { PointDrawing } from './mapObjects';
import Utils from "../../../Utils";

const CameraDrawings = () => {
  const map = useMapStore(state => state.map);
  const camerasChanges = useSyncEngStore(state => state.camerasChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const cameraIcon = useAppStore(state => state.cameraIcon);
  const cameraSelIcon = useAppStore(state => state.cameraSelIcon);

  const [isShown, setShown] = useState(false);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('cameras', selectedIds), [selectedIds]);

  const checkAndUpdateObjDrawing = (obj, map, mapMarkers) => {
    if (obj && obj.camera && !obj.camera.rm && obj.camera.lat && obj.camera.lng) {
      updateObjDrawing(obj, map, mapMarkers);
    }
  };

  const updateObjDrawing = (obj, map, mapMarkers) => {
    const camera = obj.camera;
    const id = camera.id;
    const size =       new window.google.maps.Size(46, 46);
    const selectSize = new window.google.maps.Size(80, 80);
    const icons = {
      icon:         {url: cameraIcon.url, scaledSize: size},
      selectedIcon: {url: cameraSelIcon.url, scaledSize: selectSize}
    };

    if (!obj.drawing) {
      obj.drawing = new PointDrawing(icons);
    }
    const options = {title: camera.name, pointIcon: icons};
    obj.drawing.update(map, [camera.lng, camera.lat], 
      selectedId == id, options);
    if (obj.listener) obj.listener.remove();
    obj.listener = window.google.maps.event.addListener(obj.drawing.mapobj, 
      'click', () => setSelected('cameras', id));

    mapMarkers.set(String(obj.id), {
      listener:     obj.listener, 
      drawing:      obj.drawing,
    });
  };

  const showOnMap = () => {
    if (map) {
      const objects = objectsSnap('cameras')
      const oldMarkers = getCameraMarkers();
      const newMarkers = new Map();

      for(const id in objects){
        const obj = objects[id];
        const camera = obj.camera;

        const canLocate = camera && !camera.rm && camera.lat && camera.lng;
        
        if (canLocate) {
          oldMarkers.delete(id);
          updateObjDrawing(obj, map, newMarkers);
        } else {
          if (obj.listener) obj.listener.remove();
          if (obj.drawing) obj.drawing.remove();
        }
      }
      setCameraMarkers(newMarkers);
      unClusterMarkers(oldMarkers); // the rest of oldMarkers
      removeDrawingsFromMap(oldMarkers);
      clusterMarkers(newMarkers);
    }
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap();
  }

  const onPageHide = () => {
    setShown(false);
    const allCameraMarkers = getCameraMarkers();
    unClusterMarkers(allCameraMarkers);
    removeDrawingsFromMap(allCameraMarkers);
    setCameraMarkers(new Map());
  }

  const onChanges = () => {
    if (isShown) showOnMap();
  }

  const onSelectedChange = () => {
    if (isShown) {
      const snap = objectsSnap('cameras');
      const allCameraMarkers = getCameraMarkers();
      const prevObj = snap[prevSelectedId];
      const selectObj = snap[selectedId];
      checkAndUpdateObjDrawing(prevObj, null, allCameraMarkers);
      checkAndUpdateObjDrawing(selectObj, null, allCameraMarkers);

    }
  };

  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  useEffect(() => {
    onSelectedChange();
  }, [selectedIds]);

  useMemo(onChanges, [map, camerasChanges]);
  useEffect(onChanges, [cameraIcon, cameraSelIcon]);
  
  return(null); // no html render, draw on map directly instead
}

export default CameraDrawings;