import create from 'zustand';
import { showError } from "./appStorage";
import { backend } from "../sync-engine";
import * as storageUtils from "./utils/storageUtils";

const initPoints = () => pointsStoreApi.getState().initPoints();
const setStar = (pointId, val) => pointsStoreApi.getState().setStar(pointId, val);
const postComent = (pointId, text) => pointsStoreApi.getState().postComent(pointId, text);
const setFilters = (category) => pointsStoreApi.getState().setFilters(category);
const setPointMarkers = (markers) => pointsStoreApi.getState().setPointMarkers(markers);
const getPointMarkers = () => pointsStoreApi.getState().pointMarkers;
const setOrder = (orderBy) => pointsStoreApi.getState().setOrder(orderBy);
const showRemoved = (show) => pointsStoreApi.getState().showRemoved(show);

const DEF_ORDER_BY = 1; // index of 'User' header in PointsView

const [usePointsStore, pointsStoreApi] = create((set, get) => ({
  filters: new Set(['alert', 'animal', 'info']),
  pointMarkers: new Map(), // all point markers currenly shown on map
  orderBy: DEF_ORDER_BY,
  orderAsc: true,
  removedShown: false,

  initPoints: () => 
    storageUtils.initList('points', set, DEF_ORDER_BY),

  setStar: async (pointId, val) => {
    console.debug("setStar");
    try{
      await backend.request('POST','/points/setStar', {id:pointId, value: val} );
    }catch(err){
      showError(`Fail to set star: ${err}`);
    }
  }, // setStar

  postComent: async (pointId, text) => {
    try{
      await backend.request('POST','/points/addComment', {id:pointId, text: text} );
    }catch(err){
      showError(`Fail to post comment: ${err}`);
    }
  }, // postComent

  setFilters: (category) => {
    const filters = get().filters;
    let newFilters = new Set(filters);
    if (newFilters.has(category)) {
      newFilters.delete (category);
    } else {
      newFilters.add(category);
    }
    set({ filters: newFilters })
  }, // setFilters

  setPointMarkers: (markers) => set({ pointMarkers: markers }),

  setOrder: (orderBy) => 
    storageUtils.setOrder('points', orderBy, get, set),

  showRemoved: (show) => 
    storageUtils.showRemoved('points', show, set),

}));

export { 
  usePointsStore,
  initPoints,
  setStar,
  postComent,
  setFilters,
  setPointMarkers,
  getPointMarkers,
  setOrder,
  showRemoved
};