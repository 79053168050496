import React, { useState, useEffect } from "react";
import { Typography, Select, MenuItem, TextField, Button, CircularProgress, 
  IconButton, Icon} from '@mui/material';
import { useWorkspaceStore, loadWorkspaceInfo, setLogin, setPassword, doLogin, 
  removeWorkspaceInfo, } from '../storages/workspaceStorage';
import { showOkDialog } from '../storages/appStorage';
import { useLvStore } from '../storages/lvStorage';
import LiveViewPublic from '../components/liveView/liveViewPublic';
import Image from '../components/image';
import { styled } from '@mui/styles';

import { xmlSvg } from "../components/svg";

const TextButtonSt = styled(Button)({
  margin: "24px 0px 16px",
  color: 'white',
  backgroundColor: 'orange',
  '&:hover': {
    backgroundColor: 'darkorange'},
});

const IconButtonSt = styled(IconButton)({
  margin: "24px 0px 16px 16px",
  color: 'white',
  backgroundColor: 'orange',
  '&:hover': {
    backgroundColor: 'darkorange'},
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
});

const SizedIcon = styled(Icon)({
  height: '24px', 
  width: '24px',
});

const LoginView = () => {

  const NOT_SELECTED_WSP = 'NOT_SELECTED_WSP';

  const connectionFail = useWorkspaceStore(state => state.connectionFail);
  const workspaces = useWorkspaceStore(state => state.workspaces);
  const workspaceId = useWorkspaceStore(state => state.workspaceId);
  const wsImage = useWorkspaceStore(state => state.wsImage);
  const wsLogo  = useWorkspaceStore(state => state.wsLogo);
  const lvEnabled  = useLvStore(state => state.lvEnabled);

  const [liveViewVisible, setLiveViewVisible] = useState(false);

  const selectedWsp = () => workspaceId ? workspaceId : NOT_SELECTED_WSP;

  const keydownListener = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      doLogin();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownListener);
    return () => document.removeEventListener("keydown", keydownListener);
  }, []);

  const onChangeWsp = (event) => {
    const {value} = event.target; // workspace id
    if (value == NOT_SELECTED_WSP) {
      removeWorkspaceInfo();
    } else {
      loadWorkspaceInfo(value);
    }
  }

  const onInputLogin = (e) => {
    setLogin(e.target.value);
  }
  const onInputPassword = (e) => {
    setPassword(e.target.value);
  }

  const onEmbedCode = () => {
    showOkDialog({
      title: 'Live View Embed Code', 
      message: `<iframe title="${workspaceId} Live View" height="500" width="500" src="${window.location.origin}/#/live-view/${workspaceId}"></iframe>`, 
      onOK: () => null});
  };

  const invitation = connectionFail ? 'Fail to connect to server' :'Sign in workspace:';

  return (
    <div className="loginview">
      <div className="loginimage">
        <Image imgCl='wsImage' src={wsImage} 
          progInd={<div className="fullSize flexCenter"><CircularProgress /></div>}/>
      </div>
      <div className="loginpanel">
        <div className="login">
          <Image imgCl="size5em" src={wsLogo}
            progInd={<div className="size5em flexCenter"><CircularProgress /></div>}/>
          <Typography component="h1" variant="h5">
            {invitation}
          </Typography>
          {!connectionFail && workspaces && 
          <Select
            value={selectedWsp()}
            onChange={onChangeWsp}
            variant="outlined"
            fullWidth>
            {
              Object.values(workspaces).map(wsp => (
                <MenuItem key={wsp.id} value={wsp.id}>{wsp.id} - {wsp.name}</MenuItem>))
            }
              <MenuItem key={NOT_SELECTED_WSP} value={NOT_SELECTED_WSP}>- not selected -</MenuItem>
          </Select>}
          {!connectionFail && selectedWsp() != NOT_SELECTED_WSP &&
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              label="Login"
              name="login"
              autoComplete="login"
              autoFocus
              onChange={onInputLogin}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={onInputPassword}
            />
            <TextButtonSt
              onClick={doLogin}
              fullWidth
              variant="contained">
              Sign In
            </TextButtonSt>
            {lvEnabled   && <>
            <TextButtonSt
              onClick={() => setLiveViewVisible(true)}
              variant="contained">
              Live View
            </TextButtonSt>
            <IconButtonSt
              onClick={onEmbedCode}
              title='Live View Embed Code'
            >
              <SizedIcon>{xmlSvg}</SizedIcon>
            </IconButtonSt></>}
          </div>}
        </div>
      </div>
      {liveViewVisible && <LiveViewPublic onCancel={()=>setLiveViewVisible(false)}/>}
    </div>
  )
}

export default LoginView;