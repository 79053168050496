import React, { useState, useEffect, useMemo, } from 'react';
import { styled } from '@mui/styles';
import { TextField, FormControl, InputLabel, Select, MenuItem, } from '@mui/material';
import { PointDrawing } from '../map/drawings/mapObjects';
import { TitleWithClose } from "../dialog";
import { ButtonGreen } from '../common';
import { escHandlers } from "../../storages/appStorage";
import { useCamerasStore, setCreatingCamera, createEditCamera } from '../../storages/camerasStorage';
import { useMapStore, cleanUpMapState, setTempDrawing, getTempDrawing, getChangedDrawing, 
  setNewCoordinates } from '../../storages/mapStorage';
import Utils from "../../Utils";


const StyledButton = styled(ButtonGreen)({
  margin: '1em 1em 0',
});

const EditCamera = () => {
  const map = useMapStore(state => state.map);
  const creatingCamera = useCamerasStore(state => state.creatingCamera);
  const newCoords = useMapStore(state => state.newCoords);
  
  const editMode = creatingCamera.id;
  const editCameraWithPos = editMode && creatingCamera.lng && creatingCamera.lat;

  const [name, setName]     = useState(editMode ? creatingCamera.name   : '');
  const [folder, setFolder] = useState(editMode ? creatingCamera.folder : '');
  const [prefix, setPrefix] = useState(editMode ? creatingCamera.prefix : '');
  const [coordsTxtTyped, setCoordsTxtTyped] = useState(null);
  const [coordsTyped, setCoordsTyped] = useState(null);
  const [coordsErr, setCoordsErr] = useState(null);
  const [coordinates, setCoordinates] = useState(
    editCameraWithPos ? {lng: creatingCamera.lng, lat: creatingCamera.lat} : 
    null);

  const getMapTempPoint = () => {
    const tempDrawing = getTempDrawing();
    if (tempDrawing) return tempDrawing;

    const tempPoint = new PointDrawing({icon: {url: 'images/marker_point.png'}});
    setTempDrawing(tempPoint);
    return tempPoint;
  };

  const initMapHandling = () => {
    escHandlers.set('EditCamera', onCancel);

    if (editCameraWithPos) {
      setNewCoordinates({lng: creatingCamera.lng, lat: creatingCamera.lat})
      return;
    }

    map.setOptions({draggableCursor: 'crosshair'});
    window.google.maps.event.addListener(map, 'click', function(event) {
      if (event.latLng) {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        setCoordinates({lng, lat});

        const tempPoint = getMapTempPoint();
        tempPoint.update(map, [lng, lat], false, '');
      }
    });
  };

  useEffect(() => {
    initMapHandling();
    return () => escHandlers.delete('EditCamera');
  }, []);

  const onInputName = (e) => {
    setName(e.target.value);
  }
  const onInputFolder = (e) => {
    setFolder(e.target.value);
  }
  const onInputPrefix = (e) => {
    setPrefix(e.target.value);
  }

  const onInputCoords = (e) => {
    setCoordsTxtTyped(e.target.value);
    const {pos, err} = Utils.parseLatLng(e.target.value);
    setCoordsTyped(pos);
    setCoordsErr(err);
  }

  const onSave = () => {
    if (editCameraWithPos) {
      const changedDrawing = getChangedDrawing();
      if (changedDrawing) changedDrawing.stopEdit(false);
    }

    const coords = coordsTxtTyped ? coordsTyped 
      : editCameraWithPos ? newCoords : coordinates;
    createEditCamera({id: creatingCamera.id, 
      name, folder, prefix, lat: coords.lat, lng: coords.lng})
    cleanUpAndClose();
  };

  const onCancel = () => {
    if (editCameraWithPos) {
      const changedDrawing = getChangedDrawing();
      if (changedDrawing) changedDrawing.stopEdit(true);
    }
    cleanUpAndClose();
  };

  const cleanUpAndClose = () => {
    setCreatingCamera(null);
    cleanUpMapState();
  };

  const title = editMode ? `Edit "${creatingCamera.name}"` : 'New Camera';

  const canItBeSaved = () => {
    if (coordsErr) return false;
    if (editCameraWithPos) {
      if (!name) return false;
      if (name   != creatingCamera.name) return true;
      if (folder != creatingCamera.folder) return true;
      if (prefix != creatingCamera.prefix) return true;

      if (coordsTxtTyped) {
        if (coordsTyped.lng != creatingCamera.lng) return true;
        if (coordsTyped.lat != creatingCamera.lat) return true;
      } else if (newCoords) {
        if (newCoords.lng != creatingCamera.lng) return true;
        if (newCoords.lat != creatingCamera.lat) return true;
      }
      return false;
    }
    return name && (coordsTxtTyped ? coordsTyped : coordinates);
  };

  const canSave = useMemo(canItBeSaved, 
    [name,folder,prefix, newCoords, coordinates, coordsTxtTyped]);

  const getEditCoords = () => {
    if (newCoords) return `${newCoords.lat}   ${newCoords.lng}`;
    return '';
  };

  const getCreateCoords = () => {
    if (coordinates) return `${coordinates.lat}   ${coordinates.lng}`;
    return '';
  };

  const getCoordsText = () => {
    setCoordsTxtTyped(null);
    setCoordsErr(null);
    return editCameraWithPos ? getEditCoords() : getCreateCoords();
  };

  const coordsText = useMemo(getCoordsText, [newCoords, coordinates]);

  const coordsTitle = editCameraWithPos ? 'Coordinates (drag marker):' : 'Coordinates (pick on map):';
  
  return(
  <div className='flexColumn paddingElem'>
    <TitleWithClose onClose={onCancel}>
      {title}
    </TitleWithClose>
    <TextField
      variant='outlined'
      margin='normal'
      label='Camera Name'
      value={name}
      onChange={onInputName}
    />
    <TextField
      variant='outlined'
      margin='normal'
      label='Folder'
      value={folder}
      onChange={e=>{setFolder(e.target.value)}}
    />
    <TextField
      variant='outlined'
      margin='normal'
      label='Prefix'
      value={prefix}
      onChange={e=>{setPrefix(e.target.value)}}
    />

    <TextField
      variant='outlined'
      margin='normal'
      label={coordsTitle}
      value={coordsTxtTyped !== null ? coordsTxtTyped : coordsText}
      error={Boolean(coordsErr)}
      helperText={coordsErr}
      onChange={onInputCoords}
    />
    <div>
      <StyledButton disabled={!canSave} onClick={onSave}>Save</StyledButton>
    </div>
  </div>
  );
}

export default EditCamera;