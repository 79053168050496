import React, { useEffect, forwardRef } from "react";
import MuiDialog from '@mui/material/Dialog';
import { DialogContent, Slide } from '@mui/material';
import { escHandlers } from "../../storages/appStorage";
import TitleWithClose from "./titleWithClose";

const Transition = forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Dialog = ({ title, onClose, children, actions, fullScreen, lowZind }) => {

  useEffect(() => {
    escHandlers.set('Dialog', ()=>{}); // no onClose need
    return () => escHandlers.delete('Dialog');
  }, []);

  return (
    <MuiDialog 
      open={true}
      onClose={onClose}
      maxWidth={false}
      fullScreen={fullScreen}
      sx={lowZind ? {zIndex: 900} : {}}
      TransitionComponent={Transition}
    >
      <TitleWithClose onClose={onClose}>
        {title}
      </TitleWithClose>
      <DialogContent dividers>
        {children}
      </DialogContent>
      {actions}
    </MuiDialog>
  );
};
export default Dialog;