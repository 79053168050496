// https://developers.google.com/maps/documentation/javascript/tutorial
// https://www.npmjs.com/package/google-map-react

import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSyncEngStore } from '../../../sync-engine';
import {prefID}   from "../../../sync-engine/modules/Prefs";
import MapDrawings from '../../map/mapDrawings';
import { useMapStore, initGoogleMap, getLayoutMapRef, cleanUpGoogleMap, 
  setClustererStyles } from '../../../storages/mapStorage';

//---------------------------------------------------------------------------------------
const MapWidget = ({setup}) => {
  const map = useMapStore(state => state.map);
  const mapLoaded = useMapStore(state => state.mapLoaded);
  const prefsObjects = useSyncEngStore(state => state.prefsObjects);
  const [initComplete, setInitComplete] = useState(false);

  // Move Created Map --------------------------------------------
  const widgetMapRef = useRef();

  const putMapInWidget = () => {
    const mapNode = map ? map.getDiv() : <div></div>;
    if (widgetMapRef.current) {
      widgetMapRef.current.appendChild(mapNode);
    }
  };

  const putMapInLayout = () => {
    const mapNode = map ? map.getDiv() : <div></div>;
    const layoutMapRef = getLayoutMapRef();
    if (layoutMapRef?.current) {
      layoutMapRef.current.appendChild(mapNode);
    }
  };

  const initMap = () => {
    if (setup) {
      setClustererStyles(true);
      putMapInWidget();
      return;
    }

    // Create new Map --------------------------------------------
    const prefsDefault = prefsObjects ? (prefsObjects[prefID.map_default]?.value) : null;
    if (prefsDefault) setInitComplete(true);

    initGoogleMap({
      center: prefsDefault?.center ? prefsDefault.center : {lat: -2.96521807, lng: 38.24057288},
      zoom:   prefsDefault?.zoom ? prefsDefault.zoom   : 13,
      mapDivId: 'mapWidget',
      onLV: true
    });
  }

  const deinitMap = () => {
    if (setup) {
      setClustererStyles(false);
      putMapInLayout();
      return;
    }

    cleanUpGoogleMap();
  };

  useEffect(() => {
    initMap();
    return deinitMap;
  }, []);

  const initializeCenterAndZoom = () => {
    if (setup || initComplete) return;

    const prefsDefault = prefsObjects?(prefsObjects[prefID.map_default]?.value):null;
    if (map && prefsDefault) {
        if(prefsDefault.center) map.panTo(prefsDefault.center);
        if(prefsDefault.zoom)   map.setZoom(prefsDefault.zoom);
        setInitComplete(true);
    }
  }

  useEffect(() => {
    initializeCenterAndZoom();
  }, [map, prefsObjects]);

  return (
    <>
      {!setup && !mapLoaded && <div className="circularProgress"><CircularProgress/></div>}
      <div id='mapWidget' className='mapWidget' ref={widgetMapRef}></div>
      {!setup && <MapDrawings onPubLV={!setup}/>}
    </>
  );
}

export default MapWidget;