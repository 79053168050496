import React, { useState } from "react";
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useLvStore, setLvEnabled, setLvBgColor } from "../../../../storages/lvStorage";
import { Dialog, ColorDialog } from "../../../dialog";
import Utils from "../../../../Utils";

const ColorButton = styled(Button)({
  backgroundColor: ({bg}) => bg,
  color: ({clr}) => clr,
  '&:hover': {
    backgroundColor: ({bghvr}) => bghvr,}
});


const LvSettings = ({onClose}) => {
const lvEnabled = useLvStore(state => state.lvEnabled);
const lvBgColor = useLvStore(state => state.lvBgColor);

const [colorDlgOpen, setColorDlgOpen] = useState(false);

const backgroundColor = lvBgColor ? lvBgColor : '#aaaaaa';
const {sharp: btnClr, medium: btnHwr} = Utils.color2contrastBWs(backgroundColor);
const bdColorText = lvBgColor ? lvBgColor : 'Not Set';

return <>
    {colorDlgOpen && <ColorDialog title={'Background Color'} val={backgroundColor} 
      onSave={(color) => setLvBgColor(color)} onClose={()=>setColorDlgOpen(false)}/>}
    <Dialog
      title={"Live View Settings"}
      onClose={onClose}>
        <div className='flexColumn'>
          <div>
            <input type="checkbox" checked={lvEnabled} onChange={() => setLvEnabled(!lvEnabled)}/> Live View enabled
          </div>
          <div className='flexAlignCenter paddingTop'>
            <div className='paddingRight'>Background Color</div>
            <ColorButton variant="contained" size="small" bg={backgroundColor} clr={btnClr} 
              bghvr={btnHwr}
                onClick={()=>setColorDlgOpen(true)}>
              {bdColorText}
            </ColorButton>
          </div>
        </div>
    </Dialog>
  </>;
};

export default LvSettings;