import React, { useState, useEffect } from 'react';
import { escHandlers, showOkDialog, showError } from '../../storages/appStorage';
import { useMapStore } from '../../storages/mapStorage';
import { useFeaturesStore, saveFeatures } from '../../storages/featuresStorage';
import { KmlViewer, KML } from "react-kml-viewer"
import CategoriesSelect from './categoriesSelect';
import { TitleWithClose } from "../dialog";
import { ButtonGreen } from '../common';

const ImportKML = ({onClose}) => {
  const map = useMapStore(state => state.map);
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);

  const [error, setError] = useState(null);
  const [kmlText, setKmlText] = useState(null);
  const [kml, setKml] = useState(null);

  useEffect(() => {
    escHandlers.set('ImportKML', onClose);
    return () => escHandlers.delete('ImportKML');
  }, []);

  const onFileSelected = (event) => {
    const files = event.target.files; if(!files) return;
    const file = files[0]; if(!file) return;

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      if(event.target.readyState == 2){
        console.warn('LOADED');
        setKmlText(event.target.result);
        setError(null);
      }else{
        // Error
        setKmlText(null);
        setError('File open error');
      }    
    });
    reader.readAsText(file);
  }

  const processSelected = () => {
    const selected = kml.getSelected()
    if(!selected.length){
      showError('No selected items');
      return
    }

    showOkDialog({
      title: 'Import selected', 
      message: 'Import selected items? Name is the key (CASE INSENSITIVE NOW). If an element is found in the database it will be updated or inserted otherwise', 
      onOK: () => doImport(selected)
    });
  }

  const doImport = async(selected) => {
    // Prepare data
    let data={values:[]}
    selected.forEach(item => {
      // Geometry type
      let type = 0;
      let coord = null;
      if     (item.geometry instanceof KML.Point)     {type = 1; coord = item.geometry.coordinates;}
      else if(item.geometry instanceof KML.LineString){type = 2; coord = item.geometry.coordinates;}
      else if(item.geometry instanceof KML.Polygon)   {type = 3; coord = item.geometry.outerCoordinates;}
      if(type) data.values.push({
        name       : item.name,
        type       : type,
        parent_id  : creatingFeature.parent_id,
        category_id: creatingFeature.category_id,
        coordinates: coord
      })
    });

    saveFeatures(data);
    onClose();
  }

  return <div className='flexColumn'>
    <TitleWithClose onClose={onClose}>
      Import from KML-file
    </TitleWithClose>
    <div className='paddingElem'>
      <input type="file"  accept=".kml" onChange={onFileSelected}></input>
      {kml && 
      <ButtonGreen
        onClick={processSelected}>IMPORT SELECTED
      </ButtonGreen>}
    </div>
    {kml && <div className='paddingHoriz'><CategoriesSelect/></div>}
    {error && <div className="error">{error}</div>}
    {kmlText && 
    <KmlViewer 
      kmlText     = {kmlText} 
      kmlOptions  = {{singleSelection:false}}
      map         = {map}
      onKmlChange = {setKml} 
    /> }
  </div>;
};
export default ImportKML;