const maxDelay = 2147483647;  // 2^31 - 1

const setTimeout_ = (fn, delay) => {
  if (delay > maxDelay) {
      return setTimeout(() => setTimeout_(fn, delay-maxDelay), 
        maxDelay);
  }
  return setTimeout(fn, delay);
}

const callAt = (date, fn) => {
  const now = new Date();
  const delay = date - now;
  if (fn && delay > 0) {
    return setTimeout_(fn, delay);
  }
}
export default callAt;