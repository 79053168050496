// https://developers.google.com/maps/documentation/javascript/tutorial
// https://www.npmjs.com/package/google-map-react

import React, { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useSyncEngStore } from './sync-engine';
import {prefID}   from "./sync-engine/modules/Prefs";
import MapDrawings from './components/map/mapDrawings';
import { useMapStore, initGoogleMap, setLayoutMapRef, cleanUpGoogleMap } from './storages/mapStorage';

const Map = () => {
  const map = useMapStore(state => state.map);
  const mapLoaded = useMapStore(state => state.mapLoaded);
  const prefsObjects = useSyncEngStore(state => state.prefsObjects);
  const [initComplete, setInitComplete] = useState(false);

  const layoutMapRef = useRef();

  const initializeCenterAndZoom = () => {
    if (initComplete) return;

    const prefsDefault = prefsObjects?(prefsObjects[prefID.map_default]?.value):null;
    if (map && prefsDefault) {
        if(prefsDefault.center) map.panTo(prefsDefault.center);
        if(prefsDefault.zoom)   map.setZoom(prefsDefault.zoom);
        setInitComplete(true);
    }
  }

  const initMap = () => {
    const prefsDefault = prefsObjects ? (prefsObjects[prefID.map_default]?.value) : null;
    if (prefsDefault) setInitComplete(true);

    initGoogleMap({
      center: prefsDefault?.center ? prefsDefault.center : {lat: -2.96521807, lng: 38.24057288},
      zoom:   prefsDefault?.zoom ? prefsDefault.zoom   : 13,
      mapDivId: 'map'
    });

    setLayoutMapRef(layoutMapRef);
  }

  useEffect(() => {
    initMap();
    return cleanUpGoogleMap;
  }, []);

  useEffect(() => {
    initializeCenterAndZoom();
  }, [map, prefsObjects]);
  
  return (
    <div className="layout-map" ref={layoutMapRef}>
      {!mapLoaded && <div className="circularProgress"><CircularProgress/></div>}
      <div id='map'></div>
      <MapDrawings/>
    </div>
  );
}

export default Map;
