import React, { useMemo, useState } from 'react';
import { FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useLvStore, showPageOnMapWd, setPointsMaxNumber } from "../../../../storages/lvStorage";
import DialogButton from '../../../dialog/dialogButton';
import TimeSelector from '../../../timeSelector';
import { ButtonGreen } from '../../../common';
import Utils from '../../../../Utils';

const pointsKey = 'Reports Widget';

const PointsSettings = ({onClose}) => {
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const pointsTimeFilter = useLvStore(state => state.pointsTimeFilter);
  const pointsMaxNumber = useLvStore(state => state.pointsMaxNumber);

  const [checked, setChecked] = useState(pagesOnMapWd.has(pointsKey));
  const [timeSelectorVisible, setTimeSelectorVisible] = useState(false);
  const [maxNumber, setMMaxNumber] = useState(pointsMaxNumber);

  const onInputNumber = (e) => {
    if (e.target.value < -1) return;
    if (e.target.value.length == 0) {
      setMMaxNumber(-1);
      return;
    }
    setMMaxNumber(e.target.value);
  };

  const saveAndClose = () => {
    showPageOnMapWd(pointsKey, checked);
    setPointsMaxNumber(maxNumber);
    onClose();
  };

  const getTfData = () => {
    return Utils.timeFilterData(pointsTimeFilter);
  }
  const {label} = useMemo(getTfData, [pointsTimeFilter]);

  return <>
    {timeSelectorVisible && <TimeSelector 
      onClose={()=>setTimeSelectorVisible(false)} 
      wdKey={pointsKey} tfObj={pointsTimeFilter}/>}
    <DialogButton title={'Reports Widget Settings'} okName={'Save'}
            onOK={saveAndClose} onClose={onClose}>
      <div className='flexColumn'>
        <FormControlLabel label={'Show on Map'} control={
          <Checkbox checked={checked} onChange={() => setChecked(!checked)} />}/>

        history depth<ButtonGreen onClick={()=>setTimeSelectorVisible(true)}>{label}</ButtonGreen>
        <div className='flexColumn paddingTop'>
          <TextField variant="outlined" size="small" label="Max Number To Display" type="number"
              value={maxNumber} onChange={onInputNumber}/>
          (-1 means "Display all")
        </div>
      </div>
    </DialogButton>
    </>;
};

export default PointsSettings;