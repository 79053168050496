import Prefs        from "./modules/Prefs"
import Roles        from "./modules/Roles"
import Users        from "./modules/Users"
import Features     from "./modules/Features"
import Categories   from "./modules/Categories"
import Sensors      from "./modules/Sensors"
import Points       from "./modules/Points"
import Patrols      from "./modules/Patrols"
import Ble          from "./modules/Ble"
import Cameras      from "./modules/Cameras"

const DataModules = {
    prefs       : Prefs,
    roles       : Roles,
    users       : Users,
    points      : Points,
    features    : Features,
    categories  : Categories,
    sensors     : Sensors,
    patrols     : Patrols,
    ble         : Ble,
    cameras     : Cameras,
};

export default DataModules;
