export default {
  listRowOpened: 'DarkSeaGreen',
  listExpBtn:    'ForestGreen',
  listExpBtnHov: 'DarkOliveGreen', // DarkGreen
  iconEnabl:     'DarkTurquoise',
  iconHover:   'Fuchsia',
  iconDisabl:  'Grey',
  tabEnabl:    '#4D7A58',
  tabDisabl:   '#556056',
  tabBgEnabl:  'white',
  tabBgDisabl: '#E8DDCD',
  tabHover:    'DarkGoldenRod',
  error:       'red',
  warning:     'orange'
}