import React, { useState, useMemo, useEffect, } from 'react';
import { styled } from '@mui/styles';
import { TextField, List, ListItem } from '@mui/material';
import FoldersSelect from './foldersSelect';
import CategoriesSelect from './categoriesSelect';
import { ButtonGreen } from '../common';
import { escHandlers } from "../../storages/appStorage";
import { cleanUpMapState } from '../../storages/mapStorage';
import { type, useFeaturesStore, getInitialFeature, changeCreatingFeature, 
  cleanupCreatingFeature, setShowEditFeature, 
  saveFeature } from '../../storages/featuresStorage';
import { TitleWithClose } from "../dialog";
import Utils from '../../Utils';

const TextFieldSt = styled(TextField)({
  marginBottom: '1em',
});

const StyledButton = styled(ButtonGreen)({
  margin: '1em 1em 0',
});

const EditFeature = () => {
  const initialFeature = getInitialFeature();
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);

  const [coordsTxtTyped, setCoordsTxtTyped] = useState(null);
  const [coordsTyped, setCoordsTyped] = useState(null);
  const [coordsErr, setCoordsErr] = useState(null);

  const tPoint = creatingFeature.type == type.POINT;

  useEffect(() => {
    escHandlers.set('EditFeature', () => cleanUpAndClose(true));
    return () => escHandlers.delete('EditFeature');
  }, []);

  const scrollToLastCoordItem = () => {
    if (creatingFeature?.coordinates) {
      const lastItemID = `coordId${creatingFeature.coordinates.length - 1}`;
      const lastItem = document.getElementById(lastItemID);
        if (lastItem) lastItem.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }

  const coordsEqual = (featureType, coord1, coord2) => {
    if (featureType == type.POINT) {
      return Utils.simpleArraysAreEqual(coord1, coord2);
    }

    if (!coord1 || !coord2) return false;
    if (coord1.length != coord2.length) return false;
    for (let i = 0; i < coord1.length; i++) {
      if (!Utils.simpleArraysAreEqual(coord1[i], coord2[i])) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    scrollToLastCoordItem();
  }, [creatingFeature]);

  const onSave = () => {
    if (coordsTxtTyped ) {
      changeCreatingFeature({coordinates: [coordsTyped.lng, coordsTyped.lat]});
    }
    saveFeature();
    cleanUpAndClose(false);
  };

  const cleanUpAndClose = (restorePos) => {
    cleanUpMapState();
    cleanupCreatingFeature(restorePos);
    setShowEditFeature(false);
  };

  const onInputName = (e) => {
    changeCreatingFeature({name: e.target.value});
  }

  const onInputDescr = (e) => {
    changeCreatingFeature({descr: e.target.value});
  }

  const onInputCoords = (e) => {
    setCoordsTxtTyped(e.target.value);
    const {pos, err} = Utils.parseLatLng(e.target.value);
    setCoordsTyped(pos);
    setCoordsErr(err);
  }

  const editMode = creatingFeature?.id;
  const title = editMode ? `Edit ${creatingFeature.name}` : 'New Feature';

  const typeVals = ['', 'Point', 'Line', 'Polygon'];
  const typeText = creatingFeature ? typeVals[creatingFeature.type] : '';

  const getCoordsTitle = () => {
    if (editMode) {
      return tPoint ? 'Coordinates (drag marker):' : 'Coordinates (drag markers, right click to delete):';}
    if (creatingFeature?.type) {
      return tPoint ? 'Coordinates (pick on map):' : 'Coordinates (pick on map, right click to finalize):';}
    return 'Coordinates';
  };
  const coordsTitle = getCoordsTitle(); 

  const coordsPointTitle = editMode ? 'Coordinates (drag marker):' : 'Coordinates (pick on map):';

  const getCoordsText = (creatingFeature) => {
    setCoordsTxtTyped(null);
    setCoordsErr(null);
    if (creatingFeature && creatingFeature.coordinates) {
      return `${creatingFeature.coordinates[1]}   ${creatingFeature.coordinates[0]}`;
    }
    return '';
  };
  const coordsText = useMemo(() => getCoordsText(creatingFeature),
    [creatingFeature]);

  const getCoordList = (creatingFeature) => {
    if (creatingFeature && creatingFeature.coordinates) {
      return creatingFeature.coordinates.map(
        (point, index) => <ListItem key={index} id={`coordId${index}`}>
          {`${point[1]}   ${point[0]}`}</ListItem>);
    }
    return [];
  }
  const coordListItems = useMemo(() => getCoordList(creatingFeature),
    [creatingFeature]);

  const coordCorrect = () => {
    switch (creatingFeature?.type) {
      case type.POINT: return creatingFeature.coordinates;
      case type.LINE: return creatingFeature.coordinates?.length > 1;
      case type.POLYGON: return creatingFeature.coordinates?.length > 2;
    }
  }

  const isChanged = () => {
    if (coordsTxtTyped && coordsTyped && 
          !Utils.simpleArraysAreEqual([coordsTyped.lng, coordsTyped.lat], initialFeature.coordinates)) {
      return true;
    } 
    return initialFeature.name != creatingFeature.name || 
    initialFeature.parent_id != creatingFeature.parent_id ||
    initialFeature.category_id != creatingFeature.category_id ||
    Utils.strDifferIfSet(initialFeature.descr, creatingFeature.descr) ||
    !coordsEqual(creatingFeature.type, creatingFeature.coordinates, initialFeature.coordinates);
  };

  const changed = useMemo(isChanged,
    [creatingFeature, coordsTxtTyped]);

  const canSave = !coordsErr && changed && creatingFeature.name && coordCorrect();
  const nameVal = creatingFeature.name ? creatingFeature.name : '';
  const descrVal = creatingFeature.descr ? creatingFeature.descr : '';

  return(
  <div className='flexColumn paddingButTop'>
    <TitleWithClose onClose={() => cleanUpAndClose(true)}>
      {title}
    </TitleWithClose>
    <TextFieldSt
      variant='outlined'
      margin='normal'
      label='Feature Name'
      value={nameVal}
      onChange={onInputName}
    />
    <FoldersSelect/>
    <CategoriesSelect/>
    <TextField
      variant='outlined'
      margin='normal'
      label='Description'
      value={descrVal}
      onChange={onInputDescr}
    />
    <div className='leftAlignText'>Type: {typeText}</div>
    {tPoint && <TextField
      variant='outlined'
      margin='normal'
      label={coordsPointTitle}
      value={coordsTxtTyped !== null ? coordsTxtTyped : coordsText}
      error={Boolean(coordsErr)}
      helperText={coordsErr}
      onChange={onInputCoords}
    />}
    {!tPoint && <div className='leftAlignText'>{coordsTitle}</div>}
    {!tPoint && <div className='paddingElem'>
      <List className='scrollList'>
        {coordListItems}
      </List>
    </div>}
    <div>
      <StyledButton disabled={!canSave} onClick={onSave}>Save</StyledButton>
    </div>
  </div>
  );
}

export default EditFeature;