import React, { useState, useMemo } from "react";
import { styled } from '@mui/styles';
import { Icon } from '@mui/material';
import { useLvStore, setLvEnabled, setWidget2show, saveLiveView } from "../../../storages/lvStorage";
import { patrolsSvg, reportsSvg, sensorsSvg, settingsSvg, webSvg } from "../../svg";
import LvSettings from "./settings/lvSettings";

const WdIcon = styled(Icon)({
  height: '1.5em', 
  width: '1.5em',
});

const HeaderIcon = styled(Icon)({
  height: '24px', 
  width: '24px',
  color: 'grey',
});

const WidgetsPanel = () => {
  const lvEnabled = useLvStore(state => state.lvEnabled);
  const hiddenWidgets = useLvStore(state => state.hiddenWidgets);

  const onDragStart = (e, wdKey) => {
    //a hack for firefox: https://bugzilla.mozilla.org/show_bug.cgi?id=568313
    e.dataTransfer.setData('text/plain', '');
    setWidget2show(wdKey);
  };

  const getWdIcon = (wdKey) => {  // TODO icons
    switch (wdKey) {
      case 'Map Widget':
        return <WdIcon>{webSvg}</WdIcon>;
      case 'Patrols Widget':
        return <WdIcon>{patrolsSvg}</WdIcon>;
      case 'Reports Widget':
        return <WdIcon>{reportsSvg}</WdIcon>;
      case 'Sensors Widget':
        return <WdIcon>{sensorsSvg}</WdIcon>;
      default:
        return null;
    }
  };

  const getWidgets = () => Array.from(hiddenWidgets).map(wdKey => <div
    key={wdKey}
    className="widgetIcon"
    title={wdKey}
    draggable={true}
    unselectable="on"
    onDragStart={e => onDragStart(e, wdKey)}
  >
    {getWdIcon(wdKey)}
  </div>);

  const widgets = useMemo(() => getWidgets(), [hiddenWidgets]);

  const [showSettings,     setShowSettings]     = useState(false);

  return <>
  {showSettings && <LvSettings onClose={() => setShowSettings(false)}/>}
    <div className='widgetsPanel'>
    <div>
      <HeaderIcon onClick={() => setShowSettings(true)}>{settingsSvg}</HeaderIcon>
    </div>
    <div className="divider"></div>
    widgets
    {widgets}
    {/* <div className='paddingBottom'>
      <input type="checkbox" checked={lvEnabled} onChange={() => setLvEnabled(!lvEnabled)}/> enabled
    </div> */}
    <div className="divider"></div>
    <button className="marginTop" onClick={saveLiveView}>Save</button>
  </div></>
};
export default WidgetsPanel;