import React, { useMemo, useState, useEffect } from 'react';
import { styled } from '@mui/styles';
import { TextField, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSyncEngStore } from '../../sync-engine';
import { escHandlers } from "../../storages/appStorage";
import { useUsersStore, setShowEditUser, createEditUser, 
  saveCredentials } from '../../storages/usersStorage';
import { TitleWithClose } from "../dialog";
import { ButtonGreen } from '../common';

const StyledButton = styled(ButtonGreen)({
  margin: '1em 1em 0',
});

const EditUser = () => {
  const rolesObjects = useSyncEngStore(state => state.rolesObjects, () => false);
  const creatingUser = useUsersStore(state => state.creatingUser);

  const userName = creatingUser.name ? creatingUser.name : '';

  let defRoleId = null;
  if(rolesObjects) for(const id in rolesObjects){defRoleId=id;}

    const [inputName, setInputName] = useState(userName);
    const [inputRole, setInputRole] = useState(creatingUser.role_id ? creatingUser.role_id : defRoleId);
    const [inputLogin, setInputLogin] = useState('');
    const [inputPass, setInputPass] = useState('');

  const editMode = creatingUser.id;

  useEffect(() => {
    escHandlers.set('EditUser', () => setShowEditUser(false));
    return () => escHandlers.delete('EditUser');
  }, []);

  const onInputName = (e) => setInputName(e.target.value);
  const onInputRole = (e) => 
    setInputRole(Number.parseInt(e.target.value));

  const onInputLogin = (e) => setInputLogin(e.target.value);
  const onInputPass = (e) => setInputPass(e.target.value);

  const onSave = () => {
    if (isUserChanged()) createEditUser({
      id: creatingUser.id,
      name   : inputName,
      role_id: inputRole
    });
    if (isCredentialsEntered()) saveCredentials({
      id      : creatingUser.id,
      login   : inputLogin,
      password: inputPass,
      encrypt : false
    });
    cleanUpAndClose();
  };

  const getRoles = () => {
    const roles = []
    if (rolesObjects) for(const id in rolesObjects){
      const role = rolesObjects[id];
      roles.push(<MenuItem key={id} value={id}>{role.name}</MenuItem>);
    }
    return roles;
  }; 

  const selectRoles = useMemo(() => getRoles(), [rolesObjects]);

  const cleanUpAndClose = () => {
    setShowEditUser(false);
  };

  const isUserChanged = () => {
    if (inputName && inputName !== userName) return true;
    if (editMode && inputRole !== creatingUser.role_id) return true;
    return false;
  };

  const isCredentialsEntered = () => inputLogin || inputPass;
  const isChanged = () => isUserChanged() || isCredentialsEntered();

  const title = editMode ? `Edit User "${creatingUser.name}"` : "Create User";
  const canSave = isChanged();

  return (
    <div className='flexColumn paddingButTop'>
      <TitleWithClose onClose={() => setShowEditUser(false)}>
        {title}
      </TitleWithClose>
      <Paper elevation={3} className='flexColumn paddingElem marginElem'>
        <TextField
          variant='outlined'
          margin='normal'
          label='name'
          value={inputName}
          onChange={onInputName}
        />
        <FormControl variant='outlined' fullWidth={true} margin={'dense'} size={'small'}>
          <InputLabel id='select-role-label'>role</InputLabel>
          <Select
            labelId='select-role-label'
            value={inputRole}
            onChange={onInputRole}
            label='role'
          >
            {selectRoles}
          </Select>
        </FormControl>
      </Paper>
      {editMode && 
      <Paper elevation={3} className='flexColumn paddingElem marginElem'>
        <TextField
          variant='outlined'
          margin='normal'
          label='login'
          value={inputLogin}
          onChange={onInputLogin}
        />
        <TextField
          variant='outlined'
          margin='normal'
          label='password'
          value={inputPass}
          onChange={onInputPass}
        />
      </Paper>}
      <div>
        <StyledButton disabled={!canSave} onClick={onSave}>Save</StyledButton>
      </div>
    </div>
  );
}
export default EditUser;  