import React, { Component } from "react";
import { Grid } from 'react-virtualized';

class RvGridAnimated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrollTop: null
    };

    this._scrollTopInitial = 0;
    this._setRef = this._setRef.bind(this);
    this._onScroll = this._onScroll.bind(this);
  }

  componentDidMount() {
    if (this.props.scrollToRow > -1) {
      this._initAnimation();
      
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.scrollToRow !== prevProps.scrollToRow) {
      this._initAnimation();
    }
  }

  recomputeGridSize() {
    if (this._grid) {
      this._grid.recomputeGridSize();
    }
  }

  _setRef(grid) {
    this._grid = grid;
  }

  _onScroll({ scrollTop }) {
    if (!this._animationStartTime) {
      this.setState({ scrollTop });
      this._scrollTopInitial = scrollTop;
    }
  }

  _initAnimation() {
    if (this._animationStartTime || !this._grid) {
      return;
    }

    const {scrollToRow} = this.props;
    this._animationStartTime = performance.now();
    this._scrollTopFinal = this._grid.getOffsetForCell({
      rowIndex: scrollToRow
    }).scrollTop;

    this._animate();
  }

  _animate() {
    requestAnimationFrame(() => {
      const { duration, easing } = this.props;
      const now = performance.now();
      const ellapsed = now - this._animationStartTime;
      const scrollDelta = this._scrollTopFinal - this._scrollTopInitial; // this._scrollTopFinal.scrollTop
      const easedProgress = easing(Math.min(1, ellapsed / duration));
      const scrollTop = this._scrollTopInitial + scrollDelta * easedProgress;

      this.setState({ scrollTop });

      if (ellapsed < duration) {
        this._animate();
      } else {
        this._animationStartTime = undefined;
        this._scrollTopInitial = this._scrollTopFinal;
      }
    });
  }

  render() {
    return <Grid 
      {...this.props} 
      ref={this._setRef}
      onScroll={this._onScroll}
      scrollTop={this.state.scrollTop}
      scrollToRow={undefined}/>;
  }
}

export default RvGridAnimated;