import React, { useState, useMemo, } from 'react';
import { backend } from '../../sync-engine';
import { showOkDialog } from "../../storages/appStorage";
import { deleteCamera, restoreCamera } from '../../storages/camerasStorage';
import ImageGallery from '../imageGallery';
import ImageViewer from '../ImageViewer';
import { EditIcon, DelRestorIcon, ImageMultipleIcon } from '../common';

const CameraExpElement = ({camera, images, onEdit}) => {
  const [imgGallery, showImgGallery] = useState(false);
  const [imgViewer, showImgViewer] = useState(false);
  const [currentImgInd, setCurrentImgInd] = useState(0);

  const path = backend.getWorkspaceUrl()+'/cam/';

  const photoUrls = useMemo(() => images.map((image) => path+image.id+'/'+image.file), 
    [images]);

  const onImgClick = (ind) => {
    setCurrentImgInd(ind);
    showImgViewer(true);
  };

  const onDeleteCamera = (camera) => {
    showOkDialog({
      title: 'Delete camera', 
      message: `Delete camera "${camera.name}"?`, 
      onOK: () => deleteCamera(camera.id)});
  };

  const onRestoreCamera = (camera) => {
    showOkDialog({
      title: 'Restore camera', 
      message: `Restore camera "${camera.name}"?`, 
      onOK: () => restoreCamera(camera.id)});
  };

  return (<>
    {imgGallery && 
      <ImageGallery title={`Images from "${camera.name}" camera`} 
        images={images} onImgClick={onImgClick}
        onCancel={()=>showImgGallery(false)}/>}
    {imgViewer && 
      <ImageViewer images={photoUrls} onClose={() => showImgViewer(false)} index={currentImgInd}/>}
    <div className='cameraExp'>
      <table className='expTbl leftAlignTable'>
        <tbody>
          <tr><td>Folder</td><td>{camera.folder}</td></tr>
          <tr><td>Prefix</td><td>{camera.prefix}</td></tr>
        </tbody>
      </table>
      <div>
        {!camera.rm && <>
        <ImageMultipleIcon title='Show Images' enabled={photoUrls.length} 
          onClick={photoUrls.length ? () => showImgGallery(true) : null}/>
        <EditIcon titleAccess='Edit Camera' onClick={()=>{onEdit(camera)}}/></>}
        <DelRestorIcon 
          rm={camera.rm} 
          enabled={true}
          onDelete={() => onDeleteCamera(camera)}
          onRestore={() => onRestoreCamera(camera)}/>
      </div>
    </div></>
  );
};

export default CameraExpElement;