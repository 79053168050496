import React, { useMemo, } from 'react';
import { styled } from '@mui/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useSyncEngStore } from '../../sync-engine';
import { useFeaturesStore, changeCreatingFeature } from '../../storages/featuresStorage';

const FormControlSt = styled(FormControl)({
  marginTop: '1em',
});

const CategoriesSelect = () => {
  const categoriesObjects = useSyncEngStore(state => state.categoriesObjects, () => false);
  const creatingFeature = useFeaturesStore(state => state.creatingFeature);

  const notSetVal = '--- Not Set ---';

  const categoryChange = (e) => {
    const categoryId = e.target.value == notSetVal ? null : e.target.value;
    changeCreatingFeature({category_id: categoryId});
  };

  const getCategoryItems = () => {
    const categoriesItems = [
      <MenuItem key={0} value={notSetVal}>{notSetVal}</MenuItem>
    ];

    for(const id in categoriesObjects){
      const category = categoriesObjects[id]?.category;
      if (category) {
        const item = <MenuItem key={id} value={id}>{category.name}</MenuItem>
        categoriesItems.push(item);
      }
    }

    return categoriesItems;
  }

  const categoriesSelectItems = useMemo(
    () => getCategoryItems(), [categoriesObjects]);

  return (
    <FormControlSt variant='outlined' margin={'none'} size={'medium'} 
      fullWidth={true}>
      <InputLabel id='select-category-label'>Category</InputLabel>
      <Select
        labelId='select-category-label'
        value={creatingFeature.category_id ? creatingFeature.category_id : notSetVal}
        onChange={categoryChange}
        label='Category'
      >
        {categoriesSelectItems}
      </Select>
    </FormControlSt>
  );
}

export default CategoriesSelect;