import React, { useState, useMemo } from 'react';
import {Button, TextField} from '@mui/material';
import { data, syncEngine } from "../../sync-engine";
import { postComent } from '../../storages/pointsStorage';

const CommentsPanel = ({pointId, comments, active}) => {

  const commentsArr = useMemo(() => comments.map((comment) =>
    <tr key = {comment.time}>
      <td>{new Date(comment.time).toLocaleString()}</td>
      <td>{data.getUserName(comment.user_id)}</td>
      <td>{comment.text}</td></tr>
  ), [comments]);

  const [commentForPost, setCommentForPost] = useState('');
  const handleCommentChange = (event) => setCommentForPost(event.target.value);
  const onPostComent = async () => {
    await postComent(pointId, commentForPost);
    syncEngine.syncNow();
    setCommentForPost('');
  };

  return (
    <div className='paddingElem'>
      {commentsArr?.length 
      ? <table className='fullWidth'>
        <thead><tr>
          <th>Time</th><th>User</th><th>Comment</th>
        </tr></thead>
        <tbody>{commentsArr}</tbody>
      </table>
      : 'No comments'}
      {active && <div className='pntPostComm'>
        <TextField label='Comment' variant='outlined' value={commentForPost} onChange={handleCommentChange}/>
        <br/>
        <Button variant='contained' disabled={!commentForPost} onClick={onPostComent}>Post Comment</Button>
      </div>}
    </div>
  );
}

export default CommentsPanel;
