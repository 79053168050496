import create from 'zustand';
import { showError } from "./appStorage";
import { backend, syncEngine } from '../sync-engine';
import Utils from '../Utils';

const type = {
  FOLDER: 0,
  POINT: 1,
  LINE: 2,
  POLYGON: 3
};

const categoryAlpha2Opacity = (category) => {
  if (!category) return category;
  const {color: strokeColor, opacity: strokeOpacity} = Utils.colorAlpha2Opacity(category.stroke_color);
  const {color: fillColor, opacity: fillOpacity} = Utils.colorAlpha2Opacity(category.fill_color);

  const optCat = Object.assign({}, category);
  optCat.stroke_color = strokeColor;
  optCat.stroke_opacity = strokeOpacity;
  optCat.fill_color = fillColor;
  optCat.fill_opacity = fillOpacity;
  return optCat;
};

const initFeatures = () => featuresStoreApi.getState().initFeatures();
const setFeatureLegend = (legend) => featuresStoreApi.getState().setFeatureLegend(legend);
const getFeatureLegend = () => featuresStoreApi.getState().featureLegend;
const setInitialFeature = (feature) => 
  featuresStoreApi.getState().setInitialFeature(feature);
const getInitialFeature = () => featuresStoreApi.getState().initialFeature;
const setCreatingFeature = (feature) => 
  featuresStoreApi.getState().setCreatingFeature(feature);
const getCreatingFeature = () => featuresStoreApi.getState().creatingFeature;
const setShowEditFeature = (show) => featuresStoreApi.getState().setShowEditFeature(show);
const setShowEditFolder = (show) => featuresStoreApi.getState().setShowEditFolder(show);
const changeCreatingFeature = (fields) => featuresStoreApi.getState().changeCreatingFeature(fields);
const cleanupCreatingFeature = (restorePos) => featuresStoreApi.getState().cleanupCreatingFeature(restorePos);
const setCreatingCategory = (layer) => featuresStoreApi.getState().setCreatingCategory(layer);
const setShowCategories = (show) => featuresStoreApi.getState().setShowCategories(show);
const setShowCategoryPref = (show) => featuresStoreApi.getState().setShowCategoryPref(show);
const setShowImportKML = (show) => featuresStoreApi.getState().setShowImportKML(show);
const showLayer = (id) => featuresStoreApi.getState().showLayer(id);
const hideLayer = (id) => featuresStoreApi.getState().hideLayer(id);
const createCategory = (category) => featuresStoreApi.getState().createCategory(category);
const editCategory = (category) => featuresStoreApi.getState().editCategory(category);
const editCategoryIcon = (id, file) => featuresStoreApi.getState().editCategoryIcon(id, file);
const delRestorCategory = (id, restore) => featuresStoreApi.getState().delRestorCategory(id, restore);
const saveFeature = () => featuresStoreApi.getState().saveFeature();
const saveFeatures = (data) => featuresStoreApi.getState().saveFeatures(data);
const delRestorFeature = (id, restore) => featuresStoreApi.getState().delRestorFeature(id, restore);
const setExpandedNodeIds = (ids) => featuresStoreApi.getState().setExpandedNodeIds(ids);
const getExpandedNodeIds = () => featuresStoreApi.getState().expandedNodeIds;
const showRemoved = (show) => featuresStoreApi.getState().showRemoved(show);
const showRemovedCtg = (show) => featuresStoreApi.getState().showRemovedCtg(show);

const [useFeaturesStore, featuresStoreApi] = create((set, get) => ({
  featureLegend: null,
  initialFeature: null,
  creatingFeature: null,
  showEditFeature: false,
  showEditFolder: false,
  creatingCategory: null,
  showCategories: false,
  showCategoryPref: false,
  showImportKML: false,
  unvisibleLayers: new Set(), // category ids
  expandedNodeIds: [],
  removedShown: false,
  removedCtgShown: false,

  initFeatures: () => {
    const removedShownStr = localStorage.getItem('MapLayersRemovedShown');
    const removedShown = removedShownStr ? removedShownStr == 'true' : false;
    set({ removedShown: removedShown });

    const removedCtgShownStr = localStorage.getItem('MapLayersRemovedCtgShown');
    const removedCtgShown = removedCtgShownStr ? removedCtgShownStr == 'true' : false;
    set({ removedCtgShown: removedCtgShown });

    const unvisibleLayersStr = localStorage.getItem('unvisibleLayers');
    if (unvisibleLayersStr) {
      const newLayers = new Set(unvisibleLayersStr.split(','));
      set({ unvisibleLayers: newLayers });
    } else {
      set({ unvisibleLayers: new Set() });
    }
  },

  setFeatureLegend: (legend) => set({ featureLegend: legend }),
  setInitialFeature: (feature) => set({ initialFeature: feature }),
  setShowEditFeature: (show) => set({ showEditFeature: show }),
  setShowEditFolder: (show) => set({ showEditFolder: show }),
  setCreatingFeature: (feature) => set({ creatingFeature: feature }),
  changeCreatingFeature: (fields) => {
    const changedCF = Object.assign({}, get().creatingFeature, fields);
    set({ creatingFeature: changedCF });
  },
  cleanupCreatingFeature: (restorePos) => {
    const creatingFeature = get().creatingFeature;
    if (creatingFeature?.drawing) {
      creatingFeature.drawing.stopEdit(restorePos);
      delete creatingFeature.drawing;
    }
  },

  setCreatingCategory: (layer) => set({ creatingCategory: layer }),
  setShowCategories: (show) => set({ showCategories: show }),
  setShowCategoryPref: (show) => set({ showCategoryPref: show }),
  setShowImportKML: (show) => set({ showImportKML: show }),

  showLayer: (id) => {
    const layers = get().unvisibleLayers;
    layers.delete(id);
    const newLayers = new Set(layers);
    set({ unvisibleLayers: newLayers });
    localStorage.setItem('unvisibleLayers', String(Array.from(newLayers)));
  },
  
  hideLayer: (id) => {
    const layers = get().unvisibleLayers;
    layers.add(id);
    const newLayers = new Set(layers);
    set({ unvisibleLayers: newLayers });
    localStorage.setItem('unvisibleLayers', String(Array.from(newLayers)));
  },

  createCategory : async (category) => {
    try {
      await backend.request('POST','/features/insert_category', category);
    } catch (err) {
      console.error(err);
      showError(`Fail to add new category: ${err}`);
    }
    syncEngine.syncNow();
  },

  editCategory: async (category) => {
    category.time = Date.now();
    try {    
      await backend.request('POST','/features/update_category', category);
    } catch (err) {
      console.error(err);
      showError(`Fail to save layer: ${err}`);
    }

    syncEngine.syncNow();
  },

  editCategoryIcon: async (id, file) => {
    try {    
      await backend.sendFILE(`/features/update_category_icon_svg/${id}`,file);
    } catch (err) {
      console.error(err);
      showError(`Fail to save layer icon: ${err}`);
    }

    syncEngine.syncNow();
  },

  delRestorCategory : async (id, restore) => {
    try{    
      await backend.request('POST', 
        restore ? '/features/restore_category' : '/features/remove_category', 
        {id});
    }catch(err){
      console.error(err);
      showError(`Fail to ${restore ? 'restore' : 'delete'} category: ${err}`);
      return;
    }

    syncEngine.syncNow(); 
  },

  createFeature : async (feature) => {
    try {
      await backend.request('POST','/features/insert_feature', feature);
    } catch (err) {
      console.error(err);
      showError(`Fail to add new feature: ${err}`);
    }

    syncEngine.syncNow();
  },

  editFeature : async (feature) => {
    const data = {
      id          : feature.id,
      name        : feature.name,
      type        : feature.type,
      category_id : feature.category_id,
      parent_id   : feature.parent_id,
      descr       : feature.descr ? feature.descr : '',
      coordinates : feature.coordinates
    };

    try {
      await backend.request('POST','/features/update_feature', data);
    } catch (err) {
      console.error(err);
      showError(`Fail to update feature: ${err}`);
    }

    syncEngine.syncNow();
  },

  saveFeature : async () => {
    const feature = get().creatingFeature;
    if (feature) {

      if (feature.id) {
        get().editFeature(feature);
      } else {
        get().createFeature(feature);
      }
    } 
  },

  saveFeatures : async (data) => {
    try {
      await backend.request('POST','/features/save_features', data);
    } catch (err) {
      console.error(err);
      showError(`Fail to save features: ${err}`);
    }

    syncEngine.syncNow();
  },

  delRestorFeature : async (id, restore) => {
    try{    
      await backend.request('POST', 
        restore ? '/features/restore_feature' : '/features/remove_feature', 
        {id});
    }catch(err){
      console.error(err);
      showError(`Fail to ${restore ? 'restore' : 'delete'} item: ${err}`);
      return;
    }

    syncEngine.syncNow(); 
  },

  setExpandedNodeIds: (nodeIds) => set({ expandedNodeIds: nodeIds }),

  showRemoved: (show) => {
    set({ removedShown: show });
    localStorage.setItem('MapLayersRemovedShown', Boolean(show));
  },
  showRemovedCtg: (show) => {
    set({ removedCtgShown: show });
    localStorage.setItem('MapLayersRemovedCtgShown', Boolean(show));
  },
}));

export { 
  type,
  categoryAlpha2Opacity,
  useFeaturesStore,
  initFeatures,
  setFeatureLegend,
  getFeatureLegend,
  setInitialFeature,
  getInitialFeature,
  setCreatingFeature,
  getCreatingFeature,
  setShowEditFeature,
  setShowEditFolder,
  changeCreatingFeature,
  cleanupCreatingFeature,
  setCreatingCategory,
  setShowCategories,
  setShowCategoryPref,
  setShowImportKML,
  showLayer,
  hideLayer,
  createCategory,
  editCategory,
  editCategoryIcon,
  delRestorCategory,
  saveFeature,
  saveFeatures,
  delRestorFeature,
  setExpandedNodeIds,
  getExpandedNodeIds,
  showRemoved,
  showRemovedCtg,
};