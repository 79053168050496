import React, { useState, useMemo } from "react";
import { TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { objectsSnap } from "../../sync-engine";
import { prefID } from "../../sync-engine/modules/Prefs";
import { saveCamerasSettings } from '../../storages/appStorage';
import Dialog from '../dialog/dialogButton';

const CameraSettings = ({ onClose }) => {
  const prefsObjects = objectsSnap('prefs');
  const settings = prefsObjects[prefID.ftp_server];
  const ftpHost = settings?.value?.host ? settings.value.host : '';
  const ftpUser = settings?.value?.user ? settings.value.user : '';
  const ftpPwd = settings?.value?.password ? settings.value.password : '';

  const [host, setHost] = useState(ftpHost);
  const [user, setUser] = useState(ftpUser);
  const [password, setPassword] = useState(ftpPwd);
  const [showPassword, setShowPassword] = useState(false);

  const canSave = useMemo(
    () => host != ftpHost || user != ftpUser || password != ftpPwd, 
    [host, user, password]);

  const onSave = () => {
    saveCamerasSettings({host, user, password});
    onClose();
  };

  return (
    <Dialog title={'Cameras Settings'} okName={'Save'} okDisabled={!canSave} onOK={onSave} onClose={onClose}>
      <div className='flexColumn'>
      <div className='centerAlignText'><b>FTP Server</b></div>
        <TextField
          variant='outlined'
          margin='normal'
          label='host'
          value={host}
          onChange={(e) => setHost(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='normal'
          label='user'
          value={user}
          onChange={(e) => setUser(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='normal'
          label='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
          }}
        />
      </div>
    </Dialog>
  );
}

export default CameraSettings;