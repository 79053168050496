import React from 'react';
import { useAppStore } from '../../storages/appStorage';
import { useLvStore } from '../../storages/lvStorage';
import CameraDrawings from './drawings/cameraDrawings';
import SensorDrawings from './drawings/sensorDrawings';
import FeatureDrawings from './drawings/featureDrawings';
import PatrolDrawings from './drawings/patrolDrawings';
import PointDrawings from './drawings/pointDrawings';
import UserDrawings from './drawings/userDrawings';

const MapDrawings = ({onPubLV}) => {
  const pagesOnMap = useAppStore(state => state.pagesOnMap);
  const patrolIdToShowPoints = useAppStore(state => state.patrolIdToShowPoints);
  const pagesOnMapWd = useLvStore(state => state.pagesOnMapWd);
  const showMapLayers = useLvStore(state => state.showMapLayers);


  const features =  onPubLV ? showMapLayers : true;
  const points =  onPubLV ? pagesOnMapWd.has('Reports Widget') : (pagesOnMap.has('points') || patrolIdToShowPoints);
  const cameras = onPubLV ? false                              : pagesOnMap.has('cameras');
  const patrols = onPubLV ? pagesOnMapWd.has('Patrols Widget') : pagesOnMap.has('patrols');
  const sensors = onPubLV ? pagesOnMapWd.has('Sensors Widget') : pagesOnMap.has('sensors');
  const users =   onPubLV ? false                              : pagesOnMap.has('users');

  return <>
    {features && <FeatureDrawings/>}
    {points  && <PointDrawings/>}
    {cameras && <CameraDrawings/>}
    {patrols && <PatrolDrawings/>}
    {sensors && <SensorDrawings/>}
    {users   && <UserDrawings/>}
  </>;
};

export default MapDrawings;