import React, { useState, useMemo, useEffect } from 'react';
import { useSyncEngStore } from '../../sync-engine';
import { escHandlers, showOkDialog } from "../../storages/appStorage";
import { useFeaturesStore, setShowCategories, setCreatingCategory, createCategory, delRestorCategory,
  setShowCategoryPref, showRemovedCtg, showLayer, 
  hideLayer } from '../../storages/featuresStorage';
import LabelCheckbox from "../labelCheckbox";
import NameDialog from "../dialog/nameDialog";
import { AddIcon, EditIcon, DelRestorIcon } from '../common';
import { TitleWithClose } from "../dialog";

const CategoriesList = ({id}) => {
  const categoriesObjects = useSyncEngStore(state => state.categoriesObjects, () => false);
  const categoriesChanges = useSyncEngStore(state => state.categoriesChanges);
  const removedCtgShown = useFeaturesStore(state => state.removedCtgShown);
  const unvisibleLayers = useFeaturesStore(state => state.unvisibleLayers);

  const [newCategoryOpen, setNewCategoryOpen] = useState(false);

  useEffect(() => {
    escHandlers.set('CategoriesList', () => setShowCategories(false));
    return () => escHandlers.delete('CategoriesList');
  }, []);

  const onSaveNewCategory = (name) => {
    createCategory({name})
  };

  const onVisibleClick = (e, id) => {
    if (e.target.checked) {
      showLayer(id);
    } else {
      hideLayer(id);
    }
  };

  const onEdit = (id) => {
    setCreatingCategory({id});
    setShowCategoryPref(true);
  };

  const onDelRestore = (category, restore) => {
    const DelRest = restore ? 'Restore' : 'Delete'
    showOkDialog({
      title: DelRest + ' category', 
      message: DelRest + ' category ' + `"${category.name}"?`, 
      onOK: () => delRestorCategory(category.id, restore)});
  };

  const getRows = () => {
    const rows = [];
    for(const id in categoriesObjects){
      const category = categoriesObjects[id]?.category;
      if (category && (removedCtgShown || !category.rm)) {
        const row = <tr key={id} className='categoryRow'>
          <td>
            <div className='paddingHoriz spaceBtwCentered' 
              style={category.rm ? {textDecoration: 'line-through'} : {}}>
              {category.name}
              <div className='flexAlignCenter'>
                {!category.rm && <>
                <LabelCheckbox 
                  label='visible'
                  title='The Layer is always visible'
                  checked={!unvisibleLayers.has(id)}
                  onClick={(e) => onVisibleClick(e, id)}/>
                <EditIcon onClick={() => onEdit(id)}/>
                </>}
                <DelRestorIcon
                  rm={category.rm} 
                  enabled={true}
                  onDelete={() => onDelRestore(category, false)}
                  onRestore={() => onDelRestore(category, true)}/>
              </div>
            </div></td>
        </tr>;
        rows.push(row);
      }
    }
    return rows;

  };

  const rows = useMemo(
    () => getRows(), [categoriesChanges, removedCtgShown, unvisibleLayers]);

  return <>
    {newCategoryOpen && <NameDialog title={'New Category'} onSave={onSaveNewCategory} 
        onClose={()=>setNewCategoryOpen(false)}/>}
    <div className='categoryList'>
      <TitleWithClose onClose={() => setShowCategories(false)}>
        Categories
      </TitleWithClose>
      <div className='categoryHeader'>
        <LabelCheckbox
            label='removed'
            title='Show/Hide removed Layers'
            checked={removedCtgShown}
            onClick={(e) => showRemovedCtg(e.target.checked)}/>
        <AddIcon titleAccess='Add Category'
          onClick={() => setNewCategoryOpen(true)}/>
      </div>
      <div className='yScroll'>
        <table className='fullWidth bgWhite'><tbody>
          {rows}
        </tbody></table>
      </div>
    </div>
  </>;
};

export default CategoriesList;