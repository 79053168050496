export default class Module{
    constructor() {
        //super();
        this.objects = {};
    }

    clear(){
        // let deleted = this.objects;
        this.objects = {}
    }
    
    isNotEmpty(object){
        for(const key in object){
            return true;
        }
        return false;
    }

    onStandardPropChanges(propName, listName, changes, results){
        let propChanges = changes[propName];
        if(!propChanges) return;
        propChanges.forEach(prop => {
            // search existed object
            let obj = this.objects[prop.id]; 
            if(!obj) return;
            results[prop.id] = obj;
            // Append prop value
            obj[listName].push(prop);
        });
    }

}
