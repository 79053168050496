import { useState, useMemo, useEffect, } from 'react';
import { useSyncEngStore, objectsSnap } from '../../../sync-engine';
import { useAppStore, setSelected } from "../../../storages/appStorage";
import { useSensorsStore, setSensorMarkers, getSensorMarkers, addSensorTrackColor, 
  saveSensorTrackColors, setColorIndex, 
  getColorIndex } from '../../../storages/sensorsStorage';
import { useMapStore, removeDrawingsFromMap, clusterMarkers, 
  unClusterMarkers } from '../../../storages/mapStorage';
import { PointDrawing, LineDrawing } from './mapObjects';
import Utils from '../../../Utils';

const colors = ['#0000FF', '#00A035', '#FF00F0', '#CEA200', '#9700FF'];
const dotFileNames = {
  '#0000FF': 'dot_0.svg',
  '#00A035': 'dot_1.svg',
  '#FF00F0': 'dot_2.svg',
  '#CEA200': 'dot_3.svg',
  '#9700FF': 'dot_4.svg'};

const SensorDrawings = () => {
  const map = useMapStore(state => state.map);
  const sensorsChanges = useSyncEngStore(state => state.sensorsChanges);
  const selectedIds = useAppStore(state => state.selectedIds);
  const sensorTracks = useSensorsStore(state => state.sensorTracks);
  const sensorIcon = useAppStore(state => state.sensorIcon);
  const sensorSelIcon = useAppStore(state => state.sensorSelIcon);

  const [isShown, setShown] = useState(false);

  const {selectedId, prevSelectedId} = useMemo(
    () => Utils.getSelectedIds('sensors', selectedIds), [selectedIds]);

  const getIcones = (sensor) => {
    const path = window.location.origin;
    const size =       new window.google.maps.Size(46, 46);
    const selectSize = new window.google.maps.Size(80, 80);
    switch (sensor.category) {
      case 'aslan': return {
        icon: {url: path + '/images/map_sensor_aslan_s.svg'},
        selectedIcon: {url: path + '/images/map_sensor_aslan.svg'}};
      case 'sensorbee': return {
        icon: {url: path + '/images/map_sensor_bee_s.svg'},
        selectedIcon: {url: path + '/images/map_sensor_bee.svg'}};
      default: return {
        icon: {url: sensorIcon.url, scaledSize: size},
        selectedIcon: {url: sensorSelIcon.url, scaledSize: selectSize}};
    }
  };

  const clearObjTrack = (obj) => {
    if (obj.trackDrawing) {
      obj.trackDrawing.remove();
      delete obj.trackDrawing;
    }
    if (obj.trackListener) {
      obj.trackListener.remove();
      delete obj.trackListener;
    }
    if (obj.trackDots) {
      obj.trackDots.forEach(dot => dot.remove())
      delete obj.trackDots;
    }
  };

  const getLast2kmTrack = (track) => {
    if (!track || track.length < 2) return null;

    track.reverse();
    const last2km = [];
    last2km.push(track[0]); // 1st point

    let dist = 0;
    for (let i = 1; i < track.length; i++) {
      dist += Utils.degrees2km(track[i-1], track[i]);
      if (dist > 2) break;
      last2km.push(track[i]);
    }
    
    if(last2km.length == 1) last2km.push(track[1]); // at least 2 points regardless of distance
    last2km.reverse();
    return last2km;
  };

  const getWholeTrack = (track) => track && track.length > 1 ? track : null;

  const getTrackDot = (trackPoint, name, color, selected, listener) => {

    const title = 
`${name}
${Utils.getDayTime(trackPoint.time)}`;

    const fileName = dotFileNames[color] ? dotFileNames[color] : 'dot_black.svg';
    const anchor = new window.google.maps.Point(5, 5);
    const path = window.location.origin;

    const dot = new PointDrawing({
      icon: {url: path + '/images/'+ fileName, anchor: anchor},
      selectedIcon: {url: path + '/images/dot_red.svg', anchor: anchor}});
    dot.update(map, [trackPoint.lng, trackPoint.lat], 
      selected, {title: title});

    window.google.maps.event.addListener(dot.mapobj, 
        'click', listener);
    return dot;
  };

  const getNextColor = () => {
    const colorInd = getColorIndex();
    const color = colors[colorInd];
    setColorIndex((colorInd+1) % 5);
    return color;
  };

  const checkAndUpdateObjDrawing = (obj, map, mapMarkers) => {
    if (obj && obj.sensor && !obj.sensor.rm && obj.location) {
      updateObjDrawing(obj, map, mapMarkers);
    }
  };

  const updateObjDrawing = (obj, map, mapMarkers) => {
    const sensor = obj.sensor;
    const id = sensor.id;
    const cliclListener = () => setSelected('sensors', id);
    const icons = getIcones(sensor);
    if (!obj.drawing) {
      obj.drawing = new PointDrawing(icons);
    }
    const options = {title: sensor.name, pointIcon: icons};
    obj.drawing.update(map, obj.location, 
      selectedId == id, options);
    if (obj.listener) obj.listener.remove();
    obj.listener = window.google.maps.event.addListener(obj.drawing.mapobj, 
      'click', cliclListener);

    mapMarkers.set(String(obj.id), {
      listener:     obj.listener, 
      drawing:      obj.drawing,
    });
  };

  const removeSensorDrawingsFromMap = (drawings) => { // specific for sensors
    drawings.forEach((obj) => clearObjTrack(obj));
    removeDrawingsFromMap(drawings);
  };

  const updateTrack = (obj, sensorMarker) => {
    if (obj?.sensor) {
      const sensor = obj.sensor;
      const id = sensor.id;
      const cliclListener = () => setSelected('sensors', id);
      const selected = selectedId == id;
      // track
      const currentStrokeColor = obj.trackDrawing 
        ? obj.trackDrawing.getOptions(false).strokeColor 
        : null;

      // remove old track
      clearObjTrack(obj);

      const trackPrefs = sensorTracks.get(String(id));
      if (trackPrefs) {
        const trackPoints = obj.data.filter(point => 
          Utils.isNumber(point.lng) && Utils.isNumber(point.lat) && 
          point.lng != 0 && point.lat != 0);

        const track2show = trackPrefs.type == '2km' ? getLast2kmTrack(trackPoints) : getWholeTrack(trackPoints);

        if (trackPrefs.color) getNextColor(); // to increase colorInd

        const strokeColor = currentStrokeColor 
        ? currentStrokeColor 
        : trackPrefs.color ? trackPrefs.color : getNextColor();

        if (strokeColor != trackPrefs.color) {
          addSensorTrackColor(String(sensor.id), strokeColor);
        }

        if (track2show) {
          if (trackPrefs.lines) {
            obj.trackDrawing = new LineDrawing({strokeColor: strokeColor});
            obj.trackDrawing.update(map, track2show, selected);
            obj.trackListener = window.google.maps.event.addListener(
              obj.trackDrawing.mapobj, 'click', cliclListener);
          }

          if (trackPrefs.dots) {
            obj.trackDots = track2show.map(trackPoint => 
              getTrackDot(trackPoint, sensor.name, strokeColor, selected, cliclListener));
          }
        }
      }

      if (sensorMarker) {
        sensorMarker.trackDrawing  = obj.trackDrawing;
        sensorMarker.trackListener = obj.trackListener;
        sensorMarker.trackDots     = obj.trackDots;
      }
    }
  };

  const updateTracks = (sensorMarkers) => {
    if (map) {
      const objects = objectsSnap('sensors');
      for (const id in objects) {
        updateTrack(objects[id], sensorMarkers.get(id));
      }
    }
  };

  const showOnMap = () => {
    if (map) {
      const objects = objectsSnap('sensors');
      const oldMarkers = getSensorMarkers();
      const newMarkers = new Map();

      for(const id in objects){
        const obj = objects[id];
        if (obj.sensor && !obj.sensor.rm && obj.location) {
          oldMarkers.delete(id);
          updateObjDrawing(obj, map, newMarkers);
        } else {
          if (obj.listener) obj.listener.remove();
          if (obj.drawing) obj.drawing.remove();
        }
      }
      setSensorMarkers(newMarkers);
      unClusterMarkers(oldMarkers);
      removeSensorDrawingsFromMap(oldMarkers); // the rest of oldMarkers
      clusterMarkers(newMarkers);

      updateTracks(newMarkers)
    }
  }

  const onPageShow = () => {
    setShown(true);
    showOnMap();
  }

  const onPageHide = () => {
    setShown(false);
    const allSensorMarkers = getSensorMarkers();

    unClusterMarkers(allSensorMarkers);
    removeSensorDrawingsFromMap(allSensorMarkers);
    setSensorMarkers(new Map());
    saveSensorTrackColors();
  }
  useEffect(() => {
    onPageShow();
    return onPageHide;
  }, []);

  const onChanges = () => {
    if (isShown) showOnMap();
  }
  useMemo(onChanges, [map, sensorsChanges]);
  useEffect(onChanges, [sensorIcon, sensorSelIcon]);

  const onSelectedChange = () => {
    if (isShown) {
      const snap = objectsSnap('sensors');
      if (!snap) return;  // can be at Live View
      const allSensorMarkers = getSensorMarkers();
      const prevObj = snap[prevSelectedId];
      const selectObj = snap[selectedId];
      checkAndUpdateObjDrawing(prevObj, null, allSensorMarkers);
      checkAndUpdateObjDrawing(selectObj, null, allSensorMarkers);
    }
  };
  useEffect(() => {
    onSelectedChange();
  }, [selectedIds]);

  const onTracksChange = () => {
    if (isShown) {
      const allSensorMarkers = getSensorMarkers();
      updateTracks(allSensorMarkers);
    }
  };
  useMemo(onTracksChange, [sensorTracks]);
  
  return(null); // no html render, draw on map directly instead
}

export default SensorDrawings;