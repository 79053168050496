import React, { useState } from 'react';
import { TextField } from '@mui/material';
import DialogButton from '../dialog/dialogButton';

const ColorDialog = ({title, val, onSave, onClose}) => {
  const [color, setColor] = useState(val);

  const onInputColor = (e) => {
    setColor(e.target.value);
  };

  const saveAndClose = () => {
    onSave(color)
    onClose();
  };

  const onCloseButton = (e, reason) => {
    if (reason != 'backdropClick') onClose();
  }

  const colorValid = /^#[0-9A-F]{6}$/i.test(color); // for alpha: ([0-9A-F]{2}){3,4}

  return <DialogButton title={title} okName={'Save'} okDisabled={!colorValid} 
      onOK={saveAndClose} onClose={onCloseButton}>

    <div className='flexElem'>
      <div className='colorDlgText'>
        <TextField variant="outlined" size="small" label="Color"
          inputProps={{ maxLength: 9 }} value={color} onChange={onInputColor}/>
      </div>
      <input className='colorDlgInput' type="color" value={color} 
        onChange={onInputColor}/>
    </div>
  </DialogButton>;
};

export default ColorDialog;