import React, { useState, useEffect, useMemo, } from "react";
import { CircularProgress } from "@mui/material";
import LctIcon from '@mui/icons-material/MyLocation';
import ReportsIcon from '@mui/icons-material/LocationOn';
import RvTableExpand from '../components/rvTable/rvTableExpand'
import FullProfile from '../components/users/fullProfile';
import EditUser from "../components/users/EditUser" 
import UserExpElement from '../components/users/userExpElement';
import { useSyncEngStore} from "../sync-engine";
import { useAppStore, setSelected, showPageOnMap } from "../storages/appStorage";
import { useMapStore } from '../storages/mapStorage';
import { useUsersStore, initUsers, setAddUserState, setCreatingUser, setOrder, 
  showRemoved } from '../storages/usersStorage';
import DrawerLR from '../components/drawerLeftRight';
import LabelCheckbox from "../components/labelCheckbox";
import { AddIcon, LocationIcon, SortIcon } from '../components/common';
import Utils from '../Utils';

const RepNumber = <div style={{display: "flex"}}>#<ReportsIcon/></div>;

const UsersView = () => {
  const map = useMapStore(state => state.map);
  const usersObjects = useSyncEngStore(state => state.usersObjects, () => false);
  const usersChanges = useSyncEngStore(state => state.usersChanges);
  const rolesObjects = useSyncEngStore(state => state.rolesObjects, () => false);
  const rolesChanges = useSyncEngStore(state => state.rolesChanges);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const selectedIds =  useAppStore(state => state.selectedIds);
  const pagesOnMap =   useAppStore(state => state.pagesOnMap);
  const showEditUser =    useUsersStore(state => state.showEditUser);
  const showFullProfile = useUsersStore(state => state.showFullProfile);
  const orderBy =         useUsersStore(state => state.orderBy);
  const orderAsc =        useUsersStore(state => state.orderAsc);
  const removedShown =    useUsersStore(state => state.removedShown);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    initUsers();
  }, []);

  const usersLoaded = useMemo(() => {
    return loadedMdProps.has('user');
  }, [loadedMdProps]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('users', selectedIds), [selectedIds]);

  const onAddUser = () => {
    setCreatingUser({});
    setAddUserState();
  }

  const roleIdToName = (roleId) => 
    (rolesObjects && rolesObjects[roleId]?.name) ? rolesObjects[roleId].name : 'Unknown';
  
  const locateItem = (drawing) => drawing.locate(map);

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push(<div className='sortHeader'>ID{getSortIcon(0)}</div>);
    header.push(<div className='sortHeader'>Name{getSortIcon(1)}</div>);
    header.push(<div className='sortHeader'>Group{getSortIcon(2)}</div>);
    header.push(<div className='sortHeader'>Status{getSortIcon(3)}</div>);
    header.push(<div className='sortHeader'>Last seen{getSortIcon(4)}</div>);
    header.push(<div className='sortHeader'>{RepNumber}{getSortIcon(5)}</div>);
    header.push(<LctIcon/>);
    header.push('');   // hidden value (idInd={7})
    return header;
  };

  const header = useMemo(() => getHeader(), 
    [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(usersObjects) {
        for(const id in usersObjects){
          const obj = usersObjects[id];
          const user = obj.user;

          if (user && (removedShown || !user.rm))
          {
            const statusText = obj.loginState ? 'Online' : 'Offline';
            const lStAccess = obj.loginState?.access ? obj.loginState.access : null;
            const usrPoints = obj.points;
            const canLocate = pagesOnMap.has('users') && map && user && !user.rm && 
              obj.state?.lng && obj.state.lat;
            const locator = (<LocationIcon enabled={canLocate} 
              onClick={canLocate ? () => locateItem(obj.drawing) : null}></LocationIcon>);

            const vals = [];
            vals.push(obj.id);
            vals.push(user.name);
            vals.push(roleIdToName(user.role_id));
            vals.push(statusText);
            vals.push(lStAccess);
            vals.push(usrPoints?.size);
            vals.push(locator);
            vals.push(id); // hidden id value (idInd={7})
            vals.push(user.rm); // hidden rm value (row[8])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- users count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [
        <div className='textCell'>{row[0]}</div>, // obj.id
        <div className='textCell' style={row[8] // user.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[1]}
        </div>,  // user.name
        row[2],  // role Name
        row[3],  // statusText Online-Offline
        row[4] ? new Date(row[4]).toLocaleString() : '',  // last seen
        row[5] > 0 ? row[5] : '', // Reports number
        row[6], // locator
        row[7]], // id
        ['', '', '', '', '', '', '', row[7]] // expRow
      ]);

      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });

    return [];
  };

  useMemo(getRows, 
    [map, usersChanges, rolesChanges, orderBy, orderAsc, removedShown, pagesOnMap]);

  const getExpEl = () => {
    if (selectedId && usersObjects) {
      const obj = usersObjects[selectedId];
      if (obj) {
        return <UserExpElement obj={obj}/>
      }
    }

    return <div>Fail to get user data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 422; //ID+Group+Status+...
    const freeSpace = listWidth - fixedColumns;
    const name = freeSpace > 10 ? freeSpace : 10;
    switch (index) {
      case 0: return 45;  // ID
      case 1: return name;  // Name
      case 2: return 90;  // Group
      case 3: return 80;  // Status
      case 4: return 97;  // Last seen
      case 5: return 65;  // Reports number
      case 6: return 45;  // LctIcon
    }
    return 0;
  }

  return (<>
    <DrawerLR open={showFullProfile} width={530} body={
      showFullProfile ? <FullProfile/> : <></>
    }/>

    <DrawerLR open={showEditUser} width={530} body={
      showEditUser ? <EditUser/> : <></>
    }/>

    <div className={'fullHeight flexColumn' + (showFullProfile || showEditUser ? ' disabled' : '')}>
      <div className='rightPanHeader btnsHeader'>
        <div>
        <LabelCheckbox
          label='removed'
          title='Show/Hide removed Users'
          checked={removedShown}
          onClick={(e) => showRemoved(e.target.checked)}/>
        <LabelCheckbox
          label='Show on Map'
          title='Show/Hide Users on Map'
          checked={pagesOnMap.has('users')}
          onClick={(e) => showPageOnMap('users', e.target.checked)}/>
        </div>
        {usersLoaded && <AddIcon 
          titleAccess='Add User'
          onClick={onAddUser}/>}
      </div>
      {usersLoaded 
        ? <RvTableExpand rows={rows} header={header} idInd={7} 
            clickColmns={[0,1,2,3,4,5]} selectedId={selectedId} 
            orderBy={orderBy} orderAsc={orderAsc}
            setSelectedId={(id) => setSelected('users', id)}
            expElHeight={185}
            getExpEl={getExpEl}
            getColumnWidth={getColumnWidth}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </div>
  </>);
}

export default UsersView;