import React, { useState } from 'react';
import { TextField } from '@mui/material';
import DialogButton from './dialogButton';

const TextDialog = ({title, val, onSave, onClose}) => {
  const [txtVal, setTxtVal] = useState(val);

  const onInputText = (e) => {
    setTxtVal(e.target.value);
  };

  const saveAndClose = () => {
    onSave(txtVal)
    onClose();
  };

  const canSave = txtVal != val;

  return <DialogButton title={title} okName={'Save'} okDisabled={!canSave}
      onOK={saveAndClose} onClose={onClose}>

    <div className='textDlgText'>
        <TextField variant="outlined" fullWidth={true} size="small" label="Description"
          value={txtVal} multiline rows={5} onChange={onInputText}/>
    </div>
  </DialogButton>;
};

export default TextDialog;