import Module from "../Module";

//--------------------------------------------------------------------------------
// MODULE: Categories
class  Categories extends Module{
  constructor() {
    super();
    // TABLES LIST
    this.props = {
      category: { syncUrl: '/features/load_categories' },
    }
  }

  async onDataChanges (changes) {
    return new Promise((resolve) => {
      let results = {};
      if(changes.category) this.onCategoryChanges (changes.category, results);
      resolve(this.isNotEmpty(results) ? results : null);
    });
  }

  onCategoryChanges(changes, results){
    changes.forEach(prop => {
      let obj  = this.getObject(prop.id); // id must exist
      results[prop.id] = obj;
      obj.category = prop;
    });
  }
    
  // Search/create object
  getObject(id){
    // search existed object
    let obj = this.objects[id]; 
    // Inset new object if not found
    if(!obj){
      obj = {
        id:     id,
        category: null
      };
      this.objects[id] = obj;
    }
    return obj;
  }
}

export default Categories;
