import React, { useState } from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';
import { useLvStore, setShowMapLayers } from "../../../../storages/lvStorage";
import DialogButton from '../../../dialog/dialogButton';

const MapSettings = ({onClose}) => {
  const showMapLayers = useLvStore(state => state.showMapLayers);

  const [checked, setChecked] = useState(showMapLayers);

  const saveAndClose = () => {
    setShowMapLayers(checked);
    onClose();
  };

  return <DialogButton title={'Map Widget Settings'} okName={'Save'}
      onOK={saveAndClose} onClose={onClose}>
    <FormControlLabel label={'Show map layers'} control={
      <Checkbox checked={checked} onChange={() => setChecked(!checked)} />}/>
  </DialogButton>;
};

export default MapSettings;