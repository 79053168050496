import React, { useState, useMemo, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useSyncEngStore } from '../sync-engine';
import {  useAppStore, setSelected, showOkDialog } from "../storages/appStorage";
import { useBleStore, initBle, setOrder, 
  showRemoved } from '../storages/bleStorage';
import RvTableExpand from "../components/rvTable/rvTableExpand";
import LabelCheckbox from "../components/labelCheckbox";
import { SortIcon, DelRestorIcon } from "../components/common";
import Utils from "../Utils" 

const ExpElement = ({ble}) => {

  const onDeleteBle = (ble) => {
    showOkDialog({
      title: 'Delete BLE device', 
      message: `Delete/Restore ble devices is under construction`, 
      onOK: null});
  };

  const onRestoreBle = (ble) => {
    showOkDialog({
      title: 'Restore BLE device', 
      message: `Delete/Restore ble devices is under construction`, 
      onOK: null});
  };

  return (
    <div className='paddingHoriz'>
      <div className="leftAlignText">
        <i className='paddingRight'>Description:</i>{ble.descr}
      </div>
      <div className='flexJustEnd'>
        <DelRestorIcon 
          rm={ble.rm} 
          enabled={true}
          onDelete={() => onDeleteBle(ble)}
          onRestore={() => onRestoreBle(ble)}/>
      </div>
    </div>
  );
};

const BleView = () => {
  const bleObjects = useSyncEngStore(state => state.bleObjects, () => false);
  const bleChanges = useSyncEngStore(state => state.bleChanges);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const selectedIds =  useAppStore(state => state.selectedIds);
  const orderBy =       useBleStore(state => state.orderBy);
  const orderAsc =      useBleStore(state => state.orderAsc);
  const removedShown =  useBleStore(state => state.removedShown);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    initBle();
  }, []);

  const bleLoaded = useMemo(() => {
    return loadedMdProps.has('ble');
  }, [loadedMdProps]);

  const {selectedId} = useMemo(
    () => Utils.getSelectedIds('ble', selectedIds), [selectedIds]);

  const getSortIcon = (ind) => {
    const active = orderBy == ind ? 1 : 0;
    const asc = active && !orderAsc ? 0 : 1;  
    return <SortIcon active={active} asc={asc} onClick={()=>setOrder(ind)} />;
  };

  const getHeader = () => {
    const header = [];
    header.push(<div className='sortHeader'>Id{getSortIcon(0)}</div>);
    header.push(<div className='sortHeader'>Mac{getSortIcon(1)}</div>);
    header.push(<div className='sortHeader'>Name{getSortIcon(2)}</div>);
    header.push('');   // hidden value (idInd={3})
    return header;
  };

  const header = useMemo(getHeader, [orderBy, orderAsc]);

  const buildTableRows = async () => {
    return new Promise((resolve) => {
      const forSort = [];
      if(bleObjects) {
        for(const id in bleObjects){
          const obj = bleObjects[id];
          const ble = obj.ble;

          if (ble && (removedShown || !ble.rm))
          {
            const vals = [];
            vals.push(id);
            vals.push(ble.mac);
            vals.push(ble.name);
            vals.push(id); // hidden id value (idInd={3})
            vals.push(ble.rm); // hidden rm value (row[4])
            forSort.push(vals);
          }
        }
      }
      console.log(`--- ble count ${forSort.length}`);
      forSort.sort( (a, b) => orderAsc 
        ? Utils.ascComparator(a[orderBy], b[orderBy]) 
        : -Utils.ascComparator(a[orderBy], b[orderBy]) );

      const rows = forSort.flatMap((row) => [
        [row[0], // obj.id
        row[1],  // ble.mac
        <div className='textCell' style={row[4] // ble.rm
          ? {textDecoration: 'line-through'} : {}}>
          {row[2]}
        </div>,  // ble.name
        row[3]], // id
        ['', '', '', row[3]] // expRow
      ]);
      resolve(rows);
    });
  };

  const getRows = () => {
    console.log(`-- start rows create ${Date.now()}`);
    buildTableRows().then((rows) => {
      console.log(`-- ${rows.length} rows created ${Date.now()}`);
      setRows(rows);
    });

    return [];
  };

  useMemo(getRows, [bleChanges, orderBy, orderAsc, removedShown]);

  const getExpEl = () => {
    if (selectedId && bleObjects) {
      const obj = bleObjects[selectedId];
      const ble = obj?.ble;
      if (ble) {
        return <ExpElement ble={ble}/>
      }
    }

    return <div>Fail to get BLE Device data</div>;
  };

  const getColumnWidth = ({index, listWidth}) => {
    const fixedColumns = 250; // Id + Mac
    const freeSpace = listWidth - fixedColumns;
    const name = freeSpace > 10 ? freeSpace : 10;
    switch (index) {
      case 0: return 100;  // Id
      case 1: return 150;  // Mac
      case 2: return name;  // Name
    }
    return 0;
  };

  return (<>          
    <div className='rightPanHeader flexElem'>
        <LabelCheckbox
          label='removed'
          title='Show/Hide removed BLE devices'
          checked={removedShown}
          onClick={(e) => showRemoved(e.target.checked)}/>
      </div>
      {bleLoaded 
        ? <RvTableExpand rows={rows} header={header} 
          idInd={3} clickColmns={[0,1,2]} selectedId={selectedId} 
          orderBy={orderBy} orderAsc={orderAsc}
          setSelectedId={(id) => setSelected('ble', id)}
          rowHeight={32}
          expElHeight={80}
          getExpEl={getExpEl}
          getColumnWidth={getColumnWidth}/>
        : <div className="circularProgress"><CircularProgress/></div>}
    </>);

}    

export default BleView;