import React, { useMemo } from "react";
import { useSyncEngStore } from '../../sync-engine';

const PERMISSIONS = [
  'DASHBOARD',
  'ROLES',
  'USERS',
  'POINTS',
  'PATROLS',
  'SENSORS',
  'CAMERAS',
  'BLE',
  'HISTORY'
];

const RolesTable = () => {
  const rolesObjects = useSyncEngStore(state => state.rolesObjects, () => false);
  const rolesChanges = useSyncEngStore(state => state.rolesChanges);

  const getHeaders = () => {
    const headers = [];
    PERMISSIONS.forEach( name => {
      headers.push(<th className='r_perm' key={name}>{name}</th>)});

    return headers;
  };
  const headers = useMemo(getHeaders, [PERMISSIONS]);

  const getRaws = () => {
    const rows = [];
    for (const id in rolesObjects) {
      const role = rolesObjects[id];
      if (!role.rm) {
        const cols = [];
        PERMISSIONS.forEach (name => {
          const val = role.permissions[name];
          cols.push(<td key={name}>{val ? 'X': ''}</td>);
        })

        rows.push(<tr key={role.id}><td>{id}</td><td>{role.name}</td>{cols}</tr>);
      }
    }
    return rows;
  };
  const rows = useMemo(getRaws, [rolesChanges]);

  return <table className='r_table'>
      <thead className='r_header'>
        <tr><th>id</th><th>role name</th>{headers}</tr>
      </thead>
      <tbody className='r_body'>
        {rows}
      </tbody>
    </table>;
};

export default RolesTable;