import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/styles';
import { Icon, CircularProgress } from '@mui/material';
import { useAppStore, showPageOnMap } from "../storages/appStorage";
import PointsTable from '../components/points/pointsTable';
import LabelCheckbox from "../components/labelCheckbox";
import { usePointsStore, initPoints, setFilters, showRemoved } from '../storages/pointsStorage';

const FilterIcon = styled(Icon)({
  height: '1em', 
  width: '0.69em',
  marginRight: '0.1em'
});

const PointsView = () => {
  const pagesOnMap = useAppStore(state => state.pagesOnMap);
  const loadedMdProps = useAppStore(state => state.loadedMdProps);
  const filters = usePointsStore(state => state.filters);
  const removedShown = usePointsStore(state => state.removedShown);

  useEffect(() => {
    initPoints();
  }, []);

  const pointsLoaded = useMemo(() => {
    return loadedMdProps.has('point');
  }, [loadedMdProps]);

  const getFilterPath = (category) => {
    const path = window.location.origin + '/images/';
    const selected = filters.has(category);

    switch (category) {
      case 'alert': return path + (selected ? 'alert_selected.svg' : 'alert_unselected.svg');
      case 'animal': return path + (selected ? 'animal_selected.svg' : 'animal_unselected.svg');
      case 'info': return path + (selected ? 'info_selcted.svg' : 'info_unselcted.svg');
      default: return '';
    }
  };

  const getFilterImg = (category) => 
    <img src={getFilterPath(category)} alt={category} />;

  return (<>
    <div className='rightPanHeader flexAlignCenter'>
      <LabelCheckbox
        label='removed'
        title='Show/Hide removed Reports'
        checked={removedShown}
        onClick={(e) => showRemoved(e.target.checked)}/>
      <FilterIcon 
        title='Show/Hide animal Reports'
        onClick={() => setFilters('animal')}>
        {getFilterImg('animal')}
      </FilterIcon>
      <FilterIcon
        title='Show/Hide info Reports'
        onClick={() => setFilters('info')}>
        {getFilterImg('info')}
      </FilterIcon>
      <FilterIcon
        title='Show/Hide alert Reports'
        onClick={() => setFilters('alert')}>
        {getFilterImg('alert')}
      </FilterIcon>
      <div className='paddingLeft'>
        <LabelCheckbox
          label='Show on Map'
          title='Show/Hide Reports on Map'
          checked={pagesOnMap.has('points')}
          onClick={(e) => showPageOnMap('points', e.target.checked)}/>
      </div>
    </div>
    {pointsLoaded 
      ? <PointsTable/> 
      : <div className="circularProgress"><CircularProgress/></div>}
  </>);
}

export default PointsView;