import React, { useMemo, useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { setAppInitialized, restoreAppCachedState } from "./storages/appStorage";
import { 
  useWorkspaceStore, restoreWSCachedState, loadWorkspaces, 
  loadWorkspaceInfo, loadLoginInfo } from './storages/workspaceStorage';
import { initFeatures } from './storages/featuresStorage';
import App from './App';
import NotFoundView from './views/notFoundView';
import LiveView from './components/liveView/liveView';

const AppRouter = () => {
  const workspaces = useWorkspaceStore(state => state.workspaces);

  const init = async () => {
    console.warn("***** INIT start");
    restoreWSCachedState();
    initFeatures();
    await loadWorkspaces();
    await loadWorkspaceInfo();
    await loadLoginInfo();
    restoreAppCachedState();
    console.warn("***** INIT end");
    setAppInitialized();
  }

  useEffect(() => {
    init();
  }, []);

  const getLvRoutes = () => Object.keys(workspaces ? workspaces : {}).map(
    wsId => <Route key={wsId} path={`/live-view/${wsId}`} element={<LiveView wsId={wsId} />} />);

  const lvRoutes = useMemo(getLvRoutes, [workspaces]);

  return <HashRouter>
    <Routes>
      <Route path="/" element={<App/>}/>
      <Route path="*" element={<NotFoundView/>}/>
      {lvRoutes}
    </Routes>
  </HashRouter>;
}

export default AppRouter;